import Api from '@/libraries/api';
import CreditsServices from '@/modules/settings/services/credits.services';
import * as types from './mutation_types';
import AutomationServices from '../services/automations.services';
import { backendDateFormat, dateDiff } from '../../../core/services/dates/dates.service';

export const requestRoles = ({ commit }) => commit(types.GET_ROLES);
export const receiveRolesSuccess = ({ commit }, data) => commit(types.SET_ROLES, data);
export const receiveRolesError = ({ commit }, error) => commit(types.GET_ROLES_ERROR, error);
export const fetchRoles = ({ dispatch }) => {
  dispatch('requestRoles');
  Api.get('roles')
    .then(({ data }) => { dispatch('receiveRolesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveRolesError', response); });
};

export const requestUser = ({ commit }) => commit(types.GET_USER);
export const receiveUserSuccess = ({ commit }, data) => commit(types.SET_USER, data);
export const receiveUserError = ({ commit }, error) => commit(types.GET_USER_ERROR, error);
export const fetchUser = ({ dispatch }, userId) => {
  dispatch('requestUser');
  Api.get(`users/${userId}`)
    .then(({ data }) => { dispatch('receiveUserSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUserError', response); });
};

export const requestCreateUser = ({ commit }) => commit(types.GET_CREATE_USER);
export const receiveCreateUserSuccess = ({ commit }, data) => commit(types.SET_CREATE_USER, data);
export const receiveCreateUserError = ({ commit }, error) => commit(types.GET_CREATE_USER_ERROR, error);
export const fetchCreateUser = ({ dispatch }, userData) => {
  dispatch('requestCreateUser');
  Api.postFile('users', userData)
    .then(({ data }) => {
      dispatch('receiveCreateUserSuccess', data);
      userData.append('user_id', data.result.data[0].user_id);
      dispatch('fetchCreateUserAvatar', userData);
      $('#createNewUser').modal('hide');
    })
    .catch(({ response }) => { dispatch('receiveCreateUserError', response); });
};

export const requestUpdateUser = ({ commit }) => commit(types.GET_UPDATE_USER);
export const receiveUpdateUserSuccess = ({ commit }, data) => commit(types.SET_UPDATE_USER, data);
export const receiveUpdateUserError = ({ commit }, error) => commit(types.GET_UPDATE_USER_ERROR, error);
export const fetchUpdateUser = ({ dispatch }, userData) => {
  dispatch('requestUpdateUser');
  Api.patch(`users/${userData.user_id}`, userData)
    .then(({ data }) => {
      dispatch('receiveUpdateUserSuccess', data);
      if (userData.image && typeof userData.image === 'object') {
        const formData = new FormData();
        formData.append('user_id', userData.user_id);
        formData.append('image', userData.image);
        dispatch('fetchCreateUserAvatar', formData);
      }
      $('#updateUser').modal('hide');
    })
    .catch(({ response }) => { dispatch('receiveUpdateUserError', response); });
};

export const requestCreateUserAvatar = ({ commit }) => commit(types.GET_CREATE_USER_AVATAR);
export const receiveCreateUserAvatarSuccess = ({ commit }, data) => commit(types.SET_CREATE_USER_AVATAR, data);
export const receiveCreateUserAvatarError = ({ commit }, error) => commit(types.GET_CREATE_USER_AVATAR_ERROR, error);
export const fetchCreateUserAvatar = ({ dispatch }, userData) => {
  dispatch('requestCreateUserAvatar');
  Api.postFile('users/avatar', userData)
    .then(({ data }) => { dispatch('receiveCreateUserAvatarSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateUserAvatarError', response); });
};

export const requestCreateSignature = ({ commit }) => commit(types.GET_CREATE_SIGNATURE);
export const receiveCreateSignatureSuccess = ({ commit }, data) => commit(types.SET_CREATE_SIGNATURE, data);
export const receiveCreateSignatureError = ({ commit }, error) => commit(types.GET_CREATE_SIGNATURE_ERROR, error);
export const fetchCreateSignature = ({ dispatch }, userData) => {
  dispatch('requestCreateSignature');
  Api.postFile('users/signature', userData)
    .then(({ data }) => { dispatch('receiveCreateSignatureSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateSignatureError', response); });
};

export const requestUpdateSignature = ({ commit }) => commit(types.GET_UPDATE_SIGNATURE);
export const receiveUpdateSignatureSuccess = ({ commit }, data) => commit(types.SET_UPDATE_SIGNATURE, data);
export const receiveUpdateSignatureError = ({ commit }, error) => commit(types.GET_UPDATE_SIGNATURE_ERROR, error);
export const fetchUpdateSignature = ({ dispatch }, userData) => {
  dispatch('requestUpdateSignature');
  Api.patch('users/signature', userData)
    .then(({ data }) => { dispatch('receiveUpdateSignatureSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateSignatureError', response); });
};


export const requestSocialMedia = ({ commit }) => commit(types.GET_SOCIAL_MEDIA);
export const receiveSocialMediaSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA, data);
export const receiveSocialMediaError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ERROR, error);

export const fetchSocialMedia = ({ dispatch }) => {
  dispatch('requestSocialMedia');
  Api.get('social_medias')
    .then(({ data }) => { dispatch('receiveSocialMediaSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaError', response); });
};

export const requestSocialMediaAccounts = ({ commit }) => commit(types.GET_SOCIAL_MEDIA_ACCOUNTS);
export const receiveSocialMediaAccountsSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_ACCOUNTS, data);
export const receiveSocialMediaAccountsError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ACCOUNTS_ERROR, error);

export const fetchSocialMediaAccounts = ({ dispatch }, payload) => {
  dispatch('requestSocialMediaAccounts');
  let param = '';
  if (payload && payload.request) {
    param = payload.request;
  }
  Api.get(`social_media_accounts${param}`)
    .then(({ data }) => { dispatch('receiveSocialMediaAccountsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaAccountsError', response); });
};


export const requestUpdateSocialMediaAccount = ({ commit }) => commit(types.UPDATE_SOCIAL_MEDIA_ACCOUNT);
export const receiveUpdateSocialMediaAccount = ({ commit }, data) => commit(types.UPDATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS, data);
export const receiveUpdateSocialMediaAccountError = ({ commit }, error) => commit(types.UPDATE_SOCIAL_MEDIA_ACCOUNT_ERROR, error);
export const updateSocialMediaAccount = ({ dispatch }, socialMediaAccountData) => {
  dispatch('requestUpdateSocialMediaAccount');
  Api.patch(`social_media_accounts/${socialMediaAccountData.social_media_account_id}`, socialMediaAccountData)
    .then(({ data }) => {
      dispatch('receiveUpdateSocialMediaAccount', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateSocialMediaAccountError', response);
    });
};

export const requestUpdateSocialMediaAccountPage = ({ commit }) => commit(types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE);
export const receiveUpdateSocialMediaAccountPage = ({ commit }, data) => commit(types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_SUCCESS, data);
export const receiveUpdateSocialMediaAccountPageError = ({ commit }, error) => commit(types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR, error);
export const updateSocialMediaAccountPage = ({ dispatch }, socialMediaAccountData) => {
  dispatch('requestUpdateSocialMediaAccountPage');
  Api.patch(`social_media_account_pages/${socialMediaAccountData.social_media_account_page_id}`, socialMediaAccountData)
    .then(({ data }) => {
      dispatch('receiveUpdateSocialMediaAccountPage', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateSocialMediaAccountPageError', response);
    });
};

export const requestSocialMediaAccount = ({ commit }) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT);
export const receiveSocialMediaAccountSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_ACCOUNT, data);
export const receiveSocialMediaAccountError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT_ERROR, error);

export const fetchSocialMediaAccount = ({ dispatch }, socialMediaAccountId) => {
  dispatch('requestSocialMediaAccount');
  Api.get(`social_media_accounts/${socialMediaAccountId}`)
    .then(({ data }) => { dispatch('receiveSocialMediaAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaAccountError', response); });
};

export const requestSocialMediaAccountAllPages = ({ commit }) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES);
export const receiveSocialMediaAccountAllPagesSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES, data);
export const receiveSocialMediaAccountAllPagesError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES_ERROR, error);

export const fetchSocialMediaAccountAllPages = ({ dispatch }, payload) => {
  dispatch('requestSocialMediaAccountAllPages');
  Api.get(`social_media_account_pages/social_media_account/${payload.params.paramID}${payload.request}`)
    .then(({ data }) => { dispatch('receiveSocialMediaAccountAllPagesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaAccountAllPagesError', response); });
};

export const requestSocialMediaAccountPages = ({ commit }) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT_PAGES);
export const receiveSocialMediaAccountPagesSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_ACCOUNT_PAGES, data);
export const receiveSocialMediaAccountPagesError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR, error);

export const fetchSocialMediaAccountPages = ({ dispatch }) => {
  dispatch('requestSocialMediaAccountPages');
  Api.get('social_media_account_pages?__limit=200')
    .then(({ data }) => { dispatch('receiveSocialMediaAccountPagesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaAccountPagesError', response); });
};

export const requestDeleteSocialMediaPage = ({ commit }) => commit(types.GET_DELETE_SOCIAL_MEDIA_PAGE);
export const receiveDeleteSocialMediaPageSuccess = ({ commit }, data) => commit(types.SET_DELETE_SOCIAL_MEDIA_PAGE, data);
export const receiveDeleteSocialMediaPageError = ({ commit }, error) => commit(types.GET_DELETE_SOCIAL_MEDIA_PAGE_ERROR, error);

export const fetchDeleteSocialMediaPage = ({ dispatch }, payload) => {
  dispatch('requestDeleteSocialMediaPage');
  Api.delete(`social_media_account_pages/${payload.id}`)
    .then(({ data }) => { dispatch('receiveDeleteSocialMediaPageSuccess', data); })
    .catch(({ response }) => { dispatch('receiveDeleteSocialMediaPageError', response); });
};

export const requestDeleteSocialMediaAccount = ({ commit }) => commit(types.GET_DELETE_SOCIAL_MEDIA_ACCOUNT);
export const receiveDeleteSocialMediaAccountSuccess = ({ commit }, data) => commit(types.SET_DELETE_SOCIAL_MEDIA_ACCOUNT, data);
export const receiveDeleteSocialMediaAccountError = ({ commit }, error) => commit(types.GET_DELETE_SOCIAL_MEDIA_ACCOUNT_ERROR, error);

export const fetchDeleteSocialMediaAccount = ({ dispatch }, payload) => {
  dispatch('requestDeleteSocialMediaAccount');
  Api.delete(`social_media_accounts/${payload.id}`)
    .then(({ data }) => { dispatch('receiveDeleteSocialMediaAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveDeleteSocialMediaAccountError', response); });
};

export const requestSocialMediaAccountPage = ({ commit }) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT_PAGE);
export const receiveSocialMediaAccountPageSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_ACCOUNT_PAGE, data);
export const receiveSocialMediaAccountPageError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR, error);

export const fetchSocialMediaAccountPage = ({ dispatch }, socialMediaAccountPageId) => {
  dispatch('requestSocialMediaAccountPage');
  Api.get(`social_media_account_pages/${socialMediaAccountPageId}`)
    .then(({ data }) => { dispatch('receiveSocialMediaAccountPageSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaAccountPageError', response); });
};


export const requestCreateSocialMediaAccountPage = ({ commit }) => commit(types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE);
export const receiveCreateSocialMediaAccountPageSuccess = ({ commit }, data) => commit(types.SET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE, data);
export const receiveCreateSocialMediaAccountPageError = ({ commit }, error) => commit(types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR, error);

export const fetchCreateSocialMediaAccountPage = ({ dispatch }, accountPageData) => {
  dispatch('requestCreateSocialMediaAccountPage');
  Api.post('social_media_account_pages', accountPageData)
    .then(({ data }) => { dispatch('receiveCreateSocialMediaAccountPageSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateSocialMediaAccountPageError', response); });
};

export const requestNotificationTypes = ({ commit }) => commit(types.GET_NOTIFICATION_TYPES);
export const receiveNotificationTypesSuccess = ({ commit }, data) => commit(types.SET_NOTIFICATION_TYPES, data);
export const receiveNotificationTypesError = ({ commit }, error) => commit(types.GET_NOTIFICATION_TYPES_ERROR, error);
export const fetchNotificationTypes = ({ dispatch }) => {
  dispatch('requestNotificationTypes');
  Api.get('bt/notification_types')
    .then(({ data }) => { dispatch('receiveNotificationTypesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveNotificationTypesError', response); });
};

export const requestAutomations = ({ commit }) => commit(types.GET_AUTOMATIONS);
export const receiveAutomationsSuccess = ({ commit }, data) => commit(types.SET_AUTOMATIONS, data);
export const fetchAutomations = async ({ dispatch }) => {
  await dispatch('requestAutomations');
  const data = await new AutomationServices().fetchAutomation();
  dispatch('receiveAutomationsSuccess', data);
};


export const requestUpdateAutomations = ({ commit }) => commit(types.UPDATE_AUTOMATIONS);
export const receiveUpdateAutomationsSuccess = ({ commit }, data) => commit(types.UPDATE_AUTOMATIONS_SUCCESS, data);
export const receiveUpdateAutomationsError = ({ commit }, error) => commit(types.UPDATE_AUTOMATIONS_ERROR, error);
export const updateAutomations = async ({ dispatch }, payload) => {
  dispatch('requestUpdateAutomations');
  try {
    const data = await new AutomationServices().updateAutomationNotificationRule(payload);
    dispatch('receiveAutomationsSuccess', data.result.data);
    dispatch('receiveUpdateAutomationsSuccess', data.message);
  } catch (e) {
    dispatch('receiveUpdateAutomationsError', e);
  }
};

export const requestNotificationRule = ({ commit }) => commit(types.GET_NOTIFICATION_RULE);
export const receiveNotificationRuleSuccess = ({ commit }, data) => commit(types.SET_NOTIFICATION_RULE, data);
export const receiveNotificationRuleError = ({ commit }, error) => commit(types.GET_NOTIFICATION_RULE_ERROR, error);
export const fetchNotificationRule = ({ dispatch }, notificationRuleId) => {
  dispatch('requestNotificationRule');
  Api.get(`bt/notification_rules/${notificationRuleId}`)
    .then(({ data }) => { dispatch('receiveNotificationRuleSuccess', data); })
    .catch(({ response }) => { dispatch('receiveNotificationRulesError', response); });
};

export const requestTrackingSites = ({ commit }) => commit(types.GET_TRACKING_SITES);
export const receiveTrackingSitesSuccess = ({ commit }, data) => commit(types.SET_TRACKING_SITES, data);
export const receiveTrackingSitesError = ({ commit }, error) => commit(types.GET_TRACKING_SITES_ERROR, error);
export const fetchTrackingSites = ({ dispatch }) => {
  dispatch('requestTrackingSites');
  Api.get('tracking_sites')
    .then(({ data }) => { dispatch('receiveTrackingSitesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveTrackingSitesError', response); });
};

export const requestPeriod = ({ commit }) => commit(types.GET_PERIOD);
export const receivePeriodSuccess = ({ commit }, data) => commit(types.SET_PERIOD, data);
export const receivePeriodError = ({ commit }, error) => commit(types.GET_PERIOD_ERROR, error);
export const fetchPeriod = ({ dispatch }) => {
  dispatch('requestPeriod');
  Api.get('bt/notification_periods')
    .then(({ data }) => { dispatch('receivePeriodSuccess', data); })
    .catch(({ response }) => { dispatch('receivePeriodError', response); });
};

export const requestCreateNotificationRule = ({ commit }) => commit(types.CREATE_NOTIFICATION_RULES);
export const receiveCreateNotificationRuleSuccess = ({ commit }, data) => commit(types.CREATE_NOTIFICATION_RULES_SUCCESS, data);
export const receiveCreateNotificationRuleError = ({ commit }, error) => commit(types.CREATE_NOTIFICATION_RULES_ERROR, error);
export const createNotificationRule = ({ dispatch }, notificationRule) => {
  dispatch('requestCreateNotificationRule');
  Api.post('bt/notification_rules', notificationRule)
    .then(({ data }) => { dispatch('receiveCreateNotificationRuleSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateNotificationRuleError', response); });
};

export const requestNotificationChannels = ({ commit }) => commit(types.GET_NOTIFICATION_CHANNELS);
export const receiveNotificationChannelsSuccess = ({ commit }, data) => commit(types.SET_NOTIFICATION_CHANNELS, data);
export const receiveNotificationChannelsError = ({ commit }, error) => commit(types.GET_NOTIFICATION_CHANNELS_ERROR, error);
export const fetchNotificationChannels = ({ dispatch }) => {
  dispatch('requestNotificationChannels');
  Api.get('notification_channels')
    .then(({ data }) => { dispatch('receiveNotificationChannelsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveNotificationChannelsError', response); });
};

export const requestDeleteNotificationRule = ({ commit }) => commit(types.DELETE_NOTIFICATION_RULE);
export const receiveDeleteNotificationRuleSuccess = ({ commit }, data) => commit(types.DELETE_NOTIFICATION_RULE_SUCCESS, data);
export const receiveDeleteNotificationRuleError = ({ commit }, error) => commit(types.DELETE_NOTIFICATION_RULE_ERROR, error);
export const deleteNotificationRule = ({ dispatch }, notificationRuleId) => {
  dispatch('requestDeleteNotificationRule');
  Api.delete(`bt/notification_rules/${notificationRuleId}`)
    .then(({ data }) => { dispatch('receiveDeleteNotificationRuleSuccess', data); })
    .catch(({ response }) => { dispatch('receiveDeleteNotificationRuleError', response); });
};

export const requestBrowserNotifications = ({ commit }) => commit(types.GET_BROWSER_NOTIFICATIONS);
export const receiveBrowserNotificationsSuccess = ({ commit }, data) => commit(types.SET_BROWSER_NOTIFICATIONS, data);
export const receiveBrowserNotificationsError = ({ commit }, error) => commit(types.GET_BROWSER_NOTIFICATIONS_ERROR, error);
export const fetchBrowserNotifications = ({ dispatch }) => {
  dispatch('requestBrowserNotifications');
  Api.get('user_web_push_connections')
    .then(({ data }) => { dispatch('receiveBrowserNotificationsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBrowserNotificationsError', response); });
};

export const requestUpdateBrowserNotifications = ({ commit }) => commit(types.GET_UPDATE_BROWSER_NOTIFICATIONS);
export const receiveUpdateBrowserNotificationsSuccess = ({ commit }, data) => commit(types.SET_UPDATE_BROWSER_NOTIFICATIONS, data);
export const receiveUpdateBrowserNotificationsError = ({ commit }, error) => commit(types.GET_UPDATE_BROWSER_NOTIFICATIONS_ERROR, error);
export const fetchUpdateBrowserNotifications = ({ dispatch }, webPushData) => {
  dispatch('requestUpdateBrowserNotifications');
  Api.patch(`user_web_push_connections/${webPushData.user_web_push_connection_id}`, webPushData)
    .then(({ data }) => { dispatch('receiveUpdateBrowserNotificationsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateBrowserNotificationsError', response); });
};

export const requestBlogSettings = ({ commit }) => commit(types.GET_BLOG_SETTINGS);
export const receiveBlogSettingsSuccess = ({ commit }, data) => commit(types.SET_BLOG_SETTINGS, data);
export const receiveBlogSettingsError = ({ commit }, error) => commit(types.GET_BLOG_SETTINGS_ERROR, error);
export const fetchBlogSettings = ({ dispatch }) => {
  dispatch('requestBlogSettings');
  Api.get('gb/default_settings')
    .then(({ data }) => { dispatch('receiveBlogSettingsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBlogSettingsError', response); });
};

export const requestUpdateBlogSettings = ({ commit }) => commit(types.GET_UPDATE_BLOG_SETTINGS);
export const receiveUpdateBlogSettingsSuccess = ({ commit }, data) => commit(types.SET_UPDATE_BLOG_SETTINGS, data);
export const receiveUpdateBlogSettingsError = ({ commit }, error) => commit(types.GET_UPDATE_BLOG_SETTINGS_ERROR, error);
export const fetchUpdateBlogSettings = ({ dispatch }, formData) => {
  dispatch('requestUpdateBlogSettings');
  Api.postFile('gb/default_settings', formData)
    .then(({ data }) => {
      dispatch('receiveUpdateBlogSettingsSuccess', data);
    })
    .catch(({ response }) => { dispatch('receiveUpdateBlogSettingsError', response); });
};

export const requestBlogMenus = ({ commit }) => commit(types.GET_BLOG_MENUS);
export const receiveBlogMenusSuccess = ({ commit }, data) => commit(types.SET_BLOG_MENUS, data);
export const receiveBlogMenusError = ({ commit }, error) => commit(types.GET_BLOG_MENUS_ERROR, error);
export const fetchBlogMenus = ({ dispatch }) => {
  dispatch('requestBlogMenus');
  Api.get('gb/menus?__order_by=sort')
    .then(({ data }) => {
      dispatch('receiveBlogMenusSuccess', data);
    })
    .catch(({ response }) => { dispatch('receiveBlogMenusError', response); });
};

export const requestBlogMenu = ({ commit }) => commit(types.GET_BLOG_MENU);
export const receiveBlogMenuSuccess = ({ commit }, data) => commit(types.SET_BLOG_MENU, data);
export const receiveBlogMenuError = ({ commit }, error) => commit(types.GET_BLOG_MENU_ERROR, error);
export const fetchBlogMenu = ({ dispatch }, menuId) => {
  dispatch('requestBlogMenu');
  Api.get(`gb/menus/${menuId}`)
    .then(({ data }) => {
      dispatch('receiveBlogMenuSuccess', data);
    })
    .catch(({ response }) => { dispatch('receiveBlogMenuError', response); });
};

export const requestCreateBlogMenu = ({ commit }) => commit(types.GET_CREATE_BLOG_MENU);
export const receiveCreateBlogMenuSuccess = ({ commit }, data) => commit(types.SET_CREATE_BLOG_MENU, data);
export const receiveCreateBlogMenuError = ({ commit }, error) => commit(types.GET_CREATE_BLOG_MENU_ERROR, error);
export const fetchCreateBlogMenu = ({ dispatch }, menuData) => {
  dispatch('requestCreateBlogMenu');
  Api.post('gb/menus', menuData)
    .then(({ data }) => {
      dispatch('receiveCreateBlogMenuSuccess', data);
      dispatch('fetchBlogMenus');
    })
    .catch(({ response }) => { dispatch('receiveCreateBlogMenuError', response); });
};

export const requestDeleteBlogMenu = ({ commit }) => commit(types.GET_DELETE_BLOG_MENU);
export const receiveDeleteBlogMenuSuccess = ({ commit }, data) => commit(types.SET_DELETE_BLOG_MENU, data);
export const receiveDeleteBlogMenuError = ({ commit }, error) => commit(types.GET_DELETE_BLOG_MENU_ERROR, error);
export const fetchDeleteBlogMenu = ({ dispatch }, payload) => {
  dispatch('requestDeleteBlogMenu');
  Api.delete(`gb/menus/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteBlogMenuSuccess', data);
      dispatch('fetchBlogMenus');
    })
    .catch(({ response }) => { dispatch('receiveDeleteBlogMenuError', response); });
};

export const requestUpdateBlogMenu = ({ commit }) => commit(types.GET_UPDATE_BLOG_MENU);
export const receiveUpdateBlogMenuSuccess = ({ commit }, data) => commit(types.SET_UPDATE_BLOG_MENU, data);
export const receiveUpdateBlogMenuError = ({ commit }, error) => commit(types.GET_UPDATE_BLOG_MENU_ERROR, error);
export const fetchUpdateBlogMenu = ({ dispatch }, menuData) => {
  dispatch('requestUpdateBlogMenu');
  Api.patch(`gb/menus/${menuData.menu_id}`, menuData)
    .then(({ data }) => {
      dispatch('receiveUpdateBlogMenuSuccess', data);
      dispatch('fetchBlogMenus');
    })
    .catch(({ response }) => { dispatch('receiveUpdateBlogMenuError', response); });
};

export const requestSortBlogMenu = ({ commit }) => commit(types.GET_SORT_BLOG_MENU);
export const receiveSortBlogMenuSuccess = ({ commit }, data) => commit(types.SET_SORT_BLOG_MENU, data);
export const receiveSortBlogMenuError = ({ commit }, error) => commit(types.GET_SORT_BLOG_MENU_ERROR, error);
export const fetchSortBlogMenu = ({ dispatch }, sortMenuData) => {
  dispatch('requestSortBlogMenu');
  Api.patch('gb/menus/update/sort', sortMenuData)
    .then(({ data }) => {
      dispatch('receiveSortBlogMenuSuccess', data);
      dispatch('fetchBlogMenus');
    })
    .catch(({ response }) => {
      dispatch('receiveSortBlogMenuError', response);
      dispatch('fetchBlogMenus');
    });
};

export const requestCreateSocialMedia = ({ commit }) => commit(types.CREATE_SOCIAL_MEDIA_ACCOUNT);
export const receiveCreateSocialMediaSuccess = ({ commit }, data) => commit(types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS, data);
export const receiveCreateSocialMediaError = ({ commit }, error) => commit(types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_ERROR, error);
export const createSocialMediaAccount = ({ dispatch }, socialMediaAccountData) => {
  dispatch('requestCreateSocialMedia');
  Api.post('social_media_accounts', socialMediaAccountData)
    .then(({ data }) => {
      dispatch('receiveCreateSocialMediaSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateSocialMediaError', response);
    });
};

export const requestCreateSocialMediaAccountPages = ({ commit }) => commit(types.CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES);
export const receiveCreateSocialMediaAccountPagesSuccess = ({ commit }, data) => commit(types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS, data);
export const receiveCreateSocialMediaAccountPagesError = ({ commit }, error) => commit(types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR, error);
export const createSocialMediaAccountPages = ({ dispatch }, socialMediaAccountData) => {
  dispatch('requestCreateSocialMediaAccountPages');
  Api.post('social_media_account_pages', socialMediaAccountData)
    .then(({ data }) => {
      dispatch('receiveCreateSocialMediaAccountPagesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateSocialMediaAccountPagesError', response);
    });
};

export const requestUpdateSocialMediaAccountPages = ({ commit }) => commit(types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES);
export const receiveUpdateSocialMediaAccountPagesSuccess = ({ commit }, data) => commit(types.GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS, data);
export const receiveUpdateSocialMediaAccountPagesError = ({ commit }, error) => commit(types.GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR, error);
export const UpdateSocialMediaAccountPages = ({ dispatch }, socialMediaAccountData) => {
  dispatch('requestUpdateSocialMediaAccountPages');
  Api.patch(`/ht/adaccounts/${socialMediaAccountData.social_media_account_id}`, socialMediaAccountData)
    .then(({ data }) => {
      dispatch('receiveUpdateSocialMediaAccountPagesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateSocialMediaAccountPagesError', response);
    });
};

export const requestAdAccounts = ({ commit }) => commit(types.GET_AD_ACCOUNTS);
export const receiveAdAccountsSuccess = ({ commit }, data) => commit(types.SET_AD_ACCOUNTS, data);
export const receiveAdAccountsError = ({ commit }, error) => commit(types.GET_AD_ACCOUNTS_ERROR, error);
export const fetchAdAccounts = ({ dispatch }, payload) => {
  dispatch('requestAdAccounts');
  let param = '';
  if (payload && payload.request) {
    param = payload.request;
  }
  Api.get(`/ht/adaccounts/social_media_account/${payload.params.paramID}${param}`)
    .then(({ data }) => { dispatch('receiveAdAccountsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveAdAccountsError', response); });
};


export const requestSenderEmails = ({ commit }) => commit(types.GET_SENDER_EMAILS);
export const receiveSenderEmailsSuccess = ({ commit }, data) => commit(types.SET_SENDER_EMAILS, data);
export const receiveSenderEmailsError = ({ commit }, error) => commit(types.GET_SENDER_EMAILS_ERROR, error);
export const fetchSenderEmails = ({ dispatch }) => {
  dispatch('requestSenderEmails');
  Api.get('sender_emails?__order_by=created_at')
    .then(({ data }) => { dispatch('receiveSenderEmailsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSenderEmailsError', response); });
};

export const requestSenderEmail = ({ commit }) => commit(types.GET_SENDER_EMAIL);
export const receiveSenderEmailSuccess = ({ commit }, data) => commit(types.SET_SENDER_EMAIL, data);
export const receiveSenderEmailError = ({ commit }, error) => commit(types.GET_SENDER_EMAIL_ERROR, error);
export const fetchSenderEmail = ({ dispatch }, senderId) => {
  dispatch('requestSenderEmail');
  Api.get(`sender_emails/${senderId}`)
    .then(({ data }) => { dispatch('receiveSenderEmailSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSenderEmailError', response); });
};

export const requestCreateSenderSetting = ({ commit }) => commit(types.CREATE_SENDER_SETTING);
export const receiveCreateSenderSettingSuccess = ({ commit }, data) => commit(types.CREATE_SENDER_SETTING_SUCCESS, data);
export const receiveCreateSenderSettingError = ({ commit }, error) => commit(types.CREATE_SENDER_SETTING_ERROR, error);
export const createSenderSetting = ({ dispatch }, senderData) => {
  dispatch('requestCreateSenderSetting');
  Api.post('sender_emails', senderData)
    .then(({ data }) => {
      dispatch('receiveCreateSenderSettingSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateSenderSettingError', response);
    });
};

export const requestUpdateSenderSetting = ({ commit }) => commit(types.UPDATE_SENDER_SETTING);
export const receiveUpdateSenderSettingSuccess = ({ commit }, data) => commit(types.UPDATE_SENDER_SETTING_SUCCESS, data);
export const receiveUpdateSenderSettingError = ({ commit }, error) => commit(types.UPDATE_SENDER_SETTING_ERROR, error);
export const updateSenderSetting = ({ dispatch }, senderData) => {
  dispatch('requestUpdateSenderSetting');
  Api.patch(`sender_emails/${senderData.sender_email_id}`, senderData)
    .then(({ data }) => {
      dispatch('receiveUpdateSenderSettingSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateSenderSettingError', response);
    });
};

export const requestDeleteSenderSetting = ({ commit }) => commit(types.DELETE_SENDER_SETTING);
export const receiveDeleteSenderSettingSuccess = ({ commit }, data) => commit(types.DELETE_SENDER_SETTING_SUCCESS, data);
export const receiveDeleteSenderSettingError = ({ commit }, error) => commit(types.DELETE_SENDER_SETTING_ERROR, error);
export const deleteSenderSetting = ({ dispatch }, payload) => {
  dispatch('requestDeleteSenderSetting');
  Api.delete(`sender_emails/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteSenderSettingSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteSenderSettingError', response);
    });
};

export const requestSenderSmsSettings = ({ commit }) => commit(types.GET_SENDER_SMS_SETTINGS);
export const receiveSenderSmsSettingsSuccess = ({ commit }, data) => commit(types.SET_SENDER_SMS_SETTINGS, data);
export const receiveSenderSmsSettingsError = ({ commit }, error) => commit(types.GET_SENDER_SMS_SETTINGS_ERROR, error);
export const fetchSenderSmsSettings = ({ dispatch }) => {
  dispatch('requestSenderSmsSettings');
  Api.get('sender_sms')
    .then(({ data }) => { dispatch('receiveSenderSmsSettingsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSenderSmsSettingsError', response); });
};

export const requestSenderSms = ({ commit }) => commit(types.GET_SENDER_SMS);
export const receiveSenderSmsSuccess = ({ commit }, data) => commit(types.SET_SENDER_SMS, data);
export const receiveSenderSmsError = ({ commit }, error) => commit(types.GET_SENDER_SMS_ERROR, error);
export const fetchSenderSms = ({ dispatch }, smsId) => {
  dispatch('requestSenderSms');
  Api.get(`sender_sms/${smsId}`)
    .then(({ data }) => { dispatch('receiveSenderSmsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSenderSmsError', response); });
};

export const requestCreateSenderSms = ({ commit }) => commit(types.CREATE_SENDER_SMS);
export const receiveCreateSenderSmsSuccess = ({ commit }, data) => commit(types.CREATE_SENDER_SMS_SUCCESS, data);
export const receiveCreateSenderSmsError = ({ commit }, error) => commit(types.CREATE_SENDER_SMS_ERROR, error);
export const createSenderSms = ({ dispatch }, senderData) => {
  dispatch('requestCreateSenderSms');
  Api.post('sender_sms', senderData)
    .then(({ data }) => {
      dispatch('receiveCreateSenderSmsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateSenderSmsError', response);
    });
};

export const requestUpdateSenderSms = ({ commit }) => commit(types.UPDATE_SENDER_SMS);
export const receiveUpdateSenderSmsSuccess = ({ commit }, data) => commit(types.UPDATE_SENDER_SMS_SUCCESS, data);
export const receiveUpdateSenderSmsError = ({ commit }, error) => commit(types.UPDATE_SENDER_SMS_ERROR, error);
export const updateSenderSms = ({ dispatch }, smsData) => {
  dispatch('requestUpdateSenderSms');
  Api.patch(`sender_sms/${smsData.sender_sms_id}`, smsData)
    .then(({ data }) => {
      dispatch('receiveUpdateSenderSmsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateSenderSmsError', response);
    });
};

export const requestDeleteSenderSms = ({ commit }) => commit(types.DELETE_SENDER_SMS);
export const receiveDeleteSenderSmsSuccess = ({ commit }, data) => commit(types.DELETE_SENDER_SMS_SUCCESS, data);
export const receiveDeleteSenderSmsError = ({ commit }, error) => commit(types.DELETE_SENDER_SMS_ERROR, error);
export const deleteSenderSms = ({ dispatch }, payload) => {
  dispatch('requestDeleteSenderSms');
  Api.delete(`sender_sms/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteSenderSmsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteSenderSmsError', response);
    });
};

export const requestCompanies = ({ commit }) => commit(types.GET_COMPANIES);
export const receiveCompaniesSuccess = ({ commit }, data) => commit(types.SET_COMPANIES, data);
export const receiveCompaniesError = ({ commit }, error) => commit(types.GET_COMPANIES_ERROR, error);
export const fetchCompanies = ({ dispatch }, companyId) => {
  dispatch('requestCompanies');
  Api.get(`companies/${companyId}`)
    .then(({ data }) => { dispatch('receiveCompaniesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCompaniesError', response); });
};

export const requestCompanySettings = ({ commit }) => commit(types.GET_COMPANY_SETTINGS);
export const receiveCompanySettingsSuccess = ({ commit }, data) => commit(types.SET_COMPANY_SETTINGS, data);
export const receiveCompanySettingsError = ({ commit }, error) => commit(types.GET_COMPANY_SETTINGS_ERROR, error);
export const fetchCompanySettings = ({ dispatch }) => {
  dispatch('requestCompanySettings');
  Api.get('company_settings')
    .then(({ data }) => { dispatch('receiveCompanySettingsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCompanySettingsError', response); });
};

export const requestUpdateCompanies = ({ commit }) => commit(types.GET_UPDATE_COMPANIES);
export const receiveUpdateCompaniesSuccess = ({ commit }, data) => commit(types.SET_UPDATE_COMPANIES, data);
export const receiveUpdateCompaniesError = ({ commit }, error) => commit(types.GET_UPDATE_COMPANIES_ERROR, error);
export const fetchUpdateCompanies = ({ dispatch }, formData) => {
  dispatch('requestUpdateCompanies');
  Api.postFile(`companies/${formData.get('company_id')}`, formData)
    .then(({ data }) => { dispatch('receiveUpdateCompaniesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateCompaniesError', response); });
};

export const requestUpdateCompanySettings = ({ commit }) => commit(types.GET_UPDATE_COMPANY_SETTINGS);
export const receiveUpdateCompanySettingsSuccess = ({ commit }, data) => commit(types.SET_UPDATE_COMPANY_SETTINGS, data);
export const receiveUpdateCompanySettingsError = ({ commit }, error) => commit(types.GET_UPDATE_COMPANY_SETTINGS_ERROR, error);
export const fetchUpdateCompanySettings = ({ dispatch }, settingsData) => {
  dispatch('requestUpdateCompanySettings');
  Api.patch(`company_settings/${settingsData.company_id}`, settingsData)
    .then(({ data }) => { dispatch('receiveUpdateCompanySettingsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateCompanySettingsError', response); });
};

export const requestBaitSettingsUpdate = ({ commit }) => commit(types.GET_BAIT_SETTINGS_UPDATE);
export const receiveBaitSettingsUpdateSuccess = ({ commit }, data) => commit(types.SET_BAIT_SETTINGS_UPDATE, data);
export const receiveBaitSettingsUpdateError = ({ commit }, error) => commit(types.GET_BAIT_SETTINGS_UPDATE_ERROR, error);
export const fetchBaitSettingsUpdate = ({ dispatch }, formData) => {
  dispatch('requestBaitSettingsUpdate');
  Api.postFile('bt/default_settings', formData)
    .then(({ data }) => { dispatch('receiveBaitSettingsUpdateSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBaitSettingsUpdateError', response); });
};

export const requestBaitSettings = ({ commit }) => commit(types.GET_BAIT_SETTINGS);
export const receiveBaitSettingsSuccess = ({ commit }, data) => commit(types.SET_BAIT_SETTINGS, data);
export const receiveBaitSettingsError = ({ commit }, error) => commit(types.GET_BAIT_SETTINGS_ERROR, error);
export const fetchBaitSettings = ({ dispatch }) => {
  dispatch('requestBaitSettings');
  Api.get('bt/default_settings')
    .then(({ data }) => { dispatch('receiveBaitSettingsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBaitSettingsError', response); });
};

export const requestBaitSettingsSales = ({ commit }) => commit(types.GET_BAIT_SETTINGS_SALES);
export const receiveBaitSettingsSalesSuccess = ({ commit }, data) => commit(types.SET_BAIT_SETTINGS_SALES, data);
export const receiveBaitSettingsSalesError = ({ commit }, error) => commit(types.GET_BAIT_SETTINGS_SALES_ERROR, error);
export const fetchBaitSettingsSales = ({ dispatch }) => {
  dispatch('requestBaitSettingsSales');
  Api.get('bt/default_settings?transaction_type_id=1')
    .then(({ data }) => { dispatch('receiveBaitSettingsSalesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBaitSettingsSalesError', response); });
};

export const requestBaitSettingsLettings = ({ commit }) => commit(types.GET_BAIT_SETTINGS_LETTINGS);
export const receiveBaitSettingsLettingsSuccess = ({ commit }, data) => commit(types.SET_BAIT_SETTINGS_LETTINGS, data);
export const receiveBaitSettingsLettingsError = ({ commit }, error) => commit(types.GET_BAIT_SETTINGS_LETTINGS_ERROR, error);
export const fetchBaitSettingsLettings = ({ dispatch }) => {
  dispatch('requestBaitSettingsLettings');
  Api.get('bt/default_settings?transaction_type_id=2')
    .then(({ data }) => { dispatch('receiveBaitSettingsLettingsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBaitSettingsLettingsError', response); });
};

export const requestBaitFaqs = ({ commit }) => commit(types.GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID);
export const receiveBaitFaqsSuccess = ({ commit }, data) => commit(types.SET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID, data);
export const receiveBaitFaqsError = ({ commit }, error) => commit(types.GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID_ERROR, error);
export const fetchBaitFaqs = ({ dispatch }, payload) => {
  dispatch('requestBaitFaqs');
  Api.get('bt/frequently_asked_questions', payload)
    .then(({ data }) => {
      const dataCopy = { ...data };
      dataCopy.transaction_type_id_ = payload.transaction_type_id;
      dispatch('receiveBaitFaqsSuccess', dataCopy);
    })
    .catch(({ response }) => { dispatch('receiveBaitFaqsError', response); });
};

export const requestBaitMarketingPlanOverview = ({ commit }) => commit(types.GET_BAIT_MARKETING_PLAN_OVERVIEW);
export const receiveBaitMarketingPlanOverviewSuccess = ({ commit }, data) => commit(types.SET_BAIT_MARKETING_PLAN_OVERVIEW, data);
export const receiveBaitMarketingPlanOverviewError = ({ commit }, error) => commit(types.GET_BAIT_MARKETING_PLAN_OVERVIEW_ERROR, error);
export const fetchBaitMarketingPlanOverview = ({ dispatch }) => {
  dispatch('requestBaitMarketingPlanOverview');
  Api.get('bt/contents')
    .then(({ data }) => { dispatch('receiveBaitMarketingPlanOverviewSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBaitMarketingPlanOverviewError', response); });
};

export const requestBaitCreateMarketingPlanOverview = ({ commit }) => commit(types.GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW);
export const receiveBaitCreateMarketingPlanOverviewSuccess = ({ commit }, data) => commit(types.SET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW, data);
export const receiveBaitCreateMarketingPlanOverviewError = ({ commit }, error) => commit(types.GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW_ERROR, error);
export const fetchBaitCreateMarketingPlanOverview = ({ dispatch }, btContent) => {
  dispatch('requestBaitCreateMarketingPlanOverview');
  Api.patch(`bt/contents/${btContent.content_id}`, btContent)
    .then(({ data }) => { dispatch('receiveBaitCreateMarketingPlanOverviewSuccess', data); })
    .catch(({ response }) => { dispatch('receiveBaitCreateMarketingPlanOverviewError', response); });
};


/* CUSTOM FIELD SETTINGS: START */
export const requestCustomFieldGroups = ({ commit }) => commit(types.GET_CUSTOM_FIELD_GROUPS);
export const receiveCustomFieldGroupsSuccess = ({ commit }, data) => commit(types.SET_CUSTOM_FIELD_GROUPS, data);
export const receiveCustomFieldGroupsError = ({ commit }, error) => commit(types.GET_CUSTOM_FIELD_GROUPS_ERROR, error);
export const fetchCustomFieldGroups = ({ dispatch }) => {
  dispatch('requestCustomFieldGroups');
  Api.get('custom_field_groups?__order_by=group_name')
    .then(({ data }) => { dispatch('receiveCustomFieldGroupsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCustomFieldGroupsError', response); });
};

export const requestCreateCustomFieldGroup = ({ commit }) => commit(types.GET_CREATE_CUSTOM_FIELD_GROUP);
export const receiveCreateCustomFieldGroupSuccess = ({ commit }, data) => commit(types.SET_CREATE_CUSTOM_FIELD_GROUP, data);
export const receiveCreateCustomFieldGroupError = ({ commit }, error) => commit(types.GET_CREATE_CUSTOM_FIELD_GROUP_ERROR, error);
export const createCustomFieldGroup = ({ dispatch }, payload) => {
  dispatch('requestCreateCustomFieldGroup');
  Api.post('custom_field_groups', payload)
    .then(({ data }) => { dispatch('receiveCreateCustomFieldGroupSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateCustomFieldGroupError', response); });
};

export const requestUpdateCustomFieldGroup = ({ commit }) => commit(types.GET_UPDATE_CUSTOM_FIELD_GROUP);
export const receiveUpdateCustomFieldGroupSuccess = ({ commit }, data) => commit(types.SET_UPDATE_CUSTOM_FIELD_GROUP, data);
export const receiveUpdateCustomFieldGroupError = ({ commit }, error) => commit(types.GET_UPDATE_CUSTOM_FIELD_GROUP_ERROR, error);
export const updateCustomFieldGroup = ({ dispatch }, payload) => {
  dispatch('requestUpdateCustomFieldGroup');
  Api.patch(`custom_field_groups/${payload.custom_field_group_id}`, payload)
    .then(({ data }) => { dispatch('receiveUpdateCustomFieldGroupSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateCustomFieldGroupError', response); });
};

export const requestCreateCustomField = ({ commit }) => commit(types.GET_CREATE_CUSTOM_FIELD);
export const receiveCreateCustomFieldSuccess = ({ commit }, data) => commit(types.SET_CREATE_CUSTOM_FIELD, data);
export const receiveCreateCustomFieldError = ({ commit }, error) => commit(types.GET_CREATE_CUSTOM_FIELD_ERROR, error);
export const createCustomField = ({ dispatch }, payload) => {
  dispatch('requestCreateCustomField');
  Api.post('custom_fields', payload)
    .then(({ data }) => { dispatch('receiveCreateCustomFieldSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateCustomFieldError', response); });
};

export const requestUpdateCustomField = ({ commit }) => commit(types.GET_UPDATE_CUSTOM_FIELD);
export const receiveUpdateCustomFieldSuccess = ({ commit }, data) => commit(types.SET_UPDATE_CUSTOM_FIELD, data);
export const receiveUpdateCustomFieldError = ({ commit }, error) => commit(types.GET_UPDATE_CUSTOM_FIELD_ERROR, error);
export const updateCustomField = ({ dispatch }, payload) => {
  dispatch('requestUpdateCustomField');
  Api.patch(`custom_fields/${payload.custom_field_id}`, payload)
    .then(({ data }) => { dispatch('receiveUpdateCustomFieldSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateCustomFieldError', response); });
};

export const requestSendPropertyLive = ({ commit }) => commit(types.GET_PROPERTY_LIVE);
export const receiveSendPropertyLiveSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_LIVE, data);
export const receiveSendPropertyLiveError = ({ commit }, error) => commit(types.GET_PROPERTY_LIVE_ERROR, error);
export const sendPropertyLive = ({ dispatch }, payload) => {
  dispatch('requestSendPropertyLive');
  Api.post('rightmove/test/sales', payload)
    .then(({ data }) => { dispatch('receiveSendPropertyLiveSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSendPropertyLiveError', response); });
};
/* CUSTOM FIELD SETTINGS: START */

export const requestCheckSocialAuthStatus = ({ commit }) => commit(types.GET_CHECK_SOCIAL_AUTH_STATUS);
export const receiveCheckSocialAuthStatusSuccess = ({ commit }, data) => commit(types.SET_CHECK_SOCIAL_AUTH_STATUS, data);
export const receiveCheckSocialAuthStatusError = ({ commit }, error) => commit(types.GET_CHECK_SOCIAL_AUTH_STATUS_ERROR, error);
export const fetchCheckSocialAuthStatus = ({ dispatch }) => {
  dispatch('requestCheckSocialAuthStatus');
  return Api.get('social_media_accounts/check/token')
    .then(({ data }) => { dispatch('receiveCheckSocialAuthStatusSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCheckSocialAuthStatusError', response); });
};

export const requestLocations = ({ commit }) => commit(types.GET_LOCATIONS);
export const receiveLocationsSuccess = ({ commit }, data) => commit(types.SET_LOCATIONS, data);
export const receiveLocationsError = ({ commit }, error) => commit(types.GET_LOCATIONS_ERROR, error);
export const fetchLocations = ({ dispatch }) => {
  dispatch('requestLocations');
  Api.get('locations?__order_by=sort_order&__limit=250')
    .then(({ data }) => { dispatch('receiveLocationsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveLocationsError', response); });
};

export const requestCreateLocation = ({ commit }) => commit(types.GET_CREATE_LOCATIONS);
export const receiveCreateLocationSuccess = ({ commit }, data) => commit(types.SET_CREATE_LOCATIONS, data);
export const receiveCreateLocationError = ({ commit }, error) => commit(types.GET_CREATE_LOCATIONS_ERROR, error);
export const fetchCreateLocation = ({ dispatch }, payload) => {
  dispatch('requestCreateLocation');
  Api.post('locations', payload)
    .then(({ data }) => { dispatch('receiveCreateLocationSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateLocationError', response); });
};

export const requestLocation = ({ commit }) => commit(types.GET_LOCATION);
export const receiveLocationSuccess = ({ commit }, data) => commit(types.SET_LOCATION, data);
export const receiveLocationError = ({ commit }, error) => commit(types.GET_LOCATION_ERROR, error);
export const fetchLocation = ({ dispatch }, locationId) => {
  dispatch('requestLocation');
  Api.get(`locations/${locationId}`)
    .then(({ data }) => { dispatch('receiveLocationSuccess', data); })
    .catch(({ response }) => { dispatch('receiveLocationError', response); });
};

export const requestUpdateLocation = ({ commit }) => commit(types.GET_UPDATE_LOCATIONS);
export const receiveUpdateLocationSuccess = ({ commit }, data) => commit(types.SET_UPDATE_LOCATIONS, data);
export const receiveUpdateLocationError = ({ commit }, error) => commit(types.GET_UPDATE_LOCATIONS_ERROR, error);
export const fetchUpdateLocation = ({ dispatch }, payload) => {
  dispatch('requestUpdateLocation');
  Api.patch(`locations/${payload.location_id}`, payload)
    .then(({ data }) => { dispatch('receiveUpdateLocationSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateLocationError', response); });
};

export const requestSortLocation = ({ commit }) => commit(types.GET_SORT_LOCATIONS);
export const receiveSortLocationSuccess = ({ commit }, data) => commit(types.SET_SORT_LOCATIONS, data);
export const receiveSortLocationError = ({ commit }, error) => commit(types.GET_SORT_LOCATIONS_ERROR, error);
export const sortLocation = ({ dispatch }, payload) => {
  dispatch('requestSortLocation');
  Api.patch('locations/sort', payload)
    .then(({ data }) => { dispatch('receiveSortLocationSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSortLocationError', response); });
};

export const requestProviderSettings = ({ commit }) => commit(types.GET_PROVIDER_SETTINGS);
export const receiveProviderSettingsSuccess = ({ commit }, data) => commit(types.SET_PROVIDER_SETTINGS, data);
export const receiveProviderSettingsError = ({ commit }, error) => commit(types.GET_PROVIDER_SETTINGS_ERROR, error);
export const fetchProviderSettings = ({ dispatch }) => {
  dispatch('requestProviderSettings');
  Api.get('property_publish_service_company_settings')
    .then(({ data }) => { dispatch('receiveProviderSettingsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveProviderSettingsError', response); });
};

export const fetchCreditInfo = async ({ commit, state }) => {
  if (dateDiff(backendDateFormat(new Date(), true), backendDateFormat(state.creditFetchedTime, true), 'minute') > 1 || !state.creditFetchedTime) {
    const result = await new CreditsServices().getWidgetCreditDetails();
    if (result) {
      commit(types.GET_CREDIT_INFO, result.data);
    }
  }
};
