export default {
  isLoading: false,
  companiesList: [],
  companiesTotal: null,
  membersList: [],
  membersTotal: null,
  relatedPropertyList: [],
  relatedPropertyMemberDetail: [],
  relatedPropertyTotal: null,
  customFields: null,
};
