/**
 * @typedef LocalStorageKey
 * @type {('taskUserFilterParams'|'taskBranchFilterParams'|'taskSubcategoryFilterParams')}
 */

/**
 * @param key {LocalStorageKey}
 * @param type {('array'|'string'|'number'|'boolean')}
 * @param value {any}
 * @return {void}
 */
export const setLocalSItem = (key, type, value) => {
  if (type === 'array') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const removeLocalSItem = (key) => {
  localStorage.removeItem(key);
};

/**
 * @param key {LocalStorageKey}
 * @param type {('array'|'string'|'number'|'boolean')}
 * @param isAutoClearEnabled {boolean}
 * @return {(any[]|string|object|boolean)}
 */
export const parseLocalSItem = (key, type, isAutoClearEnabled = true) => {
  const value = localStorage.getItem(key);

  if (type === 'number') {
    return +value;
  }

  if (type === 'boolean' && value) {
    /**
     * @type {'true'|'false'}
     */
    const clearValue = value.toString().toLowerCase().trim();

    if (clearValue === 'true') {
      return true;
    } if (clearValue === 'false') {
      return false;
    }
  }


  if (type === 'array') {
    if (value) {
      const data = JSON.parse(value);
      if (isAutoClearEnabled && data) {
        const cleared = data.filter(item => item);
        setLocalSItem(key, type, cleared);
        return cleared;
      }
      return data;
    }
    return [];
  }
  return value;
};
