export default {
  isLoading: false,
  bulkMails: false,
  bulkMailsDetail: false,
  sentBulkMailsListData: false,
  totalBulkMailsList: 0,
  totalBulkMails: 0,
  deleteBulkMailSuccess: null,
  deleteBulkMailError: null,
};
