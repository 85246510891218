export default [
  {
    path: '/settings',
    meta: {
      title: 'Settings',
      breadcrumb: [
        { name: 'Settings' },
      ],
    },
    name: 'Settings',
    component: () => import('@/modules/settings/views/Settings.vue'),
  },
  {
    path: '/settings/market-appraisal-settings',
    meta: {
      title: 'Market Appraisal Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Market Appraisal Settings' },
      ],
    },
    name: 'MarketAppraisalSettings',
    component: () => import('@/modules/settings/views/BaitSettings.vue'),
  },
  {
    path: '/settings/user-settings',
    meta: {
      title: 'User Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'User Settings' },
      ],
    },
    name: 'UserSettings',
    component: () => import('@/modules/settings/views/UserSettings.vue'),
  },
  {
    path: '/settings/branch-settings',
    meta: {
      title: 'Branch Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Branch Settings' },
      ],
    },
    name: 'BranchSettings',
    component: () => import('@/modules/settings/views/BranchSettings.vue'),
  },
  {
    path: '/settings/branch-settings-detail/:branch_id',
    meta: {
      title: 'Branch Detail Settings',
      breadcrumb: [
        { name: 'Branch Settings', link: '/settings/branch-settings' },
        { name: 'Branch Detail Settings' },
      ],
    },
    name: 'BranchSettingsDetail',
    component: () => import('@/modules/settings/components/branch/BranchDetail.vue'),
  },
  {
    path: '/settings/department-detail/:branch_department_id',
    meta: {
      title: 'Branch Department Detail',
    },
    name: 'BranchDepartmentDetail',
    component: () => import('@/modules/settings/components/branch/DepartmentDetail.vue'),
  },
  {
    path: '/settings/social-media-settings',
    meta: {
      title: 'Social Media Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Social Media Settings' },
      ],
    },
    name: 'SocialMediaSettings',
    component: () => import('@/modules/settings/views/SocialMediaSettings.vue'),
  },
  {
    path: '/settings/social-media-settings/ad-accounts/:social_media_account_id',
    meta: {
      title: 'Ad Account List',
      breadcrumb: [
        { name: 'Social Media Settings', link: '/settings/social-media-settings' },
        { name: 'Ad Account List' },
      ],
    },
    name: 'PageList',
    component: () => import('@/modules/settings/components/social_media/AccountPages.vue'),
  },
  {
    path: '/settings/social-media-settings/account-pages/:social_media_account_id',
    meta: {
      title: 'Account Page List',
      breadcrumb: [
        { name: 'Social Media Settings', link: '/settings/social-media-settings' },
        { name: 'Account Page List' },
      ],
    },
    name: 'AccountList',
    component: () => import('@/modules/settings/components/social_media/SocialMediaPages.vue'),
  },
  {
    path: '/settings/blog-settings',
    meta: {
      title: 'Blog Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Blog Settings' },
      ],
    },
    name: 'BlogSettings',
    component: () => import('@/modules/settings/views/GabbleSettings.vue'),
  },
  {
    path: '/settings/sms-email-settings',
    meta: {
      title: 'SMS & Email Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'SMS & Email Settings' },
      ],
    },
    name: 'SMSEmailSettings',
    component: () => import('@/modules/settings/views/SMS&EmailSettings.vue'),
  },
  {
    path: '/settings/general-settings',
    meta: {
      title: 'General Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'General Settings' },
      ],
    },
    name: 'GeneralSettings',
    component: () => import('@/modules/settings/views/GeneralSettings.vue'),
  },
  {
    path: '/settings/custom_field_settings',
    meta: {
      title: 'Custom Field Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Custom Field Settings' },
      ],
    },
    name: 'CustomFieldSettings',
    component: () => import('@/modules/settings/views/CustomFieldSettings.vue'),
  },
  {
    path: '/settings/social-media-settings/linkedin/callback',
    name: 'SocialMediaLinkedinCallback',
    component: () => import('@/modules/settings/components/social_media/auth/Callback.vue'),
  },
  {
    path: '/settings/social-media-settings/callback',
    name: 'SocialMediaCallback',
  },
  {
    path: '/property-live',
    name: 'PropertyLive',
    meta: {
      title: 'Property Live Test',
    },
    component: () => import('@/modules/settings/views/PropertyLive.vue'),
  },
  {
    path: '/settings/contracts',
    meta: {
      title: 'Contracts Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Contracts Settings' },
      ],
    },
    name: 'ContractsSettings',
    component: () => import('@/modules/settings/views/ContractsSettings.vue'),
  },
  {
    path: '/settings/locations-settings',
    meta: {
      title: 'Locations Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Locations Settings' },
      ],
    },
    name: 'LocationSettings',
    component: () => import('@/modules/settings/views/LocationSettings.vue'),
  },
  {
    path: '/settings/provider-settings',
    meta: {
      title: 'Provider Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Provider Settings' },
      ],
    },
    name: 'ProviderSettings',
    component: () => import('@/modules/settings/views/ProviderSettings.vue'),
  },
  {
    path: '/settings/provider-settings/detail/:provider_service_id',
    meta: {
      title: 'Provider Settings Detail',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Provider Settings' },
      ],
    },
    name: 'ProviderSettings',
    component: () => import('@/modules/settings/components/providers/ProviderSettingsDetail.vue'),
  },
  {
    path: '/settings/compliance-checklist-settings',
    meta: {
      title: 'Compliance Checklist Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Compliance Checklist Settings' },
      ],
    },
    name: 'ComplianceChecklistSettings',
    component: () => import('@/modules/settings/views/ComplianceChecklistSettings.vue'),
  },
  {
    path: '/settings/tasks',
    meta: {
      title: 'Taskforce Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Taskforce Settings' },
      ],
    },
    name: 'TasksSettings',
    component: () => import('@/modules/settings/views/TasksSettings.vue'),
  },

  {
    path: '/settings/tasks/templates',
    meta: {
      title: 'Taskforce Templates',
      breadcrumb: [
        { name: 'Taskforce Settings', link: '/settings/tasks' },
        { name: 'Taskforce Templates' },
      ],
    },
    name: 'TaskTemplatesSettings',
    component: () => import('@/modules/tasks/components/settings/task_templates/TaskTemplates.vue'),
  },

  {
    path: '/settings/tasks/subcategories',
    meta: {
      title: 'Taskforce Categories',
      breadcrumb: [
        { name: 'Taskforce Settings', link: '/settings/tasks' },
        { name: 'Taskforce Categories' },
      ],
    },
    name: 'TaskSubcategoriesSettings',
    component: () => import('@/modules/tasks/components/settings/subcategories/Subcategories.vue'),
  },
  {
    path: '/settings/cookie-policy',
    meta: {
      title: 'Cookie Policy',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Cookie Policy Settings' },
      ],
    },
    name: 'TasksSettings',
    component: () => import('@/modules/settings/views/CookiePolicySettings.vue'),
  },
  {
    path: '/settings/form-settings',
    meta: {
      title: 'Form Settings',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Form Settings' },
      ],
    },
    name: 'FormSettings',
    component: () => import('@/modules/settings/views/FormSettings.vue'),
  },
  {
    path: '/settings/credit-management',
    meta: {
      title: 'Credit Management',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Credit Management' },
      ],
    },
    name: 'CreditManagement',
    component: () => import('@/modules/settings/views/CreditManagement.vue'),
  },
  {
    path: '/settings/milestones',
    meta: {
      title: 'Milestones',
      breadcrumb: [
        { name: 'Settings', link: '/settings' },
        { name: 'Milestones' },
      ],
    },
    name: 'Milestones',
    component: () => import('@/modules/settings/views/Milestones.vue'),
  },
];
