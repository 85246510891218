export default [{
  name: 'CompanyStats',
  path: '/statistics/crm/company',
  meta: {
    title: 'Company Crm Statistics',
    breadcrumb: [
      { name: 'Company Crm Statistics' },
    ],
    hidePageHeader: true,
  },
  component: () => import('../views/CompanyStats.vue'),
},
{
  name: 'BranchStats',
  path: '/statistics/crm/branch/:branch_id',
  meta: {
    title: 'Branch Crm Statistics',
    breadcrumb: [
      { name: 'Branch Crm Statistics' },
    ],
    hidePageHeader: true,
  },
  component: () => import('../views/BranchStats.vue'),
},
{
  name: 'StatContactList',
  path: '/statistics/crm/contacts',
  meta: {
    title: 'Contact List',
    breadcrumb: [
      { name: 'Contact List' },
    ],
    hidePageHeader: false,
  },
  component: () => import('../components/list/StatContactList.vue'),
},
{
  name: 'StatOfferList',
  path: '/statistics/crm/offers',
  meta: {
    title: 'Received Offers',
    breadcrumb: [
      { name: 'Received Offers' },
    ],
    hidePageHeader: false,
  },
  component: () => import('../components/list/StatOfferList.vue'),
},
{
  name: 'StatFallenThroughList',
  path: '/statistics/crm/offers',
  meta: {
    title: 'Fallen Through Offers',
    breadcrumb: [
      { name: 'Fallen Through Offers' },
    ],
    hidePageHeader: false,
  },
  component: () => import('../components/list/StatOfferList.vue'),
},
{
  name: 'StatStcList',
  path: '/statistics/crm/sold_stc',
  meta: {
    title: 'Offer List',
    breadcrumb: [
      { name: 'Offer List' },
    ],
    hidePageHeader: false,
  },
  component: () => import('../components/list/StatStcList.vue'),
},
{
  name: 'StatPipelineList',
  path: '/statistics/crm/pipelines',
  meta: {
    title: 'Pipelines',
    breadcrumb: [
      { name: 'Pipelines' },
    ],
    hidePageHeader: false,
  },
  component: () => import('../components/list/StatPipelineList.vue'),
},
{
  name: 'StatPropertyList',
  path: '/statistics/crm/properties',
  meta: {
    title: 'Property List',
    breadcrumb: [
      { name: 'Property List' },
    ],
    hidePageHeader: false,
    isFromStats: true,
  },
  component: () => import('../components/list/StatPropertyList.vue'),
},
{
  name: 'DashboardPropertyList',
  path: '/statistics/crm/properties',
  meta: {
    title: 'Property List',
    breadcrumb: [
      { name: 'Property List' },
    ],
    hidePageHeader: false,
    isFromStats: false,
  },
  component: () => import('../components/list/StatPropertyList.vue'),
},
{
  name: 'StatMaList',
  path: '/statistics/crm/ma',
  meta: {
    title: 'Property List',
    breadcrumb: [
      { name: 'Property List' },
    ],
    hidePageHeader: false,
  },
  component: () => import('../components/list/StatMaList.vue'),
},
{
  name: 'StatViewingList',
  path: '/statistics/crm/viewings',
  meta: {
    title: 'Viewings',
    breadcrumb: [
      { name: 'Viewings' },
    ],
    hidePageHeader: false,
  },
  component: () => import('../components/list/statViewingList.vue'),
},
];
