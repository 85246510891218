import Vue from 'vue';
import * as types from './mutation_types';
import * as ErrorManager from '../../../mixins/ErrorManager';

export default {
  [types.GET_CSV_IMPORT_FIELDS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'csvImportFields', null);
    Vue.set(state, 'csvImportFieldsError', null);
  },
  [types.SET_CSV_IMPORT_FIELDS](state, data) {
    Vue.set(state, 'csvImportFields', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CSV_IMPORT_FIELDS_ERROR](state, error) {
    Vue.set(state, 'csvImportFieldsError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_IMPORT_CSV](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'importCsvSuccess', null);
    Vue.set(state, 'importCsvError', null);
  },
  [types.SET_IMPORT_CSV_SUCCESS](state, data) {
    Vue.set(state, 'importCsvSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_IMPORT_CSV_ERROR](state, error) {
    Vue.set(state, 'importCsvError', ErrorManager.getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_MAIL_LISTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_MAIL_LISTS](state, data) {
    Vue.set(state, 'mailLists', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_MAIL_LISTS_ERROR](state, error) {
    if (error.data.code === 404) {
      Vue.set(state, 'mailLists', []);
    }
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 2000,
    });
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CSV](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CSV](state, data) {
    Vue.set(state, 'csvList', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CSV_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 2000,
    });
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SEND_CSV](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendCsvSuccess', null);
    Vue.set(state, 'sendCsvError', null);
  },
  [types.SET_SEND_CSV_SUCCESS](state, data) {
    Vue.set(state, 'sendCsvSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SEND_CSV_ERROR](state, error) {
    Vue.set(state, 'sendCsvError', ErrorManager.getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
};
