export default {
  isLoading: false,
  error: null,
  cdnResult: null,
  cdnShortenerResult: null,
  cdnUploadProcessed: false,
  cdnUploadProcessedSuccess: false,
  cdnUploadProcessedError: false,
  cdnShortenerUploadProcessed: false,
  cdnShortenerUploadProcessedSuccess: false,
  cdnShortenerUploadProcessedError: false,
};
