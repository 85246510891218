export const eventTypes = state => state.eventTypes;
export const createValuationErrors = state => state.createValuationErrors;
export const calendarEventProcessed = state => state.calendarEventProcessed;
export const calendarEventProcessedSuccess = state => state.calendarEventProcessedSuccess;
export const calendarEventProcessedError = state => state.calendarEventProcessedError;
export const calendarEvents = (state) => {
  const { events } = state;
  if (!events) {
    return [];
  }
  const selectedTypes = new Set(state.selectedTypes);
  const selectedTransactionTypes = new Set(state.selectedTransactionTypes);
  const selectedViewingTypes = new Set(state.selectedViewingTypes);
  const selectedConfirmationTypes = new Set(state.selectedConfirmationTypes);
  const selectedUsers = new Set(state.selectedUsers);

  return events.filter((item) => {
    if (!selectedTypes.has(item.event_type_id)) {
      return false;
    }
    if (item.transaction_type_id && !selectedTransactionTypes.has(item.transaction_type_id)) {
      return false;
    }
    if (item.viewing_appointment && !selectedViewingTypes.has(item.viewing_appointment.viewing_appointment_type_id)) {
      return false;
    }
    if (item.viewing_appointment && !selectedConfirmationTypes.has(item.viewing_appointment.viewing_appointment_status_id)) {
      return false;
    }
    if (item.responsible_user && item.responsible_user.user_id && !selectedUsers.has(item.responsible_user.user_id)) {
      if ((!item.users || item.users.every(user => !selectedUsers.has(user.user_id)))) {
        return false;
      }
      if (!item.resourceIds || item.resourceIds.every(user => !selectedUsers.has(user))) {
        return false;
      }
    }
    if (item.responsible_user) {
      item.backgroundColor = item.responsible_user.color;
    }
    return true;
  });
};

export const miniCalendarEvents = (state) => {
  const { events } = state;
  if (!events) {
    return [];
  }
  const selectedTypes = new Set(state.selectedTypes);
  const selectedTransactionTypes = new Set(state.selectedTransactionTypes);
  const selectedViewingTypes = new Set(state.selectedViewingTypes);
  const selectedConfirmationTypes = new Set(state.selectedConfirmationTypes);
  const selectedUsers = new Set(state.selectedUsers);

  return events.filter((item) => {
    if (!selectedTypes.has(item.event_type_id)) {
      return false;
    }
    if (item.transaction_type_id && !selectedTransactionTypes.has(item.transaction_type_id)) {
      return false;
    }
    if (item.viewing_appointment && !selectedViewingTypes.has(item.viewing_appointment.viewing_appointment_type_id)) {
      return false;
    }
    if (item.viewing_appointment && !selectedConfirmationTypes.has(item.viewing_appointment.viewing_appointment_status_id)) {
      return false;
    }
    if (!selectedUsers.has(item.responsible_user.user_id)) {
      if ((!item.users || item.users.every(user => !selectedUsers.has(user.user_id)))) {
        return false;
      }
      if (!item.resourceIds || item.resourceIds.every(user => !selectedUsers.has(user))) {
        return false;
      }
    }
    if (item.responsible_user) {
      item.backgroundColor = item.responsible_user.color;
    }
    return true;
  });
};
export const error = state => state.error;
export const event = (state) => {
  if (state.event !== null) {
    if (state.event.contact_group_id !== null || state.event.contact_group_id !== 0) {
      state.event.contacts = state.event.contact_group.map(cg => cg.contact_id);
    }
    if (state.event.contact_group.length === 0) {
      state.event.contacts.push(state.event.contact_id);
    }
    return state.event;
  }
  return state.event;
};
export const selectedUsers = state => state.selectedUsers;
export const selectedDialogUsers = state => state.selectedDialogUsers;
export const selectedTypes = state => state.selectedTypes;
export const selectedDialogTypes = state => state.selectedDialogTypes;
export const selectedTransactionTypes = state => state.selectedTransactionTypes;
export const selectedDialogTransactionTypes = state => state.selectedDialogTransactionTypes;
export const fetchedRange = state => state.fetchedRange;
export const fetchedRecurring = state => state.fetchedRecurring;

// Viewing
export const show = state => state.show;
export const selectedApplicant = state => state.selectedApplicant;
export const selectedProperty = state => state.selectedProperty;
export const searchProfiles = state => state.searchProfiles;
export const viewedContact = state => state.viewedContact;
export const viewingData = state => state.viewingData;
export const tenantContact = state => state.tenant_contact;
export const calendarModal = state => state.calendarModal;
