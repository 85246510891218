export default [
  {
    name: 'Suppliers',
    path: '/suppliers',
    meta: {
      title: 'Suppliers',
      breadcrumb: [
        { name: 'Suppliers' },
      ],
    },
    component: () => import('../views/Suppliers.vue'),
  },
  {
    name: 'Companies',
    path: '/suppliers/companies/:category',
    meta: {
      title: 'Companies',
      breadcrumb: [
        { name: 'Suppliers', link: '/suppliers' },
        { name: 'Companies' },
      ],
    },
    component: () => import('../components/Companies.vue'),
  },
  {
    name: 'Employees',
    path: '/suppliers/companies/:category/members/:company',
    meta: {
      title: 'Employees',
      breadcrumb: [
        { name: 'Suppliers', link: '/suppliers' },
        { name: 'Employees' },
      ],
    },
    component: () => import('../components/Members.vue'),
  },
  {
    name: 'Related Property',
    path: '/suppliers/companies/:category/members/:company/related-property/:member',
    meta: {
      title: 'Related Property',
      breadcrumb: [
        { name: 'Suppliers', link: '/suppliers' },
        { name: 'Related Property' },
      ],
    },
    component: () => import('../components/RelatedProperty.vue'),
  },
];
