export default class SalutationsConstants {
  static DR = 1

  static MISS = 2

  static MR = 3

  static MRS = 4

  static MS = 5

  static UNKNOWN = 16


  static salutations = [
    {
      name: 'Dr.',
      salutation_id: SalutationsConstants.DR,
    },
    {
      name: 'Miss',
      salutation_id: SalutationsConstants.MISS,
    },
    {
      name: 'Mr.',
      salutation_id: SalutationsConstants.MR,
    },
    {
      name: 'Mrs.',
      salutation_id: SalutationsConstants.MRS,
    },
    {
      name: 'Ms.',
      salutation_id: SalutationsConstants.MS,
    },
    {
      name: 'Unknown',
      salutation_id: SalutationsConstants.UNKNOWN,
    },
  ];
}
