import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestCommunicationMethods = ({ commit }) => commit(types.GET_COMMUNICATION_METHODS);
export const receiveCommunicationMethodsSuccess = ({ commit }, data) => commit(types.SET_COMMUNICATION_METHODS, data);
export const receiveCommunicationMethodsError = ({ commit }, error) => commit(types.GET_COMMUNICATION_METHODS_ERROR, error);
export const fetchCommunicationMethods = ({ dispatch }) => {
  dispatch('requestCommunicationMethods');
  Api.get('communication_methods?display=1')
    .then(({ data }) => {
      dispatch('receiveCommunicationMethodsSuccess', data);
    })
    .catch(({ response }) => { dispatch('receiveCommunicationMethodsError', response); });
};
