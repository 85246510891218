export default {
  error: null,
  isLoading: false,
  roles: null,
  user: null,
  browserNotifications: null,
  accounts: null,
  senderEmails: null,
  senderEmail: null,
  senderSmsSettings: null,
  senderSms: null,
  companies: null,
  trackingSites: null,
  period: null,
  defaultSettings: null,
  defaultSettingsSales: null,
  defaultSettingsLettings: null,
  salesFaqs: null,
  lettingsFaqs: null,
  marketingPlanOverview: null,
  overviewMarketingPlanUpdateMessage: null,
  companySettings: null,

  notificationTypes: null,
  automations: null,
  notificationRule: null,
  notificationChannels: null,
  socialMediaAccountAllPages: null,

  createRuleProcessed: false,
  createRuleProcessedSuccess: false,
  createRuleProcessedError: false,

  updateRuleProcessedSuccess: false,
  updateRuleProcessedError: false,

  createUserProcessedSuccess: false,
  createUserProcessedError: false,

  updateUserProcessedSuccess: false,
  updateUserProcessedError: false,

  updateSocialMediaProcessed: false,
  updateSocialMediaProcessedSuccess: false,
  updateSocialMediaProcessedError: false,

  createSocialMediaProcessed: false,
  createSocialMediaProcessedSuccess: false,
  createSocialMediaProcessedError: false,

  createSocialMediaAccountPagesProcessed: false,
  createSocialMediaAccountPagesProcessedSuccess: false,
  createSocialMediaAccountPagesProcessedError: false,

  updateSocialMediaAccountPagesProcessed: false,
  updateSocialMediaAccountPagesProcessedSuccess: false,
  updateSocialMediaAccountPagesProcessedError: false,

  updateSocialMediaAccountPageProcessed: false,
  updateSocialMediaAccountPageProcessedSuccess: false,
  updateSocialMediaAccountPageProcessedError: false,

  createSocialMediaPageProcessed: false,
  createSocialMediaPageProcessedSuccess: false,
  createSocialMediaPageProcessedError: false,

  deleteSocialMediaPageProcessed: false,
  deleteSocialMediaPageProcessedSuccess: false,
  deleteSocialMediaPageProcessedError: false,

  deleteSocialMediaAccountProcessed: false,
  deleteSocialMediaAccountProcessedSuccess: false,
  deleteSocialMediaAccountProcessedError: false,

  socialMedia: null,
  socialMediaError: null,
  socialMediaAccounts: null,
  socialMediaAccountsTotal: null,
  socialMediaAccountsError: null,
  socialMediaAccount: null,
  socialMediaAccountError: null,
  socialMediaAccountPages: null,
  socialMediaAccountPagesError: null,
  socialMediaAccountPage: null,
  socialMediaAccountPageError: null,

  deleteUserProcessed: false,
  deleteUserProcessedSuccess: false,
  deleteUserProcessedError: false,

  updateWebPushProcessed: false,
  updateWebPushProcessedSuccess: false,
  updateWebPushProcessedError: false,

  createNewEmailSenderProcessed: false,
  createNewEmailSenderProcessedSuccess: false,
  createNewEmailSenderProcessedError: false,

  createNewSmsSenderProcessed: false,
  createNewSmsSenderProcessedSuccess: false,
  createNewSmsSenderProcessedError: false,

  updateEmailSenderProcessed: false,
  updateEmailSenderProcessedSuccess: false,
  updateEmailSenderProcessedError: false,

  deleteEmailSenderProcessed: false,
  deleteEmailSenderProcessedSuccess: false,
  deleteEmailSenderProcessedError: false,

  updateSmsSenderProcessed: false,
  updateSmsSenderProcessedSuccess: false,
  updateSmsSenderProcessedError: false,

  deleteSmsSenderProcessed: false,
  deleteSmsSenderProcessedSuccess: false,
  deleteSmsSenderProcessedError: false,

  updateCompaniesLogoProcessed: false,
  updateCompaniesLogoProcessedSuccess: false,
  updateCompaniesLogoProcessedError: false,

  updateCompanySettingsProcessed: false,
  updateCompanySettingsProcessedSuccess: false,
  updateCompanySettingsProcessedError: false,

  updateBaitSettingsProcessed: false,
  updateBaitSettingsProcessedSuccess: false,
  updateBaitSettingsProcessedError: false,

  updateBaitMarketingPlanOverviewProcessed: false,
  updateBaitMarketingPlanOverviewProcessedSuccess: false,
  updateBaitMarketingPlanOverviewProcessedError: false,

  blogSettings: null,
  blogSettingsError: null,
  updateBlogSettings: null,
  updateBlogSettingsError: null,
  blogMenus: null,
  blogMenusError: null,
  blogMenu: null,
  blogMenuError: null,
  createBlogMenu: null,
  createBlogMenuError: null,
  deleteBlogMenu: null,
  deleteBlogMenuError: null,
  updateBlogMenu: null,
  updateBlogMenuError: null,
  sortBlogMenu: null,
  sortBlogMenuError: null,
  colors: [
    {
      background: '#8585bc',
    },
    {
      background: '#ae6fc7',
    },
    {
      background: '#ac6c6c',
    },
    {
      background: '#cbd070',
    },
    {
      background: '#83c76f',
    },
    {
      background: '#ffb432',
    },
    {
      background: '#2690ec',
    },
    {
      background: '#09baa6',
    },
    {
      background: '#77a734',
    },
    {
      background: '#d91b1b',
    },
    {
      background: '#6eadff',
    },
    {
      background: '#ed0080',
    },
    {
      background: '#232b2b',
    },
    {
      background: '#09acba',
    },
    {
      background: '#c94d5c',
    },
    {
      background: '#52749b',
    },
  ],
  userError: false,
  customFieldGroups: false,
  updateCustomFieldGroupSuccess: false,
  updateCustomFieldGroupError: false,
  createCustomFieldGroupSuccess: false,
  createCustomFieldGroupError: false,
  createCustomFieldSuccess: false,
  createCustomFieldError: false,
  updateCustomFieldSuccess: false,
  updateCustomFieldError: false,
  propertyLive: null,
  socialAuthStatus: null,
  locations: null,
  location: null,
  updateSortLocationSuccess: false,
  updateSortLocationError: false,
  providerSettings: null,
  zonesData: [
    {
      mapName: 'Branch 1',
      mapType: 'draw',
      lat: 54.564102717124044,
      lng: -5.943986659641274,
      paths: [
        {
          lat: 54.564102717124044,
          lng: -5.943986659641274,
        },
        {
          lat: 54.59235883012511,
          lng: -6.006471400852211,
        },
        {
          lat: 54.63093059066503,
          lng: -5.968019252414711,
        },
        {
          lat: 54.62337817604498,
          lng: -5.900727992649086,
        },
        {
          lat: 54.59235883012511,
          lng: -5.885621791477211,
        },
        {
          lat: 54.564102717124044,
          lng: -5.888368373508461,
        },
      ],
    },
    {
      mapName: 'Branch 2',
      mapType: 'postcode',
      lat: 53.29805947373136,
      lng: -6.331803774450506,
      paths: [],
      postCodes: [
        {
          postcode: 'CM38 EQ',
        },
        {
          postcode: 'BT9 6GH',
        },
        {
          postcode: 'E18 2LY',
        },
        {
          postcode: 'EN5 5ED',
        },
      ],
    },
    {
      mapName: 'Branch 3',
      lat: 53.62117642516291,
      lng: -2.2185382433844247,
      paths: [
        {
          lat: 53.62117642516291,
          lng: -2.2185382433844247,
        },
        {
          lat: 53.57227643342311,
          lng: -2.2075519152594247,
        },
        {
          lat: 53.570237705340745,
          lng: -2.1594867297125497,
        },
        {
          lat: 53.593065845877554,
          lng: -2.1285876818609872,
        },
        {
          lat: 53.612623012223246,
          lng: -2.1093616076422372,
        },
        {
          lat: 53.62809934733768,
          lng: -2.1121081896734872,
        },
        {
          lat: 53.63217112404421,
          lng: -2.1464404650641122,
        },
        {
          lat: 53.63298543223597,
          lng: -2.1883258410406747,
        },
      ],
    },
    {
      mapName: 'Branch 4',
      lat: 53.45067368527961,
      lng: -2.9846374938672193,
      paths: [
        {
          lat: 53.45067368527961,
          lng: -2.9846374938672193,
        },
        {
          lat: 53.39830127810476,
          lng: -2.9805176208203443,
        },
        {
          lat: 53.37331984909452,
          lng: -2.929019207734407,
        },
        {
          lat: 53.399120093177956,
          lng: -2.891940350312532,
        },
        {
          lat: 53.455989149261704,
          lng: -2.8843872497265943,
        },
        {
          lat: 53.467026833393824,
          lng: -2.938632244843782,
        },
      ],
    },
  ],
  creditInfo: null,
  creditFetchedTime: null,
};
