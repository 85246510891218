export const GET_BAIT = 'GET_BAIT';
export const SET_BAIT = 'SET_BAIT';
export const GET_BAIT_ERROR = 'GET_BAIT_ERROR';

export const GET_LENGTH_OF_AGREEMENTS = 'GET_LENGTH_OF_AGREEMENTS';
export const SET_LENGTH_OF_AGREEMENTS = 'SET_LENGTH_OF_AGREEMENTS';
export const GET_LENGTH_OF_AGREEMENTS_ERROR = 'GET_LENGTH_OF_AGREEMENTS_ERROR';
export const RESET_LENGTH_OF_AGREEMENTS = 'RESET_LENGTH_OF_AGREEMENTS';

export const CREATE_LENGTH_OF_AGREEMENT = 'CREATE_LENGTH_OF_AGREEMENT';
export const CREATE_LENGTH_OF_AGREEMENT_SUCCESS = 'CREATE_LENGTH_OF_AGREEMENT_SUCCESS';
export const CREATE_LENGTH_OF_AGREEMENT_ERROR = 'CREATE_LENGTH_OF_AGREEMENT_ERROR';

export const UPDATE_LENGTH_OF_AGREEMENT = 'UPDATE_LENGTH_OF_AGREEMENT';
export const UPDATE_LENGTH_OF_AGREEMENT_SUCCESS = 'UPDATE_LENGTH_OF_AGREEMENT_SUCCESS';
export const UPDATE_LENGTH_OF_AGREEMENT_ERROR = 'UPDATE_LENGTH_OF_AGREEMENT_ERROR';

export const GET_BAIT_DEFAULT_SETTINGS = 'GET_BAIT_DEFAULT_SETTINGS';
export const SET_BAIT_DEFAULT_SETTINGS = 'SET_BAIT_DEFAULT_SETTINGS';
export const GET_BAIT_DEFAULT_SETTINGS_ERROR = 'GET_BAIT_DEFAULT_SETTINGS_ERROR';
export const RESET_BAIT_DEFAULT_SETTINGS = 'RESET_BAIT_DEFAULT_SETTINGS';

export const GET_BAIT_FORM_ANSWERS = 'GET_BAIT_FORM_ANSWERS';
export const SET_BAIT_FORM_ANSWERS = 'SET_BAIT_FORM_ANSWERS';
export const RESET_BAIT_FORM_ANSWERS = 'RESET_BAIT_FORM_ANSWERS';

export const GET_FEES = 'GET_FEES';
export const SET_FEES = 'SET_FEES';
export const GET_FEES_ERROR = 'GET_FEES_ERROR';
export const RESET_FEES = 'RESET_FEES';

export const CREATE_FEE = 'CREATE_FEE';
export const CREATE_FEE_SUCCESS = 'CREATE_FEE_SUCCESS';
export const CREATE_FEE_ERROR = 'CREATE_FEE_ERROR';

export const UPDATE_FEE = 'UPDATE_FEE';
export const UPDATE_FEE_SUCCESS = 'UPDATE_FEE_SUCCESS';
export const UPDATE_FEE_ERROR = 'UPDATE_FEE_ERROR';

export const SAVE_FEES = 'SAVE_FEES';
export const SAVE_FEES_SUCCESS = 'SAVE_FEES_SUCCESS';
export const SAVE_FEES_ERROR = 'SAVE_FEES_ERROR';

export const DELETE_FEE = 'DELETE_FEE';
export const DELETE_FEE_SUCCESS = 'DELETE_FEE_SUCCESS';
export const DELETE_FEE_ERROR = 'DELETE_FEE_ERROR';

export const UPDATE_BAIT = 'UPDATE_BAIT';
export const UPDATE_BAIT_SUCCESS = 'UPDATE_BAIT_SUCCESS';
export const UPDATE_BAIT_ERROR = 'UPDATE_BAIT_ERROR';

export const SEND_APPOINTMENT_MAIL = 'SEND_APPOINTMENT_MAIL';
export const SEND_APPOINTMENT_MAIL_SUCCESS = 'SEND_APPOINTMENT_MAIL_SUCCESS';
export const SEND_APPOINTMENT_MAIL_ERROR = 'SEND_APPOINTMENT_MAIL_ERROR';

export const GET_BAIT_STATUSES = 'GET_BAIT_STATUSES';
export const SET_BAIT_STATUSES = 'SET_BAIT_STATUSES';
export const GET_BAIT_STATUSES_ERROR = 'GET_BAIT_STATUSES_ERROR';

export const UPDATE_BAIT_STATUS = 'UPDATE_BAIT_STATUS';
export const UPDATE_BAIT_STATUS_SUCCESS = 'UPDATE_BAIT_STATUS_SUCCESS';
export const UPDATE_BAIT_STATUS_ERROR = 'UPDATE_BAIT_STATUS_ERROR';

export const REVERT_BAIT_LAST_STATUS = 'REVERT_BAIT_LAST_STATUS';
export const REVERT_BAIT_LAST_STATUS_SUCCESS = 'REVERT_BAIT_LAST_STATUS_SUCCESS';
export const REVERT_BAIT_LAST_STATUS_ERROR = 'REVERT_BAIT_LAST_STATUS_ERROR';

export const GET_FAQS_BY_BAIT_ID = 'GET_FAQS_BY_BAIT_ID';
export const SET_FAQS_BY_BAIT_ID = 'SET_FAQS_BY_BAIT_ID';
export const GET_FAQS_BY_BAIT_ID_ERROR = 'GET_FAQS_BY_BAIT_ID_ERROR';
export const RESET_FAQS_BY_BAIT_ID = 'RESET_FAQS_BY_BAIT_ID';

export const CREATE_FAQ = 'CREATE_FAQ';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_ERROR = 'CREATE_FAQ_ERROR';

export const UPDATE_FAQ = 'UPDATE_FAQ';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_ERROR = 'UPDATE_FAQ_ERROR';

export const DELETE_FAQ = 'DELETE_FAQ';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR';

export const GET_COMPARABLE_PROPERTIES_BY_BAIT_ID = 'GET_COMPARABLE_PROPERTIES_BY_BAIT_ID';
export const SET_COMPARABLE_PROPERTIES_BY_BAIT_ID = 'SET_COMPARABLE_PROPERTIES_BY_BAIT_ID';
export const GET_COMPARABLE_PROPERTIES_BY_BAIT_ID_ERROR = 'GET_COMPARABLE_PROPERTIES_BY_BAIT_ID_ERROR';
export const RESET_COMPARABLE_PROPERTIES_BY_BAIT_ID = 'RESET_COMPARABLE_PROPERTIES_BY_BAIT_ID';

export const CREATE_COMPARABLE_PROPERTY = 'CREATE_COMPARABLE_PROPERTY';
export const CREATE_COMPARABLE_PROPERTY_SUCCESS = 'CREATE_COMPARABLE_PROPERTY_SUCCESS';
export const CREATE_COMPARABLE_PROPERTY_ERROR = 'CREATE_COMPARABLE_PROPERTY_ERROR';

export const UPDATE_COMPARABLE_PROPERTY = 'UPDATE_COMPARABLE_PROPERTY';
export const UPDATE_COMPARABLE_PROPERTY_SUCCESS = 'UPDATE_COMPARABLE_PROPERTY_SUCCESS';
export const UPDATE_COMPARABLE_PROPERTY_ERROR = 'UPDATE_COMPARABLE_PROPERTY_ERROR';

export const DELETE_COMPARABLE_PROPERTY = 'DELETE_COMPARABLE_PROPERTY';
export const DELETE_COMPARABLE_PROPERTY_SUCCESS = 'DELETE_COMPARABLE_PROPERTY_SUCCESS';
export const DELETE_COMPARABLE_PROPERTY_ERROR = 'DELETE_COMPARABLE_PROPERTY_ERROR';

export const SEND_APPOINTMENT_OR_BAIT_SMS = 'SEND_APPOINTMENT_OR_BAIT_SMS';
export const SEND_APPOINTMENT_OR_BAIT_SMS_SUCCESS = 'SEND_APPOINTMENT_OR_BAIT_SMS_SUCCESS';
export const SEND_APPOINTMENT_OR_BAIT_SMS_ERROR = 'SEND_APPOINTMENT_OR_BAIT_SMS_ERROR';

export const GET_CONTENTS_BY_BAIT_ID = 'GET_CONTENTS_BY_BAIT_ID';
export const SET_CONTENTS_BY_BAIT_ID = 'SET_CONTENTS_BY_BAIT_ID';
export const GET_CONTENTS_BY_BAIT_ID_ERROR = 'GET_CONTENTS_BY_BAIT_ID_ERROR';
export const RESET_CONTENTS_BY_BAIT_ID = 'RESET_CONTENTS_BY_BAIT_ID';

export const CREATE_BAIT_CONTENT = 'CREATE_BAIT_CONTENT';
export const CREATE_BAIT_CONTENT_SUCCESS = 'CREATE_BAIT_CONTENT_SUCCESS';
export const CREATE_BAIT_CONTENT_ERROR = 'CREATE_BAIT_CONTENT_ERROR';

export const SEND_APPOINTMENT_OR_BAIT_EMAIL = 'SEND_APPOINTMENT_OR_BAIT_EMAIL';
export const SEND_APPOINTMENT_OR_BAIT_EMAIL_SUCCESS = 'SEND_APPOINTMENT_OR_BAIT_EMAIL_SUCCESS';
export const SEND_APPOINTMENT_OR_BAIT_EMAIL_ERROR = 'SEND_APPOINTMENT_OR_BAIT_EMAIL_ERROR';

export const GET_BAIT_CONTENTS = 'GET_BAIT_CONTENTS';
export const SET_BAIT_CONTENTS = 'SET_BAIT_CONTENTS';
export const GET_BAIT_CONTENTS_ERROR = 'GET_BAIT_CONTENTS_ERROR';

export const CREATE_COMPLETION_TOOL = 'CREATE_COMPLETION_TOOL';
export const CREATE_COMPLETION_TOOL_SUCCESS = 'CREATE_COMPLETION_TOOL_SUCCESS';
export const CREATE_COMPLETION_TOOL_ERROR = 'CREATE_COMPLETION_TOOL_ERROR';

export const GET_BAIT_ASKED_QUESTIONS = 'GET_BAIT_ASKED_QUESTIONS';
export const SET_BAIT_ASKED_QUESTIONS = 'SET_BAIT_ASKED_QUESTIONS';
export const GET_BAIT_ASKED_QUESTIONS_ERROR = 'GET_BAIT_ASKED_QUESTIONS_ERROR';
export const RESET_BAIT_ASKED_QUESTIONS = 'RESET_BAIT_ASKED_QUESTIONS';

export const GET_SEND_QUICK_EMAIL_AND_SMS = 'GET_SEND_QUICK_EMAIL_AND_SMS';
export const SET_SEND_QUICK_EMAIL_AND_SMS = 'SET_SEND_QUICK_EMAIL_AND_SMS';
export const RESET_SEND_QUICK_EMAIL_AND_SMS = 'RESET_SEND_QUICK_EMAIL_AND_SMS';
export const SET_SEND_QUICK_EMAIL_AND_SMS_ERROR = 'SET_SEND_QUICK_EMAIL_AND_SMS_ERROR';

export const GET_CHECK_BAIT_REQUIREMENTS = 'GET_CHECK_BAIT_REQUIREMENTS';
export const SET_CHECK_BAIT_REQUIREMENTS = 'SET_CHECK_BAIT_REQUIREMENTS';
export const GET_CHECK_BAIT_REQUIREMENTS_ERROR = 'GET_CHECK_BAIT_REQUIREMENTS_ERROR';

export const GET_CHANGE_BAIT_PUBLISH_STATUS = 'GET_CHANGE_BAIT_PUBLISH_STATUS';
export const SET_CHANGE_BAIT_PUBLISH_STATUS_SUCCESS = 'SET_CHANGE_BAIT_PUBLISH_STATUS_SUCCESS';
export const GET_CHANGE_BAIT_PUBLISH_STATUS_ERROR = 'GET_CHANGE_BAIT_PUBLISH_STATUS_ERROR';
