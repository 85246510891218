import ApiService from '@/core/api/api.service';

export default class ContractsTagsServices extends ApiService {
  constructor() {
    super();
    this.url = 'sg/tags';
  }

  setLoader() {
    this.loader = false;
  }

  async fetch() {
    this.params = {
      __limit: 250,
    };
    const { result: { data } } = await this.get();
    return data;
  }

  async create(payload) {
    this.payload = payload;
    return this.baseRemitter('patch', 'data');
  }
}
