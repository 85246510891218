import Vue from 'vue';
import loginService from '@/modules/users/services/login.service';
import logoutService from '@/modules/users/services/logout.service';

import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';

export default {
  /* Login */
  [types.SET_TOKEN](state, data) {
    Vue.set(state, 'isLoading', false);
    loginService(data);
  },
  [types.SET_REMEMBER]() {
    localStorage.setItem('is_remember', true);
  },
  [types.SET_NOT_REMEMBER]() {
    localStorage.setItem('is_remember', false);
  },
  [types.RESET_TOKEN_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'authError', null);
  },
  [types.GET_TOKEN](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'authError', null);
  },
  [types.GET_TOKEN_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'authError', error);
  },
  /* Reset Password */
  [types.RESET_RESET_PASSWORD](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'resetPasswordError', null);
    Vue.set(state, 'resetPasswordSuccess', null);
  },
  [types.RESET_PASSWORD](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'resetPasswordError', null);
    Vue.set(state, 'resetPasswordSuccess', null);
  },
  [types.GET_RESET_PASSWORD_ERROR](state, error) {
    Vue.set(state, 'resetPasswordError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_RESET_PASSWORD_SUCCESS](state, success) {
    Vue.set(state, 'resetPasswordSuccess', success);
    Vue.set(state, 'isLoading', false);
  },
  /* Update Password */
  [types.RESET_UPDATE_PASSWORD](state) {
    Vue.set(state, 'updatePasswordSuccess', null);
    Vue.set(state, 'updatePasswordError', null);
  },
  [types.UPDATE_PASSWORD](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updatePasswordSuccess', null);
    Vue.set(state, 'updatePasswordError', null);
  },
  [types.GET_UPDATE_PASSWORD_ERROR](state, error) {
    Vue.set(state, 'updatePasswordError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_PASSWORD_SUCCESS](state, success) {
    Vue.set(state, 'updatePasswordSuccess', success);
    Vue.set(state, 'isLoading', false);
  },
  /* BACKEND AUTO LOGIN:START */
  [types.MAKE_AUTO_BACKEND_LOGIN](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_MAKE_AUTO_BACKEND_LOGIN_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    loginService(data);
  },
  [types.GET_MAKE_AUTO_BACKEND_LOGIN_ERROR]() {
    logoutService();
  },
  /* BACKEND AUTO LOGIN:FINISH */
};
