export default {
  error: null,
  isLoading: false,
  dashboardSalesStats: {},
  dashboardLettingsStats: {},
  mapSalesStats: [],
  mapLettingsStats: [],
  dashboardLeads: {
    sales: null,
    lettings: null,
  },
};
