export default [{
  name: 'Registration',
  path: 'applicant',
  meta: {
    title: 'Registration',
    breadcrumb: [
      { name: 'Contacts', link: '/contacts' },
      { name: 'Registration' },
    ],
  },
  component: () => import('../views/RegistrationMenu.vue'),
},
{
  name: 'BuyerRegistration',
  path: 'applicant/:transaction_type',
  meta: {
    title: 'Registration',
    breadcrumb: [
      { name: 'Registration', link: '/applicant' },
      { name: 'Buyer Registration' },
    ],
  },
  component: () => import('../views/Registration.vue'),
}];
