import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestSharedPostsInSocialMedia = ({ commit }) => commit(types.GET_SHARED_POSTS_IN_SOCIAL_MEDIA);
export const receiveSharedPostsInSocialMediaSuccess = ({ commit }, data) => commit(types.SET_SHARED_POSTS_IN_SOCIAL_MEDIA, data);
export const receiveSharedPostsInSocialMediaError = ({ commit }, error) => commit(types.GET_SHARED_POSTS_IN_SOCIAL_MEDIA_ERROR, error);

export const fetchSharedPostsInSocialMedia = ({ dispatch }, filter) => {
  dispatch('requestSharedPostsInSocialMedia');

  Api.get(`gb/articles${filter.request}`)
    .then(({ data }) => { dispatch('receiveSharedPostsInSocialMediaSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSharedPostsInSocialMediaError', response); });
};
