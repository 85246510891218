import { parseLocalSItem } from '@/core/providers/local_storage/localStorage.provider';

export const users = state => state.users;
export const me = state => state.user;
export const userRoles = (state) => {
  const roles = [];
  if (state.user && state.user.roles) {
    const allRoles = state.user.roles;
    for (let i = 0; i < allRoles.length; i += 1) {
      roles.push(allRoles[i].role_id);
    }
  }
  return roles;
};

export const isNewLeadsActive = (state, getters) => {
  if (getters.me) {
    if (!getters.isProduction) {
      return true;
    }

    return [293, 309, 302, 369, 269, 393, 359, 314, 297, 342, 315, 400, 405, 397, 278, 420, 255, 339, 384, 391, 363, 294, 407, 292, 272, 379, 375, 296, 276, 253, 280, 334].includes(getters.me.company_id);
  }
  return false;
};

export const isProduction = () => process.env.VUE_APP_LIFESYCLE_ENVIRONMENT_TYPE === 'production';

export const isDevelopment = () => process.env.VUE_APP_LIFESYCLE_ENVIRONMENT_TYPE === 'development';

export const isUzairEnabled = (state, getters, rootState, rootGetters) => {
  const appConfigs = rootGetters['Common/appConfigs'] ? rootGetters['Common/appConfigs'] : null;
  const usersMe = rootGetters['Users/me'];
  const isLogged = !!rootState.Users.isLogged;

  if (appConfigs && isLogged && usersMe) {
    return appConfigs.is_uzair_launched;
  }
  return false;
};

export const isMagicModeActive = (state, getters, rootState, rootGetters) => parseLocalSItem('isMagicMode', 'number') && (parseLocalSItem('is_not_natural', 'number') || rootGetters['Users/isUzairEnabled']);

// eslint-disable-next-line no-unused-vars
export const isMagicModeEnabled = (state, getters, rootState, rootGetters) => !!parseLocalSItem('isMagicMode', 'number');
