export default {
  data() {
    return {
      all: [],
    };
  },
  computed: {
    pagination() {
      return this.$store.state.Request.pagination;
    },
  },
  methods: {
    setSingleSelect(action, store) {
      this.$store.dispatch(`${action}/setSingle`, store);
    },
    unsetSingleSelect(action, store) {
      this.$store.dispatch(`${action}/unsetSingle`, store);
    },
    pageExists() {
      return this.all.find(p => p.activePage === this.pagination.activePage);
    },
    selectAll(action, store) {
      if (this.pageExists()) {
        this.unsetSelectAll(action, store);
      } else {
        this.setSelectAll(action, store);
      }
    },
    setSelectAll(action, store) {
      this.all.push({
        activePage: parseInt(this.pagination.activePage, 0),
      });
      this.$store.dispatch(`${action}/setAll`, store);
    },
    unsetSelectAll(action, store) {
      this.all.splice(this.all.findIndex(p => p.activePage === this.pagination.activePage), 1);
      this.$store.dispatch(`${action}/unsetAll`, store);
    },
  },
};
