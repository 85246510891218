export default [{
  name: 'BulkSms',
  path: '/bulk_sms',
  meta: {
    title: 'Bulk SMS',
    breadcrumb: [
      { name: 'Bulk SMS' },
    ],
  },
  component: () => import('../views/BulkSms.vue'),
},
{
  name: 'BulkSmsDetail',
  path: '/bulk_sms_detail/:bulk_sms_id',
  meta: {
    title: 'Bulk SMS Details',
    breadcrumb: [
      { name: 'Bulk SMS', link: '/bulk_sms' },
      { name: 'Bulk SMS Details' },
    ],
  },
  component: () => import('../views/BulkSmsDetail.vue'),
}];
