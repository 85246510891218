import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_PROPERTY_TYPES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PROPERTY_TYPES](state, data) {
    Vue.set(state, 'propertyTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_TYPES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
};
