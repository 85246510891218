export default class BankHolidaysConstants {
  static BANK_HOLIDAYS = [
    '2024-08-26',
    '2024-12-25',
    '2024-12-26',
    '2025-01-01',
    '2025-04-18',
    '2025-04-21',
    '2025-05-05',
    '2025-05-26',
    '2025-08-25',
    '2025-12-25',
    '2025-12-26',
    '2026-01-01',
    '2026-04-03',
    '2026-04-06',
    '2026-05-04',
    '2026-05-25',
    '2026-08-31',
    '2026-12-25',
    '2026-12-28',
  ]
}
