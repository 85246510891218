export default [{
  name: 'SalesProgression',
  path: '/sales_progression',
  meta: {
    title: 'Sales Progression',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'Sales Progression', link: '/sales_progression' },
    ],
  },
  component: () => import('../views/SalesProgression.vue'),
},
{
  name: 'Offers',
  path: '/offers',
  meta: {
    title: 'Offers',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'Offers', link: '/offers' },
    ],
  },
  component: () => import('../views/Offers.vue'),
},
{
  name: 'WaitingFeedbacks',
  path: '/waiting_feedbacks',
  meta: {
    title: 'Viewing Feedback',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'Waiting Feedbacks', link: '/waiting_feedbacks' },
    ],
  },
  component: () => import('../views/WaitingFeedbacks.vue'),
},
{
  name: 'Viewings',
  path: '/viewings',
  meta: {
    title: 'Viewings',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'Viewings', link: '/viewings' },
    ],
  },
  component: () => import('../views/Viewings.vue'),
},
{
  name: 'OfferDetail',
  path: '/offers/offer_detail/:offer_id',
  meta: {
    title: 'Sales Progression / Offer Detail',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'Sales Progression', link: '/sales_progression' },
      { name: 'Offer Detail' },
    ],
  },
  component: () => import('../views/OfferDetail.vue'),
},
{
  name: 'MemorandumOfSale',
  path: '/offers/offer_detail/:offer_id/memorandum_of_sale',
  meta: {
    title: 'Memorandum Of Sale',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'Sales Progression', link: '/sales_progression' },
    ],
  },
  component: () => import('../views/MemorandumOfSale.vue'),
},
{
  name: 'SalesChain',
  path: '/offers/offer_detail/:offer_id/sales-chain/:sale_chain_id',
  meta: {
    title: 'Sales Chain',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'Sales Chain' },
    ],
  },
  beforeEnter: (to, from, next) => {
    to.meta.breadcrumb[1].link = `/offers/offer_detail/${to.params.offer_id}`;
    next();
  },
  component: () => import('../views/SalesChain.vue'),
}];
