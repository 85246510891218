/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import { mapActions, mapState, mapGetters } from 'vuex';
import { buildQuery } from '@/core/utils/helper';

export default {
  /* When we send element to object, we do not change value but
   if you want to clear object's element, you should send like search: null
   status: 1 -> created (for hiding default sort, limit.) */
  computed: {
    ...mapState('Request', ['request', 'payload', 'pagination', 'sort', 'runningRequests']),
    ...mapGetters('Request', ['centralLoader', 'isProcessInTheBackground']),
  },
  methods: {
    ...mapActions('Request', [
      'setAction',
      'setLimit',
      'setSearch',
      'setSort',
      'setStaticFilter',
      'setDynamicFilter',
      'setTotal',
      'setPerPage',
      'setTotalPages',
      'setActivePage',
      'setSortSequence',
      'setSortField',
      'setSortTitle',
      'setSearchType',
      'setCentralLoader',
      'manageRequest',
    ]),
    requestGenerator(data) {
      let offsetVal = null;
      this.setAction(data.action);

      if (data.query) {
        const limitVal = this.controlField(data.query.limit, 'limit');
        const searchVal = this.controlField(data.query.search, 'search');
        const sortVal = this.controlField(data.query.sort, 'sort');
        const staticFilterVal = this.controlField(data.query.staticFilter, 'staticFilter');
        const dynamicFilterVal = this.controlField(data.query.dynamicFilter, 'dynamicFilter');

        // Offset Control and Calculate
        offsetVal = this.pagination.activePage !== 1 ? this.calculateOffset() : null;

        // Limit
        if (data.query.limit) {
          this.setLimit(limitVal);
        }
        // Search: Start
        if (data.query.search) {
          this.setSearch(this.encodeObjectForSearch(searchVal));
          offsetVal = 0;
          this.setActivePage(1);
          if (searchVal && searchVal.q) {
            this.setSearchType('general');
          } else if (data.settings.type === 'filter') {
            this.setSearchType(data.settings.type);
          } else {
            this.setSearchType('detailed');
          }
        } else if (!searchVal) {
          this.setSearch(null);
          offsetVal = 0;
          this.setActivePage(1);
        }
        // Search: End
        // Sort
        if (data.query.sort) {
          this.setSort(sortVal);
        }
        // Static Filter
        if (data.query.staticFilter) {
          this.setStaticFilter(this.encodeObjectForFilter(staticFilterVal));
        }
        // Dynamic Filter
        if (data.query.dynamicFilter) {
          this.setDynamicFilter(this.encodeObjectForFilter(dynamicFilterVal));
        }
      }
      const itemsData = { offsetVal, settings: { ...data.settings }, params: { ...data.params } };
      return this.getItems(itemsData);
    },
    requestGeneratorByURL(data) {
      const search = [];
      const dynamicFilter = [];
      this.setAction(data.action);
      // Control URL
      for (const [key, value] of Object.entries(data.query)) {
        if (key.slice(0, 7) === '_search') {
          if (Array.isArray(value)) {
            value.forEach((a) => {
              search.push(`${key}=${a}`);
            });
          } else {
            search.push(`${key}=${decodeURIComponent(value)}`);
          }
        } else if (key.slice(0, 7) !== '_search' && key.slice(0, 7) !== '__limit' && key.slice(0, 10) !== '__order_by' && key.slice(0, 8) !== '__offset') {
          dynamicFilter.push(`${key}=${decodeURIComponent(value)}`);
        }
      }

      const limitVal = this.controlField(data.query.__limit, 'limit');
      const searchVal = this.controlField(search.join('&'), 'search');
      const sortVal = this.controlField(data.query.__order_by, 'sort');
      const offsetVal = data.query.__offset;
      const dynamicFilterVal = this.controlField(dynamicFilter.join('&'), 'dynamicFilter');

      if (offsetVal) {
        this.setActivePage(this.calculateActivePage(offsetVal));
      }
      if (data.query.__limit) {
        this.setLimit(limitVal);
      }
      if (search.join('&')) {
        this.setSearch(searchVal);
        if (searchVal && searchVal.slice(0, 10) === '_search[q]') {
          this.setSearchType('general');
        } else {
          this.setSearchType('detailed');
        }
      }
      if (data.query.__order_by) {
        this.setSort(sortVal);
      }
      if (dynamicFilter.join('&')) {
        this.setDynamicFilter(dynamicFilterVal);
      }
      const itemsData = { offsetVal, settings: { ...data.settings }, params: { ...data.params } };
      return this.getItems(itemsData);
    },
    getItems(data) {
      const requestData = [];
      let requestStr = null;
      let request = null;

      if (this.payload.search) {
        requestData.push(this.payload.search);
      }
      if (this.payload.sort) {
        requestData.push(`__order_by=${this.payload.sort}`);
      }
      if (this.payload.staticFilter) {
        requestData.push(this.payload.staticFilter);
      }
      if (this.payload.dynamicFilter) {
        requestData.push(this.payload.dynamicFilter);
      }
      if (data.offsetVal) {
        requestData.push(`__offset=${data.offsetVal}`);
      }

      if (requestData && requestData.length > 0) {
        requestStr = `&${requestData.join('&')}`;
      }

      if (requestStr) {
        const searchObj = this.convertStringRouterQuery(this.payload.search);
        const dynamicObj = this.convertStringRouterQuery(this.payload.dynamicFilter);

        const query = {
          ...searchObj,
          ...dynamicObj,
        };

        if (data.settings.showQuery && this.payload.sort) {
          // when showQuery settings is true, show sort in query.
          query.__order_by = this.payload.sort;
        }
        if (data.settings.showQuery && this.payload.limit !== 20) {
          // when showQuery settings is true, show limit in query.
          query.__limit = this.payload.limit;
        }
        if (data.offsetVal && data.offsetVal > 0) {
          query.__offset = data.offsetVal;
        }

        if (data.settings.routerChange) {
          this.$router.push({
            query,
          });
        }

        request = `?__limit=${this.payload.limit}${requestStr}`;
      } else {
        if (data.settings.routerChange) {
          this.$router.push({});
        }
        request = `?__limit=${this.payload.limit}`;
      }
      return this.$store.dispatch(this.request.action, { request, params: { ...data.params } });
    },
    controlField(val, field) {
      return val !== undefined ? val : this.payload[field];
    },
    encodeObjectForSearch(obj) {
      const str = [];
      for (const a in obj) {
        if (obj.hasOwnProperty(a)) {
          // eslint-disable-next-line
          if (obj[a] && typeof obj[a] === 'object') {
            for (const b in obj[a]) {
              if (obj[a][b] && typeof obj[a][b] === 'object') {
                // eslint-disable-next-line
                for (const c in obj[a][b]) {
                  if (c === '[]') {
                    if (Array.isArray(obj[a][b][c])) {
                      // array - contact profiles or tags
                      for (let i = 0; i < obj[a][b][c].length; i += 1) {
                        if (obj[a][b][c][i]) {
                          str.push(`_search${encodeURIComponent(a)}[${encodeURIComponent(b)}]${encodeURIComponent(c)}=${obj[a][b][c][i]}`);
                        }
                      }
                    } else {
                      Object.keys(obj[a][b][c]).forEach((valueKey) => {
                        if (this.checkValue(obj[a][b][c][valueKey])) {
                          str.push(`_search${encodeURIComponent(a)}${encodeURIComponent(b)}${encodeURIComponent(c)}[${valueKey}]=${obj[a][b][c][valueKey]}`);
                        }
                      });
                    }
                  } else if (obj[a][b][c]) {
                    // gte - lte
                    str.push(`_search${encodeURIComponent(a)}[${encodeURIComponent(b)}]${encodeURIComponent(c)}=${obj[a][b][c]}`);
                  }
                }
              } else if (obj[a][b] !== null && obj[a][b] !== undefined) {
                str.push(`_search${encodeURIComponent(a)}[${encodeURIComponent(b)}]=${encodeURIComponent(obj[a][b])}`);
              }
            }
          } else {
            str.push(`_search[${encodeURIComponent(a)}]=${encodeURIComponent(obj[a])}`);
          }
        }
      }
      return str.join('&');
    },
    encodeObjectForFilter(obj) {
      const str = [];
      for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (Array.isArray(obj[p]) === false) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
          } else {
            obj[p].forEach((val) => {
              str.push(`${encodeURIComponent(p)}[]=${encodeURIComponent(val)}`);
            });
          }
        }
      }
      return str.join('&');
    },
    convertStringRouterQuery(val) {
      if (val) {
        const str = val.replace(/=/g, ':').split('&');

        const obj = {};

        const keyStore = [];
        str.forEach((item) => {
          const key = item.split(':')[0];
          if (keyStore.includes(key)) {
            obj[key] = [];
          } else {
            obj[key] = key.slice(key.length - 2, key.length) === '[]' ? [] : null;
          }
          keyStore.push(key);
        });


        str.forEach((item) => {
          Object.keys(obj).forEach((key) => {
            if (key === item.split(':')[0]) {
              if (Array.isArray(obj[key])) {
                obj[key].push(item.split(':')[1]);
              } else {
                obj[key] = item.split(':')[1];
              }
            }
          });
        });

        return obj;
      }
      return null;
    },
    calculateOffset() {
      return Math.ceil((this.pagination.activePage - 1) * this.pagination.perPage);
    },
    calculateSpacing(len) {
      return Math.ceil((this.pagination.activePage - 1) * this.pagination.perPage + len);
    },
    calculateActivePage(offsetVal) {
      return Math.ceil((offsetVal / this.pagination.perPage) + 1);
    },
    setPaginationData(totalCount) {
      const totalPages = Math.ceil(totalCount / this.pagination.perPage);
      this.setTotal(totalCount);
      this.setTotalPages(totalPages);
    },
    getByPage(data, overrideSettings) {
      this.setActivePage(data.page);
      const itemsData = { offsetVal: this.calculateOffset(), settings: {}, params: { ...data.params } };
      // show limit in query.
      if (overrideSettings) {
        itemsData.settings.showQuery = overrideSettings.showQuery;
        itemsData.settings.routerChange = overrideSettings.routerChange;
      } else {
        itemsData.settings.showQuery = true;
        itemsData.settings.routerChange = true;
      }
      this.getItems(itemsData);
    },
    resetRequest() {
      this.setActivePage(1);
      this.setLimit(20);
      this.setSearch(null);
      this.setSort(null);
      this.setStaticFilter(null);
      this.setDynamicFilter(null);
      this.setSearchType(null);

      this.setTotal(0);
      this.setPerPage(20);
      this.setTotalPages(5);
      this.setActivePage(1);

      this.setSortSequence(null);
      this.setSortField(null);
      this.setSortTitle('filter by');
    },
    doSort(newSort, overrideSettings) {
      const sequence = newSort.algorithm === '+' ? '' : '-';
      this.setSortSequence(sequence);
      const sequenceTitle = sequence === '-' ? '<i class="fal fa-arrow-alt-to-bottom"></i>' : '<i class="fal fa-arrow-alt-to-top"></i>';
      this.setSortTitle(`${sequenceTitle} ${newSort.name}`);
      this.setSortField(newSort.name);

      const { action } = this;

      // query
      const sort = `${this.sort.sequence}${this.sort.field}`;
      // settings
      const settings = {};
      if (overrideSettings) {
        settings.showQuery = overrideSettings.showQuery;
        settings.routerChange = overrideSettings.routerChange;
      } else {
        settings.showQuery = true;
        settings.routerChange = true;
      }

      // params
      const params = this.params ? this.params : {};

      const data = {
        action,
        query: { sort },
        settings,
        params,
      };
      this.requestGenerator(data);
    },
    sortByURL(val) {
      let index = null;
      if (val && val.slice(0, 1) === '-') {
        this.setSortSequence('-');
        this.setSortField(val.slice(1, val.length));
        index = this.sortFields.find(
          item => item.name === this.sort.field,
        );
        if (index) {
          this.setSortTitle(`<i class="fal fa-arrow-alt-to-bottom"></i> ${index.title}`);
        }
      } else if (val) {
        this.setSortSequence('');
        this.setSortField(val);
        index = this.sortFields.find(
          item => item.name === this.sort.field,
        );
        if (index) {
          this.setSortTitle(`<i class="fal fa-arrow-alt-to-top"></i> ${index.title}`);
        }
      }
    },
    sortAsDefault() {
      const DEFAULT_SORT = this.sortFields.find(field => field.default);
      const SEQUENCE = DEFAULT_SORT.algorithm === '+' ? '' : DEFAULT_SORT.algorithm;
      this.setSortSequence(SEQUENCE);
      this.setSortField(DEFAULT_SORT.name);
      const sort = `${this.sort.sequence}${this.sort.field}`;
      this.sortByURL(sort);
      return sort;
    },
    /**
     * Sets pagination data by data that came and its count.
     * @param {integer} count - data count
     * @param {array} data - data that includes list item.
     */
    setPaginationDataWhenDataCame(count, data) {
      if (count > 0 && data && data.length > 0) {
        this.setPaginationData(count);
      } else {
        this.setPaginationData(0);
      }
    },
    fillQuickSearchData(obj) {
      const query = { ...this.$route.query };
      Object.keys(query).forEach((val) => {
        const path = decodeURIComponent(val.slice(7));
        if (path.slice(0, 4) === '[is]' && this[obj]['[is]']) {
          const is = path.slice(4);
          if (is.slice(is.length - 2, is.length) === '[]') {
            let values;
            if (Array.isArray(query[val])) {
              values = query[val].map(x => parseFloat(x));
            } else {
              values = [parseFloat(query[val])];
            }
            if (this[obj]['[is]'][decodeURIComponent(is.slice(1, is.length - 3))] && this[obj]['[is]'][decodeURIComponent(is.slice(1, is.length - 3))]['[]']) {
              this[obj]['[is]'][decodeURIComponent(is.slice(1, is.length - 3))]['[]'] = values;
            }
          } else {
            this[obj]['[is]'][decodeURIComponent(is.slice(1, is.length - 1))] = decodeURIComponent(query[val]);
          }
        } else if (path.slice(0, 7) === '[range]' && this[obj]['[range]']) {
          const range = path.slice(7);
          if (range.slice(range.length - 5, range.length) === '[gte]') {
            this[obj]['[range]'][decodeURIComponent(range.slice(1, range.length - 6))]['[gte]'] = decodeURIComponent(query[val]);
          } else if (range.slice(range.length - 5, range.length) === '[lte]') {
            this[obj]['[range]'][decodeURIComponent(range.slice(1, range.length - 6))]['[lte]'] = decodeURIComponent(query[val]);
          }
        } else if (path.slice(0, 6) === '[like]' && this[obj]['[like]']) {
          const like = path.slice(6);
          this[obj]['[like]'][decodeURIComponent(like.slice(1, like.length - 1))] = query[val];
        } else if (path.slice(0, 8) === '[nested]' && this[obj]['[nested]']) {
          const nested = path.slice(8);
          if (nested.slice(0, 4) === '[is]') {
            const nestedArray = path.slice(12);
            if (nestedArray.slice(0, 2) === '[]') {
              const is = path.slice(14);
              this[obj]['[nested]']['[is]']['[]'][decodeURIComponent(is.slice(1, is.length - 1))] = query[val];
            }
          }
        }
      });
      if (this.routerIncomingData) {
        this.routerIncomingData = { ...this[obj] };
      }
    },
    buildQuery,
  },
};
