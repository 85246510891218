export const GET_SEARCH = 'GET_SEARCH';
export const SET_SEARCH = 'SET_SEARCH';
export const GET_SEARCH_ERROR = 'GET_SEARCH_ERROR';
export const RESET_SEARCH = 'RESET_SEARCH';

export const GET_ELASTIC_SEARCH = 'GET_ELASTIC_SEARCH';
export const SET_ELASTIC_SEARCH = 'SET_ELASTIC_SEARCH';
export const GET_ELASTIC_SEARCH_ERROR = 'GET_ELASTIC_SEARCH_ERROR';
export const RESET_ELASTIC_SEARCH = 'RESET_ELASTIC_SEARCH';


export const OPEN_LS_MODAL = 'OPEN_LS_MODAL';
export const CLOSE_LS_MODAL = 'CLOSE_LS_MODAL';
export const CLOSE_ALL_LS_MODALS = 'CLOSE_ALL_LS_MODALS';

export const SET_CONFIGS = 'SET_CONFIGS';
