export const GET_TAGS = 'GET_TAGS';
export const SET_TAGS = 'SET_TAGS';
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR';

export const GET_CREATE_TAG = 'GET_CREATE_TAG';
export const SET_CREATE_TAG_SUCCESS = 'SET_CREATE_TAG_SUCCESS';
export const GET_CREATE_TAG_ERROR = 'GET_CREATE_TAG_ERROR';

export const GET_AUTOCOMPLETE_TAG = 'GET_AUTOCOMPLETE_TAG';
export const SET_AUTOCOMPLETE_SUCCESS = 'SET_AUTOCOMPLETE_SUCCESS';
export const GET_AUTOCOMPLETE_ERROR = 'GET_AUTOCOMPLETE_ERROR';
