export const GET_FUNNEL_STATS = 'GET_FUNNEL_STATS';
export const SET_FUNNEL_STATS = 'SET_FUNNEL_STATS';
export const GET_FUNNEL_STATS_ERROR = 'GET_FUNNEL_STATS_ERROR';

export const GET_FUNNEL_STATS_CONTACTS = 'GET_FUNNEL_STATS_CONTACTS';
export const SET_FUNNEL_STATS_CONTACTS = 'SET_FUNNEL_STATS_CONTACTS';
export const GET_FUNNEL_STATS_CONTACTS_ERROR = 'GET_FUNNEL_STATS_CONTACTS_ERROR';

export const GET_CONTENT_STATS = 'GET_CONTENT_STATS';
export const SET_CONTENT_STATS = 'SET_CONTENT_STATS';
export const GET_CONTENT_STATS_ERROR = 'GET_CONTENT_STATS_ERROR';

export const GET_TEAM_STATS = 'GET_TEAM_STATS';
export const SET_TEAM_STATS = 'SET_TEAM_STATS';
export const GET_TEAM_STATS_ERROR = 'GET_TEAM_STATS_ERROR';

export const GET_PROPERTY_STATS = 'GET_PROPERTY_STATS';
export const SET_PROPERTY_STATS = 'SET_PROPERTY_STATS';
export const GET_PROPERTY_STATS_ERROR = 'GET_PROPERTY_STATS_ERROR';


export const GET_AUTOPILOT_STATS = 'GET_AUTOPILOT_STATS';
export const SET_AUTOPILOT_STATS = 'SET_AUTOPILOT_STATS';
export const GET_AUTOPILOT_STATS_ERROR = 'GET_AUTOPILOT_STATS_ERROR';
