export default {
  isLoading: false,
  error: null,
  success: null,
  vendorFunnelStats: null,
  landlordFunnelStats: null,
  contentStats: null,
  teamStats: null,
  propertyStats: null,
  funnelStatsContactsTotal: 0,
  funnelStatsContacts: null,
  autopilotStats: {},
};
