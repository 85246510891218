export default {
  user: null,
  users: null,
  error: null,
  isLogged: false,
  isLoading: false,
  branchUsers: null,
  userNotifyTypes: false,
  lsLang: 'en',
};
