import Api from '@/libraries/api';
import * as types from './mutation_types';

import { PropertyProfileServices, PropertyProfileTimelineServices } from '../services/propertyProfile.services';
import { PropertyProfileLiveServices } from '../services/propertyProfileLive.services';
import { PropertyDetailServices } from '../services/propertyDetail.services';

export const requestProperties = ({ commit }) => commit(types.GET_PROPERTIES);
export const receivePropertiesSuccess = ({ commit }, data) => commit(types.SET_PROPERTIES, data);
export const receivePropertiesError = ({ commit }, error) => commit(types.GET_PROPERTIES_ERROR, error);
export const fetchProperties = ({ dispatch }, filter) => {
  dispatch('requestProperties');
  let url;
  if (filter.params && filter.params.transaction_type) {
    url = `${filter.params.endpoint}${filter.request}&transaction_type_id=${filter.params.transaction_type}`;
  } else {
    url = `${filter.params.endpoint}${filter.request}`;
  }
  Api.get(url)
    .then(({ data }) => {
      dispatch('receivePropertiesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertiesError', response);
    });
};

export const requestPropertyTypes = ({ commit }) => commit(types.GET_PROPERTY_TYPES);
export const receivePropertyTypesSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_TYPES, data);
export const receivePropertyTypesError = ({ commit }, error) => commit(types.GET_PROPERTY_TYPES_ERROR, error);
export const fetchPropertyTypes = ({ dispatch }) => {
  dispatch('requestPropertyTypes');

  Api.get('properties/property_types')
    .then(({ data }) => {
      dispatch('receivePropertyTypesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyTypesError', response);
    });
};

export const requestPropertyStyles = ({ commit }) => commit(types.GET_PROPERTY_STYLES);
export const receivePropertyStylesSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_STYLES, data);
export const receivePropertyStylesError = ({ commit }, error) => commit(types.GET_PROPERTY_STYLES_ERROR, error);
export const fetchPropertyStyles = ({ dispatch }, type) => {
  dispatch('requestPropertyStyles');

  Api.get(`properties/property_styles?property_type_id=${type}`)
    .then(({ data }) => {
      dispatch('receivePropertyStylesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyStylesError', response);
    });
};

export const requestTenureTypes = ({ commit }) => commit(types.GET_TENURE_TYPES);
export const receiveTenureTypesSuccess = ({ commit }, data) => commit(types.SET_TENURE_TYPES, data);
export const receiveTenureTypesError = ({ commit }, error) => commit(types.GET_TENURE_TYPES_ERROR, error);
export const fetchTenureTypes = ({ dispatch }) => {
  dispatch('requestTenureTypes');

  Api.get('properties/tenure_types')
    .then(({ data }) => {
      dispatch('receiveTenureTypesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveTenureTypesError', response);
    });
};

export const requestAllPropertyProfileData = ({ dispatch }, propertyProfileId) => {
  dispatch('requestPropertyProfile');

  Api.get(`property_profiles/${propertyProfileId}`)
    .then(({ data }) => {
      dispatch('receivePropertyProfileSuccess', data);
      dispatch('fetchPropertyContacts', propertyProfileId);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyProfileError', response);
    });
};

export const requestUpdateProperty = ({ commit }) => commit(types.GET_UPDATE_PROPERTY);
export const receiveUpdatePropertySuccess = ({ commit }, data) => commit(types.SET_UPDATED_PROPERTY, data);
export const receiveUpdatePropertyError = ({ commit }, error) => commit(types.GET_UPDATE_PROPERTY_ERROR, error);
export const updateProperty = ({ dispatch }, payload) => {
  dispatch('requestUpdateProperty');

  Api.patch(`properties/${payload.property_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveUpdatePropertySuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdatePropertyError', response);
    });
};

export const requestUpdatePropertyProfile = ({ commit }) => commit(types.GET_UPDATE_PROPERTY_PROFILE);
export const receiveUpdatePropertyProfileSuccess = ({ commit }, data) => commit(types.SET_UPDATED_PROPERTY_PROFILE, data);
export const receiveUpdatePropertyProfileError = ({ commit }, error) => commit(types.GET_UPDATE_PROPERTY_PROFILE_ERROR, error);
export const updatePropertyProfile = ({ dispatch }, payload) => {
  dispatch('requestUpdatePropertyProfile');

  Api.patch(`properties/${payload.property_id}/profiles/${payload.property_profile_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveUpdatePropertyProfileSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdatePropertyProfileError', response);
    });
};


export const requestUpdatePropertyInformation = ({ commit }) => commit(types.GET_UPDATE_PROPERTY_INFORMATION);
export const receiveUpdatePropertyInformationSuccess = ({ commit }, data) => commit(types.SET_UPDATED_PROPERTY_INFORMATION, data);
export const receiveUpdatePropertyInformationError = ({ commit }, error) => commit(types.SET_UPDATE_PROPERTY_INFORMATION_ERROR, error);
export const updatePropertyInformation = ({ dispatch }, payload) => {
  dispatch('requestUpdatePropertyInformation');

  Api.patch(`properties/${payload.property_id}`, payload)
    .then(({ data: propertyData }) => {
      Api.patch(`properties/${payload.property_id}/profiles/${payload.property_profile_id}`, payload)
        .then(({ data: propertyProfileData }) => {
          dispatch('receiveUpdatePropertyInformationSuccess', { propertyProfileData, propertyData });
        })
        .catch(({ response }) => {
          dispatch('receiveUpdatePropertyInformationError', response);
        });
    })
    .catch(({ response }) => {
      dispatch('receiveUpdatePropertyInformationError', response);
    });
};


export const requestPropertyProfile = ({ commit }) => commit(types.GET_PROPERTY_PROFILE);
export const receivePropertyProfileSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_PROFILE, data);
export const receivePropertyProfileError = ({ commit }, error) => commit(types.GET_PROPERTY_PROFILE_ERROR, error);
export const updatePropertyProfilePartially = ({ commit }, data) => commit(types.UPDATE_PROPERTY_PROFILE_PARTIALLY, data);
export const fetchPropertyProfile = async ({ dispatch }, propertyProfileId) => {
  dispatch('requestPropertyProfile');
  try {
    const data = await new PropertyProfileServices().show(propertyProfileId);
    dispatch('receivePropertyProfileSuccess', data);
  } catch (response) {
    dispatch('receivePropertyProfileError', response);
  }
};

export const fetchUpdatedPropertyProfile = ({ dispatch }, payload) => {
  Api.get(`property_profiles/${payload}`)
    .then(({ data }) => {
      dispatch('receivePropertyProfileSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyProfileError', response);
    });
};

export const requestPropertyPrices = ({ commit }) => commit(types.GET_PROPERTY_PRICES);
export const receivePropertyPricesSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_PRICES, data);
export const receivePropertyPricesError = ({ commit }, error) => commit(types.GET_PROPERTY_PRICES_ERROR, error);
export const fetchPropertyPrices = ({ dispatch }, propertyProfileId) => {
  dispatch('requestPropertyPrices');

  Api.get(`property_profiles/${propertyProfileId}/prices`)
    .then(({ data }) => {
      dispatch('receivePropertyPricesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyPricesError', response);
    });
};

export const requestPropertyContacts = ({ commit }) => commit(types.GET_PROPERTY_CONTACTS);
export const resetPropertyContacts = ({ commit }) => commit(types.RESET_PROPERTY_CONTACTS);
export const receivePropertyContactsSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_CONTACTS, data);
export const receivePropertyContactsError = ({ commit }, error) => commit(types.GET_PROPERTY_CONTACTS_ERROR, error);
export const fetchPropertyContacts = async ({ dispatch }, propertyProfileId) => {
  dispatch('requestPropertyContacts');
  try {
    const data = await new PropertyProfileServices().fetchContacts(propertyProfileId);
    dispatch('receivePropertyContactsSuccess', data);
  } catch (response) {
    dispatch('receivePropertyContactsError', response);
  }
};

export const requestExportProperties = ({ commit }) => commit(types.GET_EXPORT_PROPERTIES);
export const receiveExportPropertiesSuccess = ({ commit }, data) => {
  commit(types.SET_EXPORT_PROPERTIES, data);
};
export const receiveExportPropertiesError = ({ commit }, error) => commit(types.GET_EXPORT_PROPERTIES_ERROR, error);
export const fetchExportProperties = ({ dispatch }, filter) => {
  dispatch('requestExportProperties');
  let filters = [...filter];
  if (filters) {
    filters = filters.join('&');
  }
  const query = `?${filters}&__limit=250`;

  Api.get(`property_profiles${query}`)
    .then(({ data }) => {
      dispatch('receiveExportPropertiesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveExportPropertiesError', response);
    });
};

export const setAll = ({ commit }, data) => {
  commit(types.SET_ALL_PROPERTY, data);
};
export const unsetAll = ({ commit }, data) => {
  commit(types.UNSET_ALL_PROPERTY, data);
};
export const clearSelectedProperties = ({ commit }) => {
  commit(types.CLEAR_SELECTED_PROPERTIES);
};

export const setSingle = ({ commit }, data) => {
  commit(types.SET_SELECTED_PROPERTY, data);
};

export const unsetSingle = ({ commit }, data) => {
  commit(types.UNSET_SELECTED_PROPERTY, data);
};


export const requestAdvancedSearch = ({ commit }) => commit(types.GET_ADVANCED_SEARCH);
export const receiveAdvancedSearchSuccess = ({ commit }, data) => commit(types.SET_ADVANCED_SEARCH, data);
export const receiveAdvancedSearchError = ({ commit }, error) => commit(types.GET_ADVANCED_SEARCH_ERROR, error);
export const fetchAdvancedSearch = ({ dispatch }, filters) => {
  dispatch('requestAdvancedSearch');
  Api.get('properties/filter?__offset=0&__limit=20', { filters: JSON.stringify(filters) })
    .then(({ data }) => {
      dispatch('receiveAdvancedSearchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAdvancedSearchError', response);
    });
};

export const requestPropertyFromAddress = ({ commit }) => {
  commit(types.GET_PROPERTY_FROM_ADDRESS);
};
export const receivePropertyFromAddressSuccess = ({ commit }, data) => {
  commit(types.SET_PROPERTY_FROM_ADDRESS, data);
};
export const receivePropertyFromAddressError = ({ commit }, error) => {
  commit(types.GET_PROPERTY_FROM_ADDRESS_ERROR, error);
};
export const fetchPropertyFromAddress = ({ dispatch }, address) => {
  dispatch('requestPropertyFromAddress');
  // delete address.lat;
  // delete address.long;

  Api.get('property_profiles/active_profile_from_address', address)
    .then(({ data }) => {
      dispatch('receivePropertyFromAddressSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyFromAddressError', response);
    });
};

export const requestBaitModuleEntries = ({ commit }) => commit(types.GET_BAIT_MODULE_ENTRIES);
export const receiveBaitModuleEntriesSuccess = ({ commit }, data) => commit(types.SET_BAIT_MODULE_ENTRIES, data);
export const receiveBaitModuleEntriesError = ({ commit }, error) => commit(types.GET_BAIT_MODULE_ENTRIES_ERROR, error);
export const fetchBaitModuleEntries = ({ dispatch }, filters) => {
  dispatch('requestBaitModuleEntries');
  Api.get(`bt/module_entries?bait_id=${filters.bait_id}`)
    .then(({ data }) => {
      dispatch('receiveBaitModuleEntriesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBaitModuleEntriesError', response);
    });
};


export const requestDeleteBaitModuleEntry = ({ commit }) => commit(types.GET_DELETE_BAIT_MODULE_ENTRY);
export const receiveDeleteBaitModuleEntrySuccess = ({ commit }, data) => commit(types.SET_DELETE_BAIT_MODULE_ENTRY, data);
export const receiveDeleteBaitModuleEntryError = ({ commit }, error) => commit(types.GET_DELETE_BAIT_MODULE_ENTRY_ERROR, error);
export const deleteBaitModuleEntry = ({ dispatch }, moduleEntryId) => {
  dispatch('requestDeleteBaitModuleEntry');
  Api.delete(`bt/module_entries/${moduleEntryId}`)
    .then(({ data }) => {
      dispatch('receiveDeleteBaitModuleEntrySuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteBaitModuleEntryError', response);
    });
};
export const requestCreateOrUpdateBaitModule = ({ commit }) => {
  commit(types.GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY);
};
export const receiveCreateOrUpdateBaitModuleSuccess = ({ commit }, data) => {
  commit(types.SET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY, data);
};
export const receiveCreateOrUpdateBaitModuleError = ({ commit }, error) => {
  commit(types.GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY_ERROR, error);
};
export const createOrUpdateBaitModuleEntry = ({ dispatch }, moduleData) => {
  dispatch('requestCreateOrUpdateBaitModule');
  Api.postFile('bt/module_entries', moduleData)
    .then((data) => {
      dispatch('receiveCreateOrUpdateBaitModuleSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateOrUpdateBaitModuleError', response);
    });
};


export const requestCreateGalleryPhoto = ({ commit }) => {
  commit(types.GET_CREATE_GALLERY_PHOTO);
};
export const receiveCreateGalleryPhotoSuccess = ({ commit }, data) => {
  commit(types.SET_CREATE_GALLERY_PHOTO, data);
};
export const receiveCreateGalleryPhotoError = ({ commit }, error) => {
  commit(types.GET_CREATE_GALLERY_PHOTO_ERROR, error);
};
export const createGalleryPhoto = ({ dispatch }, photoData) => {
  dispatch('requestCreateGalleryPhoto');
  Api.postFile(`property_profiles/${photoData.get('property_profile_id')}/medias`, photoData)
    .then((data) => {
      dispatch('receiveCreateGalleryPhotoSuccess', data);
      dispatch('fetchGalleryPhotos', photoData.get('property_profile_id'));
    })
    .catch(({ response }) => {
      dispatch('receiveCreateGalleryPhotoError', response);
    });
};

export const requestGalleryPhotos = ({ commit }) => commit(types.GET_GALLERY_PHOTOS);
export const receiveGalleryPhotosSuccess = ({ commit }, data) => commit(types.SET_GALLERY_PHOTOS, data);
export const receiveGalleryPhotosError = ({ commit }, error) => commit(types.GET_GALLERY_PHOTOS_ERROR, error);
export const fetchGalleryPhotos = ({ dispatch }, propertyId) => {
  dispatch('requestGalleryPhotos');
  Api.get(`property_profiles/${propertyId}/medias`)
    .then(({ data }) => {
      dispatch('receiveGalleryPhotosSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveGalleryPhotosError', response);
    });
};

export const requestDeleteGalleryPhoto = ({ commit }) => commit(types.GET_DELETE_GALLERY_PHOTOS);
export const receiveDeleteGalleryPhotoSuccess = ({ commit }, data) => commit(types.SET_DELETE_GALLERY_PHOTOS, data);
export const receiveDeleteGalleryPhotoError = ({ commit }, error) => commit(types.GET_DELETE_GALLERY_PHOTOS_ERROR, error);
export const deleteGalleryPhoto = ({ dispatch }, propertyPhotos) => {
  dispatch('requestDeleteGalleryPhoto');

  Api.delete(`property_profiles/${propertyPhotos.property_profile_id}/medias/${propertyPhotos.property_profile_media_id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteGalleryPhotoSuccess', data);
      dispatch('fetchGalleryPhotos', propertyPhotos.property_profile_id);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteGalleryPhotoError', response);
    });
};

export const requestRevokePropertyContact = ({ commit }) => commit(types.GET_REVOKE_PROPERTY_CONTACT);
export const receiveRevokePropertyContactSuccess = ({ commit }, data) => commit(types.SET_REVOKE_PROPERTY_CONTACT, data);
export const receiveRevokePropertyContactError = ({ commit }, error) => commit(types.GET_REVOKE_PROPERTY_CONTACT_ERROR, error);
export const revokePropertyContact = ({ dispatch }, payload) => {
  dispatch('requestRevokePropertyContact');

  Api.delete(`property_profiles/${payload.property_profile_id}/contacts/${payload.contact_id}`)
    .then(({ data }) => {
      dispatch('receiveRevokePropertyContactSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveRevokePropertyContactError', response);
    });
};

export const requestAddContactToProperty = ({ commit }) => {
  commit(types.GET_ADD_CONTACT_TO_PROPERTY);
};
export const receiveAddContactToPropertySuccess = ({ commit }, data) => {
  commit(types.SET_ADD_CONTACT_TO_PROPERTY, data);
};
export const receiveAddContactToPropertyError = ({ commit }, error) => {
  commit(types.GET_ADD_CONTACT_TO_PROPERTY_ERROR, error);
};
export const addContactToProperty = ({ dispatch }, contact) => {
  dispatch('requestAddContactToProperty');
  Api.post(`property_profiles/${contact.property_profile_id}/contacts`, contact)
    .then((data) => {
      dispatch('receiveAddContactToPropertySuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAddContactToPropertyError', response);
    });
};

export const requestCompanyInfo = ({ commit }) => commit(types.GET_COMPANY_INFO);
export const receiveCompanyInfoSuccess = ({ commit }, data) => commit(types.GET_COMPANY_INFO_SUCCESS, data);
export const receiveCompanyInfoError = ({ commit }, error) => commit(types.GET_COMPANY_INFO_ERROR, error);
export const fetchCompanyInfo = ({ dispatch }, companyId) => {
  dispatch('requestCompanyInfo');

  Api.get(`companies/${companyId}`)
    .then(({ data }) => {
      dispatch('receiveCompanyInfoSuccess', data.result.data[0]);
    })
    .catch(({ response }) => {
      dispatch('receiveCompanyInfoError', response);
    });
};

export const requestAwaitingValuations = ({ commit }) => commit(types.GET_AWAITING_VALUATIONS);
export const receiveAwaitingValuationsError = ({ commit }, error) => commit(types.GET_AWAITING_VALUATIONS_ERROR, error);
export const receiveAwaitingValuationsSuccess = ({ commit }, data) => commit(types.SET_AWAITING_VALUATIONS, data);
export const fetchAwaitingValuations = ({ dispatch }, payload) => {
  dispatch('requestAwaitingValuations');
  Api.get(`property_profiles/not_sent_yet_ma${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveAwaitingValuationsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAwaitingValuationsError', response);
    });
};

export const requestPropertyProfileTimelineData = ({ commit }) => commit(types.GET_PROPERTY_PROFILE_TIMELINE_DATA);
export const receivePropertyProfileTimelineDataError = ({ commit }, error) => commit(types.GET_PROPERTY_PROFILE_TIMELINE_DATA_ERROR, error);
export const receivePropertyProfileTimelineDataSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_PROFILE_TIMELINE_DATA, data);
export const fetchPropertyProfileTimelineData = async ({ dispatch }, payload) => {
  dispatch('requestPropertyProfileTimelineData');
  try {
    const data = await new PropertyProfileTimelineServices().getTimelineData(payload.params.property_profile_id, payload.request);
    dispatch('receivePropertyProfileTimelineDataSuccess', data);
  } catch (response) {
    dispatch('receivePropertyProfileTimelineDataError', response);
  }
};

export const fetchPropertyProfileLastTimelineData = async ({ commit }, payload) => {
  const { result } = await new PropertyProfileTimelineServices().getTimelineData(payload.params.property_profile_id, payload.request);
  commit(types.SET_PROPERTY_PROFILE_LAST_TIMELINE_DATA, result.data[0]);
  return result;
};

export const requestRelatedPropertyProfiles = ({ commit }) => commit(types.GET_RELATED_PROPERTY_PROFILES);
export const receiveRelatedPropertyProfilesError = ({ commit }, error) => commit(types.GET_RELATED_PROPERTY_PROFILES_ERROR, error);
export const receiveRelatedPropertyProfilesSuccess = ({ commit }, data) => commit(types.SET_RELATED_PROPERTY_PROFILES, data);
export const fetchRelatedPropertyProfiles = ({ dispatch }, payload) => {
  dispatch('requestRelatedPropertyProfiles');
  Api.get(`properties/${payload}/profiles?__limit=250`)
    .then(({ data }) => {
      dispatch('receiveRelatedPropertyProfilesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveRelatedPropertyProfilesError', response);
    });
};


export const requestPropertyProfileLive = ({ commit }) => commit(types.GET_PROPERTY_PROFILE_LIVE);
export const receivePropertyProfileLiveError = ({ commit }, error) => commit(types.GET_PROPERTY_PROFILE_LIVE_ERROR, error);
export const receivePropertyProfileLiveSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_PROFILE_LIVE, data);
export const fetchPropertyProfileLive = async ({ dispatch }, id) => {
  dispatch('requestPropertyProfileLive');
  try {
    const { data } = await new PropertyProfileLiveServices().show(id);
    dispatch('receivePropertyProfileLiveSuccess', data);
  } catch (e) {
    dispatch('receivePropertyProfileLiveError', e);
  }
};

export const requestPriceQualifiers = ({ commit }) => commit(types.GET_PRICE_QUALIFIERS);
export const receivePriceQualifiersError = ({ commit }, error) => commit(types.GET_PRICE_QUALIFIERS_ERROR, error);
export const receivePriceQualifiersSuccess = ({ commit }, data) => commit(types.SET_PRICE_QUALIFIERS, data);
export const fetchPriceQualifiers = async ({ dispatch }, payload) => {
  dispatch('requestPriceQualifiers');
  try {
    const data = await new PropertyDetailServices().getPriceQualifiers(payload);
    dispatch('receivePriceQualifiersSuccess', data);
  } catch (response) {
    dispatch('receivePriceQualifiersError', response);
  }
};

export const setCrmNotesError = ({ commit }, error) => commit(types.SET_CRM_NOTES_ERROR, error);
export const setCrmNotesSuccess = ({ commit }, data) => commit(types.SET_CRM_NOTES_SUCCESS, data);
