import Api from '@/libraries/api';
import Akya from '@/libraries/akya';
import router from '@/router';

import AudiencesServices from '@/modules/audiences/services/audiences.services';
import QuestionsServices from '@/modules/buyer_registration/services/questions.services';
import { isValueFilled } from '@/core/utils/helper';
import * as types from './mutation_types';
import { ContactsServices } from '../services/contacts.services';

export const requestContacts = ({ commit }) => commit(types.GET_CONTACTS);
export const receiveContactsError = ({ commit }, error) => commit(types.GET_CONTACTS_ERROR, error);
export const receiveContactsSuccess = ({ commit }, data) => commit(types.SET_CONTACTS, data);
export const fetchContacts = ({ dispatch }, payload) => {
  dispatch('requestContacts');
  Api.get(`contacts${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactsError', response);
    });
};

export const fetchProactiveContacts = ({ dispatch }, payload) => {
  dispatch('requestContacts');
  Api.get(`proactive/${payload.params.type}${payload.request}&profile=${payload.params.profile}`)
    .then(({ data }) => {
      dispatch('receiveContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactsError', response);
    });
};

export const fetchFunnelStatsContacts = ({ dispatch }, payload) => {
  dispatch('requestContacts');
  Api.get(
    `/reports/funnel/contacts/${payload.params.type}${payload.request}&profile=${payload.params.profile}`,
  )
    .then(({ data }) => {
      dispatch('receiveContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactsError', response);
    });
};

export const fetchDashboardBaitStatusContacts = ({ dispatch }, payload) => {
  dispatch('requestContacts');
  Api.get(
    `/bt/reports/dashboard/detail/bait_status_contacts/${payload.params.bait_status_id}${payload.request}&transaction_type_id=${payload.params.transaction_type_id}`,
  )
    .then(({ data }) => {
      dispatch('receiveContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactsError', response);
    });
};

export const fetchBlogVisitContacts = ({ dispatch }, payload) => {
  dispatch('requestContacts');
  Api.get(`/contacts/visit_blogs/${payload.params.article_id}${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactsError', response);
    });
};

export const fetchJourneyStepAppliedToContacts = ({ dispatch }, payload) => {
  dispatch('requestContacts');
  Api.get(`/drips/drip_step_applied_to_contacts/${payload.params.drip_step_id}${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactsError', response);
    });
};

export const fetchDynamicAudienceResultPreview = async ({ dispatch }, payload) => {
  dispatch('requestContacts');
  try {
    const data = await new AudiencesServices().preview(
      `contacts/view${payload.request}`,
      payload.params.static_query,
    );
    await dispatch('receiveContactsSuccess', data);
  } catch (response) {
    await dispatch('receiveContactsError', response);
  }
};

export const fetchAutopilotInsightStatistics = ({ dispatch }, payload) => {
  dispatch('requestContacts');
  Api.get(`/reports/autopilot_insights/${payload.params.status}${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactsError', response);
    });
};

export const resetContact = ({ commit }) => commit(types.RESET_CONTACT);
export const requestContact = ({ commit }) => commit(types.GET_CONTACT);
export const receiveContactSuccess = ({ commit }, data) => commit(types.SET_CONTACT, data);
export const receiveContactError = ({ commit }, error) => commit(types.GET_CONTACT_ERROR, error);
export const fetchContact = ({ dispatch }, contactId) => {
  dispatch('requestContact');
  Api.get(`contacts/${contactId}`)
    .then(({ data }) => {
      dispatch('receiveContactSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactError', response);
    });
};

export const requestUpdateContact = ({ commit }) => commit(types.GET_WILL_BE_UPDATE_CONTACT);
export const receiveUpdateContactSuccess = ({ commit }, data) => commit(types.SET_UPDATED_CONTACT, data);
export const receiveUpdateContactError = ({ commit }, error) => commit(types.SET_UPDATE_CONTACT_ERROR, error);

export const updateContact = ({ dispatch }, contact) => {
  dispatch('requestUpdateContact');

  Api.patch(`contacts/${contact.contact_id}`, contact)
    .then(({ data }) => {
      dispatch('receiveUpdateContactSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateContactError', response);
    });
};

export const requestContactSources = ({ commit }) => commit(types.GET_CONTACT_SOURCES);
export const receiveContactSourcesError = ({ commit }, error) => commit(types.GET_CONTACT_SOURCES_ERROR, error);
export const receiveContactSourcesSuccess = ({ commit }, data) => commit(types.SET_CONTACT_SOURCES, data);
export const fetchContactSources = ({ dispatch }) => {
  dispatch('requestContactSources');
  Api.get('contact_sources')
    .then(({ data }) => {
      dispatch('receiveContactSourcesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactSourcesError', response);
    });
};

export const requestContactLeadSources = ({ commit }) => {
  commit(types.GET_CONTACT_LEAD_SOURCES);
};
export const receiveContactLeadSourcesError = ({ commit }, error) => {
  commit(types.GET_CONTACT_LEAD_SOURCES_ERROR, error);
};
export const receiveContactLeadSourcesSuccess = ({ commit }, data) => {
  commit(types.SET_CONTACT_LEAD_SOURCES, data);
};
export const fetchContactLeadSources = ({ dispatch }) => {
  dispatch('requestContactLeadSources');
  Api.get('contact_lead_sources')
    .then(({ data }) => {
      dispatch('receiveContactLeadSourcesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactLeadSourcesError', response);
    });
};

export const requestCreateContact = ({ commit }) => {
  commit(types.GET_LAST_CREATED_CONTACT);
};
export const receiveCreateContactError = ({ commit }, error) => {
  commit(types.GET_LAST_CREATED_CONTACT_ERROR, error);
};
export const receiveCreateContactSuccess = ({ commit }, data) => {
  commit(types.SET_LAST_CREATED_CONTACT, data);
};
export const createContact = ({ dispatch }, payload) => {
  dispatch('requestCreateContact');
  Api.post('contacts', payload)
    .then(({ data }) => {
      dispatch('receiveCreateContactSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateContactError', response);
    });
};

export const requestProfiles = ({ commit }) => commit(types.GET_PROFILES);
export const receiveProfilesError = ({ commit }, error) => commit(types.GET_PROFILES_ERROR, error);
export const receiveProfilesSuccess = ({ commit }, data) => commit(types.SET_PROFILES, data);
export const fetchProfiles = async ({ dispatch }) => {
  dispatch('requestProfiles');
  try {
    const data = await new ContactsServices().fetchContactProfiles();
    dispatch('receiveProfilesSuccess', data);
  } catch (response) {
    dispatch('receiveProfilesError', response);
  }
};

export const setAll = ({ commit }, data) => {
  commit(types.SET_ALL_CONTACT, data);
};

export const unsetAll = ({ commit }, data) => {
  commit(types.UNSET_ALL_CONTACT, data);
};

export const clearSelectedContacts = ({ commit }) => {
  commit(types.CLEAR_SELECTED_CONTACTS);
};

export const setSingle = ({ commit }, data) => {
  commit(types.SET_SELECTED_CONTACT, data);
};
export const unsetSingle = ({ commit }, data) => {
  commit(types.UNSET_SELECTED_CONTACT, data);
};

export const setCorrespondanceAddress = ({ commit }, data) => {
  commit(types.SET_CORRESPONDANCE_ADDRESS, data);
};

export const requestExportContacts = ({ commit }) => commit(types.GET_EXPORT_CONTACTS);
export const receiveExportContactsSuccess = ({ commit }, data) => commit(types.SET_EXPORT_CONTACTS, data);
export const receiveExportContactsError = ({ commit }, error) => commit(types.GET_EXPORT_CONTACTS_ERROR, error);
export const fetchExportContacts = ({ dispatch }, filter) => {
  dispatch('requestExportContacts');
  let filters = [...filter];
  if (filters) {
    filters = filters.join('&');
  }
  const query = `?${filters}&__limit=250`;
  Api.get(`contacts${query}`)
    .then(({ data }) => {
      dispatch('receiveExportContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveExportContactsError', response);
    });
};

export const requestSMSSenders = ({ commit }) => commit(types.GET_SMS_SENDERS);
export const receiveSMSSendersSuccess = ({ commit }, data) => commit(types.SET_SMS_SENDERS, data);
export const receiveSMSSendersError = ({ commit }, error) => commit(types.GET_SMS_SENDERS_ERROR, error);
export const fetchSMSSenders = ({ dispatch }) => {
  dispatch('requestSMSSenders');
  Api.get('sender_sms')
    .then(({ data }) => {
      dispatch('receiveSMSSendersSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSMSSendersError', response);
    });
};

export const requestSendSMS = ({ commit }) => commit(types.SEND_SMS);
export const receiveSendSMSSuccess = ({ commit }, data) => commit(types.SEND_SMS_SUCCESS, data);
export const receiveSendSMSError = ({ commit }, error) => commit(types.SEND_SMS_ERROR, error);
export const sendSMS = ({ dispatch }, payload) => {
  dispatch('requestSendSMS');
  Api.post('single_sent_sms', payload)
    .then(({ data }) => {
      dispatch('receiveSendSMSSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSendSMSError', response);
    });
};

export const requestStickyNote = ({ commit }) => commit(types.GET_STICKY_NOTE);
export const receiveStickyNoteSuccess = ({ commit }, data) => commit(types.SET_STICKY_NOTE, data);
export const receiveStickyNotesError = ({ commit }, error) => commit(types.GET_STICKY_NOTE_ERROR, error);
export const fetchStickyNote = ({ dispatch }, contactId) => {
  dispatch('requestStickyNote');
  Api.get(`contacts/${contactId}/sticky_notes`)
    .then(({ data }) => {
      dispatch('receiveStickyNoteSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveStickyNotesError', response);
    });
};

export const requestUpdateStickyNote = ({ commit }) => commit(types.UPDATE_STICKY_NOTE);
export const receiveUpdateStickyNoteSuccess = ({ commit }, data) => commit(types.UPDATE_STICKY_NOTE_SUCCESS, data);
export const receiveUpdateStickyNoteError = ({ commit }, error) => commit(types.UPDATE_STICKY_NOTE_ERROR, error);
export const updateStickyNote = ({ dispatch }, noteData) => {
  dispatch('requestUpdateStickyNote');
  Api.post('sticky_notes', noteData)
    .then(({ data }) => {
      dispatch('receiveUpdateStickyNoteSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateStickyNoteError', response);
    });
};

export const requestContactDetailRelatedProperties = ({ commit }) => commit(types.GET_CONTACT_DETAIL_RELATED_PROPERTIES);
export const requestContactDetailRelatedPropertiesSuccess = ({ commit }, data) => commit(types.GET_CONTACT_DETAIL_RELATED_PROPERTIES_SUCCESS, data);
export const requestContactDetailRelatedPropertiesError = ({ commit }, error) => commit(types.GET_CONTACT_DETAIL_RELATED_PROPERTIES_ERROR, error);
export const fetchRelatedPRoperties = ({ dispatch }, payload) => {
  dispatch('requestContactDetailRelatedProperties');
  Api.get(`property_profiles/contact/${payload}?__limit=250`)
    .then(({ data }) => {
      dispatch('requestContactDetailRelatedPropertiesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('requestContactDetailRelatedPropertiesError', response);
    });
};
export const requestContactMailLists = ({ commit }) => commit(types.GET_CONTACT_MAIL_LISTS);
export const receiveContactMailListsError = ({ commit }, error) => commit(types.GET_CONTACT_MAIL_LISTS_ERROR, error);
export const receiveContactMailListsSuccess = ({ commit }, data) => commit(types.SET_CONTACT_MAIL_LISTS, data);
export const fetchContactMailLists = ({ dispatch }, contactId) => {
  dispatch('requestContactMailLists');
  Api.get(`mail_list_contacts/${contactId}`)
    .then(({ data }) => {
      dispatch('receiveContactMailListsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactMailListsError', response);
    });
};

export const requestContactBlogReads = ({ commit }) => commit(types.GET_CONTACT_BLOG_READS);
export const receiveContactBlogReadsError = ({ commit }, error) => commit(types.GET_CONTACT_BLOG_READS_ERROR, error);
export const receiveContactBlogReadsSuccess = ({ commit }, data) => commit(types.SET_CONTACT_BLOG_READS, data);
export const fetchContactBlogReads = ({ dispatch }, blogData) => {
  dispatch('requestContactBlogReads');
  Api.get(
    `activities/gabble_blog_visit/${blogData.contactId}/activities?${blogData.query}&${blogData.offset}`,
  )
    .then(({ data }) => {
      dispatch('receiveContactBlogReadsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactBlogReadsError', response);
    });
};

/* CUSTOM FIELDS AND CUSTOM FIELD GROUPS: START */
export const requestCustomFieldTypes = ({ commit }) => commit(types.GET_CUSTOM_FIELD_TYPES);
export const receiveCustomFieldTypesError = ({ commit }, error) => commit(types.GET_CUSTOM_FIELD_TYPES_ERROR, error);
export const receiveCustomFieldTypesSuccess = ({ commit }, data) => commit(types.SET_CUSTOM_FIELD_TYPES, data);
export const fetchCustomFieldTypes = ({ dispatch }) => {
  dispatch('requestCustomFieldTypes');
  Api.get('custom_field_types')
    .then(({ data }) => {
      dispatch('receiveCustomFieldTypesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCustomFieldTypesError', response);
    });
};

export const requestCustomFieldGroups = ({ commit }) => commit(types.GET_CUSTOM_FIELD_GROUPS);
export const receiveCustomFieldGroupsError = ({ commit }, error) => commit(types.GET_CUSTOM_FIELD_GROUPS_ERROR, error);
export const receiveCustomFieldGroupsSuccess = ({ commit }, data) => commit(types.SET_CUSTOM_FIELD_GROUPS, data);
export const fetchCustomFieldGroups = ({ dispatch }) => {
  dispatch('requestCustomFieldGroups');
  Api.get('custom_field_groups?__order_by=group_name')
    .then(({ data }) => {
      dispatch('receiveCustomFieldGroupsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCustomFieldGroupsError', response);
    });
};

export const requestCreateCustomFieldGroup = ({ commit }) => commit(types.CREATE_CUSTOM_FIELD_GROUP);
export const receiveCreateCustomFieldGroupSuccess = ({ commit }, data) => commit(types.CREATE_CUSTOM_FIELD_GROUP_SUCCESS, data);
export const receiveCreateCustomFieldGroupError = ({ commit }, error) => commit(types.CREATE_CUSTOM_FIELD_GROUP_ERROR, error);
export const createCustomFieldGroup = ({ dispatch }, payload) => {
  dispatch('requestCreateCustomFieldGroup');
  Api.post('custom_field_groups', payload)
    .then(({ data }) => {
      dispatch('receiveCreateCustomFieldGroupSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateCustomFieldGroupError', response);
    });
};

export const requestDeleteCustomFieldGroup = ({ commit }) => commit(types.DELETE_CUSTOM_FIELD_GROUP);
export const receiveDeleteCustomFieldGroupSuccess = ({ commit }, data) => commit(types.DELETE_CUSTOM_FIELD_GROUP_SUCCESS, data);
export const receiveDeleteCustomFieldGroupError = ({ commit }, error) => commit(types.DELETE_CUSTOM_FIELD_GROUP_ERROR, error);
export const deleteCustomFieldGroup = ({ dispatch }, payload) => {
  dispatch('requestDeleteCustomFieldGroup');
  Api.delete(`custom_field_groups/${payload}`)
    .then(({ data }) => {
      dispatch('receiveDeleteCustomFieldGroupSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteCustomFieldGroupError', response);
    });
};

export const requestCreateCustomField = ({ commit }) => commit(types.CREATE_CUSTOM_FIELD);
export const receiveCreateCustomFieldSuccess = ({ commit }, data) => commit(types.CREATE_CUSTOM_FIELD_SUCCESS, data);
export const receiveCreateCustomFieldError = ({ commit }, error) => commit(types.CREATE_CUSTOM_FIELD_ERROR, error);
export const createCustomField = ({ dispatch }, payload) => {
  dispatch('requestCreateCustomField');
  Api.post('custom_fields', payload)
    .then(({ data }) => {
      dispatch('receiveCreateCustomFieldSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateCustomFieldError', response);
    });
};

export const requestCustomFields = ({ commit }) => commit(types.GET_CUSTOM_FIELDS);
export const receiveCustomFieldsError = ({ commit }, error) => commit(types.GET_CUSTOM_FIELDS_ERROR, error);
export const receiveCustomFieldsSuccess = ({ commit }, data) => commit(types.SET_CUSTOM_FIELDS, data);
export const fetchCustomFields = ({ dispatch }, payload) => {
  dispatch('requestCustomFields');
  Api.get(
    `custom_fields/contacts/${payload.contact_id}?__limit=250${payload.offset}&__order_by=field_name`,
  )
    .then(({ data }) => {
      dispatch('receiveCustomFieldsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCustomFieldsError', response);
    });
};

export const requestUpdateCustomField = ({ commit }) => commit(types.UPDATE_CUSTOM_FIELD);
export const receiveUpdateCustomFieldSuccess = ({ commit }, data) => commit(types.UPDATE_CUSTOM_FIELD_SUCCESS, data);
export const receiveUpdateCustomFieldError = ({ commit }, error) => commit(types.UPDATE_CUSTOM_FIELD_ERROR, error);
export const updateCustomField = ({ dispatch }, payload) => {
  dispatch('requestUpdateCustomField');

  Api.post('custom_field_values', payload)
    .then(({ data }) => {
      dispatch('receiveUpdateCustomFieldSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateCustomFieldError', response);
    });
};
/* CUSTOM FIELDS AND CUSTOM FIELD GROUPS: END */

/* TAGS: START */

export const getTagsByContact = ({ commit }) => commit(types.GET_TAGS_BY_CONTACT);
export const getTagsByContactError = ({ commit }, error) => commit(types.GET_TAGS_BY_CONTACT_ERROR, error);
export const getTagsByContactSuccess = ({ commit }, data) => commit(types.GET_TAGS_BY_CONTACT_SUCCESS, data);
export const getTagsByContactId = ({ dispatch }, payload) => {
  dispatch('getTagsByContact');
  Api.get(`/contact_tags/${payload}`)
    .then(({ data }) => {
      dispatch('getTagsByContactSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('getTagsByContactError', response);
    });
};

export const insertNewTagToContact = ({ commit }) => commit(types.INSERT_NEW_TAG_TO_CONTACT);
export const insertNewTagToContactError = ({ commit }, error) => commit(types.INSERT_NEW_TAG_TO_CONTACT_ERROR, error);
export const insertNewTagToContactSuccess = ({ commit }, data) => commit(types.INSERT_NEW_TAG_TO_CONTACT_SUCCESS, data);
export const insertTagToContact = ({ dispatch }, insertData) => {
  dispatch('insertNewTagToContact');
  Api.post(`/contact_tags/${insertData.contact_id}/assign_tag`, insertData.arr)
    .then(({ data }) => {
      dispatch('insertNewTagToContactSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('insertNewTagToContactError', response);
    });
};

export const deleteTagFromContact = ({ commit }) => commit(types.DELETE_TAG_FROM_CONTACT);
export const deleteTagFromContactError = ({ commit }, error) => commit(types.DELETE_TAG_FROM_CONTACT_ERROR, error);
export const deleteTagFromContactSuccess = ({ commit }, data) => commit(types.DELETE_TAG_FROM_CONTACT_SUCCESS, data);
export const deleteTagContact = ({ dispatch }, deleteData) => {
  dispatch('deleteTagFromContact');
  Api.post(`/contact_tags/${deleteData.contact_id}/revoke_tag`, deleteData.arr)
    .then(({ data }) => {
      dispatch('deleteTagFromContactSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('deleteTagFromContactError', response);
    });
};

/* TAGS: END */

export const insertNewTagToCompanyTags = ({ commit }) => commit(types.INSERT_NEW_TAG_TO_COMPANY_TAGS);
export const insertNewTagToCompanyTagsError = ({ commit }, error) => commit(types.INSERT_NEW_TAG_TO_COMPANY_TAGS_ERROR, error);
export const insertNewTagToCompanyTagsSuccess = ({ commit }, data) => commit(types.INSERT_NEW_TAG_TO_COMPANY_TAGS_SUCCESS, data);
export const insertNewTagToCompany = ({ dispatch }, addedData) => {
  dispatch('insertNewTagToCompanyTags');
  Api.post('/tags', addedData)
    .then(({ data }) => {
      const ids = [];
      data.result.data.forEach((item) => {
        ids.push(item.tag_id);
      });
      const insertData = {
        contact_id: router.currentRoute.params.contact_id,
        arr: {},
      };
      if (ids.length === 1) {
        [insertData.arr.tag_id] = ids;
        dispatch('insertTagToContact', insertData);
      } else if (ids.length > 1) {
        insertData.arr.tags = ids;
        dispatch('insertTagToContact', insertData);
      }
      dispatch('insertNewTagToCompanyTagsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('insertNewTagToCompanyTagsError', response);
    });
};

/* TAGS: END */

/* Contact Detail CRM Notes Get Activities */
export const getActivitiesCrmNotes = ({ commit }) => commit(types.GET_ACTIVITIES_CRM_NOTES);
export const getActivitiesCrmNotesError = ({ commit }, error) => commit(types.GET_ACTIVITIES_CRM_NOTES_ERROR, error);
export const getActivitiesCrmNotesSuccess = ({ commit }, data) => commit(types.GET_ACTIVITIES_CRM_NOTES_SUCCESS, data);
export const getActivitiesCrmNote = ({ dispatch }) => {
  dispatch('getActivitiesCrmNotes');
  Api.get('/activities/contact_historical_note')
    .then(({ data }) => {
      dispatch('getActivitiesCrmNotesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('getActivitiesCrmNotesError', response);
    });
};

/* Contact Detail Add CRM Notes To Contact */
export const setCrmNotes = ({ commit }) => commit(types.SET_CRM_NOTES);
export const setCrmNotesError = ({ commit }, error) => commit(types.SET_CRM_NOTES_ERROR, error);
export const setCrmNotesSuccess = ({ commit }, data) => commit(types.SET_CRM_NOTES_SUCCESS, data);
export const fetchLastContactTimelineData = ({ commit }, { contactId, request }) => Api.get(
  `contacts/${contactId}/timeline?${request}`,
).then((res) => {
  commit(types.SET_LAST_CONTACT_DATA, res.data.result.data[0]);
  return res;
});
export const setCrmNote = ({ dispatch }, sendData) => {
  dispatch('setCrmNotes');
  Api.post('/contact_historical_notes', sendData)
    .then(({ data }) => {
      dispatch('setCrmNotesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('setCrmNotesError', response);
    });
};

export const requestContactSubscriptions = ({ commit }) => commit(types.GET_CONTACT_SUBSCRIPTIONS);
export const receiveContactSubscriptionsError = ({ commit }, error) => commit(types.GET_CONTACT_SUBSCRIPTIONS_ERROR, error);
export const receiveContactSubscriptionsSuccess = ({ commit }, data) => commit(types.SET_CONTACT_SUBSCRIPTIONS, data);
export const fetchContactSubscriptions = ({ dispatch }, contactId) => {
  dispatch('requestContactSubscriptions');
  Api.get(`contact_subscriptions/contacts/${contactId}`)
    .then(({ data }) => {
      dispatch('receiveContactSubscriptionsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactSubscriptionsError', response);
    });
};

export const requestSaveContactSubscriptions = ({ commit }) => commit(types.GET_SAVE_CONTACT_SUBSCRIPTIONS);
export const receiveSaveContactSubscriptionsSuccess = ({ commit }, data) => commit(types.SET_SAVE_CONTACT_SUBSCRIPTIONS, data);
export const receiveSaveContactSubscriptionsError = ({ commit }, error) => commit(types.GET_SAVE_CONTACT_SUBSCRIPTIONS_ERROR, error);
export const saveContactSubscriptions = ({ dispatch }, confirmData) => {
  dispatch('requestSaveContactSubscriptions');
  Api.patch('contact_subscriptions', confirmData)
    .then(({ data }) => {
      dispatch('receiveSaveContactSubscriptionsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSaveContactSubscriptionsError', response);
    });
};

export const requestFormEntries = ({ commit }) => commit(types.GET_FORM_ENTRIES);
export const receiveFormEntriesSuccess = ({ commit }, data) => commit(types.SET_FORM_ENTRIES, data);
export const receiveFormEntriesError = ({ commit }, error) => commit(types.GET_FORM_ENTRIES_ERROR, error);
export const fetchFormEntries = ({ dispatch }, contactId) => {
  dispatch('requestFormEntries');

  Api.get(`form_entries/contact/${contactId}`)
    .then(({ data }) => {
      dispatch('receiveFormEntriesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFormEntriesError', response);
    });
};

/* EMAIL SENDERS:START */
export const requestEMAILSenders = ({ commit }) => commit(types.GET_EMAIL_SENDERS);
export const receiveEMAILSendersSuccess = ({ commit }, data) => commit(types.SET_EMAIL_SENDERS, data);
export const receiveEMAILSendersError = ({ commit }, error) => commit(types.GET_EMAIL_SENDERS_ERROR, error);
export const fetchEMAILSenders = ({ dispatch }) => {
  dispatch('requestEMAILSenders');
  Api.get('sender_emails')
    .then(({ data }) => {
      dispatch('receiveEMAILSendersSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveEMAILSendersError', response);
    });
};
/* EMAIL SENDERS:FINISH */

/* SEND EMAIL */
export const requestSendEmail = ({ commit }) => commit(types.SEND_EMAIL);
export const receiveSendEmailSuccess = ({ commit }, data) => commit(types.SEND_EMAIL_SUCCESS, data);
export const receiveSendEmailError = ({ commit }, error) => commit(types.SEND_EMAIL_ERROR, error);
export const sendEmail = ({ dispatch }, payload) => {
  dispatch('requestSendEmail');
  Api.post('emails/contact', payload)
    .then(({ data }) => {
      dispatch('receiveSendEmailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSendEmailError', response);
    });
};

export const startSendMailProcess = ({ dispatch }, payload) => {
  Api.get(`mail_templates/edit_session/${payload.edit_session}`)
    .then(({ data }) => {
      const sendEmailPayload = { ...payload };
      sendEmailPayload.mail_template_id = data.result.data.mail_template_id;
      dispatch('sendEmail', sendEmailPayload);
    })
    .catch(({ response }) => {
      dispatch('receiveSendEmailError', response);
    });
};

/* SEND EMAIL */

export const requestCountries = ({ commit }) => commit(types.GET_COUNTRIES);
export const receiveCountriesSuccess = ({ commit }, data) => commit(types.SET_COUNTRIES, data);
export const receiveCountriesError = ({ commit }, error) => commit(types.GET_COUNTRIES_ERROR, error);
export const fetchCountries = ({ dispatch }) => {
  dispatch('requestCountries');

  Api.get('countries?__limit=250')
    .then(({ data }) => {
      dispatch('receiveCountriesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCountriesError', response);
    });
};

/* Get Bait Status of Market Appraisal in Contact Detail Page */
export const getBaitStatusOfMarketAppraisal = ({ commit }) => commit(types.GET_BAIT_STATUS_OF_MARKET_APPRAISAL);
export const getBaitStatusOfMarketAppraisalSuccess = ({ commit }, data) => commit(types.GET_BAIT_STATUS_OF_MARKET_APPRAISAL_SUCCESS, data);
export const getBaitStatusOfMarketAppraisalError = ({ commit }, error) => commit(types.GET_BAIT_STATUS_OF_MARKET_APPRAISAL_ERROR, error);
export const fetchBaitStatusOfMarketAppraisal = ({ dispatch }, payload) => {
  dispatch('getBaitStatusOfMarketAppraisal');
  Api.get(`contacts/${payload}/bait`)
    .then(({ data }) => {
      dispatch('getBaitStatusOfMarketAppraisalSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('getBaitStatusOfMarketAppraisalError', response);
    });
};

export const requestGdpr = ({ commit }) => commit(types.GET_GDPR);
export const receiveGdprSuccess = ({ commit }, data) => commit(types.SET_GDPR, data);
export const receiveGdprError = ({ commit }, error) => commit(types.GET_GDPR_ERROR, error);
export const fetchGdpr = ({ dispatch }, contactId) => {
  dispatch('requestGdpr');

  Akya.get(`shortener/gdpr?contact_id=${contactId}`)
    .then(({ data }) => {
      dispatch('receiveGdprSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveGdprError', response);
    });
};

/* Timeline: START */
export const requestActivities = ({ commit }) => commit(types.GET_ACTIVITIES);

export const receiveActivitiesError = ({ commit }, error) => commit(types.GET_ACTIVITIES_ERROR, error);

export const receiveActivitiesSuccess = ({ commit }, data) => commit(types.SET_ACTIVITIES, data);

export const fetchActivities = (
  { dispatch },
  contactId,
  limit = null,
  offset = null,
  orderBy = 'sentence',
  display = '1',
) => {
  dispatch('requestActivities');
  return Api.get(
    `activities/for_front_end?display=${display}&__order_by=${orderBy}&__limit=${limit}&__offset=${offset}&contact_id=${contactId}`,
  )
    .then(({ data }) => dispatch('receiveActivitiesSuccess', data))
    .catch(({ response }) => dispatch('receiveActivitiesError', response));
};

export const requestCompanySubscriptions = ({ commit }) => commit(types.GET_COMPANY_SUBSCRIPTIONS);
export const receiveCompanySubscriptionsError = ({ commit }, error) => commit(types.GET_COMPANY_SUBSCRIPTIONS_ERROR, error);
export const receiveCompanySubscriptionsSuccess = ({ commit }, data) => commit(types.SET_COMPANY_SUBSCRIPTIONS, data);
export const fetchCompanySubscriptions = ({ dispatch }, companyID) => {
  dispatch('requestCompanySubscriptions');
  return Api.get(`subscription_types?company_id=${companyID}`)
    .then(({ data }) => {
      dispatch('receiveCompanySubscriptionsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCompanySubscriptionsError', response);
    });
};

export const resetMultipleContacts = ({ commit }) => commit(types.RESET_MULTIPLE_CONTACTS);
export const requestMultipleContacts = ({ commit }) => commit(types.GET_MULTIPLE_CONTACTS);
export const receiveMultipleContactsError = ({ commit }, error) => commit(types.GET_MULTIPLE_CONTACTS_ERROR, error);
export const receiveMultipleContactsSuccess = ({ commit }, data) => commit(types.SET_MULTIPLE_CONTACTS, data);
export const fetchMultipleContacts = ({ dispatch }, payload) => {
  dispatch('requestMultipleContacts');
  if (payload && payload.length > 0 && payload[0]) {
    for (let i = 0; i < payload.length; i += 1) {
      Api.get(`contacts/${payload[i]}`)
        .then(({ data }) => {
          dispatch('receiveMultipleContactsSuccess', data);
        })
        .catch(({ response }) => {
          dispatch('receiveMultipleContactsError', response);
        });
    }
  } else {
    dispatch('resetMultipleContacts');
  }
};

export const requestContactTimelineData = ({ commit }) => commit(types.GET_CONTACT_TIMELINE_DATA);
export const receiveContactTimelineDataError = ({ commit }, error) => commit(types.GET_CONTACT_TIMELINE_DATA_ERROR, error);
export const receiveContactTimelineDataSuccess = ({ state, commit }, data) => new Promise((resolve) => {
  let list = [];
  const resultData = data.result.data;

  if (state.contactTimelineData == null) {
    state.contactTimelineData = [];
  }

  if (resultData.length) {
    list = state.contactTimelineData.concat(resultData);
  } else {
    list = state.contactTimelineData;
  }

  const commitData = {
    data: list,
    total: data.result.meta.total,
  };

  // commit data
  commit(types.SET_CONTACT_TIMELINE_DATA, commitData);

  resolve(data);
});

export const resetTimeLineData = ({ commit }) => commit(types.RESET_CONTACT_TIMELINE_DATA);

export const fetchContactTimelineData = ({ dispatch }, payload) => {
  dispatch('requestContactTimelineData');

  const fetchActivityContent = dispatch('fetchActivitiesContentGroup');
  const fetchTimelineData = Api.get(
    `contacts/${payload.contact_id}/timeline?${payload.request}`,
  ).then(({ data }) => dispatch('receiveContactTimelineDataSuccess', data));

  return Promise.all([fetchActivityContent, fetchTimelineData])
    .then(res => res[res.length - 1])
    .catch(({ response }) => {
      dispatch('receiveContactTimelineDataError', response);
    });
};

export const fetchActivitiesContentGroup = ({ commit }) => new Promise((resolve, reject) => Api.get('activities/activities_content_group')
  .then(({ data }) => {
    const { result } = data;
    commit(types.SET_ACTIVITIES_CONTENT_GROUP, result.data);
    resolve(data);
  })
  .catch(({ err }) => {
    reject(err);
  }));

/* CONTACT FILE OPERATIONS: START */
export const requestContactFiles = ({ commit }) => commit(types.GET_CONTACT_FILES);
export const receiveContactFilesError = ({ commit }, error) => commit(types.GET_CONTACT_FILES_ERROR, error);
export const receiveContactFilesSuccess = ({ commit }, data) => commit(types.SET_CONTACT_FILES, data);
export const fetchContactFiles = ({ dispatch }, payload) => {
  dispatch('requestContactFiles');

  Api.get(`contact_files/contact/${payload}`)
    .then(({ data }) => {
      dispatch('receiveContactFilesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactFilesError', response);
    });
};

export const requestUploadContactFile = ({ commit }) => commit(types.GET_UPLOAD_CONTACT_FILE);
export const receiveUploadContactFileSuccess = ({ commit }, data) => commit(types.SET_UPLOADED_CONTACT_FILE, data);
export const receiveUploadContactFileError = ({ commit }, error) => commit(types.SET_UPLOAD_CONTACT_FILE_ERROR, error);
export const uploadContactFile = ({ dispatch }, payload) => {
  dispatch('requestUploadContactFile');

  Api.postFile('contact_files', payload)
    .then(({ data }) => {
      dispatch('receiveUploadContactFileSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUploadContactFileError', response);
    });
};

export const requestDeleteContactFile = ({ commit }) => commit(types.GET_DELETE_CONTACT_FILE);
export const receiveDeleteContactFileSuccess = ({ commit }, data) => commit(types.SET_DELETED_CONTACT_FILE, data);
export const receiveDeleteContactFileError = ({ commit }, error) => commit(types.SET_DELETE_CONTACT_FILE_ERROR, error);
export const deleteContactFile = ({ dispatch }, payload) => {
  dispatch('requestDeleteContactFile');

  Api.delete(`contact_files/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteContactFileSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteContactFileError', response);
    });
};

export const requestUpdateContactFile = ({ commit }) => commit(types.GET_UPDATE_CONTACT_FILE);
export const receiveUpdateContactFileSuccess = ({ commit }, data) => commit(types.SET_UPDATED_CONTACT_FILE, data);
export const receiveUpdateContactFileError = ({ commit }, error) => commit(types.SET_UPDATE_CONTACT_FILE_ERROR, error);
export const updateContactFile = ({ dispatch }, payload) => {
  dispatch('requestUpdateContactFile');

  Api.patch(`contact_files/${payload.contact_file_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveUpdateContactFileSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateContactFileError', response);
    });
};
/* CONTACT FILE OPERATIONS: END */

/* CONTACT PROFILE PHOTO: START */
export const requestUpdateProfilePhoto = ({ commit }) => commit(types.GET_UPDATE_PROFILE_PHOTO);
export const receiveUpdateProfilePhotoSuccess = ({ commit }, data) => commit(types.SET_UPDATED_PROFILE_PHOTO, data);
export const receiveUpdateProfilePhotoError = ({ commit }, error) => commit(types.SET_UPDATE_PROFILE_PHOTO_ERROR, error);
export const updateProfilePhoto = ({ dispatch }, payload) => {
  dispatch('requestUpdateProfilePhoto');

  Api.postFile(`contacts/${payload.get('contact_id')}/avatar`, payload)
    .then(({ data }) => {
      dispatch('receiveUpdateProfilePhotoSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateProfilePhotoError', response);
    });
};
/* CONTACT PROFILE PHOTO: END */

/* GET ACTIVITY DETAIL: START */
export const requestGetActivityDetail = ({ commit }) => commit(types.GET_CONTACT_ACTIVITY_DETAIL);
export const receiveGetActivityDetailError = ({ commit }, error) => commit(types.GET_CONTACT_ACTIVITY_DETAIL_ERROR, error);
export const receiveGetActivityDetailSuccess = ({ commit }, data) => commit(types.SET_CONTACT_ACTIVITY_DETAIL, data);
export const fetchGetActivityDetail = ({ dispatch }, activityId) => {
  dispatch('requestGetActivityDetail');

  Api.get(`activities/contact_activities_content/${activityId}`)
    .then(({ data }) => {
      dispatch('receiveGetActivityDetailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveGetActivityDetailError', response);
    });
};
/* GET ACTIVITY DETAIL: END */

/* GET CONTACT'S LEAD DATA: START */
export const requestContactLeadData = ({ commit }) => commit(types.GET_CONTACT_LEAD_DATA);
export const receiveContactLeadDataError = ({ commit }, error) => commit(types.GET_CONTACT_LEAD_DATA_ERROR, error);
export const receiveContactLeadDataSuccess = ({ commit }, data) => commit(types.SET_CONTACT_LEAD_DATA, data);
export const fetchContactLeadData = ({ dispatch }, payload) => {
  dispatch('requestContactLeadData');

  Api.get(`leads/${payload.contact_id}`)
    .then(({ data }) => {
      dispatch('receiveContactLeadDataSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactLeadDataError', response);
    });
};
/* GET CONTACT'S LEAD DATA: END */

/* GET CONTACT'S VALUATION DATA: START */
export const requestContactOnlineValuationData = ({ commit }) => commit(types.GET_CONTACT_ONLINE_VALUATION_DATA);
export const receiveContactOnlineValuationDataError = ({ commit }, error) => commit(types.GET_CONTACT_ONLINE_VALUATION_DATA_ERROR, error);
export const receiveContactOnlineValuationDataSuccess = ({ commit }, data) => commit(types.SET_CONTACT_ONLINE_VALUATION_DATA, data);
export const fetchContactOnlineValuationData = ({ dispatch }, payload) => {
  dispatch('requestContactOnlineValuationData');

  Api.get(`leads/online_valuations/contact/${payload.contact_id}`)
    .then(({ data }) => {
      dispatch('receiveContactOnlineValuationDataSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactOnlineValuationDataError', response);
    });
};
/* GET CONTACT'S VALUATION DATA: END */

export const getSelectedApplicant = ({ commit }) => commit(types.GET_SELECTED_APPLICANT);
export const getSelectedApplicantError = ({ commit }, error) => commit(types.GET_SELECTED_APPLICANT_ERROR, error);
export const setSelectedApplicant = ({ commit }, payload) => {
  commit(types.SET_SELECTED_APPLICANT, payload);
};

export const fetchApplicantQuestions = async ({ commit, state }, type) => {
  // if it is empty, fetch and commit.
  if (!isValueFilled(state.applicantQuestions[type])) {
    const { data } = await new QuestionsServices().list(type);
    commit(types.SET_APPLICANT_QUESTIONS, { data, type });
  }
};
