// to find the function name easily

// eslint-disable-next-line import/prefer-default-export
export const cacheCleanerService = () => {
  localStorage.removeItem('is_not_natural');
  localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
  localStorage.removeItem('akya_login_cookie_sent_control');
  localStorage.removeItem('session_start_time');
  localStorage.removeItem('is_remember');
  localStorage.removeItem('selectedConfirmationTypes'); // TODO temp
};

// eslint-disable-next-line import/prefer-default-export
export const calendarCacheCleanerService = () => {
  localStorage.removeItem('selectedTransactionTypes');
  localStorage.removeItem('selectedViewingTypes');
  localStorage.removeItem('selectedEventTypes');
  localStorage.removeItem('filteredBranches');
  localStorage.removeItem('calendarCollapses');
  localStorage.removeItem('selectedUsers');
};


// eslint-disable-next-line import/prefer-default-export
export const filterCleanerService = () => {
  localStorage.removeItem('taskUserFilterParams');
  localStorage.removeItem('taskSubcategoryFilterParams');
  localStorage.removeItem('taskBranchFilterParams');
  localStorage.removeItem('taskMenuName');
  localStorage.removeItem('FilterSortParams');
  localStorage.removeItem('my_tasks-FilterSortParams');
  localStorage.removeItem('unassigned-FilterSortParams');
  localStorage.removeItem('to_do-FilterSortParams');
  localStorage.removeItem('in_progress-FilterSortParams');
  localStorage.removeItem('resolved-FilterSortParams');
  localStorage.removeItem('upcoming-FilterSortParams');
  localStorage.removeItem('overdue-FilterSortParams');
  localStorage.removeItem('dashboard_transaction_type_filter');
  localStorage.removeItem('dashboard_branch_filter');
  localStorage.removeItem('dashboard_period_filter');
  localStorage.removeItem('taskBranchFilterParams');
  localStorage.removeItem('sales_progression_filters');
  localStorage.removeItem('outstanding_offers_filters');
  localStorage.removeItem('viewing_feedback_filters');
  localStorage.removeItem('viewings_filters');
  localStorage.removeItem('contact_list_filters');
  localStorage.removeItem('property_list_filters_market_appraisals');
  localStorage.removeItem('property_list_filters_instructions');
  localStorage.removeItem('property_list_filters_prospects');
};
