export default [{
  name: 'Blog',
  path: '/blog',
  meta: {
    title: 'Content',
    breadcrumb: [
      { name: 'Marketing & Autopilot', link: '/marketing' },
      { name: 'Content' },
    ],
  },
  component: () => import('../views/Blog.vue'),
},
{
  name: 'BlogCreate',
  path: '/blog/create',
  meta: {
    title: 'Create Content',
    breadcrumb: [
      { name: 'Content', link: '/blog' },
      { name: 'Create Content' },
    ],
  },
  component: () => import('../components/BlogCreate.vue'),
},
{
  name: 'BlogUpdate',
  path: '/blog/update/:article_id',
  meta: {
    title: 'Update Content',
    breadcrumb: [
      { name: 'Content', link: '/blog' },
      { name: 'Update Content' },
    ],
  },
  component: () => import('../components/BlogCreate.vue'),
}];
