import Vue from 'vue';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';

export default {
  [types.GET_ONLINE_VALUATION_DETAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'onlineValuationDetail', false);
  },
  [types.SET_ONLINE_VALUATION_DETAIL](state, data) {
    Vue.set(state, 'onlineValuationDetail', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ONLINE_VALUATION_DETAIL_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'onlineValuationDetailError', getErrors(error)[0]);
  },

  [types.GET_CONVERT_ONLINE_VALUATION_TO_MA](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'convertOnlineValuationToMaSuccess', false);
    Vue.set(state, 'convertOnlineValuationToMaError', false);
  },
  [types.SET_CONVERT_ONLINE_VALUATION_TO_MA](state, data) {
    Vue.set(state, 'convertOnlineValuationToMaSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONVERT_ONLINE_VALUATION_TO_MA_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'convertOnlineValuationToMaError', getErrors(error)[0]);
  },
};
