import ApiService from '@/core/api/api.service';

export default class SupplierCompanyServices extends ApiService {
  getCompaniesList({ payload, query }) {
    this.loader = true;
    this.url = `/sp/sectors/${payload.categoryId}/clients?${query}`;
    return this.get();
  }

  getCompanyDetail(payload) {
    this.loader = true;
    this.url = `/sp/sectors/${payload.categoryId}/clients/${payload.companyId}`;
    return this.get();
  }

  addCompany({ payload, query }) {
    this.payload = query;
    this.url = `/sp/sectors/${payload.category}/clients`;
    return this.post();
  }

  updateCompany({ payload, query }) {
    this.payload = query;
    this.url = `/sp/sectors/${payload.category}/clients/${payload.company}`;
    return this.patch();
  }

  deleteCompany({ payload, query }) {
    this.payload = query;
    this.url = `/sp/sectors/${payload.category}/clients/${payload.company}`;
    return this.delete();
  }
}
