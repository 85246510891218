export default {
  error: null,
  isLoading: false,
  articles: null,
  articlesMinimal: null,
  totalBlogs: null,
  getBlogSuccess: null,
  getBlogError: null,
  createBlogSuccess: null,
  createBlogError: null,
  deleteBlogSuccess: null,
  deleteBlogError: null,
  updateBlogSuccess: null,
  updateBlogError: null,
  updateBlogActive: null,
  updateBlogActiveError: null,
  articleCategories: null,
  shareOnSocialMediaSuccess: false,
  shareOnSocialMediaError: false,
  blogWaitingShares: false,
  blogSharedInfo: null,
  socialMediaAllPosts: null,
};
