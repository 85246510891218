import api from '@/core/api/api.types';
import { controlConnectionProblems, getErrors } from '@/mixins/ErrorManager';
import vueApp from '@/main';
import { produceKey } from '@/core/utils/helper';
import interceptor from './authInterceptor';


export default class ApiService {
  constructor() {
    this.setApiType();
    this.__setApi();
    this.setLoader();
    this.uniqueId = produceKey();
    this.url = '';
    this.name = false;
    this.payload = {};
    this.params = {};
  }

  setApiType() {
    this.apiType = 'VUE_APP_API_URL';
  }

  setLoader() {
    this.loader = true;
  }

  __setApi() {
    this.api = api(this.apiType);
    this.api.interceptors.response.use(undefined, interceptor(this.api));
  }

  __manageRequest() {
    const requestData = {
      request: { unique_id: this.uniqueId, show: this.loader }, status: true,
    };
    if (this.name) {
      requestData.request.name = this.name;
    }
    vueApp.manageRequest(requestData);
  }

  async request(body) {
    let result;
    this.__manageRequest();

    try {
      body.url = this.url;
      body.headers = {
        ...body.headers,
        Accept: 'application/json',
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
      };
      result = await this.api(body);
    } catch (e) {
      this.__exit();
      controlConnectionProblems(e);
      throw getErrors(e.response);
    }
    this.__exit();
    return result.data;
  }

  async get() {
    return this.request({
      method: 'get',
      params: this.params,
    });
  }

  async put() {
    return this.request({
      method: 'put',
      data: this.payload,
    });
  }

  async post() {
    return this.request({
      method: 'post',
      data: this.payload,
      params: this.params,
    });
  }

  async delete() {
    return this.request({
      method: 'delete',
      data: this.payload,
    });
  }

  async patch() {
    return this.request({
      method: 'patch',
      data: this.payload,
      params: this.params,
    });
  }

  async postFile() {
    return this.request({
      method: 'post',
      data: this.payload,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
   * BaseRemitter returns:
   * If request response is true -> "single success message"
   * Else -> "false"
   * @param method {String} : method of request
   * @param type
   * @param showSuccessMessage
   * @returns {Promise<boolean|*>}
   */
  async baseRemitter(method, type = 'message', showSuccessMessage = true) {
    try {
      if (type === 'message') {
        const { message } = await this[method]();
        vueApp.successMessage(message);
        return message;
      }
      const { message, result: { data } } = await this[method]();
      if (showSuccessMessage) {
        vueApp.successMessage(message);
      }
      return data;
    } catch (e) {
      vueApp.errorMessage(e[0]);
      return false;
    }
  }

  async __baseRemitter(method, type = 'message', showSuccessMessage = true) {
    try {
      if (type === 'message') {
        const { message } = await this[method]();
        vueApp.successMessage(message);
        return message;
      }
      const { message, result } = await this[method]();
      if (showSuccessMessage) {
        vueApp.successMessage(message);
      }
      return result;
    } catch (e) {
      return e;
    }
  }

  __exit() {
    vueApp.manageRequest({ request: { unique_id: this.uniqueId }, status: false });
    if (this.loader) {
      this.loader = false;
    }
  }
}
