import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_PROPERTY_STYLES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PROPERTY_STYLES](state, data) {
    Vue.set(state, 'propertyStyles', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_STYLES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_ALL_PROPERTY_STYLES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_ALL_PROPERTY_STYLES](state, data) {
    Vue.set(state, 'propertyAllStyles', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ALL_PROPERTY_STYLES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
};
