export default {
  isLoading: false,
  bulkSms: false,
  bulkSmsDetail: false,
  sentBulkSmsListData: false,
  totalBulkSmsList: 0,
  totalBulkSms: 0,
  deleteBulkSmsSuccess: null,
  deleteBulkSmsError: null,
};
