export const SET_CREDIT_BALANCE = 'SET_CREDIT_BALANCE';

export const SET_TALKS = 'SET_TALKS';
export const LOAD_MORE_TALKS = 'LOAD_MORE_TALKS';
export const SET_TALKS_META = 'SET_TALKS_META';
export const DELETE_TALK = 'DELETE_TALK';
export const SET_ACTIVE_TALK_ID = 'SET_ACTIVE_TALK_ID';
export const SET_ACTIVE_TALK = 'SET_ACTIVE_TALK';

export const UPDATE_TALK = 'UPDATE_TALK';

export const OPEN_UZAIR = 'OPEN_UZAIR';
export const CLOSE_UZAIR = 'CLOSE_UZAIR';

export const HARD_CLOSE_UZAIR = 'HARD_CLOSE_UZAIR';

export const CLEAR_UZAIR_POPUP_CONFIG = 'CLEAR_UZAIR_POPUP_CONFIG';

export const ADD_RESPONSE_TRANSFER_MEMORY = 'ADD_RESPONSE_TRANSFER_MEMORY';

export const TOGGLE_UZAIR_ICON = 'TOGGLE_UZAIR_ICON';

export const MINIMIZE_UZAIR = 'MINIMIZE_UZAIR';
