import ApiService from '@/core/api/api.service';

export default class TagsServices extends ApiService {
  constructor() {
    super();
    this.url = 'tags';
  }

  setLoader() {
    this.loader = false;
  }

  async fetch(companyId) {
    this.url = `${this.url}/companies/${companyId}/tags`;
    this.params = {
      __limit: 800,
    };
    const { result: { data } } = await this.get();
    return data;
  }

  async create(payload) {
    this.payload = payload;
    return this.baseRemitter('post', 'data');
  }
}
