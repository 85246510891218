import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.SET_BRANCHES](state, data) {
    Vue.set(state, 'branches', data.result.data);
    Vue.set(state, 'totalBranches', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCHES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCHES_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_BRANCHES](state, data) {
    Vue.set(state, 'branches', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', null);
  },
  [types.GET_BRANCHES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCHES_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_BRANCH_SEARCH](state, data) {
    Vue.set(state, 'branches', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCH_SEARCH](state) {
    Vue.set(state, 'error', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCH_SEARCH_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', message.data.message);
    Vue.set(state, 'branches', []);
  },

  [types.SET_BRANCH_DETAIL](state, data) {
    Vue.set(state, 'branch', data.result.data[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCH_DETAIL](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCH_DETAIL_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_BRANCH](state, data) {
    Vue.set(state, 'branch', data.result.data[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCH](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCH_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_CREATE_BRANCH](state, data) {
    Vue.set(state, 'branch', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createBranchProcessed', false);
    Vue.set(state, 'createBranchProcessedSuccess', 'Create Branch successfully.');
  },
  [types.GET_CREATE_BRANCH](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createBranchProcessed', true);
    Vue.set(state, 'createBranchProcessedSuccess', false);
    Vue.set(state, 'createBranchProcessedError', false);
  },
  [types.GET_CREATE_BRANCH_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createBranchProcessed', false);
    let showingMessage = null;
    if (error && error.data && error.data.code === 400) {
      let messages = '';
      if (error.data.result['users.0']) {
        messages = error.data.result['users.0'][0].message;
        showingMessage = messages;
      } else {
        messages = Object.keys(error.data.result).map(el => error.data.result[el]);
        messages = messages.map(item => item.join());
        [showingMessage] = messages;
      }
    }
    if (showingMessage) {
      Vue.set(state, 'createBranchProcessedError', showingMessage);
    } else {
      Vue.set(state, 'createBranchProcessedError', error.data.message);
    }
  },

  [types.SET_CREATE_DEPARTMENT](state, data) {
    Vue.set(state, 'branchDepartments', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createDepartmentProcessed', false);
    Vue.set(state, 'createDepartmentProcessedSuccess', 'Create Department successfully.');
  },
  [types.GET_CREATE_DEPARTMENT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createDepartmentProcessed', true);
    Vue.set(state, 'createDepartmentProcessedSuccess', false);
    Vue.set(state, 'createDepartmentProcessedError', false);
  },
  [types.GET_CREATE_DEPARTMENT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createDepartmentProcessed', false);
    Vue.set(state, 'createDepartmentProcessedError', 'Create Department error.');
  },

  [types.GET_UPDATE_DEPARTMENT](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateDepartmentProcessed', false);
    Vue.set(state, 'updateDepartmentProcessedSuccess', 'Update Department successfully.');
  },
  [types.SET_UPDATE_DEPARTMENT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateDepartmentProcessed', true);
    Vue.set(state, 'updateDepartmentProcessedSuccess', false);
    Vue.set(state, 'updateDepartmentProcessedError', false);
  },
  [types.GET_UPDATE_DEPARTMENT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateDepartmentProcessed', false);
    Vue.set(state, 'updateDepartmentProcessedError', 'Update Department error.');
  },

  [types.GET_CREATE_DEPARTMENT_COMMUNICATION_INFO](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createDepartmentCommunicationInfoProcessed', false);
    Vue.set(state, 'createDepartmentCommunicationInfoProcessedSuccess', 'Update Department successfully.');
  },
  [types.SET_CREATE_DEPARTMENT_COMMUNICATION_INFO](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createDepartmentCommunicationInfoProcessed', true);
    Vue.set(state, 'createDepartmentCommunicationInfoProcessedSuccess', false);
    Vue.set(state, 'createDepartmentCommunicationInfoProcessedError', false);
  },
  [types.GET_CREATE_DEPARTMENT_COMMUNICATION_INFO_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createDepartmentCommunicationInfoProcessed', false);
    Vue.set(state, 'createDepartmentCommunicationInfoProcessedError', 'Update Department error.');
  },

  [types.SET_UPDATE_BRANCH](state, data) {
    Vue.set(state, 'branch', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateBranchProcessed', false);
    Vue.set(state, 'updateBranchProcessedSuccess', 'Update Branch successfully.');
  },
  [types.GET_UPDATE_BRANCH](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateBranchProcessed', true);
    Vue.set(state, 'updateBranchProcessedSuccess', false);
    Vue.set(state, 'updateBranchProcessedError', false);
  },
  [types.GET_UPDATE_BRANCH_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
    Vue.set(state, 'updateBranchProcessed', false);
    Vue.set(state, 'updateBranchProcessedError', 'Update Branch error.');
  },

  [types.SET_BRANCH_DEPARTMENTS](state, data) {
    Vue.set(state, 'branchDepartments', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCH_DEPARTMENTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCH_DEPARTMENTS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_BRANCH_DEPARTMENT](state, data) {
    Vue.set(state, 'branchDepartment', data.result.data[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCH_DEPARTMENT](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCH_DEPARTMENT_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_UPDATE_BRANCH_DEPARTMENT](state, data) {
    Vue.set(state, 'branchDepartmentUpdate', data.result.data[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_BRANCH_DEPARTMENT](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_UPDATE_BRANCH_DEPARTMENT_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_BRANCH_DEPARTMENT_DATA_FEED](state, data) {
    Vue.set(state, 'branchDepartmentDataFeed', data.result.data[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCH_DEPARTMENT_DATA_FEED](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCH_DEPARTMENT_DATA_FEED_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },


  [types.SET_DEPARTMENTS](state, data) {
    Vue.set(state, 'departments', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DEPARTMENTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_DEPARTMENTS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_DATA_PROVIDERS](state, data) {
    Vue.set(state, 'data_providers', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DATA_PROVIDERS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_DATA_PROVIDERS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_DATA_PROVIDER](state, data) {
    Vue.set(state, 'data_provider', data.result.data[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DATA_PROVIDER](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_DATA_PROVIDER_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },
};
