import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('Users', ['me', 'userRoles']),
    ...mapState('Users', ['users', 'lsLang']),
    ...mapState('Branches', ['branches']),
    ...mapState('ValuationAppointment', ['commonTypesSales', 'commonTypesLettings']),
    canMakeAdvancedTransaction() {
      return this.me && [1, 5].some(roleID => this.me.role_id === roleID);
    },
  },
  methods: {
    ...mapActions('Users', ['fetchUsers']),
    ...mapActions('Branches', ['fetchBranches']),
    ...mapActions('ValuationAppointment', ['fetchCommonTypesSales', 'fetchCommonTypesLettings']),
    willBranchShow(branchId) {
      if (this.me.role_level_id === 1) {
        return true;
      }
      return this.me.branch_id === branchId;
    },
    willUserShow(userId) {
      if (this.me.role_level_id === 1 || this.me.role_level_id === 2) {
        return true;
      }
      return userId === this.me.user_id;
    },
    canMakeValuation(userId) {
      if (!userId) {
        return this.me.can_make_valuation;
      }
      const user = this.users.find(usr => usr.user_id === userId);
      return user.can_make_valuation;
    },
  },
  watch: {},
};

/*
*
2 Company Owner
3 Branch Manager
4 Standard User
5 admin
*/
