export default [
  {
    path: '/forms',
    meta: {
      title: 'Forms',
      breadcrumb: [
        { name: 'Forms' },
      ],
    },
    name: 'FormsSettings',
    component: () => import('@/modules/forms/views/Forms.vue'),
  },
  {
    path: '/forms/build_form',
    meta: {
      title: 'Build Form',
      breadcrumb: [
        { name: 'Forms', link: '/forms' },
        { name: 'Build Form' },
      ],
    },
    name: 'BuildForm',
    component: () => import('@/modules/forms/views/BuildForm.vue'),
  },
  {
    path: '/forms/:form_id',
    meta: {
      title: 'Update Form',
      breadcrumb: [
        { name: 'Forms', link: '/forms' },
        { name: 'Update Form' },
      ],
    },
    name: 'UpdateForm',
    component: () => import('@/modules/forms/views/BuildForm.vue'),
  },
];
