import Vue from 'vue';
import * as types from './mutation_types';
// eslint-disable-next-line
import { getErrors } from '@/mixins/ErrorManager';

export default {
  [types.GET_UPLOAD_CUSTOM_CDN](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'cdnResult', null);
    Vue.set(state, 'cdnUploadProcessed', true);
    Vue.set(state, 'cdnUploadProcessedSuccess', false);
    Vue.set(state, 'cdnUploadProcessedError', false);
  },
  [types.SET_UPLOAD_CUSTOM_CDN](state, data) {
    Vue.set(state, 'cdnResult', data.result);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'cdnUploadProcessedSuccess', data.message);
  },
  [types.GET_UPLOAD_CUSTOM_CDN_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'cdnResult', null);
    Vue.set(state, 'cdnUploadProcessedError', getErrors(error)[0]);
  },

  [types.GET_UPLOAD_SHORTENER](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'cdnShortenerResult', null);
    Vue.set(state, 'cdnShortenerUploadProcessed', true);
    Vue.set(state, 'cdnShortenerUploadProcessedSuccess', false);
    Vue.set(state, 'cdnShortenerUploadProcessedError', false);
  },
  [types.SET_UPLOAD_SHORTENER](state, data) {
    Vue.set(state, 'cdnShortenerResult', data.result);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'cdnShortenerUploadProcessedSuccess', data.message);
  },
  [types.GET_UPLOAD_SHORTENER_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'cdnShortenerResult', null);
    Vue.set(state, 'cdnShortenerUploadProcessedError', getErrors(error)[0]);
  },
};
