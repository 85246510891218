export default [{
  name: 'AML',
  path: '/aml',
  meta: {
    title: 'AML Requests',
    breadcrumb: [
      { name: 'Operations', link: '/operations' },
      { name: 'AML Requests', link: '/aml' },
    ],
  },
  component: () => import('../views/Aml.vue'),
}];
