import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_TRANSACTION_TYPES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_TRANSACTION_TYPES](state, data) {
    Vue.set(state, 'transactionTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_TRANSACTION_TYPES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
};
