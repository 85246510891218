import ApiService from '@/core/api/api.service';

export default class ApplicantSearchServices extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async search(payload, params) {
    this.loader = false;
    this.url = `/contacts?${payload}`;
    this.payload = payload;
    this.params = params;
    const { result } = await this.get();
    return result;
  }
}
