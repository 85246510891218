export const GET_BRANCHES = 'GET_BRANCHES';
export const SET_BRANCHES = 'SET_BRANCHES';
export const GET_BRANCHES_ERROR = 'GET_BRANCHES_ERROR';

export const GET_BRANCH = 'GET_BRANCH';
export const SET_BRANCH = 'SET_BRANCH';
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR';

export const GET_BRANCH_SEARCH = 'GET_BRANCH_SEARCH';
export const SET_BRANCH_SEARCH = 'SET_BRANCH_SEARCH';
export const GET_BRANCH_SEARCH_ERROR = 'GET_BRANCH_SEARCH_ERROR';

export const GET_BRANCH_DEPARTMENTS = 'GET_BRANCH_DEPARTMENTS';
export const SET_BRANCH_DEPARTMENTS = 'SET_BRANCH_DEPARTMENTS';
export const GET_BRANCH_DEPARTMENTS_ERROR = 'GET_BRANCH_DEPARTMENTS_ERROR';

export const GET_BRANCH_DEPARTMENT = 'GET_BRANCH_DEPARTMENT';
export const SET_BRANCH_DEPARTMENT = 'SET_BRANCH_DEPARTMENT';
export const GET_BRANCH_DEPARTMENT_ERROR = 'GET_BRANCH_DEPARTMENT_ERROR';


export const GET_BRANCH_DEPARTMENT_DATA_FEED = 'GET_BRANCH_DEPARTMENT_DATA_FEED';
export const SET_BRANCH_DEPARTMENT_DATA_FEED = 'SET_BRANCH_DEPARTMENT_DATA_FEED';
export const GET_BRANCH_DEPARTMENT_DATA_FEED_ERROR = 'GET_BRANCH_DEPARTMENT_DATA_FEED_ERROR';

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const GET_DEPARTMENTS_ERROR = 'GET_DEPARTMENTS_ERROR';

export const GET_DATA_PROVIDERS = 'GET_DATA_PROVIDERS';
export const SET_DATA_PROVIDERS = 'SET_DATA_PROVIDERS';
export const GET_DATA_PROVIDERS_ERROR = 'GET_DATA_PROVIDERS_ERROR';

export const GET_DATA_PROVIDER = 'GET_DATA_PROVIDER';
export const SET_DATA_PROVIDER = 'SET_DATA_PROVIDER';
export const GET_DATA_PROVIDER_ERROR = 'GET_DATA_PROVIDER_ERROR';

export const GET_CREATE_DEPARTMENT = 'GET_CREATE_DEPARTMENT';
export const SET_CREATE_DEPARTMENT = 'SET_CREATE_DEPARTMENT';
export const GET_CREATE_DEPARTMENT_ERROR = 'GET_CREATE_DEPARTMENT_ERROR';

export const GET_UPDATE_DEPARTMENT = 'GET_UPDATE_DEPARTMENT';
export const SET_UPDATE_DEPARTMENT = 'SET_UPDATE_DEPARTMENT';
export const GET_UPDATE_DEPARTMENT_ERROR = 'GET_UPDATE_DEPARTMENT_ERROR';

export const GET_CREATE_DEPARTMENT_COMMUNICATION_INFO = 'GET_CREATE_DEPARTMENT_COMMUNICATION_INFO';
export const SET_CREATE_DEPARTMENT_COMMUNICATION_INFO = 'SET_CREATE_DEPARTMENT_COMMUNICATION_INFO';
export const GET_CREATE_DEPARTMENT_COMMUNICATION_INFO_ERROR = 'GET_CREATE_DEPARTMENT_COMMUNICATION_INFO_ERROR';

export const GET_UPDATE_BRANCH_DEPARTMENT = 'GET_UPDATE_BRANCH_DEPARTMENT';
export const SET_UPDATE_BRANCH_DEPARTMENT = 'SET_UPDATE_BRANCH_DEPARTMENT';
export const GET_UPDATE_BRANCH_DEPARTMENT_ERROR = 'GET_UPDATE_BRANCH_DEPARTMENT_ERROR';

export const GET_BRANCH_DETAIL = 'GET_BRANCH_DETAIL';
export const SET_BRANCH_DETAIL = 'SET_BRANCH_DETAIL';
export const GET_BRANCH_DETAIL_ERROR = 'GET_BRANCH_DETAIL_ERROR';

export const GET_CREATE_BRANCH = 'GET_CREATE_BRANCH';
export const SET_CREATE_BRANCH = 'SET_CREATE_BRANCH';
export const GET_CREATE_BRANCH_ERROR = 'GET_CREATE_BRANCH_ERROR';

export const GET_UPDATE_BRANCH = 'GET_UPDATE_BRANCH';
export const SET_UPDATE_BRANCH = 'SET_UPDATE_BRANCH';
export const GET_UPDATE_BRANCH_ERROR = 'GET_UPDATE_BRANCH_ERROR';
