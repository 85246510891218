export const GET_BULK_MAILS = 'GET_BULK_MAILS';
export const SET_BULK_MAILS = 'SET_BULK_MAILS';
export const GET_BULK_MAILS_ERROR = 'GET_BULK_MAILS_ERROR';

export const GET_BULK_MAILS_DETAIL = 'GET_BULK_MAILS_DETAIL';
export const SET_BULK_MAILS_DETAIL = 'SET_BULK_MAILS_DETAIL';
export const GET_BULK_MAILS_DETAIL_ERROR = 'GET_BULK_MAILS_DETAIL_ERROR';

export const GET_RESET_SENT_BULK_MAILS_DETAIL_LIST = 'GET_RESET_SENT_BULK_MAILS_DETAIL_LIST';
export const GET_SENT_BULK_MAILS_DETAIL_LIST = 'GET_SENT_BULK_MAILS_DETAIL_LIST';
export const SET_SENT_BULK_MAILS_DETAIL_LIST = 'SET_SENT_BULK_MAILS_DETAIL_LIST';
export const GET_SENT_BULK_MAILS_DETAIL_LIST_ERROR = 'GET_SENT_BULK_MAILS_DETAIL_LIST_ERROR';

export const GET_DELETE_BULK_MAIL = 'GET_DELETE_BULK_MAIL';
export const SET_DELETE_BULK_MAIL = 'SET_DELETE_BULK_MAIL';
export const GET_DELETE_BULK_MAIL_ERROR = 'GET_DELETE_BULK_MAIL_ERROR';
