export default [{
  name: 'SmsList',
  path: '/sms-list',
  meta: {
    title: 'SMS List',
    breadcrumb: [
      { name: 'SMS List' },
    ],
  },
  component: () => import('../views/SmsLists.vue'),
},
];
