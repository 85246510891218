import ApiService from '@/core/api/api.service';

export default class MailTemplatesServices extends ApiService {
  constructor() {
    super();
    this.url = 'mail_templates';
  }

  search(params) {
    this.params = params;
    this.url = 'mail_templates/list/select_box';
    return this.get();
  }

  async retrieveOne(id) {
    this.url = `${this.url}/${id}`;
    const { result: { data } } = await this.get();
    return data;
  }


  async fetch() {
    this.url = 'mail_templates/read/all_data';
    const { result: { data } } = await this.get();
    return data;
  }

  async retrieveCompanyDefault() {
    this.url = `${this.url}/company/default_template`;
    const { result: { data } } = await this.get();
    return data;
  }

  async getTemplateIdOfDraft(editSession) {
    this.url = `mail_templates/edit_session/${editSession}`;
    const { result: { data } } = await this.get();
    return data;
  }

  async getAllEmailTemplates() {
    this.url = 'mail_templates/read/all_data';
    const { result: { data } } = await this.get();
    return data;
  }
}
