import moment from 'moment';

export const contacts = state => (state.contacts != null ? state.contacts : []);
export const searchedContacts = state => (state.searchedContacts !== null ? state.searchedContacts : []);
export const contactIds = state => (state.contacts !== null ? state.contacts.map(item => item.contact_id) : []);
export const contact = state => state.contact;
export const listedContacts = state => state.listedContacts;
export const valuationContacts = state => state.valuationContacts;
export const lastCreatedContact = state => state.lastCreatedContact;
export const correspondanceAddress = state => state.correspondanceAddress;
export const profiles = state => state.profiles;
export const smsSenders = state => state.smsSenders;
export const defaultSender = (state) => {
  let sender = null;
  if (state.smsSenders && state.smsSenders.length > 0) {
    state.smsSenders.forEach((item) => {
      if (item.default === true) {
        sender = item.sender_sms_id;
      }
    });
  }
  return sender;
};
export const defaultEmailSender = (state) => {
  let sender = null;
  if (state.emailSenders && state.emailSenders.length > 0) {
    state.emailSenders.forEach((item) => {
      if (item.default === true) {
        sender = item.sender_email_id;
      }
    });
  }
  return sender;
};
export const activityDatePoints = (state) => {
  const datePoints = {};
  const convertForEqual = date => moment(date).format('DD-MM-YYYY');

  state.contactTimelineData.forEach((item, key) => {
    const activityDate = item.activity_date;
    const lastItem = Object.keys(datePoints);

    if (
      !lastItem.length
      || convertForEqual(datePoints[lastItem[lastItem.length - 1]]) !== convertForEqual(activityDate)
    ) {
      datePoints[key] = activityDate;
    }
  });
  return datePoints;
};
export const error = state => state.error;
export const success = state => state.success;
export const customFieldTypes = state => state.customFieldTypes;
export const customFieldGroups = state => state.customFieldGroups;
export const companysProducts = state => state.companysProducts;
export const customFields = state => state.customFields;
export const totalCustomFields = state => state.totalCustomFields;
export const emailSenders = state => state.emailSenders;
export const activities = state => state.activities;
export const contactFoundError = state => state.contactFoundError;
export const sendEmailError = state => state.sendEmailError;
export const sendEmailSuccess = state => state.sendEmailSuccess;
export const sendSMSError = state => state.sendSMSError;
export const sendSMSSuccess = state => state.sendSMSSuccess;
export const contactLeadSources = state => state.contactLeadSources;
export const contactSources = state => state.contactSources;
export const contactCompanySubscriptions = state => state.contactCompanySubscriptions;
export const contactCompanySubscriptionsError = state => state.contactCompanySubscriptionsError;
export const multipleContacts = state => state.multipleContacts;
export const multipleContactsError = state => state.multipleContactsError;
export const contactSubscriptions = state => state.contactSubscriptions;
