import authRequired from '@/router/auth/authRequired';
/* Modules */
import Dashboard from '@/modules/dashboard/router';
import Contacts from '@/modules/contacts/router';
import Properties from '@/modules/properties/router';
import Calendar from '@/modules/calendar/router';
import MarketAppraisal from '@/modules/market_appraisal/router';
import MailTemplates from '@/modules/mail_templates/router';
import Audiences from '@/modules/audiences/router';
import JourneyList from '@/modules/journey_list/router';
import SmsList from '@/modules/sms_list/router';
import Sms from '@/modules/sms/router';
import Blog from '@/modules/blog/router';
import SendMailAndSms from '@/modules/send_mail_and_sms/router';
import CsvImport from '@/modules/csv/router';
import Marketing from '@/modules/marketing/router';
import Stats from '@/modules/stats/router';
import Settings from '@/modules/settings/router';
import ValuationAppointment from '@/modules/valuation_appointment/router';
import NotFound from '@/router/information';
import Forms from '@/modules/forms/router';
import Campaigns from '@/modules/campaigns/router';
import BulkSms from '@/modules/bulk_sms/router';
import BulkMails from '@/modules/bulk_mail/router';
import Esignatures from '@/modules/e_signatures/router';
import Shortener from '@/modules/shortener/router';
import Operations from '@/modules/operations/router';
import LeadsNext from '@/modules/leads_next/router';
import BuyerRegistration from '@/modules/buyer_registration/router';
import AddingProperty from '@/modules/adding_manuel_property/router';
import Offers from '@/modules/offers/router';
import FollowUps from '@/modules/follow_ups/router';
import BlankPage from '@/modules/blank_page/router';
import CrmStats from '@/modules/crm_stats/router';
import Suppliers from '@/modules/suppliers/router';
import NewDashboard from '@/modules/the_dashboard/router';
import AML from '@/modules/aml/router';
import SocialMediaShare from '../../modules/social_media_share/router';
import OnlineValuations from '../../modules/online_valuations/router';
import Tasks from '../../modules/tasks/router';
import Invoices from '../../modules/invoices/router';

export default [{
  path: '/',
  meta: {
    title: 'Dashboard',
  },
  beforeEnter: authRequired,
  component: () => import('@/modules/Index.vue'),
  children: [].concat(Dashboard, Contacts, Properties, Calendar, MarketAppraisal, MailTemplates, Audiences, JourneyList, SmsList, Sms, Blog, SendMailAndSms,
    CsvImport, Marketing, Stats, Settings, ValuationAppointment, Forms, OnlineValuations, SocialMediaShare, Campaigns, BulkSms, BulkMails, Esignatures,
    Shortener, Operations, LeadsNext, BuyerRegistration, AddingProperty, BlankPage, Offers, FollowUps, CrmStats, Suppliers, Tasks, Invoices, NewDashboard,
    AML, NotFound),
  /**
   * @summary IMPORTANT!! WE HAVE TO PUT NOT FOUND TO END OF THE CONCAT. BECAUSE IT INCLUDES ALL ROUTER, SO PUT OTHER ROUTERS TO ITS LEFT.
   */
}];
