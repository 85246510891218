export default class TotalCountAdapter {
  constructor(response) {
    this.response = response;
  }

  get count() {
    let count = 0;
    if (this.response) {
      if (this.response.meta && this.response.meta.total > 0) {
        count = this.response.meta.total;
      } else if (this.response.data && this.response.data[0] && this.response.data[0].total_count) {
        count = this.response.data[0].total_count;
      }
    }
    return count;
  }
}
