import Vue from 'vue';
import * as types from './mutation_types';
import { getErrors } from '../../../mixins/ErrorManager';

export default {
  [types.RESET_CREATE_VALUATION_APPOINTMENT](state) {
    Vue.set(state, 'valuationAppointmentProcessSuccess', false);
    Vue.set(state, 'valuationAppointmentProcessError', false);
  },
  [types.GET_CREATE_VALUATION_APPOINTMENT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'valuationAppointmentProcessSuccess', false);
    Vue.set(state, 'valuationAppointmentProcessError', false);
  },
  [types.SET_CREATE_VALUATION_APPOINTMENT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'valuationAppointmentProcessSuccess', data);
  },
  [types.GET_CREATE_VALUATION_APPOINTMENT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'valuationAppointmentProcessError', getErrors(error)[0]);
  },

  [types.GET_UPDATE_VALUATION_APPOINTMENT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateValuationAppointmentProcessSuccess', false);
    Vue.set(state, 'updateValuationAppointmentProcessError', false);
  },
  [types.SET_UPDATE_VALUATION_APPOINTMENT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateValuationAppointmentProcessSuccess', data.data);
  },
  [types.GET_UPDATE_VALUATION_APPOINTMENT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateValuationAppointmentProcessError', getErrors(error)[0]);
  },

  [types.GET_CHECK_USER_AVAILABILITY](state) {
    Vue.set(state, 'checkUserAvailabilityError', null);
    Vue.set(state, 'checkUserAvailabilitySuccess', null);
  },
  [types.SET_CHECK_USER_AVAILABILITY](state, data) {
    Vue.set(state, 'checkUserAvailabilityError', null);
    Vue.set(state, 'checkUserAvailabilitySuccess', data.data.message);
  },
  [types.GET_CHECK_USER_AVAILABILITY_ERROR](state, error) {
    Vue.set(state, 'checkUserAvailabilityError', error.data.message);
  },

  [types.SET_COMMON_TYPES](state, payload) {
    if (payload.transactionType === 'sales') {
      Vue.set(state, 'commonTypesSales', payload.data.data);
    } else {
      Vue.set(state, 'commonTypesLettings', payload.data.data);
    }
  },

};
