export default {
  computed: {
    fullAddressControl() {
      return !!((this.fullAddress) && (this.fullAddress.address_1 || this.fullAddress.address_2 || this.fullAddress.address_3 || this.fullAddress.address_4 || this.fullAddress.address_5 || this.fullAddress.postcode));
    },
  },
  methods: {
    isVariableFilled(val) {
      return !!(val && val !== '' && val !== ' ');
    },
  },
};
