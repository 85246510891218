export const GET_CREATE_VALUATION_APPOINTMENT = 'GET_CREATE_VALUATION_APPOINTMENT';
export const RESET_CREATE_VALUATION_APPOINTMENT = 'RESET_CREATE_VALUATION_APPOINTMENT';
export const SET_CREATE_VALUATION_APPOINTMENT = 'SET_CREATE_VALUATION_APPOINTMENT';
export const GET_CREATE_VALUATION_APPOINTMENT_ERROR = 'GET_CREATE_VALUATION_APPOINTMENT_ERROR';


export const GET_UPDATE_VALUATION_APPOINTMENT = 'GET_UPDATE_VALUATION_APPOINTMENT';
export const SET_UPDATE_VALUATION_APPOINTMENT = 'SET_UPDATE_VALUATION_APPOINTMENT';
export const GET_UPDATE_VALUATION_APPOINTMENT_ERROR = 'GET_UPDATE_VALUATION_APPOINTMENT_ERROR';

export const GET_CHECK_USER_AVAILABILITY = 'GET_CHECK_USER_AVAILABILITY';
export const SET_CHECK_USER_AVAILABILITY = 'SET_CHECK_USER_AVAILABILITY';
export const GET_CHECK_USER_AVAILABILITY_ERROR = 'GET_CHECK_USER_AVAILABILITY_ERROR';

export const SET_COMMON_TYPES = 'SET_COMMON_TYPES';
