import ApiService from '@/core/api/api.service';

export default class NotificationServices extends ApiService {
  async fetchTypes() {
    this.url = 'notify_user_types';
    this.params = {
      is_active: true,
    };
    const { result: { data } } = await this.get();
    return data;
  }
}
