import ApiService from '@/core/api/api.service';

export default class OffersServices extends ApiService {
  async getOffers() {
    this.url = 'cr/offers/memorandum_of_sale';
    const { result } = await this.get();
    return result;
  }

  async getAllOffers() {
    this.url = 'cr/offers/all';
    const { result } = await this.get();
    return result;
  }

  async getOfferChain(offerId) {
    this.url = `cr/offers/${offerId}/memorandum_of_sale/chain`;
    const { result } = await this.get();
    return result;
  }

  async getOfferSteps(offerId) {
    this.loader = false;
    this.url = `cr/offers/${offerId}/memorandum_of_sale/steps`;
    const { result } = await this.get();
    return result;
  }

  async getOfferExpectedValues(offerId) {
    this.loader = false;
    this.url = `cr/offers/${offerId}/memorandum_of_sale/expected_values`;
    const { result } = await this.get();
    return result;
  }

  async getOfferNotes(offerId) {
    this.loader = false;
    this.url = `cr/offers/${offerId}/memorandum_of_sale/notes?__order_by=-created_at`;
    const { result } = await this.get();
    return result;
  }

  async getGeneralInfo(offerId) {
    this.url = `cr/offers/${offerId}/memorandum_of_sale/general_info`;
    const { result } = await this.get();
    return result;
  }

  updateStepDates(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/steps/${payload.id}`;
    return this.baseRemitter('patch', 'message');
  }

  createOfferChain(payload) {
    this.payload = payload.payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/chain`;
    return this.baseRemitter('post', 'message');
  }

  deleteOfferChain(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/chain/${payload.chain_step_id}`;
    return this.baseRemitter('delete', 'message');
  }

  updateOfferChain(data) {
    this.payload = data.payload;
    this.url = `cr/offers/${data.offer_id}/memorandum_of_sale/chain/${data.chain_step_id}`;
    return this.baseRemitter('patch', 'message');
  }

  updateExpectedValues(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/expected_values`;
    return this.baseRemitter('patch', 'message');
  }

  updateGeneralInfo(payload) {
    this.payload = payload.payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/general_info`;
    return this.baseRemitter('patch', 'message');
  }

  createNote(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/notes`;
    return this.baseRemitter('post', 'message');
  }

  updateNote(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/notes/${payload.memorandum_internal_note_id}`;
    return this.baseRemitter('patch', 'message');
  }

  deleteNote(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/notes/${payload.memorandum_internal_note_id}`;
    return this.baseRemitter('delete', 'message');
  }

  offerCanceling(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/offers/${payload.offer_id}/cancel`;
    return this.baseRemitter('patch', 'message');
  }

  offerUpdate(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/offers/${payload.offer_id}/price`;
    return this.baseRemitter('patch', 'message');
  }

  sendMemorandumMail(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/${payload.receiver}/report/email`;
    return this.baseRemitter('post', 'message');
  }

  sendMailToAll(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/memorandum_of_sale/all/report/email`;
    return this.baseRemitter('post', 'message');
  }

  async getMailSettings() {
    this.url = 'common_email_sms_variables';
    const { result } = await this.get();
    return result;
  }

  setAsNoShow(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}`;
    return this.baseRemitter('patch', 'message');
  }

  changeIgnoreStatusOfStep(offerId, type, payload) {
    this.payload = payload;
    this.url = `cr/offers/${offerId}/memorandum_of_sale/steps/${type}/ignore`;
    return this.baseRemitter('patch', 'data');
  }

  sendOfferForm(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/new_offers`;
    return this.baseRemitter('post', 'data');
  }

  async uploadOfferFiles(payload) {
    this.payload = payload;
    this.url = 'cdn/private/upload';
    const { result } = await this.postFile();
    return result;
  }

  async previewOfferFiles(path) {
    this.url = `cdn/private/preview?file_path=${path}`;
    const { result } = await this.get();
    return result;
  }

  updateOfferForm(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/new_offers/${payload.offer_id}`;
    return this.baseRemitter('patch', 'data');
  }

  async getNewOffer(payload) {
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/new_offers/${payload.offer_id}`;
    const { result } = await this.get();
    return result;
  }

  async getNewOfferFromWithOfferId(payload) {
    this.url = `cr/new_offers/${payload.offer_id}`;
    const { result } = await this.get();
    return result;
  }

  sendOfferNote(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/new_offers/${payload.offer_id}/note`;
    return this.baseRemitter('post', 'data');
  }
}
