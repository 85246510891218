export const branches = state => state.branches;
export const createBranchProcessed = state => state.createBranchProcessed;
export const createBranchProcessedSuccess = state => state.createBranchProcessedSuccess;
export const createBranchProcessedError = state => state.createBranchProcessedError;
export const updateBranchProcessed = state => state.updateBranchProcessed;
export const updateBranchProcessedSuccess = state => state.updateBranchProcessedSuccess;
export const updateBranchProcessedError = state => state.updateBranchProcessedError;
export const createDepartmentProcessed = state => state.createDepartmentProcessed;
export const createDepartmentProcessedSuccess = state => state.createDepartmentProcessedSuccess;
export const createDepartmentProcessedError = state => state.createDepartmentProcessedError;
