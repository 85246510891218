import Api from '@/libraries/api';
import CalendarEventServices from '@/modules/calendar/services/calendarEvent.services';
import CalendarRecurringEventsServices from '@/modules/calendar/services/calendarRecurringEvents.services';
import CalendarEventSearchServices from '@/modules/calendar/services/calendarEventSearch.services';
import ApplicantsServices from '@/modules/contacts/services/applicants.services';
import ApplicantSearchServices from '@/modules/calendar/services/applicantSearch.services';
import { PropertyProfileServices } from '@/modules/properties/services/propertyProfile.services';
import * as types from './mutation_types';

export const requestEvents = ({ commit }) => commit(types.GET_EVENTS);
export const receiveEventsError = ({ commit }, error) => commit(types.GET_EVENTS_ERROR, error);
export const receiveEventsSuccess = ({ commit }, data) => commit(types.SET_EVENTS, data);
export const setFetchedRange = ({ commit }, month) => commit(types.SET_FETCHED_RANGE, month);
export const setFetchedRecurring = ({ commit }, month) => commit(types.SET_FETCHED_RECURRING, month);
export const setFollowUpCount = ({ commit }, count) => commit(types.SET_FOLLOW_UP, count);
export const setMaNotYetSentCount = ({ commit }, count) => commit(types.SET_MA_NOT_YET_SENT, count);
export const fetchEvents = async ({ dispatch }, range) => {
  dispatch('requestEvents');
  const queryParams = {
    'start[lte]': range.end,
    'start[gte]': range.start,
    'start[order]': range.order,
  };
  const eventQuery = {
    'start[lte]': range.end,
    'start[gte]': range.start,
    'start[order]': range.order,
  };
  dispatch('setFetchedRange', { start: range.start, end: range.end });
  const result = { data: [], meta: {} };
  const viewingResult = { data: [], meta: {} };
  const calendarEventServices = new CalendarEventServices();
  async function getCalendarEvents() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const newData = await calendarEventServices.fetchEvents(eventQuery);
      result.data = result.data.concat(newData.data);
      result.meta = newData.meta;
      eventQuery.__offset = result.meta.offset;
      if (result && result.meta && result.meta.after_key && result.meta.after_key.length > 0) {
        Object.assign(eventQuery, { 'after_key[0]': result.meta.after_key[0] });
        Object.assign(eventQuery, { 'after_key[1]': result.meta.after_key[1] });
      }
      dispatch('setFollowUpCount', result.meta.follow_up_count);
      // TODO Fatih will check it
      dispatch('setMaNotYetSentCount', result.meta.ma_not_yet_sent_count);
      dispatch('receiveEventsSuccess', result.data);
      if (!result.meta.more_records) {
        break;
      }
    }
    return result;
  }
  async function getCalendarViewings() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const newViewingData = await calendarEventServices.fetchViewingEvents(queryParams);
      viewingResult.data = viewingResult.data.concat(newViewingData.data);
      viewingResult.meta = newViewingData.meta;
      queryParams.__offset = viewingResult.meta.offset;
      if (viewingResult && viewingResult.meta && viewingResult.meta.after_key && viewingResult.meta.after_key.length > 0) {
        Object.assign(queryParams, { 'after_key[0]': viewingResult.meta.after_key[0] });
      }
      dispatch('receiveEventsSuccess', viewingResult.data);
      if (!viewingResult.meta.more_records) {
        break;
      }
    }
    return viewingResult;
  }
  await Promise.all([
    getCalendarEvents(),
    getCalendarViewings(),
  ]);
};

export const adjustRecurringEvents = async ({ dispatch }, range) => {
  dispatch('requestEvents');
  const queryParams = {
    'start[lte]': range.end,
    'start[gte]': range.start,
    'start[order]': range.order,
  };
  const { users } = range;
  dispatch('setFetchedRecurring', { start: range.start, end: range.end });
  async function getCalendarRecurring() {
    const newRecurringData = await new CalendarRecurringEventsServices().fetchRecurringEvents(queryParams);
    if (newRecurringData) {
      dispatch('recurringEventAdapter', { newRecurringData, users });
    }
  }
  await Promise.all([
    getCalendarRecurring(),
  ]);
};
export const requestEvent = ({ commit }) => commit(types.GET_EVENT);
export const receiveEventError = ({ commit }, error) => commit(types.GET_EVENT_ERROR, error);
export const receiveEventSuccess = ({ commit }, data) => commit(types.SET_EVENT, data);
export const unsetEvent = ({ commit }) => commit(types.UNSET_EVENT);
export const fetchEvent = ({ dispatch }, eventId) => {
  dispatch('requestEvent');
  Api.get(`calendar/events/${eventId}`).then(({ data }) => {
    dispatch('receiveEventSuccess', data.result.data);
  });
};

export const requestEventTypes = ({ commit }) => commit(types.GET_EVENT_TYPES);
export const receiveEventTypesError = ({ commit }) => commit(types.GET_EVENT_TYPES_ERROR);
export const receiveEventTypesSuccess = ({ commit }, data) => commit(types.SET_EVENT_TYPES, data);
export const fetchEventTypes = ({ dispatch }) => {
  dispatch('requestEventTypes');
  Api.get('calendar/event_types?__order_by=event_type_id').then(({ data }) => {
    dispatch('receiveEventTypesSuccess', data.result.data);
  });
};
export const requestCalendarEventsByStatus = ({ commit }) => {
  commit(types.GET_CALENDAR_EVENTS_BY_STATUS);
};
export const receiveCalendarEventsByStatusSuccess = ({ commit }, data) => {
  commit(types.SET_CALENDAR_EVENTS_BY_STATUS, data);
};
export const receiveCalendarEventsByStatusError = ({ commit }, error) => {
  commit(types.GET_CALENDAR_EVENTS_BY_STATUS_ERROR, error);
};

export const fetchCalendarEventsByStatus = ({ dispatch }, eventStatusId) => {
  dispatch('requestCalendarEventsByStatus');
  Api.get(`${'calendar/events?__limit=250&event_status_id='}${eventStatusId}`)
    .then(({ data }) => {
      dispatch('receiveCalendarEventsByStatusSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCalendarEventsByStatusError', response);
    });
};

export const requestEventStatuses = ({ commit }) => commit(types.GET_EVENT_STATUSES);
export const receiveEventStatusesSuccess = ({ commit }, data) => commit(types.SET_EVENT_STATUSES, data);
export const receiveEventStatusesError = ({ commit }, error) => commit(types.GET_EVENT_STATUSES_ERROR, error);

export const fetchEventStatuses = ({ dispatch }) => {
  dispatch('requestEventStatuses');
  Api.get('calendar/event_statuses')
    .then(({ data }) => {
      dispatch('receiveEventStatusesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveEventStatusesError', response);
    });
};

export const requestUpdateCalendarEventStatus = ({ commit }) => {
  commit(types.UPDATE_CALENDAR_EVENT_STATUS);
};
export const receiveUpdateCalendarEventStatusSuccess = ({ commit }, data) => {
  commit(types.UPDATE_CALENDAR_EVENT_STATUS_SUCCESS, data);
};
export const receiveUpdateCalendarEventStatusError = ({ commit }, error) => {
  commit(types.UPDATE_CALENDAR_EVENT_STATUS_ERROR, error);
};

export const updateCalendarEventStatus = ({ dispatch }, payload) => {
  dispatch('requestUpdateCalendarEventStatus');
  Api.patch(
    `calendar/events/update_event_status/${payload.event_id}/${payload.event_status_id}`,
    payload,
  )
    .then(({ data }) => {
      dispatch('receiveUpdateCalendarEventStatusSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateCalendarEventStatusError', response);
    });
};

export const requestCreateCalendarEvent = ({ commit }) => {
  commit(types.CREATE_CALENDAR_EVENT);
};
export const receiveCreateCalendarEventSuccess = ({ commit }, data) => {
  commit(types.CREATE_CALENDAR_EVENT_SUCCESS, data);
};
export const receiveCreateCalendarEventError = ({ commit }, error) => {
  commit(types.CREATE_CALENDAR_EVENT_ERROR, error);
};

export const addEventToEvents = ({ commit }, data) => commit(types.ADD_EVENT, data);
export const createCalendarEvent = ({ dispatch }, event) => {
  dispatch('requestCreateCalendarEvent');
  Api.post('calendar/events', event)
    .then((data) => {
      dispatch('receiveCreateCalendarEventSuccess', data);
      dispatch('addEventToEvents', data.data.result.data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateCalendarEventError', response);
    });
};

export const setSkeletons = ({ commit }, data) => commit(types.SET_SKELETONS, data);
export const recurringEventAdapter = ({ dispatch }, data) => {
  const { skeletons } = data.newRecurringData.meta;
  if (skeletons.length) {
    dispatch('setSkeletons', skeletons);
  }
  const unCustomizedRecurringEvents = {};
  const mappedUsers = data.users.map(usr => ({
    color: usr.color,
    fullname: usr.fullname,
    salutation_id: usr.salutation_id,
    user_id: usr.user_id,
  }));
  const userFinder = (attendees) => {
    const userData = { responsible_user: {}, users: [] };
    if (attendees) {
      for (let i = 0; i < attendees.length; i += 1) {
        const usr = mappedUsers.find(user => user.user_id === attendees[i]);
        if (i === 0) {
          userData.responsible_user = usr;
        } else {
          userData.users.push(usr);
        }
      }
    }
    return userData;
  };
  skeletons.forEach((skeleton) => {
    for (let i = 0; i < skeleton.attendees.length; i += 1) {
      unCustomizedRecurringEvents[skeleton.recurring_event_id] = userFinder(skeleton.attendees);
    }
  });
  if (data.newRecurringData) {
    dispatch('receiveEventsSuccess', data.newRecurringData.data.map((event) => {
      if (!event.isCustomized) {
        Object.assign(event, unCustomizedRecurringEvents[event.recurring_event_id]);
      } else {
        Object.assign(event, userFinder(event.attendees));
      }
      return event;
    }));
  }
};

export const createRecurringEvent = async ({ dispatch }, data) => {
  const newRecurringData = await new CalendarRecurringEventsServices().createRecurringEvent(data.event);
  if (newRecurringData) {
    dispatch('recurringEventAdapter', { newRecurringData, users: data.users });
  }
};
export const updateRecurringEvent = async ({ commit }, data) => {
  const newRecurringData = await new CalendarRecurringEventsServices().updateRecurringEvent(data.event, data.actionType, data.eventId);
  if (newRecurringData) {
    // eslint-disable-next-line no-inner-declarations
    async function removeCalendarRecurring() {
      await commit(types.REMOVE_RECURRING_EVENTS);
    }
    await removeCalendarRecurring();
  }
};
export const dragRecurringEvent = async ({ commit }, data) => {
  const newRecurringData = await new CalendarRecurringEventsServices().dragRecurringEvent(data.event, data.actionType, data.eventId);
  if (newRecurringData) {
    if (data.fetchEvents) {
      // eslint-disable-next-line no-inner-declarations
      async function removeCalendarRecurring() {
        await commit(types.REMOVE_RECURRING_EVENTS);
      }
      await removeCalendarRecurring();
    }
  }
};

export const requestUpdateCalendarEvent = ({ commit }) => {
  commit(types.UPDATE_CALENDAR_EVENT);
};
export const receiveUpdateCalendarEventSuccess = ({ commit }, data) => {
  commit(types.UPDATE_CALENDAR_EVENT_SUCCESS, data);
};
export const receiveUpdateCalendarEventError = ({ commit }, error) => {
  commit(types.UPDATE_CALENDAR_EVENT_ERROR, error);
};
export const updateEvent = ({ dispatch }, event) => {
  dispatch('requestUpdateCalendarEvent');
  Api.patch(`calendar/events/${event.id}`, event.event)
    .then((data) => {
      dispatch('receiveUpdateCalendarEventSuccess', data);
      dispatch('addEventToEvents', data.data.result.data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateCalendarEventError', response);
    });
};

export const deleteRecurringEvent = async ({ commit }, data) => {
  const result = await new CalendarRecurringEventsServices().deleteRecurringEvent(data.actionType, data.eventId);
  if (result) {
    // eslint-disable-next-line no-inner-declarations
    async function removeCalendarRecurring() {
      await commit(types.REMOVE_RECURRING_EVENTS);
    }
    await removeCalendarRecurring();
  }
};

export const requestCalendarUserSettings = ({ commit }) => {
  commit(types.CALENDAR_USER_SETTINGS);
};
export const receiveCalendarUserSettingsSuccess = ({ commit }, data) => {
  commit(types.CALENDAR_USER_SETTINGS_SUCCESS, data);
};
export const receiveCalendarUserSettingsError = ({ commit }, error) => {
  commit(types.CALENDAR_USER_SETTINGS_ERROR, error);
};
export const fetchCalendarUserSettings = ({ dispatch }) => {
  dispatch('requestCalendarUserSettings');
  Api.get('calendar/user_settings')
    .then((data) => {
      dispatch('receiveCalendarUserSettingsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCalendarUserSettingsError', response);
    });
};

export const requestSearchCalendarEvent = ({ commit }) => commit(types.SEARCH_CALENDAR_EVENT);
export const receiveSearchCalendarEventSuccess = ({ commit }, data) => commit(types.SEARCH_CALENDAR_EVENT_SUCCESS, data);
export const receiveSearchCalendarEventError = ({ commit }, error) => commit(types.SEARCH_CALENDAR_EVENT_ERROR, error);

export const searchCalendarEvent = async ({ dispatch }, keyword) => {
  const result = await new CalendarEventSearchServices().searchEvents(encodeURIComponent(keyword));
  dispatch('requestSearchCalendarEvent');
  dispatch('receiveSearchCalendarEventSuccess', result);
};

export const setCollapses = ({ commit }, data) => {
  commit(types.SET_COLLAPSES, data);
};

export const setAllUsers = ({ commit }, data) => {
  commit(types.SET_ALL_USERS, data);
};

export const setAllTypes = ({ commit }, data) => {
  commit(types.SET_ALL_TYPES, data);
};

export const setAllTransactionTypes = ({ commit }, data) => {
  commit(types.SET_ALL_TRANSACTION_TYPES, data);
};

export const setAllViewingTypes = ({ commit }, data) => {
  commit(types.SET_ALL_VIEWING_TYPES, data);
};

export const setAllConfirmationTypes = ({ commit }, data) => {
  commit(types.SET_ALL_CONFIRMATION_TYPES, data);
};

export const setAllDialogUsers = ({ commit }, data) => {
  commit(types.SET_ALL_DIALOG_USERS, data);
};

export const setAllDialogTypes = ({ commit }, data) => {
  commit(types.SET_ALL_DIALOG_TYPES, data);
};

export const setAllDialogTransactionTypes = ({ commit }, data) => {
  commit(types.SET_ALL_DIALOG_TRANSACTION_TYPES, data);
};

export const setAllDialogViewingTypes = ({ commit }, data) => {
  commit(types.SET_ALL_DIALOG_VIEWING_TYPES, data);
};

export const setAllDialogConfirmationTypes = ({ commit }, data) => {
  commit(types.SET_ALL_DIALOG_CONFIRMATION_TYPES, data);
};

export const selectDate = ({ commit }, data) => {
  commit(types.SET_SELECT_DATE, data);
};

export const patchEventToEvents = ({ commit }, data) => commit(types.PATCH_EVENT, data);
export const requestDropResizeCalendarEvent = ({ commit }) => {
  commit(types.DROP_RESIZE_CALENDAR_EVENT);
};
export const fetchDropResizeCalendarEvent = ({ dispatch }, updateData) => {
  dispatch('requestDropResizeCalendarEvent');
  Api.patch(`calendar/events/drop_calendar_event/${updateData.event_id}`, updateData)
    .then((data) => {
      dispatch('patchEventToEvents', data.data.result.data);
    });
};

export const resetState = ({ commit }) => commit(types.RESET_STATE);

// Viewing operations
export const searchApplicantOrProperty = ({ commit }, type) => commit(types.SEARCH_APPLICANT_OR_PROPERTY, type);
export const resetSearchApplicantOrProperty = ({ commit }) => commit(types.RESET_SEARCH_APPLICANT_OR_PROPERTY);
export const removeApplicant = ({ commit }) => commit(types.REMOVE_APPLICANT);
export const removeProperty = ({ commit }) => commit(types.REMOVE_PROPERTY);
export const setSelectedProperty = ({ commit }, property) => commit(types.SET_SELECTED_PROPERTY, property);
export const setProperty = ({ dispatch }, config) => {
  dispatch('setSelectedProperty', config.property);
  const configData = { ...config };
  configData.action = 'create';
};
export const setSelectedApplicant = ({ commit }, applicant) => commit(types.SET_SELECTED_APPLICANT, applicant);
export const unsetSelectedApplicant = ({ commit }) => commit(types.UNSET_SELECTED_APPLICANT);
export const selectApplicant = ({ commit }, data) => commit(types.SELECT_APPLICANT, data);
export const findViewedContact = ({ commit }, data) => commit(types.FIND_VIEWED_CONTACT, data);
export const setApplicants = async ({ dispatch }, contact) => {
  const payload = {
    contact_id: contact.contact_id,
  };
  const { data } = await new ApplicantsServices().searchApplicant({}, payload);
  dispatch('selectApplicant', { contact, data });
  dispatch('findViewedContact', { filterBy: contact });
};
export const resetViewingState = ({ commit }) => commit(types.RESET_VIEWING_STATE);
export const handleViewingData = ({ commit }, config) => commit(types.HANDLE_VIEWING_DATA, config);
export const setApplicantList = ({ commit }, data) => commit(types.SET_APPLICANT_LIST, data);
export const setApplicantListMeta = ({ commit }, data) => commit(types.SET_APPLICANT_LIST_META, data);
export const resetApplicantList = ({ commit }) => commit(types.RESET_APPLICANT_LIST);
export const searchApplicants = async ({ dispatch }, payload) => {
  const { data, meta } = await new ApplicantSearchServices().search(payload);
  dispatch('setApplicantList', { data, meta });

  return { data, meta };
};
export const getTenant = ({ commit }, config) => commit(types.GET_TENANT, config);
export const checkTenantExists = async ({ dispatch, state }, config) => {
  const profileId = 5;
  const payload = {
    profileId: null,
  };
  if (config.action === 'create') {
    payload.profileId = state.selectedProperty.property.property_profile_id;
  } else if (config.property_profile_id) {
    payload.profileId = config.property_profile_id;
  } else {
    payload.profileId = config.property.property_profile_id;
  }
  const result = await new PropertyProfileServices().getContact(payload.profileId, profileId);
  dispatch('getTenant', result.result.data);
};
export const controlStatusAvailability = ({ commit }) => commit(types.CONTROL_STATUS_AVAILABILITY);
export const openCalendarModal = ({ commit }, selector) => commit(types.OPEN_CALENDAR_MODAL, selector);
