import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.SET_EMAIL_SENDERS](state, data) {
    Vue.set(state, 'emailSenders', data);
  },

  [types.SET_DEFAULT_EMAIL_TEMPLATE](state, data) {
    Vue.set(state, 'defaultEmailTemplate', data);
  },
};
