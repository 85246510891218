import ApiService from '@/core/api/api.service';

export default class UsersServices extends ApiService {
  async fetchMe() {
    this.url = 'users/me';
    const { result } = await this.get();
    return result[0];
  }

  create(payload) {
    this.url = 'users2';
    this.payload = payload;
    return this.baseRemitter('post', 'message');
  }

  update(payload, userId) {
    this.url = `users2/${userId}`;
    this.payload = payload;
    return this.baseRemitter('post', 'message');
  }
}
