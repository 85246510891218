import DashboardStatisticsServices from '@/modules/dashboard/services/dashboardStatistics.services';
import * as types from './mutation_types';

export const requestDashboardStats = ({ commit }) => commit(types.GET_DASHBOARD_STATS);
export const receiveDashboardStatsSuccess = ({ commit }, data) => commit(types.SET_DASHBOARD_STATS, data);
export const receiveDashboardStatsError = ({ commit }, data) => commit(types.GET_DASHBOARD_STATS_ERROR, data);
export const fetchDashboardStats = async ({ commit }, payload) => {
  const newData = await new DashboardStatisticsServices().fetchDashboardStats(payload);
  commit(types.SET_DASHBOARD_STATS, { newData, transactionType: payload });
};
