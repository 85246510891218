export default [{
  name: 'BlankPage',
  path: 'blank_page',
  meta: {
    title: 'Preview Applicant',
    breadcrumb: [
      { name: 'Preview Applicant' },
    ],
  },
  component: () => import('@/modules/blank_page/views/BlankPage.vue'),
}];
