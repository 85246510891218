import Api from '@/libraries/api';
import router from '@/router';
import * as types from './mutation_types';

export const requestBranches = ({ commit }) => commit(types.GET_BRANCHES);
export const receiveBranchesSuccess = ({ commit }, data) => commit(types.SET_BRANCHES, data);
export const receiveBranchesError = ({ commit }, error) => commit(types.GET_BRANCHES_ERROR, error);
export const fetchBranches = ({ dispatch, state }, payload) => {
  if (!state.branches || router.history.current.fullPath === '/settings/branch-settings') {
    dispatch('requestBranches');
    let param = '';
    if (payload && payload.request) {
      param = payload.request;
    }
    Api.get(`branches${param}?__limit=30`)
      .then(({ data }) => {
        dispatch('receiveBranchesSuccess', data);
      })
      .catch(({ response }) => {
        dispatch('receiveBranchesError', response);
      });
  }
};

export const requestBranchSearch = ({ commit }) => commit(types.GET_BRANCH_SEARCH);
export const receiveBranchSearchSuccess = ({ commit }, data) => commit(types.SET_BRANCH_SEARCH, data);
export const receiveBranchSearchError = ({ commit }, error) => commit(types.GET_BRANCH_SEARCH_ERROR, error);
export const fetchBranchSearch = ({ dispatch }, keyword) => {
  dispatch('requestBranchSearch');
  Api.get(`branches?name=${keyword}`)
    .then(({ data }) => {
      dispatch('receiveBranchSearchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBranchSearchError', response);
    });
};

export const requestBranch = ({ commit }) => commit(types.GET_BRANCH);
export const receiveBranchSuccess = ({ commit }, data) => commit(types.SET_BRANCH, data);
export const receiveBranchError = ({ commit }, error) => commit(types.GET_BRANCH_ERROR, error);
export const fetchBranch = ({ dispatch }, branchId) => {
  dispatch('requestBranch');
  Api.get(`branches/${branchId}`)
    .then(({ data }) => {
      dispatch('receiveBranchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBranchError', response);
    });
};

export const requestBranchDetail = ({ commit }) => commit(types.GET_BRANCH_DETAIL);
export const receiveBranchDetailSuccess = ({ commit }, data) => commit(types.SET_BRANCH_DETAIL, data);
export const receiveBranchDetailError = ({ commit }, error) => commit(types.GET_BRANCH_DETAIL_ERROR, error);
export const fetchBranchDetail = ({ dispatch }, branchId) => {
  dispatch('requestBranchDetail');
  Api.get(`branches/${branchId}`)
    .then(({ data }) => {
      dispatch('receiveBranchDetailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBranchDetailError', response);
    });
};

export const requestCreateBranch = ({ commit }) => commit(types.GET_CREATE_BRANCH);
export const receiveCreateBranchSuccess = ({ commit }, data) => commit(types.SET_CREATE_BRANCH, data);
export const receiveCreateBranchError = ({ commit }, error) => commit(types.GET_CREATE_BRANCH_ERROR, error);
export const fetchCreateBranch = ({ dispatch }, branchData) => {
  dispatch('requestCreateBranch');
  Api.post('branches', branchData)
    .then(({ data }) => {
      dispatch('receiveCreateBranchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateBranchError', response);
    });
};

export const requestUpdateBranch = ({ commit }) => commit(types.GET_UPDATE_BRANCH);
export const receiveUpdateBranchSuccess = ({ commit }, data) => commit(types.SET_UPDATE_BRANCH, data);
export const receiveUpdateBranchError = ({ commit }, error) => commit(types.GET_UPDATE_BRANCH_ERROR, error);
export const fetchUpdateBranch = ({ dispatch }, branchData) => {
  dispatch('requestUpdateBranch');
  Api.patch(`branches/${branchData.branch_id}`, branchData)
    .then(({ data }) => {
      dispatch('receiveUpdateBranchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateBranchError', response);
    });
};


export const requestBranchDepartments = ({ commit }) => commit(types.GET_BRANCH_DEPARTMENTS);
export const receiveBranchDepartmentsSuccess = ({ commit }, data) => commit(types.SET_BRANCH_DEPARTMENTS, data);
export const receiveBranchDepartmentsError = ({ commit }, error) => commit(types.GET_BRANCH_DEPARTMENTS_ERROR, error);
export const fetchBranchDepartments = ({ dispatch }, branchId) => {
  dispatch('requestBranchDepartments');
  Api.get(`branches/${branchId}/branch_departments`)
    .then(({ data }) => {
      dispatch('receiveBranchDepartmentsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBranchDepartmentsError', response);
    });
};

export const requestCreateDepartment = ({ commit }) => commit(types.GET_CREATE_DEPARTMENT);
export const receiveCreateDepartmentSuccess = ({ commit }, data) => commit(types.SET_CREATE_DEPARTMENT, data);
export const receiveCreateDepartmentError = ({ commit }, error) => commit(types.GET_CREATE_DEPARTMENT_ERROR, error);
export const fetchCreateDepartment = ({ dispatch }, departmentData) => {
  dispatch('requestCreateDepartment');
  Api.post('branch_departments', departmentData)
    .then(({ data }) => {
      dispatch('receiveCreateDepartmentSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateDepartmentError', response);
    });
};

export const requestUpdateDepartment = ({ commit }) => commit(types.GET_UPDATE_DEPARTMENT);
export const receiveUpdateDepartmentSuccess = ({ commit }, data) => commit(types.SET_UPDATE_DEPARTMENT, data);
export const receiveUpdateDepartmentError = ({ commit }, error) => commit(types.GET_UPDATE_DEPARTMENT_ERROR, error);
export const fetchUpdateDepartment = ({ dispatch }, departmentData) => {
  dispatch('requestUpdateDepartment');
  Api.patch(`branches/branch_contact_info/${departmentData.contact_info_id}`, departmentData)
    .then(({ data }) => {
      dispatch('receiveUpdateDepartmentSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateDepartmentError', response);
    });
};

export const requestCreateDepartmentCommunicationInfo = ({ commit }) => commit(types.GET_CREATE_DEPARTMENT_COMMUNICATION_INFO);
export const receiveCreateDepartmentCommunicationInfoSuccess = ({ commit }, data) => commit(types.SET_CREATE_DEPARTMENT_COMMUNICATION_INFO, data);
export const receiveCreateDepartmentCommunicationInfoError = ({ commit }, error) => commit(types.GET_CREATE_DEPARTMENT_COMMUNICATION_INFO_ERROR, error);
export const fetchCreateDepartmentCommunicationInfo = ({ dispatch }, departmentData) => {
  dispatch('requestCreateDepartmentCommunicationInfo');
  Api.post(`branches/${departmentData.branch_id}/branch_contact_info`, departmentData)
    .then(({ data }) => {
      dispatch('receiveCreateDepartmentCommunicationInfoSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateDepartmentCommunicationInfoError', response);
    });
};

export const requestBranchDepartment = ({ commit }) => commit(types.GET_BRANCH_DEPARTMENT);
export const receiveBranchDepartmentSuccess = ({ commit }, data) => commit(types.SET_BRANCH_DEPARTMENT, data);
export const receiveBranchDepartmentError = ({ commit }, error) => commit(types.GET_BRANCH_DEPARTMENT_ERROR, error);
export const fetchBranchDepartment = ({ dispatch }, branchDepartmentId) => {
  dispatch('requestBranchDepartment');
  Api.get(`branch_departments/${branchDepartmentId}`)
    .then(({ data }) => {
      dispatch('receiveBranchDepartmentSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBranchDepartmentError', response);
    });
};


export const requestUpdateBranchDepartment = ({ commit }) => commit(types.GET_UPDATE_BRANCH_DEPARTMENT);
export const receiveUpdateBranchDepartmentSuccess = ({ commit }, data) => commit(types.SET_UPDATE_BRANCH_DEPARTMENT, data);
export const receiveUpdateBranchDepartmentError = ({ commit }, error) => commit(types.GET_UPDATE_BRANCH_DEPARTMENT_ERROR, error);
export const fetchUpdateBranchDepartment = ({ dispatch }, departmentId) => {
  dispatch('requestUpdateBranchDepartment');
  Api.get(`branch_departments/${departmentId}`)
    .then(({ data }) => {
      dispatch('receiveUpdateBranchDepartmentSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateBranchDepartmentError', response);
    });
};

export const requestBranchDepartmentDataFeed = ({ commit }) => commit(types.GET_BRANCH_DEPARTMENT_DATA_FEED);
export const receiveBranchDepartmentDataFeedSuccess = ({ commit }, data) => commit(types.SET_BRANCH_DEPARTMENT_DATA_FEED, data);
export const receiveBranchDepartmentDataFeedError = ({ commit }, error) => commit(types.GET_BRANCH_DEPARTMENT_DATA_FEED_ERROR, error);
export const fetchBranchDepartmentDataFeed = ({ dispatch }, branchDepartmentId) => {
  dispatch('requestBranchDepartmentDataFeed');
  Api.get(`branch_departments/${branchDepartmentId}/branch_data_feeds`)
    .then(({ data }) => {
      dispatch('receiveBranchDepartmentDataFeedSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBranchDepartmentDataFeedError', response);
    });
};

export const requestDepartments = ({ commit }) => commit(types.GET_DEPARTMENTS);
export const receiveDepartmentsSuccess = ({ commit }, data) => commit(types.SET_DEPARTMENTS, data);
export const receiveDepartmentsError = ({ commit }, error) => commit(types.GET_DEPARTMENTS_ERROR, error);
export const fetchDepartments = ({ dispatch }) => {
  dispatch('requestDepartments');
  Api.get('departments')
    .then(({ data }) => {
      dispatch('receiveDepartmentsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDepartmentsError', response);
    });
};

export const requestDataProviders = ({ commit }) => commit(types.GET_DATA_PROVIDERS);
export const receiveDataProvidersSuccess = ({ commit }, data) => commit(types.SET_DATA_PROVIDERS, data);
export const receiveDataProvidersError = ({ commit }, error) => commit(types.GET_DATA_PROVIDERS_ERROR, error);
export const fetchDataProviders = ({ dispatch }) => {
  dispatch('requestDataProviders');
  Api.get('data_providers')
    .then(({ data }) => {
      dispatch('receiveDataProvidersSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDataProvidersError', response);
    });
};

export const requestDataProvider = ({ commit }) => commit(types.GET_DATA_PROVIDER);
export const receiveDataProviderSuccess = ({ commit }, data) => commit(types.SET_DATA_PROVIDER, data);
export const receiveDataProviderError = ({ commit }, error) => commit(types.GET_DATA_PROVIDER_ERROR, error);
export const fetchDataProvider = ({ dispatch }, dataProvideID) => {
  dispatch('requestDataProvider');
  Api.get(`data_providers/${dataProvideID}`)
    .then(({ data }) => {
      dispatch('receiveDataProviderSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDataProviderError', response);
    });
};
