export const GET_ROLES = 'GET_ROLES';
export const SET_ROLES = 'SET_ROLES';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_CREATE_USER = 'GET_CREATE_USER';
export const SET_CREATE_USER = 'SET_CREATE_USER';
export const GET_CREATE_USER_ERROR = 'GET_CREATE_USER_ERROR';

export const GET_UPDATE_USER = 'GET_UPDATE_USER';
export const SET_UPDATE_USER = 'SET_UPDATE_USER';
export const GET_UPDATE_USER_ERROR = 'GET_UPDATE_USER_ERROR';

export const GET_CREATE_USER_AVATAR = 'GET_CREATE_USER_AVATAR';
export const SET_CREATE_USER_AVATAR = 'SET_CREATE_USER_AVATAR';
export const GET_CREATE_USER_AVATAR_ERROR = 'GET_CREATE_USER_AVATAR_ERROR';

export const GET_CREATE_SIGNATURE = 'GET_CREATE_SIGNATURE';
export const SET_CREATE_SIGNATURE = 'SET_CREATE_SIGNATURE';
export const GET_CREATE_SIGNATURE_ERROR = 'GET_CREATE_SIGNATURE_ERROR';

export const GET_UPDATE_SIGNATURE = 'GET_UPDATE_SIGNATURE';
export const SET_UPDATE_SIGNATURE = 'SET_UPDATE_SIGNATURE';
export const GET_UPDATE_SIGNATURE_ERROR = 'GET_UPDATE_SIGNATURE_ERROR';

export const GET_SOCIAL_MEDIA = 'GET_SOCIAL_MEDIA';
export const SET_SOCIAL_MEDIA = 'SET_SOCIAL_MEDIA';
export const GET_SOCIAL_MEDIA_ERROR = 'GET_SOCIAL_MEDIA_ERROR';

export const GET_SOCIAL_MEDIA_ACCOUNTS = 'GET_SOCIAL_MEDIA_ACCOUNTS';
export const SET_SOCIAL_MEDIA_ACCOUNTS = 'SET_SOCIAL_MEDIA_ACCOUNTS';
export const GET_SOCIAL_MEDIA_ACCOUNTS_ERROR = 'GET_SOCIAL_MEDIA_ACCOUNTS_ERROR';

export const GET_SOCIAL_MEDIA_ACCOUNT = 'GET_SOCIAL_MEDIA_ACCOUNT';
export const SET_SOCIAL_MEDIA_ACCOUNT = 'SET_SOCIAL_MEDIA_ACCOUNT';
export const GET_SOCIAL_MEDIA_ACCOUNT_ERROR = 'GET_SOCIAL_MEDIA_ACCOUNT_ERROR';

export const GET_SOCIAL_MEDIA_ACCOUNT_PAGES = 'GET_SOCIAL_MEDIA_ACCOUNT_PAGES';
export const SET_SOCIAL_MEDIA_ACCOUNT_PAGES = 'SET_SOCIAL_MEDIA_ACCOUNT_PAGES';
export const GET_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR = 'GET_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR';

export const GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES = 'GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES';
export const SET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES = 'SET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES';
export const GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES_ERROR = 'GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES_ERROR';

export const GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE = 'GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE';
export const SET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE = 'SET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE';
export const GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR = 'GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR';

export const UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE = 'UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE';
export const UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_SUCCESS = 'UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_SUCCESS';
export const UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR = 'UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR';

export const GET_SOCIAL_MEDIA_ACCOUNT_PAGE = 'GET_SOCIAL_MEDIA_ACCOUNT_PAGE';
export const SET_SOCIAL_MEDIA_ACCOUNT_PAGE = 'SET_SOCIAL_MEDIA_ACCOUNT_PAGE';
export const GET_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR = 'GET_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR';

export const GET_DELETE_SOCIAL_MEDIA_PAGE = 'GET_DELETE_SOCIAL_MEDIA_PAGE';
export const SET_DELETE_SOCIAL_MEDIA_PAGE = 'SET_DELETE_SOCIAL_MEDIA_PAGE';
export const GET_DELETE_SOCIAL_MEDIA_PAGE_ERROR = 'GET_DELETE_SOCIAL_MEDIA_PAGE_ERROR';

export const GET_DELETE_SOCIAL_MEDIA_ACCOUNT = 'GET_DELETE_SOCIAL_MEDIA_ACCOUNT';
export const SET_DELETE_SOCIAL_MEDIA_ACCOUNT = 'SET_DELETE_SOCIAL_MEDIA_ACCOUNT';
export const GET_DELETE_SOCIAL_MEDIA_ACCOUNT_ERROR = 'GET_DELETE_SOCIAL_MEDIA_ACCOUNT_ERROR';

export const UPDATE_SOCIAL_MEDIA_ACCOUNT = 'UPDATE_SOCIAL_MEDIA_ACCOUNT';
export const UPDATE_SOCIAL_MEDIA_ACCOUNT_ERROR = 'UPDATE_SOCIAL_MEDIA_ACCOUNT_ERROR';
export const UPDATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS = 'UPDATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS';

export const GET_NOTIFICATION_TYPES = 'GET_NOTIFICATION_TYPES';
export const SET_NOTIFICATION_TYPES = 'SET_NOTIFICATION_TYPES';
export const GET_NOTIFICATION_TYPES_ERROR = 'GET_NOTIFICATION_TYPES_ERROR';

export const GET_AUTOMATIONS = 'GET_AUTOMATIONS';
export const SET_AUTOMATIONS = 'SET_AUTOMATIONS';

export const GET_NOTIFICATION_RULE = 'GET_NOTIFICATION_RULE';
export const SET_NOTIFICATION_RULE = 'SET_NOTIFICATION_RULE';
export const GET_NOTIFICATION_RULE_ERROR = 'GET_NOTIFICATION_RULE_ERROR';

export const GET_TRACKING_SITES = 'GET_TRACKING_SITES';
export const SET_TRACKING_SITES = 'SET_TRACKING_SITES';
export const GET_TRACKING_SITES_ERROR = 'GET_TRACKING_SITES_ERROR';

export const GET_PERIOD = 'GET_PERIOD';
export const SET_PERIOD = 'SET_PERIOD';
export const GET_PERIOD_ERROR = 'GET_PERIOD_ERROR';

export const CREATE_NOTIFICATION_RULES = 'CREATE_NOTIFICATION_RULES';
export const CREATE_NOTIFICATION_RULES_SUCCESS = 'CREATE_NOTIFICATION_RULES_SUCCESS';
export const CREATE_NOTIFICATION_RULES_ERROR = 'CREATE_NOTIFICATION_RULES_ERROR';

export const UPDATE_AUTOMATIONS = 'UPDATE_AUTOMATIONS';
export const UPDATE_AUTOMATIONS_SUCCESS = 'UPDATE_AUTOMATIONS_SUCCESS';
export const UPDATE_AUTOMATIONS_ERROR = 'UPDATE_AUTOMATIONS_ERROR';

export const GET_NOTIFICATION_CHANNELS = 'GET_NOTIFICATION_CHANNELS';
export const SET_NOTIFICATION_CHANNELS = 'SET_NOTIFICATION_CHANNELS';
export const GET_NOTIFICATION_CHANNELS_ERROR = 'GET_NOTIFICATION_CHANNELS_ERROR';

export const DELETE_NOTIFICATION_RULE = 'DELETE_NOTIFICATION_RULE';
export const DELETE_NOTIFICATION_RULE_SUCCESS = 'DELETE_NOTIFICATION_RULE_SUCCESS';
export const DELETE_NOTIFICATION_RULE_ERROR = 'DELETE_NOTIFICATION_RULE_ERROR';

export const GET_BROWSER_NOTIFICATIONS = 'GET_BROWSER_NOTIFICATIONS';
export const SET_BROWSER_NOTIFICATIONS = 'SET_BROWSER_NOTIFICATIONS';
export const GET_BROWSER_NOTIFICATIONS_ERROR = 'GET_BROWSER_NOTIFICATIONS_ERROR';

export const GET_UPDATE_BROWSER_NOTIFICATIONS = 'GET_UPDATE_BROWSER_NOTIFICATIONS';
export const SET_UPDATE_BROWSER_NOTIFICATIONS = 'SET_UPDATE_BROWSER_NOTIFICATIONS';
export const GET_UPDATE_BROWSER_NOTIFICATIONS_ERROR = 'GET_UPDATE_BROWSER_NOTIFICATIONS_ERROR';

export const GET_BLOG_SETTINGS = 'GET_BLOG_SETTINGS';
export const SET_BLOG_SETTINGS = 'SET_BLOG_SETTINGS';
export const GET_BLOG_SETTINGS_ERROR = 'GET_BLOG_SETTINGS_ERROR';

export const GET_UPDATE_BLOG_SETTINGS = 'GET_UPDATE_BLOG_SETTINGS';
export const SET_UPDATE_BLOG_SETTINGS = 'SET_UPDATE_BLOG_SETTINGS';
export const GET_UPDATE_BLOG_SETTINGS_ERROR = 'GET_UPDATE_BLOG_SETTINGS_ERROR';

export const GET_BLOG_MENUS = 'GET_BLOG_MENUS';
export const SET_BLOG_MENUS = 'SET_BLOG_MENUS';
export const GET_BLOG_MENUS_ERROR = 'GET_BLOG_MENUS_ERROR';

export const GET_BLOG_MENU = 'GET_BLOG_MENU';
export const SET_BLOG_MENU = 'SET_BLOG_MENU';
export const GET_BLOG_MENU_ERROR = 'GET_BLOG_MENU_ERROR';

export const GET_CREATE_BLOG_MENU = 'GET_CREATE_BLOG_MENU';
export const SET_CREATE_BLOG_MENU = 'SET_CREATE_BLOG_MENU';
export const GET_CREATE_BLOG_MENU_ERROR = 'GET_CREATE_BLOG_MENU_ERROR';

export const GET_DELETE_BLOG_MENU = 'GET_DELETE_BLOG_MENU';
export const SET_DELETE_BLOG_MENU = 'SET_DELETE_BLOG_MENU';
export const GET_DELETE_BLOG_MENU_ERROR = 'GET_DELETE_BLOG_MENU_ERROR';

export const GET_UPDATE_BLOG_MENU = 'GET_UPDATE_BLOG_MENU';
export const SET_UPDATE_BLOG_MENU = 'SET_UPDATE_BLOG_MENU';
export const GET_UPDATE_BLOG_MENU_ERROR = 'GET_UPDATE_BLOG_MENU_ERROR';

export const GET_SORT_BLOG_MENU = 'GET_SORT_BLOG_MENU';
export const SET_SORT_BLOG_MENU = 'SET_SORT_BLOG_MENU';
export const GET_SORT_BLOG_MENU_ERROR = 'GET_SORT_BLOG_MENU_ERROR';

export const CREATE_SOCIAL_MEDIA_ACCOUNT = 'CREATE_SOCIAL_MEDIA_ACCOUNT';
export const GET_CREATE_SOCIAL_MEDIA_ACCOUNT_ERROR = 'GET_CREATE_SOCIAL_MEDIA_ACCOUNT_ERROR';
export const GET_CREATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS = 'GET_CREATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS';

export const CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES = 'CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES';
export const GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS = 'GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS';
export const GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR = 'GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR';

export const UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES = 'UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES';
export const GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS = 'GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS';
export const GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR = 'GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR';

export const GET_AD_ACCOUNTS = 'GET_AD_ACCOUNTS';
export const SET_AD_ACCOUNTS = 'SET_AD_ACCOUNTS';
export const GET_AD_ACCOUNTS_ERROR = 'GET_AD_ACCOUNTS_ERROR';

export const GET_SENDER_EMAILS = 'GET_SENDER_EMAILS';
export const SET_SENDER_EMAILS = 'SET_SENDER_EMAILS';
export const GET_SENDER_EMAILS_ERROR = 'GET_SENDER_EMAILS_ERROR';

export const GET_SENDER_EMAIL = 'GET_SENDER_EMAIL';
export const SET_SENDER_EMAIL = 'SET_SENDER_EMAIL';
export const GET_SENDER_EMAIL_ERROR = 'GET_SENDER_EMAIL_ERROR';

export const CREATE_SENDER_SETTING = 'CREATE_SENDER_SETTING';
export const CREATE_SENDER_SETTING_SUCCESS = 'CREATE_SENDER_SETTING_SUCCESS';
export const CREATE_SENDER_SETTING_ERROR = 'CREATE_SENDER_SETTING_ERROR';

export const UPDATE_SENDER_SETTING = 'UPDATE_SENDER_SETTING';
export const UPDATE_SENDER_SETTING_SUCCESS = 'UPDATE_SENDER_SETTING_SUCCESS';
export const UPDATE_SENDER_SETTING_ERROR = 'UPDATE_SENDER_SETTING_ERROR';

export const DELETE_SENDER_SETTING = 'DELETE_SENDER_SETTING';
export const DELETE_SENDER_SETTING_SUCCESS = 'DELETE_SENDER_SETTING_SUCCESS';
export const DELETE_SENDER_SETTING_ERROR = 'DELETE_SENDER_SETTING_ERROR';

export const GET_SENDER_SMS_SETTINGS = 'GET_SENDER_SMS_SETTINGS';
export const SET_SENDER_SMS_SETTINGS = 'SET_SENDER_SMS_SETTINGS';
export const GET_SENDER_SMS_SETTINGS_ERROR = 'GET_SENDER_SMS_SETTINGS_ERROR';

export const GET_SENDER_SMS = 'GET_SENDER_SMS';
export const SET_SENDER_SMS = 'SET_SENDER_SMS';
export const GET_SENDER_SMS_ERROR = 'GET_SENDER_SMS_ERROR';

export const CREATE_SENDER_SMS = 'CREATE_SENDER_SMS';
export const CREATE_SENDER_SMS_SUCCESS = 'CREATE_SENDER_SMS_SUCCESS';
export const CREATE_SENDER_SMS_ERROR = 'CREATE_SENDER_SMS_ERROR';

export const UPDATE_SENDER_SMS = 'UPDATE_SENDER_SMS';
export const UPDATE_SENDER_SMS_SUCCESS = 'UPDATE_SENDER_SMS_SUCCESS';
export const UPDATE_SENDER_SMS_ERROR = 'UPDATE_SENDER_SMS_ERROR';

export const DELETE_SENDER_SMS = 'DELETE_SENDER_SMS';
export const DELETE_SENDER_SMS_SUCCESS = 'DELETE_SENDER_SMS_SUCCESS';
export const DELETE_SENDER_SMS_ERROR = 'DELETE_SENDER_SMS_ERROR';

export const GET_COMPANIES = 'GET_COMPANIES';
export const SET_COMPANIES = 'SET_COMPANIES';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';

export const GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';
export const GET_COMPANY_SETTINGS_ERROR = 'GET_COMPANY_SETTINGS_ERROR';

export const GET_UPDATE_COMPANIES = 'GET_UPDATE_COMPANIES';
export const SET_UPDATE_COMPANIES = 'SET_UPDATE_COMPANIES';
export const GET_UPDATE_COMPANIES_ERROR = 'GET_UPDATE_COMPANIES_ERROR';

export const GET_UPDATE_COMPANY_SETTINGS = 'GET_UPDATE_COMPANY_SETTINGS';
export const SET_UPDATE_COMPANY_SETTINGS = 'SET_UPDATE_COMPANY_SETTINGS';
export const GET_UPDATE_COMPANY_SETTINGS_ERROR = 'GET_UPDATE_COMPANY_SETTINGS_ERROR';

export const GET_BAIT_SETTINGS_UPDATE = 'GET_BAIT_SETTINGS_UPDATE';
export const SET_BAIT_SETTINGS_UPDATE = 'SET_BAIT_SETTINGS_UPDATE';
export const GET_BAIT_SETTINGS_UPDATE_ERROR = 'GET_BAIT_SETTINGS_UPDATE_ERROR';

export const GET_BAIT_SETTINGS = 'GET_BAIT_SETTINGS';
export const SET_BAIT_SETTINGS = 'SET_BAIT_SETTINGS';
export const GET_BAIT_SETTINGS_ERROR = 'GET_BAIT_SETTINGS_ERROR';

export const GET_BAIT_SETTINGS_SALES = 'GET_BAIT_SETTINGS_SALES';
export const SET_BAIT_SETTINGS_SALES = 'SET_BAIT_SETTINGS_SALES';
export const GET_BAIT_SETTINGS_SALES_ERROR = 'GET_BAIT_SETTINGS_SALES_ERROR';

export const GET_BAIT_SETTINGS_LETTINGS = 'GET_BAIT_SETTINGS_LETTINGS';
export const SET_BAIT_SETTINGS_LETTINGS = 'SET_BAIT_SETTINGS_LETTINGS';
export const GET_BAIT_SETTINGS_LETTINGS_ERROR = 'GET_BAIT_SETTINGS_LETTINGS_ERROR';

export const GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID = 'GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID';
export const SET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID = 'SET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID';
export const GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID_ERROR = 'GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID_ERROR';

export const GET_BAIT_MARKETING_PLAN_OVERVIEW = 'GET_BAIT_MARKETING_PLAN_OVERVIEW';
export const SET_BAIT_MARKETING_PLAN_OVERVIEW = 'SET_BAIT_MARKETING_PLAN_OVERVIEW';
export const GET_BAIT_MARKETING_PLAN_OVERVIEW_ERROR = 'GET_BAIT_MARKETING_PLAN_OVERVIEW_ERROR';

export const GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW = 'GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW';
export const SET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW = 'SET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW';
export const GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW_ERROR = 'GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW_ERROR';

export const GET_CUSTOM_FIELD_GROUPS = 'GET_CUSTOM_FIELD_GROUPS';
export const GET_CUSTOM_FIELD_GROUPS_ERROR = 'GET_CUSTOM_FIELD_GROUPS_ERROR';
export const SET_CUSTOM_FIELD_GROUPS = 'SET_CUSTOM_FIELD_GROUPS';

export const GET_CREATE_CUSTOM_FIELD_GROUP = 'GET_CREATE_CUSTOM_FIELD_GROUP';
export const SET_CREATE_CUSTOM_FIELD_GROUP = 'SET_CREATE_CUSTOM_FIELD_GROUP';
export const GET_CREATE_CUSTOM_FIELD_GROUP_ERROR = 'GET_CREATE_CUSTOM_FIELD_GROUP_ERROR';

export const GET_UPDATE_CUSTOM_FIELD_GROUP = 'GET_UPDATE_CUSTOM_FIELD_GROUP';
export const GET_UPDATE_CUSTOM_FIELD_GROUP_ERROR = 'GET_UPDATE_CUSTOM_FIELD_GROUP_ERROR';
export const SET_UPDATE_CUSTOM_FIELD_GROUP = 'SET_UPDATE_CUSTOM_FIELD_GROUP';

export const GET_CREATE_CUSTOM_FIELD = 'GET_CREATE_CUSTOM_FIELD';
export const SET_CREATE_CUSTOM_FIELD = 'SET_CREATE_CUSTOM_FIELD';
export const GET_CREATE_CUSTOM_FIELD_ERROR = 'GET_CREATE_CUSTOM_FIELD_ERROR';

export const GET_UPDATE_CUSTOM_FIELD = 'GET_UPDATE_CUSTOM_FIELD';
export const SET_UPDATE_CUSTOM_FIELD = 'SET_UPDATE_CUSTOM_FIELD';
export const GET_UPDATE_CUSTOM_FIELD_ERROR = 'GET_UPDATE_CUSTOM_FIELD_ERROR';

export const GET_PROPERTY_LIVE = 'GET_PROPERTY_LIVE';
export const SET_PROPERTY_LIVE = 'SET_PROPERTY_LIVE';
export const GET_PROPERTY_LIVE_ERROR = 'GET_PROPERTY_LIVE_ERROR';

export const GET_CHECK_SOCIAL_AUTH_STATUS = 'GET_CHECK_SOCIAL_AUTH_STATUS';
export const SET_CHECK_SOCIAL_AUTH_STATUS = 'SET_CHECK_SOCIAL_AUTH_STATUS';
export const GET_CHECK_SOCIAL_AUTH_STATUS_ERROR = 'GET_CHECK_SOCIAL_AUTH_STATUS_ERROR';

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';

export const GET_CREATE_LOCATIONS = 'GET_CREATE_LOCATIONS';
export const SET_CREATE_LOCATIONS = 'SET_CREATE_LOCATIONS';
export const GET_CREATE_LOCATIONS_ERROR = 'GET_CREATE_LOCATIONS_ERROR';

export const GET_LOCATION = 'GET_LOCATION';
export const SET_LOCATION = 'SET_LOCATION';
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR';

export const GET_UPDATE_LOCATIONS = 'GET_UPDATE_LOCATIONS';
export const SET_UPDATE_LOCATIONS = 'SET_UPDATE_LOCATIONS';
export const GET_UPDATE_LOCATIONS_ERROR = 'GET_UPDATE_LOCATIONS_ERROR';

export const GET_SORT_LOCATIONS = 'GET_SORT_LOCATIONS';
export const SET_SORT_LOCATIONS = 'SET_SORT_LOCATIONS';
export const GET_SORT_LOCATIONS_ERROR = 'GET_SORT_LOCATIONS_ERROR';

export const GET_PROVIDER_SETTINGS = 'GET_PROVIDER_SETTINGS';
export const SET_PROVIDER_SETTINGS = 'SET_PROVIDER_SETTINGS';
export const GET_PROVIDER_SETTINGS_ERROR = 'GET_PROVIDER_SETTINGS_ERROR';

export const GET_CREDIT_INFO = 'GET_CREDIT_INFO';
