export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';

export const SET_USERS = 'SET_USERS';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const SET_BRANCH_USERS = 'SET_BRANCH_USERS';
export const GET_BRANCH_USERS = 'GET_BRANCH_USERS';
export const GET_BRANCH_USERS_ERROR = 'GET_BRANCH_USERS_ERROR';
