import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_CONTACT_ADDRESS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CONTACT_ADDRESS](state, data) {
    Vue.set(state, 'contactAddress', data.addresses);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_ADDRESS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_ADDRESS](state) {
    Vue.set(state, 'contactAddress', null);
  },
  [types.GET_CONTACT_ADDRESS_DETAILS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CONTACT_ADDRESS_DETAILS](state, data) {
    Vue.set(state, 'contactAddressDetails', data.results[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_ADDRESS_DETAILS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PROPERTY_ADDRESS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'propertyAddressProcessedError', false);
  },
  [types.SET_PROPERTY_ADDRESS](state, data) {
    Vue.set(state, 'propertyAddress', data.addresses);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_ADDRESS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyAddressProcessedError', 'Address not found.');
  },

  [types.RESET_ADDRESS](state) {
    Vue.set(state, 'propertyAddress', null);
  },
  [types.GET_PROPERTY_ADDRESS_DETAILS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PROPERTY_ADDRESS_DETAILS](state, data) {
    Vue.set(state, 'propertyAddressDetails', data.results[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_ADDRESS_DETAILS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_SELECTED_PROPERTY_ADDRESS](state, data) {
    Vue.set(state, 'selectedPropertyAddress', data);
  },
  [types.GET_EVENT_ADDRESS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_EVENT_ADDRESS](state, data) {
    Vue.set(state, 'eventAddress', data.addresses);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EVENT_ADDRESS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_ADDRESS](state) {
    Vue.set(state, 'eventAddress', null);
  },
  [types.GET_EVENT_ADDRESS_DETAILS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_EVENT_ADDRESS_DETAILS](state, data) {
    Vue.set(state, 'eventAddressDetails', data.results[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EVENT_ADDRESS_DETAILS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_SELECTED_EVENT_ADDRESS](state, data) {
    Vue.set(state, 'selectedEventAddress', data);
  },

  // POSTCODE SEARCHER
  [types.GET_ADDRESSES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactAddresses', null);
    Vue.set(state, 'contactAddressesError', null);
    Vue.set(state, 'propertyAddresses', null);
    Vue.set(state, 'propertyAddressesError', null);
  },
  [types.SET_ADDRESSES](state, data) {
    if (data.type === 'Contact') {
      Vue.set(state, 'contactAddresses', data.data.addresses);
    } else if (data.type === 'Property') {
      Vue.set(state, 'propertyAddresses', data.data.addresses);
    } else if (data.type === 'Branch') {
      Vue.set(state, 'branchAddresses', data.data.addresses);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ADDRESSES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    if (error.type === 'Contact') {
      Vue.set(state, 'contactAddressesError', error.data);
    } else if (error.type === 'Property') {
      Vue.set(state, 'propertyAddressesError', error.data);
    } else if (error.type === 'Branch') {
      Vue.set(state, 'branchAddressesError', error.data);
    }
  },
  [types.RESET_ADDRESSES](state, data) {
    Vue.set(state, 'isLoading', false);
    if (data.type === 'Contact') {
      Vue.set(state, 'contactAddresses', null);
      Vue.set(state, 'contactAddressesError', null);
    } else if (data.type === 'Property') {
      Vue.set(state, 'propertyAddresses', null);
      Vue.set(state, 'propertyAddressesError', null);
    } else if (data.type === 'Branch') {
      Vue.set(state, 'branchAddresses', null);
      Vue.set(state, 'branchAddressesError', null);
    }
  },
  [types.GET_ADDRESS_DETAILS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactAddressDetail', null);
    Vue.set(state, 'propertyAddressDetail', null);
    Vue.set(state, 'contactAddressDetailError', null);
    Vue.set(state, 'propertyAddressDetailError', null);
    Vue.set(state, 'branchAddressesDetail', null);
    Vue.set(state, 'branchAddressesDetailError', null);
  },
  [types.SET_ADDRESS_DETAILS](state, data) {
    let addressDetail = [];
    if (data && data.data && data.data.result && data.data.result[0]) {
      addressDetail = { ...data.data.results[0] };
    }
    if (data.type === 'Contact') {
      Vue.set(state, 'contactAddressDetail', addressDetail);
    } else if (data.type === 'Property') {
      Vue.set(state, 'propertyAddressDetail', addressDetail);
    } else if (data.type === 'Branch') {
      addressDetail = { ...data.data.result.data.results[0] };
      Vue.set(state, 'branchAddressesDetail', addressDetail);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ADDRESS_DETAILS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    if (error.type === 'Contact') {
      Vue.set(state, 'contactAddressDetailError', error.data);
    } else if (error.type === 'Property') {
      Vue.set(state, 'propertyAddressDetailError', error.data);
    } else if (error.type === 'Branch') {
      Vue.set(state, 'branchAddressesDetailError', error.data);
    }
  },
};
