export default [
  {
    path: '/',
    redirect: '/dashboard',
    meta: {
      title: 'Dashboard',
      breadcrumb: [
        { name: 'Dashboard' },
      ],
      hidePageHeader: true,
    },
    name: 'HomePage',
    component: () => import('@/modules/the_dashboard/views/TheDashboardV2.vue'),
  },
  {
    path: '/dashboard',
    meta: {
      title: 'Dashboard',
      breadcrumb: [
        { name: 'Dashboard' },
      ],
      hidePageHeader: true,
    },
    name: 'NewDashboard',
    component: () => import('@/modules/the_dashboard/views/TheDashboardV2.vue'),
  },
  {
    path: '/number-of-applicants',
    meta: {
      title: 'Number Of Applicants',
    },
    name: 'NumberOfApplicants',
    component: () => import('@/modules/the_dashboard/components/TheNumberOfApplicants.vue'),
  },
  {
    path: '/number-of-applicants',
    meta: {
      title: 'Sales - New Applicants',
    },
    name: 'NumberOfApplicantsSales',
    component: () => import('@/modules/the_dashboard/components/TheNumberOfApplicants.vue'),
  },
  {
    path: '/number-of-applicants',
    meta: {
      title: 'Lettings - New Applicants',
    },
    name: 'NumberOfApplicantsLettings',
    component: () => import('@/modules/the_dashboard/components/TheNumberOfApplicants.vue'),
  },
  {
    path: '/active_potential_viewings',
    meta: {
      title: 'Active Potential Viewings',
      breadcrumb: [
        { name: 'Dashboard' },
      ],
    },
    name: 'SellYourProperties',
    component: () => import('@/modules/the_dashboard/components/active_potential_listings/SellYourProperties.vue'),
  },
  {
    path: '/active_potential_listings',
    meta: {
      title: 'Active Potential Listings',
      breadcrumb: [
        { name: 'Dashboard' },
      ],
    },
    name: 'ActivePotentialListings',
    component: () => import('@/modules/the_dashboard/components/active_potential_viewings/ActivePotentialViewings.vue'),
  },
  {
    path: '/:from_list/muted_list',
    meta: {
      title: 'Muted List',
      breadcrumb: [
        { name: 'Dashboard' },
      ],
    },
    name: 'MutedList',
    component: () => import('@/modules/the_dashboard/components/mute_operations/MutedList.vue'),
  },
];
