export const GET_BULK_SMS = 'GET_BULK_SMS';
export const SET_BULK_SMS = 'SET_BULK_SMS';
export const GET_BULK_SMS_ERROR = 'GET_BULK_SMS_ERROR';

export const GET_BULK_SMS_DETAIL = 'GET_BULK_SMS_DETAIL';
export const SET_BULK_SMS_DETAIL = 'SET_BULK_SMS_DETAIL';
export const GET_BULK_SMS_DETAIL_ERROR = 'GET_BULK_SMS_DETAIL_ERROR';

export const GET_SENT_BULK_SMS_DETAIL_LIST = 'GET_SENT_BULK_SMS_DETAIL_LIST';
export const SET_SENT_BULK_SMS_DETAIL_LIST = 'SET_SENT_BULK_SMS_DETAIL_LIST';
export const GET_SENT_BULK_SMS_DETAIL_LIST_ERROR = 'GET_SENT_BULK_SMS_DETAIL_LIST_ERROR';
export const GET_RESET_SENT_BULK_SMS_DETAIL_LIST = 'GET_RESET_SENT_BULK_SMS_DETAIL_LIST';

export const GET_DELETE_BULK_SMS = 'GET_DELETE_BULK_SMS';
export const SET_DELETE_BULK_SMS = 'SET_DELETE_BULK_SMS';
export const GET_DELETE_BULK_SMS_ERROR = 'GET_DELETE_BULK_SMS_ERROR';
