// eslint-disable-next-line import/no-cycle
import Api from '@/libraries/api';
import * as types from './mutation_types';


export const requestSentCampaigns = ({ commit }) => commit(types.GET_SENT_CAMPAIGNS);
export const receiveSentCampaignsSuccess = ({ commit }, data) => commit(types.SET_SENT_CAMPAIGNS, data);
export const receiveSentCampaignsError = ({ commit }, error) => commit(types.GET_SENT_CAMPAIGNS_ERROR, error);
export const fetchSentCampaigns = ({ dispatch }, payload) => {
  dispatch('requestSentCampaigns');
  Api.get(`cp/campaigns${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveSentCampaignsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignsError', response);
    });
};


export const requestSentCampaignDetail = ({ commit }) => commit(types.GET_SENT_CAMPAIGN_DETAIL);
export const receiveSentCampaignDetailSuccess = ({ commit }, data) => commit(types.SET_SENT_CAMPAIGN_DETAIL, data);
export const receiveSentCampaignDetailError = ({ commit }, error) => commit(types.GET_SENT_CAMPAIGN_DETAIL_ERROR, error);
export const fetchSentCampaignDetail = ({ dispatch }, params) => {
  dispatch('requestSentCampaignDetail');
  Api.get(`search_engine/stats/mails/campaign-info/${params.campaign_id}`)
    .then(({ data }) => {
      dispatch('receiveSentCampaignDetailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignDetailError', response);
    });
};

export const requestResetSentCampaignDetailList = ({ commit }) => commit(types.GET_RESET_SENT_CAMPAIGN_DETAIL_LIST);
export const requestSentCampaignDetailList = ({ commit }) => commit(types.GET_SENT_CAMPAIGN_DETAIL_LIST);
export const receiveSentCampaignDetailListSuccess = ({ commit }, data) => commit(types.SET_SENT_CAMPAIGN_DETAIL_LIST, data);
export const receiveSentCampaignDetailListError = ({ commit }, error) => commit(types.GET_SENT_CAMPAIGN_DETAIL_LIST_ERROR, error);


export const fetchSentCampaignDetailListEmailSent = ({ dispatch }, payload) => {
  dispatch('requestSentCampaignDetailList');
  Api.get(`/search_engine/stats/mails/campaigns/${payload.params.campaign_id}/email_send${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveSentCampaignDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignDetailListError', response);
    });
};

export const fetchSentCampaignDetailListEmailOpen = ({ dispatch }, payload) => {
  dispatch('requestSentCampaignDetailList');
  Api.get(`/search_engine/stats/mails/campaigns/${payload.params.campaign_id}/email_open${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveSentCampaignDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignDetailListError', response);
    });
};

export const fetchSentCampaignDetailListEmailClick = ({ dispatch }, payload) => {
  dispatch('requestSentCampaignDetailList');
  Api.get(`/search_engine/stats/mails/campaigns/${payload.params.campaign_id}/email_click${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveSentCampaignDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignDetailListError', response);
    });
};

export const fetchSentCampaignDetailListArticles = ({ dispatch }, payload) => {
  dispatch('requestSentCampaignDetailList');
  Api.get(`/cp/campaigns/${payload.params.campaign_id}/articles${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveSentCampaignDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignDetailListError', response);
    });
};

export const requestSentCampaignEmailTemplate = ({ commit }) => commit(types.GET_SENT_CAMPAIGN_EMAIL_TEMPLATE);
export const receiveSentCampaignEmailTemplateSuccess = ({ commit }, data) => commit(types.SET_SENT_CAMPAIGN_EMAIL_TEMPLATE, data);
export const receiveSentCampaignEmailTemplateError = ({ commit }, error) => commit(types.GET_SENT_CAMPAIGN_EMAIL_TEMPLATE_ERROR, error);
export const fetchSentCampaignEmailTemplate = ({ dispatch }, params) => {
  dispatch('requestSentCampaignEmailTemplate');
  Api.get(`/cp/campaigns/${params.campaign_id}/preview`)
    .then(({ data }) => {
      dispatch('receiveSentCampaignEmailTemplateSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignEmailTemplateError', response);
    });
};

export const fetchNextMonthCampaignEmailTemplate = ({ dispatch }) => {
  dispatch('requestSentCampaignEmailTemplate');
  Api.get('/cp/campaigns/preview')
    .then(({ data }) => {
      dispatch('receiveSentCampaignEmailTemplateSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSentCampaignEmailTemplateError', response);
    });
};

export const requestNextMonthCampaignBlogs = ({ commit }) => commit(types.GET_NEXT_MONTH_CAMPAIGN_BLOGS);
export const receiveNextMonthCampaignBlogsSuccess = ({ commit }, data) => commit(types.SET_NEXT_MONTH_CAMPAIGN_BLOGS, data);
export const receiveNextMonthCampaignBlogsError = ({ commit }, error) => commit(types.GET_NEXT_MONTH_CAMPAIGN_BLOGS_ERROR, error);
export const fetchNextMonthCampaignBlogs = ({ dispatch }) => {
  dispatch('requestNextMonthCampaignBlogs');
  Api.get('cp/campaigns/settings/send_this_month')
    .then(({ data }) => {
      dispatch('receiveNextMonthCampaignBlogsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveNextMonthCampaignBlogsError', response);
    });
};

export const requestRemoveBlogFromCampaign = ({ commit }) => commit(types.GET_REMOVE_BLOG_FROM_CAMPAIGN);
export const receiveRemoveBlogFromCampaignSuccess = ({ commit }, data) => commit(types.SET_REMOVE_BLOG_FROM_CAMPAIGN, data);
export const receiveRemoveBlogFromCampaignError = ({ commit }, error) => commit(types.GET_REMOVE_BLOG_FROM_CAMPAIGN_ERROR, error);
export const removeBlogFromCampaign = ({ dispatch }, payload) => {
  dispatch('requestRemoveBlogFromCampaign');
  Api.patch(`cp/campaigns/article/revoke/${payload.article_id}`)
    .then(({ data }) => {
      dispatch('receiveRemoveBlogFromCampaignSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveRemoveBlogFromCampaignError', response);
    });
};


export const requestSortNewsletterBlogs = ({ commit }) => commit(types.GET_SORT_NEWSLETTER_BLOGS);
export const receiveSortNewsletterBlogsSuccess = ({ commit }, data) => commit(types.SET_SORT_NEWSLETTER_BLOGS, data);
export const receiveSortNewsletterBlogsError = ({ commit }, error) => commit(types.GET_SORT_NEWSLETTER_BLOGS_ERROR, error);
export const sortNewsletterBlogs = ({ dispatch }, payload) => {
  dispatch('requestSortNewsletterBlogs');
  Api.patch('cp/campaigns/article/sort', payload)
    .then(({ data }) => {
      dispatch('receiveSortNewsletterBlogsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSortNewsletterBlogsError', response);
    });
};

export const requestSendNewsletterTestEmail = ({ commit }) => commit(types.GET_SEND_NEWSLETTER_TEST_EMAIL);
export const receiveSendNewsletterTestEmailSuccess = ({ commit }, data) => commit(types.SET_SEND_NEWSLETTER_TEST_EMAIL, data);
export const receiveSendNewsletterTestEmailError = ({ commit }, error) => commit(types.GET_SEND_NEWSLETTER_TEST_EMAIL_ERROR, error);
export const sendNewsletterTestEmail = ({ dispatch }, payload) => {
  dispatch('requestSendNewsletterTestEmail');
  Api.post('cp/campaigns/send_test_mail', payload)
    .then(({ data }) => {
      dispatch('receiveSendNewsletterTestEmailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSendNewsletterTestEmailError', response);
    });
};

export const requestCampaignSettings = ({ commit }) => commit(types.GET_CAMPAIGN_SETTINGS);
export const receiveCampaignSettingsSuccess = ({ commit }, data) => commit(types.SET_CAMPAIGN_SETTINGS, data);
export const receiveCampaignSettingsError = ({ commit }, error) => commit(types.GET_CAMPAIGN_SETTINGS_ERROR, error);
export const fetchCampaignSettings = ({ dispatch }, payload) => {
  dispatch('requestCampaignSettings');
  Api.get('cp/default_settings/campaign', payload)
    .then(({ data }) => {
      dispatch('receiveCampaignSettingsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCampaignSettingsError', response);
    });
};
