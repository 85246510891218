import moment from 'moment';

export default {
  filters: {
    dateFormat(date) {
      const dateTime = new Date(date);
      return moment(dateTime).format('DD/MM/YYYY');
    },
    dateTimeFormat(date) {
      const dateTime = new Date(date);
      return moment(dateTime).format('DD/MM/YYYY HH:mm');
    },
  },
};
