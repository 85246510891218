import ApiService from '@/core/api/api.service';

// eslint-disable-next-line
export class ContactsServices extends ApiService {
  async fetchContactProfiles() {
    this.url = 'profiles';
    const { result: { data } } = await this.get();
    return data;
  }

  create(payload) {
    this.payload = payload;
    this.url = 'contacts';
    return this.post();
  }

  update(payload) {
    this.payload = payload;
    this.url = `contacts/${payload.contact_id}`;
    return this.patch();
  }

  async getReports(payload) {
    this.loader = false;
    this.payload = payload;
    this.url = `contacts/${payload}/overview_reports`;
    const { result } = await this.get();
    return result;
  }

  async previewContactFile(payload) {
    this.payload = payload;
    this.url = 'cdn_file_contents/private';
    const { result } = await this.post();
    return result;
  }

  async fetchApplicants(contactId) {
    this.url = `https://api.test-aws.lifesycle.co.uk/api/v2/contact_applicants/${contactId}`;
    return this.get();
  }

  async sendAmlSms(payload) {
    this.payload = payload;
    this.url = `/contact_aml_check/send_sms/${payload.contact_id}`;
    const result = await this.post();
    return result;
  }
}

export class ContactSearchService extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async search(payload, params) {
    this.url = '/contacts/view';
    this.payload = payload;
    this.params = params;
    const { result } = await this.post();
    return result;
  }

  async searchContacts(payload, params) {
    this.url = '/contacts';
    this.payload = payload;
    this.params = params;
    const { result } = await this.get();
    return result;
  }
}

export class UpdateBuyerProfileService extends ApiService {
  async updateBuyerProfile(payload) {
    this.payload = payload;
    this.url = `/contact_applicants/${payload.applicant_id}`;
    return this.patch();
  }
}

export class ContactSubscriptionService extends ApiService {
  async getContactSubscriptions(contactId) {
    this.url = `contact_subscriptions/contacts/${contactId}`;
    const { result } = await this.get();
    return result;
  }

  async getContactSubscriptionActivities(contactId) {
    this.url = `contacts/${contactId}/subscribe/timeline`;
    const { result } = await this.get();
    return result;
  }

  async updateContactSubscription(payload, contactId) {
    this.payload = payload;
    this.url = `contact_subscriptions/${contactId}`;
    return this.patch();
  }
}
