import ApiService from '@/core/api/api.service';

export default class AudiencesServices extends ApiService {
  constructor() {
    super();
    this.base = 'audiences';
  }

  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async show(id) {
    this.url = `${this.base}/detail/${id}`;
    const { result } = await this.get();
    return result.data;
  }

  create(payload) {
    this.url = `${this.base}/dynamic`;
    this.payload = payload;
    return this.post();
  }

  update(payload, id) {
    this.url = `${this.base}/dynamic/${id}`;
    this.payload = payload;
    return this.put();
  }

  remove(id, showMessage = false) {
    this.url = `${this.base}/${id}`;
    return this.__baseRemitter('delete', 'data', showMessage);
  }

  forceRemove(id) {
    this.url = `${this.base}/${id}?force=true`;
    return this.__baseRemitter('delete', 'data', true);
  }

  preview(url, payload) {
    this.url = url;
    this.payload = payload;
    return this.post();
  }

  async search(payload, params, config) {
    this.url = `${this.base}/list/${config.mail_list_id}`;
    this.params = params;
    if (payload && payload[0]) {
      this.params.searchByKeyword = payload[0].searchByKeyword;
    }
    const { result } = await this.get();
    return result;
  }

  async createStaticAudience(payload) {
    this.url = `${this.base}/static`;
    this.payload = payload;
    return this.baseRemitter('post', 'data', false);
  }

  async updateStaticAudience(payload) {
    this.url = `${this.base}/static/add_contact`;
    this.payload = payload;
    return this.baseRemitter('patch', 'data', false);
  }
}
