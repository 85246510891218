export default [{
  name: 'CreateContractTemplate',
  path: '/create_contract_template',
  meta: {
    title: 'Create Contract Template',
    breadcrumb: [
      { name: 'Contract Templates', link: '/contract_templates' },
      { name: 'Create Contract Template' },
    ],
  },
  component: () => import('../views/CreateOrUpdateContractTemplate.vue'),
},
{
  name: 'UpdateContractTemplate',
  path: '/update_contract_template/:contract_template_id',
  meta: {
    title: 'Update Contract Template',
    breadcrumb: [
      { name: 'Contracts Templates', link: '/contract_templates' },
      { name: 'Update Template' },
    ],
  },
  component: () => import('../views/CreateOrUpdateContractTemplate.vue'),
},
{
  name: 'Contracts',
  path: '/contracts',
  meta: {
    title: 'Contracts',
    breadcrumb: [
      { name: 'Contracts' },
    ],
  },
  component: () => import('../views/E_signature.vue'),
},
{
  name: 'ContractDrafts',
  path: '/contract_templates',
  meta: {
    title: 'Contract Templates',
    breadcrumb: [
      { name: 'Contracts', link: '/contracts' },
      { name: 'Contract Templates' },
    ],
  },
  component: () => import('../views/ContractDrafts.vue'),
},
{
  name: 'CreateContract',
  path: '/create_contract',
  meta: {
    title: 'Send Contract',
    breadcrumb: [
      { name: 'Contracts', link: '/contracts' },
      { name: 'Create Contract' },
    ],
  },
  component: () => import('../views/CreateOrUpdateContract.vue'),
},
{
  name: 'ContractDetail',
  path: '/contract/:document_id',
  meta: {
    title: 'Contract Detail',
    breadcrumb: [
      { name: 'Contracts', link: '/contracts' },
      { name: 'Contract Detail' },
    ],
  },
  component: () => import('../views/ContractDetail.vue'),
}];
