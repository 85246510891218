import Vue from 'vue';
import { produceKey } from '@/core/utils/helper';
import * as types from './mutation_types';

export default {
  [types.SET_CREDIT_BALANCE](state, data) {
    Vue.set(state, 'creditBalance', data);
  },

  [types.SET_TALKS](state, data) {
    Vue.set(state, 'talks', data);
  },

  [types.SET_TALKS_META](state, meta) {
    Vue.set(state, 'talksMeta', meta);
  },

  [types.LOAD_MORE_TALKS](state, data) {
    Vue.set(state, 'talks', [...state.talks, ...data]);
  },

  [types.DELETE_TALK](state, id) {
    Vue.set(state, 'talks', state.talks.filter(talk => talk._id !== id));
  },

  [types.SET_ACTIVE_TALK_ID](state, id) {
    Vue.set(state, 'activeTalkId', id);
  },

  [types.SET_ACTIVE_TALK](state, talk) {
    Vue.set(state, 'activeTalk', talk);
  },

  [types.UPDATE_TALK](state, talk) {
    Vue.set(state, 'activeTalk', {
      ...state.activeTalk,
      ai_messages: talk.ai_messages,
    });
  },

  [types.OPEN_UZAIR](state, config) {
    Vue.set(state, 'uzairPopup', {
      ...state.uzairPopup,
      show: true,
      is_visible: true,
      config,
    });
  },

  [types.CLOSE_UZAIR](state) {
    Vue.set(state, 'uzairPopup', {
      ...state.uzairPopup,
      show: false,
      is_visible: false,
      data: false,
    });
  },

  [types.HARD_CLOSE_UZAIR](state) {
    Vue.set(state, 'uzairPopup', {
      ...state.uzairPopup,
      selector: produceKey(),
      show: false,
      is_visible: false,
      data: false,
      config: {},
    });
    Vue.set(state, 'activeTalkId', null);
    Vue.set(state, 'activeTalk', null);
  },

  [types.CLEAR_UZAIR_POPUP_CONFIG](state) {
    Vue.set(state, 'uzairPopup', {
      ...state.uzairPopup,
      show: true,
      is_visible: true,
      data: false,
      config: {},
    });
  },

  [types.ADD_RESPONSE_TRANSFER_MEMORY](state, results) {
    Vue.set(state, 'responseTransferMemory', results);
  },

  [types.TOGGLE_UZAIR_ICON](state, { nextStatus }) {
    Vue.set(state, 'isUzairIconBig', nextStatus);
  },

  [types.MINIMIZE_UZAIR](state) {
    Vue.set(state, 'uzairPopup', {
      ...state.uzairPopup,
      is_visible: false,
    });
  },
};
