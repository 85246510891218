export default [
  {
    path: '/valuation/:valuation_id',
    meta: {
      title: 'Market Appraisal Appointment',
      breadcrumb: [
        { name: 'Market Appraisal Appointment' },
      ],
    },
    name: 'Valuation Appointment',
    component: () => import('@/modules/market_appraisal/views/Preview.vue'),
  },
];
