import SupplierCompanyServices from '@/modules/suppliers/services/supplierCompany.services';
import SupplierMembersServices from '@/modules/suppliers/services/supplierMembers.services';
import * as types from './mutation_types';

export const fetchCompaniesList = ({ commit }, { payload, query }) => new SupplierCompanyServices()
  .getCompaniesList({ payload, query })
  .then(({ result }) => {
    commit(types.SET_COMPANIES_DATA, {
      data: result.data,
      meta: result.meta.total,
    });
  })
  .catch(() => {
    commit(types.SET_COMPANIES_DATA, {
      data: [],
      meta: null,
    });
  });

export const fetchMembersList = ({ commit }, { payload, query }) => new SupplierMembersServices()
  .getMembersList({ payload, query })
  .then(({ result }) => {
    commit(types.SET_MEMBERS_DATA, {
      data: result.data,
      meta: result.meta.total,
    });
  })
  .catch(() => {
    commit(types.SET_MEMBERS_DATA, {
      data: [],
      meta: null,
    });
  });

export const fetchRelatedProperties = ({ commit }, { payload, query }) => {
  commit(types.RESET_RELATED_PROPERTY_DATA);
  new SupplierMembersServices()
    .getRelatedProperties({ payload, query })
    .then(({ result }) => {
      commit(types.SET_RELATED_PROPERTY_DATA, {
        data: result.data,
        meta: result.meta.total,
      });
    })
    .catch(() => {
      commit(types.SET_RELATED_PROPERTY_DATA, {
        data: null,
        meta: null,
      });
    });
};

export const fetchCustomFields = ({ commit }, { payload, query }) => new SupplierMembersServices().getCustomFields({ payload, query }).then(({ result }) => {
  commit(types.SET_CUSTOM_FIELDS_DATA, result.data);
}).catch(() => {
  commit(types.RESET_CUSTOM_FIELDS_DATA);
});
