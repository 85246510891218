import Axios from 'axios';
import Api from '@/libraries/api';
import { cacheCleanerService, calendarCacheCleanerService } from '@/modules/users/services/cacheCleaner.service';
import { getLocalStorageData, setLocalStorageData } from '@/core/utils/helper';
import * as types from './mutation_types';

export const requestToken = ({ commit }) => commit(types.GET_TOKEN);
export const receiveTokenSuccess = ({ commit }, data) => {
  localStorage.setItem('logged_in', 'true');
  commit(types.SET_TOKEN, data);
};

export const fetchRemember = ({ commit }, data) => {
  commit(types.SET_REMEMBER, data);
};

export const fetchNotRemember = ({ commit }, data) => {
  commit(types.SET_NOT_REMEMBER, data);
};

export const receiveTokenError = ({ commit }, error) => commit(types.GET_TOKEN_ERROR, error);
export const resetTokenError = ({ commit }) => commit(types.RESET_TOKEN_ERROR);


export const fetchToken = ({ dispatch }, auth) => {
  dispatch('requestToken');
  Axios.post(process.env.VUE_APP_API_AUTH_URL, {
    grant_type: 'password',
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    username: auth.email,
    password: auth.password,
  })
    .then(({ data }) => {
      dispatch('receiveTokenSuccess', data);
      const isRecurringFiltered = getLocalStorageData('recurring_set');
      let selectedTypes = getLocalStorageData('selectedEventTypes');
      if (!isRecurringFiltered) {
        if (selectedTypes && selectedTypes.length) {
          if (!selectedTypes.includes('11')) {
            selectedTypes += ',11';
            setLocalStorageData('selectedEventTypes', selectedTypes);
          }
        } else {
          setLocalStorageData('selectedEventTypes', '1,9,10,3,2,6,4,5,11');
        }
        setLocalStorageData('recurring_set', true);
      }
    })
    .catch(data => dispatch('receiveTokenError', data.response.data.message));
};

export const setUserAsNotNatural = () => {
  localStorage.setItem('is_not_natural', '1');
};

export const requestMakeAutoBackendLogin = ({ commit }) => commit(types.MAKE_AUTO_BACKEND_LOGIN);
export const receiveMakeAutoBackendLoginSuccess = ({ commit }, data) => commit(types.GET_MAKE_AUTO_BACKEND_LOGIN_SUCCESS, data);
export const receiveMakeAutoBackendLoginError = ({ commit }, error) => commit(types.GET_MAKE_AUTO_BACKEND_LOGIN_ERROR, error);
export const makeAutoBackendLogin = ({ dispatch }, payload) => {
  dispatch('requestMakeAutoBackendLogin');
  cacheCleanerService();
  calendarCacheCleanerService();
  Axios.post(`${process.env.VUE_APP_API_BASE_URL}oauth/verified/code`, payload)
    .then(({ data }) => {
      dispatch('receiveMakeAutoBackendLoginSuccess', data.result.data);
      dispatch('setUserAsNotNatural');
    })
    .catch(({ response }) => {
      dispatch('receiveMakeAutoBackendLoginError', response);
    });
};

export const resetResetPassword = ({ commit }) => commit(types.RESET_RESET_PASSWORD);
export const requestResetPassword = ({ commit }) => commit(types.RESET_PASSWORD);
export const receiveResetPasswordSuccess = ({ commit }, data) => commit(types.GET_RESET_PASSWORD_SUCCESS, data);
export const receiveResetPasswordError = ({ commit }, error) => commit(types.GET_RESET_PASSWORD_ERROR, error);
export const resetPassword = ({ dispatch }, authData) => {
  dispatch('requestResetPassword');
  Api.patch('password/forget', authData)
    .then(({ data }) => {
      dispatch('receiveResetPasswordSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveResetPasswordError', response);
    });
};

export const resetUpdatePassword = ({ commit }) => commit(types.RESET_UPDATE_PASSWORD);
export const requestUpdatePassword = ({ commit }) => commit(types.UPDATE_PASSWORD);
export const receiveUpdatePasswordSuccess = ({ commit }, data) => commit(types.GET_UPDATE_PASSWORD_SUCCESS, data);
export const receiveUpdatePasswordError = ({ commit }, error) => commit(types.GET_UPDATE_PASSWORD_ERROR, error);
export const updatePassword = ({ dispatch }, authData) => {
  dispatch('requestUpdatePassword');
  Api.patch(`password/forget/token?token=${authData.token}`, authData)
    .then(({ data }) => {
      dispatch('receiveUpdatePasswordSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdatePasswordError', response);
    });
};
