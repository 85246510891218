import ApiService from '@/core/api/api.service';

export default class ApplicantsServices extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async search(payload, params) {
    this.loader = false;
    this.url = '/contacts/applicants/matches';
    this.payload = payload;
    this.params = params;
    const { result } = await this.post();
    return result;
  }

  async searchApplicant(payload, params) {
    this.loader = false;
    this.url = '/contacts/applicants';
    this.payload = payload;
    this.params = params;
    const { result } = await this.get();
    return result;
  }
}
