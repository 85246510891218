import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestMailLists = ({ commit }) => commit(types.GET_MAIL_LISTS);
export const receiveMailListsSuccess = ({ commit }, data) => commit(types.SET_MAIL_LISTS, data);
export const receiveMailListsError = ({ commit }, error) => commit(types.GET_MAIL_LISTS_ERROR, error);
export const fetchMailLists = ({ dispatch }, payload) => {
  dispatch('requestMailLists');
  Api.get(`drips${payload.request}`)
    .then(({ data }) => { dispatch('receiveMailListsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveMailListsError', response); });
};

export const requestUpdateDrips = ({ commit }) => commit(types.GET_UPDATE_DRIPS);
export const receiveUpdateDripsSuccess = ({ commit }, data) => commit(types.SET_UPDATE_DRIPS, data);
export const receiveUpdateDripsError = ({ commit }, error) => commit(types.GET_UPDATE_DRIPS_ERROR, error);
export const fetchUpdateDrips = ({ dispatch }, dripData) => {
  dispatch('requestUpdateDrips');
  Api.patch(`drips/${dripData.drip_id}`, dripData)
    .then(({ data }) => { dispatch('receiveUpdateDripsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateDripsError', response); });
};

export const requestDrip = ({ commit }) => commit(types.GET_DRIP);
export const receiveDripSuccess = ({ commit }, data) => commit(types.SET_DRIP, data);
export const receiveDripError = ({ commit }, error) => commit(types.GET_DRIP_ERROR, error);
export const fetchDrip = ({ dispatch }, dripId) => {
  dispatch('requestDrip');
  Api.get(`drips/${dripId}`)
    .then(({ data }) => {
      dispatch('receiveDripSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDripError', response);
    });
};

export const requestSendSupportMail = ({ commit }) => commit(types.GET_SEND_SUPPORT_MAIL);
export const receiveSendSupportMailSuccess = ({ commit }, data) => commit(types.SET_SEND_SUPPORT_MAIL, data);
export const receiveSendSupportMailError = ({ commit }, error) => commit(types.GET_SEND_SUPPORT_MAIL_ERROR, error);
export const fetchSendSupportMail = ({ dispatch }, mailData) => {
  dispatch('requestSendSupportMail');
  Api.post('support_requests', mailData)
    .then(({ data }) => { dispatch('receiveSendSupportMailSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSendSupportMailError', response); });
};


export const requestUpdateDrip = ({ commit }) => commit(types.GET_UPDATE_DRIP);
export const receiveUpdateDripSuccess = ({ commit }, data) => commit(types.SET_UPDATE_DRIP, data);
export const receiveUpdateDripError = ({ commit }, error) => commit(types.GET_UPDATE_DRIP_ERROR, error);
export const fetchUpdateDrip = ({ dispatch }, payload) => {
  dispatch('requestUpdateDrip');
  Api.patch(`drip_steps/${payload.data.drip_step_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveUpdateDripSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateDripError', response);
    });
};
