export default {
  error: null,
  isLoading: false,
  lengthOfAgreements: null,
  lengthOfAgreement: null,
  defaultSettings: null,
  baitFormAnswer: [],
  fees: null,
  fee: null,
  feeCreateSuccess: null,
  feeCreateError: null,
  feeUpdateSuccess: null,
  feeUpdateError: null,
  deletedFee: null,
  deletedFeeError: null,
  baits: null,
  bait: null,
  baitSuccess: null,
  baitError: null,
  sendAppointmentMailSuccess: null,
  sendAppointmentMailError: null,
  baitStatuses: null,
  updatingBaitStatusSuccess: null,
  updatingBaitStatusError: null,
  FAQsByBaitId: null,
  FAQsByBaitIdError: null,
  FAQCreateMessage: null,
  FAQUpdateMessage: null,
  FAQDeleteMessage: null,
  createFAQSuccess: null,
  createFAQsError: null,
  updateFAQSuccess: null,
  updateFAQsError: null,
  deleteFAQSuccess: null,
  deleteFAQsError: null,
  comparablePropertiesByBaitId: null,
  comparablePropertiesByBaitIdError: null,
  createComparablePropertySuccess: null,
  createComparablePropertysError: null,
  updateComparablePropertySuccess: null,
  updateComparablePropertysError: null,
  deleteComparablePropertySuccess: null,
  deleteComparablePropertysError: null,
  sendAppointmentOrBaitSmsSuccess: null,
  sendAppointmentOrBaitSmsError: null,
  contentsByBaitId: null,
  contentsByBaitIdError: null,
  createBaitContentSuccess: null,
  createBaitContentError: null,
  sendAppointmentOrBaitEmailSuccess: null,
  sendAppointmentOrBaitEmailError: null,
  baitContents: null,
  baitContentsError: null,
  createCompletionToolSuccess: null,
  createCompletionToolError: null,
  askedQuestions: null,
  askedQuestionsError: null,
  updateBaitSuccess: false,
  updateBaitError: false,
  updateLengthOfAgreementSuccess: false,
  updateLengthOfAgreementError: false,
  sendQuickEmailAndSmsResults: null,
  checkBaitRequirementsState: null,
  changeBaitPublishStatusSuccess: null,
  changeBaitPublishStatusError: null,
  sendQuickEmailAndSmsResultsError: null,
  revertBaitLastStatusSuccess: null,
  revertBaitLastStatusError: null,
  allFeesData: [],
};
