// eslint-disable-next-line import/no-cycle
import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestBulkSms = ({ commit }) => commit(types.GET_BULK_SMS);
export const receiveBulkSmsSuccess = ({ commit }, data) => commit(types.SET_BULK_SMS, data);
export const receiveBulkSmsError = ({ commit }, error) => commit(types.GET_BULK_SMS_ERROR, error);
export const fetchBulkSms = ({ dispatch }, payload) => {
  dispatch('requestBulkSms');
  Api.get(`bulk_sms${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsError', response);
    });
};

export const requestBulkSmsDetail = ({ commit }) => commit(types.GET_BULK_SMS_DETAIL);
export const receiveBulkSmsDetailSuccess = ({ commit }, data) => commit(types.SET_BULK_SMS_DETAIL, data);
export const receiveBulkSmsDetailError = ({ commit }, error) => commit(types.GET_BULK_SMS_DETAIL_ERROR, error);
export const fetchBulkSmsDetail = ({ dispatch }, params) => {
  dispatch('requestBulkSmsDetail');
  Api.get(`/bulk_sms/${params.bulk_sms_id}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsDetailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsDetailError', response);
    });
};

export const requestResetSentBulkSmsDetailList = ({ commit }) => commit(types.GET_RESET_SENT_BULK_SMS_DETAIL_LIST);
export const requestBulkSmsSentDetailList = ({ commit }) => commit(types.GET_SENT_BULK_SMS_DETAIL_LIST);
export const receiveBulkSmsSentDetailListSuccess = ({ commit }, data) => commit(types.SET_SENT_BULK_SMS_DETAIL_LIST, data);
export const receiveBulkSmsSentDetailListError = ({ commit }, error) => commit(types.GET_SENT_BULK_SMS_DETAIL_LIST_ERROR, error);


export const fetchBulkSmsSent = ({ dispatch }, payload) => {
  dispatch('requestBulkSmsSentDetailList');
  Api.get(`/bulk_sms/${payload.params.bulk_sms_id}/sms_sent${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsSentDetailListError', response);
    });
};


export const fetchBulkSmsSentClick = ({ dispatch }, payload) => {
  dispatch('requestBulkSmsSentDetailList');
  Api.get(`/bulk_sms/${payload.params.bulk_sms_id}/sms_click${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsSentDetailListError', response);
    });
};

export const fetchBulkSmsSentStatusDelivered = ({ dispatch }, payload) => {
  dispatch('requestBulkSmsSentDetailList');
  Api.get(`/bulk_sms/${payload.params.bulk_sms_id}/sms_status/delivered${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsSentDetailListError', response);
    });
};

export const fetchBulkSmsSentStatusInvalid = ({ dispatch }, payload) => {
  dispatch('requestBulkSmsSentDetailList');
  Api.get(`/bulk_sms/${payload.params.bulk_sms_id}/sms_status/invalid${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsSentDetailListError', response);
    });
};

export const fetchBulkSmsSentStatusUndelivered = ({ dispatch }, payload) => {
  dispatch('requestBulkSmsSentDetailList');
  Api.get(`/bulk_sms/${payload.params.bulk_sms_id}/sms_status/undelivered${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsSentDetailListError', response);
    });
};

export const fetchBulkSmsSentStatusUnknown = ({ dispatch }, payload) => {
  dispatch('requestBulkSmsSentDetailList');
  Api.get(`/bulk_sms/${payload.params.bulk_sms_id}/sms_status/unknown${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkSmsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkSmsSentDetailListError', response);
    });
};

export const requestDeleteBulkSms = ({ commit }) => commit(types.GET_DELETE_BULK_SMS);
export const receiveDeleteBulkSmsSuccess = ({ commit }, data) => commit(types.SET_DELETE_BULK_SMS, data);
export const receiveDeleteBulkSmsError = ({ commit }, error) => commit(types.GET_DELETE_BULK_SMS_ERROR, error);
export const fetchDeleteBulkSms = ({ dispatch }, payload) => {
  dispatch('requestDeleteBulkSms');
  Api.delete(`bulk_sms/${payload.bulk_sms_id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteBulkSmsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteBulkSmsError', response);
    });
};
