import vueApp from '@/main';
import ErrorLoggerService from './errorLogger.service';

export default class ErrorHandlerService {
  static onError(error, vm, info) {
    console.info(error);
    const component = vm.$options.components[vm.$options.name];
    const payload = {
      error: {},
      error_info: info,
      company_id: vueApp.me.company_id,
      user_id: vueApp.me.user_id,
      vue_errors: {
        component_name: vm.$options.name,
        component_path: vm.$route.fullPath,
        component_info: component.options,
        component_components: component.options.components,
        component_watchers: component.options.watch,
        component_computed: component.options.computed,
      },
    };
    if (error.stack) {
      payload.error = error.stack;
    }
    payload.error = error.message;
    // new ErrorLoggerService().logErrors(payload);
  }
}
