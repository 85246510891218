export default (to, from, next) => {
  // if user is logged, prevent to enter auth routers.
  if (localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) && localStorage.getItem(process.env.VUE_APP_TOKEN_NAME).length > 0) {
    if (to && to.name === 'BackendLogin') {
      // if token exists and we are in backend login view.
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      next();
    } else {
      next('/dashboard');
    }
  } else {
    localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
    next();
  }
};
