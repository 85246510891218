import Vue from 'vue';
import Router from 'vue-router';
/* Modules */
import AuthRoutes from '@/router/auth';
import DashboardRoutes from '@/router/dashboard';


Vue.use(Router);
const routes = [];
export default new Router({
  mode: 'history',
  routes: routes.concat(
    AuthRoutes, DashboardRoutes,
  ),
});
