import ApiService from '@/core/api/api.service';

export default class ContractTemplatesServices extends ApiService {
  constructor(props) {
    super(props);
    this.url = 'sg/templates';
  }


  async create(payload) {
    this.payload = payload;
    this.url = `${this.url}`;
    return this.post();
  }

  async retrieve(templateId) {
    this.url = `${this.url}/${templateId}`;
    const { result: { data } } = await this.get();
    return data;
  }

  async list(queryParams) {
    this.url = `${this.url}${queryParams}`;
    const { result } = await this.get();
    return result;
  }

  async update(templateId, payload) {
    this.url = `${this.url}/${templateId}`;
    this.payload = payload;
    return this.patch();
  }

  async archive(templateId) {
    this.url = `${this.url}/${templateId}`;
    return this.baseRemitter('delete');
  }
}
