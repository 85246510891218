import ApiService from '@/core/api/api.service';


// eslint-disable-next-line import/prefer-default-export
export class PropertyDetailServices extends ApiService {
  async getPropertyCommonTypes(transactionTypeId) {
    this.url = `properties/common_types?transaction_type_id=${transactionTypeId}&group=LIVE&__order_by=name`;
    return this.get();
  }

  async getPriceQualifiers(transactionTypeId) {
    this.loader = false;
    this.url = `properties/price_types?transaction_type_id=${transactionTypeId}&group=LIVE&__order_by=name`;
    return this.get();
  }

  async update(payload) {
    this.payload = payload;
    this.url = `property_profiles/${this.payload.property_profile.property_profile_id}`;
    return this.baseRemitter('patch', 'data');
  }

  async getPropertyProfile(propertyProfileId) {
    this.url = `property_profiles/${propertyProfileId}`;
    return this.get();
  }

  async patchPropertyLocation(addressId, params) {
    this.params = params;
    this.url = `property_addresses/${addressId}`;
    return this.baseRemitter('patch', 'message');
  }

  async getPropertyCoordinates(addressId) {
    this.url = `property_addresses/${addressId}`;
    return this.get();
  }
}
