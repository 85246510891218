import Api from '@/libraries/api';
import AudiencesServices from '@/modules/audiences/services/audiences.services';
import CalendarEventServices from '@/modules/calendar/services/calendarEvent.services';
import * as types from './mutation_types';

const IAudiencesServices = new AudiencesServices();

export const requestAudiences = ({ commit }) => commit(types.GET_AUDIENCES);
export const receiveAudiencesError = ({ commit }, error) => commit(types.GET_AUDIENCES_ERROR, error);
export const receiveAudiencesSuccess = ({ commit }, data) => commit(types.SET_AUDIENCES, data);
export const receiveLoadMoreAudiencesSuccess = ({ commit }, data) => commit(types.SET_LOAD_MORE_AUDIENCES, data);
export const fetchAudiences = ({ dispatch }, payload) => {
  dispatch('requestAudiences');

  Api.get(`mail_lists${payload.request}`)
    .then(({ data }) => {
      if (payload.loadMore) {
        dispatch('receiveLoadMoreAudiencesSuccess', data);
      } else {
        dispatch('receiveAudiencesSuccess', data);
      }
    })
    .catch(({ response }) => {
      dispatch('receiveAudiencesError', response);
    });
};

export const requestStaticAudiences = ({ commit }) => commit(types.GET_STATIC_AUDIENCES);
export const receiveStaticAudiencesError = ({ commit }, error) => commit(types.GET_STATIC_AUDIENCES_ERROR, error);
export const receiveStaticAudiencesSuccess = ({ commit }, data) => commit(types.SET_STATIC_AUDIENCES, data);
export const fetchStaticAudiences = ({ dispatch }) => {
  dispatch('requestStaticAudiences');

  Api.get('mail_lists?mail_list_type_id=1&__order_by=mail_lists.name&__limit=250')
    .then(({ data }) => {
      dispatch('receiveStaticAudiencesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveStaticAudiencesError', response);
    });
};

export const setAll = ({ commit }, data) => {
  commit(types.SET_ALL_AUDIENCES, data);
};
export const unsetAll = ({ commit }, data) => {
  commit(types.UNSET_ALL_AUDIENCES, data);
};

export const setSingle = ({ commit }, data) => {
  commit(types.SET_SELECTED_AUDIENCES, data);
};

export const unsetSingle = ({ commit }, data) => {
  commit(types.UNSET_SELECTED_AUDIENCES, data);
};

export const clearSelectedAudiences = ({ commit }) => {
  commit(types.CLEAR_SELECTED_AUDIENCES);
};

export const requestDeleteAudience = ({ commit }) => commit(types.GET_DELETE_AUDIENCE);
export const receiveDeleteAudienceError = ({ commit }, error) => commit(types.GET_DELETE_AUDIENCE_ERROR, error);
export const receiveDeleteAudienceSuccess = ({ commit }, data) => commit(types.SET_DELETE_AUDIENCE_SUCCESS, data);
export const deleteAudience = async ({ dispatch }, payload) => {
  dispatch('requestDeleteAudience');
  try {
    const data = await new AudiencesServices().remove(payload.id);
    await dispatch('receiveDeleteAudienceSuccess', data);
  } catch (response) {
    await dispatch('receiveDeleteAudienceError', response);
  }
};


export const requestAddStaticAudience = ({ commit }) => commit(types.GET_ADD_STATIC_AUDIENCE);
export const receiveAddStaticAudienceError = ({ commit }, error) => commit(types.GET_ADD_STATIC_AUDIENCE_ERROR, error);
export const receiveAddStaticAudienceSuccess = ({ commit }, data) => commit(types.SET_ADD_STATIC_AUDIENCE_SUCCESS, data);
export const addStaticAudience = ({ dispatch }, payload) => {
  dispatch('requestAddStaticAudience');

  Api.post('mail_lists', payload)
    .then(({ data }) => {
      dispatch('receiveAddStaticAudienceSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAddStaticAudienceError', response);
    });
};

export const requestCloneAudience = ({ commit }) => commit(types.GET_CLONE_AUDIENCE);
export const receiveCloneAudienceError = ({ commit }, error) => commit(types.GET_CLONE_AUDIENCE_ERROR, error);
export const receiveCloneAudienceSuccess = ({ commit }, data) => commit(types.SET_CLONE_AUDIENCE_SUCCESS, data);
export const cloneAudience = ({ dispatch }, payload) => {
  dispatch('requestCloneAudience');

  Api.get(`mail_lists/clone/${payload.mail_list_id}`)
    .then(({ data }) => {
      dispatch('receiveCloneAudienceSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCloneAudienceError', response);
    });
};

export const requestAudienceDetails = ({ commit }) => commit(types.GET_AUDIENCE_DETAILS);
export const receiveAudienceDetailsError = ({ commit }, error) => commit(types.GET_AUDIENCE_DETAILS_ERROR, error);
export const receiveAudienceDetailsSuccess = ({ commit }, data) => commit(types.SET_AUDIENCE_DETAILS, data);
export const fetchAudienceDetails = ({ dispatch }, payload) => {
  dispatch('requestAudienceDetails');

  Api.get(`mail_lists/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveAudienceDetailsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAudienceDetailsError', response);
    });
};

export const requestAddContactsToAudienceWithEmails = ({ commit }) => commit(types.GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS);
export const receiveAddContactsToAudienceWithEmailsError = ({ commit }, error) => commit(types.GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_ERROR, error);
export const receiveAddContactsToAudienceWithEmailsSuccess = ({ commit }, data) => commit(types.SET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_SUCCESS, data);
export const addContactsToAudienceWithEmails = ({ dispatch }, payload) => {
  dispatch('requestAddContactsToAudienceWithEmails');

  Api.post(`mail_list_contacts/bulk_email/${payload.mail_list_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveAddContactsToAudienceWithEmailsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAddContactsToAudienceWithEmailsError', response);
    });
};

export const requestCreateAudience = ({ commit }) => commit(types.CREATE_AUDIENCE);
export const receiveCreateAudienceSuccess = ({ commit }, data) => commit(types.CREATE_AUDIENCE_SUCCESS, data);
export const receiveCreateAudienceError = ({ commit }, error) => commit(types.CREATE_AUDIENCE_ERROR, error);
export const createAudience = ({ dispatch }, payload) => {
  dispatch('requestCreateAudience');
  IAudiencesServices.createStaticAudience(payload)
    .then((data) => {
      dispatch('receiveCreateAudienceSuccess', { result: { data }, message: 'Audience was created.' });
    })
    .catch(({ response }) => {
      dispatch('receiveCreateAudienceError', response);
    });
};
export const updateAudience = ({ dispatch }, payload) => {
  dispatch('requestCreateAudience');
  IAudiencesServices.updateStaticAudience(payload)
    .then((data) => {
      dispatch('receiveCreateAudienceSuccess', { result: { data }, message: 'Contacts were added.' });
    })
    .catch(({ response }) => {
      dispatch('receiveCreateAudienceError', response);
    });
};

export const requestDynamicAudienceBuilderSchema = ({ commit }) => commit(types.GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA);
export const resetDynamicAudienceBuilderSchema = ({ commit }) => commit(types.RESET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA);
export const receiveDynamicAudienceBuilderSchemaError = ({ commit }, error) => commit(types.GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA_ERROR, error);
export const receiveDynamicAudienceBuilderSchemaSuccess = ({ commit }, data) => commit(types.SET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA, data);
export const fetchDynamicAudienceBuilderSchema = ({ dispatch, state }) => {
  dispatch('requestDynamicAudienceBuilderSchema');
  if (state.schemaBase) {
    dispatch('receiveDynamicAudienceBuilderSchemaSuccess', {
      result: {
        data: state.schemaBase,
      },
    });
  } else {
    dispatch('requestDynamicAudienceBuilderSchema');

    Api.get('mail_lists/schema')
      .then(({ data }) => {
        dispatch('receiveDynamicAudienceBuilderSchemaSuccess', data);
      })
      .catch(({ response }) => {
        dispatch('receiveDynamicAudienceBuilderSchemaError', response);
      });
  }
};

export const updateAvailableForms = ({ commit }, data) => commit(types.UPDATE_AVAILABLE_FORMS, data);

export const setBuilderData = ({ commit }, data) => {
  commit(types.SET_BUILDER_DATA, data);
};

export const setUnsavedSchema = ({ commit }, data) => {
  commit(types.SET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA, data);
};

export const requestSendBulkEmail = ({ commit }) => commit(types.SEND_BULK_EMAIL);
export const receiveSendBulkEmailSuccess = ({ commit }, data) => commit(types.SEND_BULK_EMAIL_SUCCESS, data);
export const receiveSendBulkEmailError = ({ commit }, error) => commit(types.SEND_BULK_EMAIL_ERROR, error);
export const sendBulkEmail = ({ dispatch }, payload) => {
  dispatch('requestSendBulkEmail');
  Api.post('bulk_mails', payload)
    .then(({ data }) => {
      dispatch('receiveSendBulkEmailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSendBulkEmailError', response);
    });
};

export const requestDynamicAudienceSearch = ({ commit }) => commit(types.GET_DYNAMIC_AUDIENCE_SEARCH);
export const receiveDynamicAudienceSearchError = ({ commit }, error) => commit(types.GET_DYNAMIC_AUDIENCE_SEARCH_ERROR, error);
export const receiveDynamicAudienceSearchSuccess = ({ commit }, data) => commit(types.SET_DYNAMIC_AUDIENCE_SEARCH, data);
export const fetchDynamicAudienceSearch = ({ dispatch }, payload) => {
  dispatch('requestDynamicAudienceSearch');
  const joinedPayload = payload && payload.length > 0 ? `?${payload.join('&')}` : [];
  Api.get(`contacts/audience_search${joinedPayload}`)
    .then(({ data }) => {
      dispatch('receiveDynamicAudienceSearchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDynamicAudienceSearchError', response);
    });
};
