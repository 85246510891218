import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestSolny = ({ commit }) => commit(types.GET_SOLNY);
export const receiveSolnySuccess = ({ commit }, data) => commit(types.SET_SOLNY, data);
export const receiveSolnyError = ({ commit }, error) => commit(types.GET_SOLNY_ERROR, error);
export const fetchSolny = ({ dispatch }, filter) => {
  dispatch('requestSolny');
  Api.get(`sn/solny${filter.request}`)
    .then(({ data }) => { dispatch('receiveSolnySuccess', data); })
    .catch(({ response }) => { dispatch('receiveSolnyError', response); });
};

export const requestSolnyDetail = ({ commit }) => commit(types.GET_SOLNY_DETAIL);
export const receiveSolnyDetailSuccess = ({ commit }, data) => commit(types.SET_SOLNY_DETAIL, data);
export const receiveSolnyDetailError = ({ commit }, error) => commit(types.GET_SOLNY_DETAIL_ERROR, error);
export const fetchSolnyDetail = ({ dispatch }, solnyId) => {
  dispatch('requestSolnyDetail');
  Api.get(`sn/solny/${solnyId}`)
    .then(({ data }) => { dispatch('receiveSolnyDetailSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSolnyDetailError', response); });
};

export const requestSolnyBrochure = ({ commit }) => commit(types.GET_SOLNY_BROCHURE);
export const receiveSolnyBrochureSuccess = ({ commit }, data) => commit(types.SET_SOLNY_BROCHURE, data);
export const receiveSolnyBrochureError = ({ commit }, error) => commit(types.GET_SOLNY_BROCHURE_ERROR, error);
export const fetchSolnyBrochure = ({ dispatch }, solnyId) => {
  dispatch('requestSolnyBrochure');
  Api.get(`sn/solny/${solnyId}`)
    .then(({ data }) => { dispatch('receiveSolnyBrochureSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSolnyBrochureError', response); });
};

export const requestPaperCopies = ({ commit }) => commit(types.GET_PAPER_COPIES);
export const receivePaperCopiesSuccess = ({ commit }, data) => commit(types.SET_PAPER_COPIES, data);
export const receivePaperCopiesError = ({ commit }, error) => commit(types.GET_PAPER_COPIES_ERROR, error);
export const fetchPaperCopies = ({ dispatch }) => {
  dispatch('requestPaperCopies');
  Api.get('sn/paper_copies')
    .then(({ data }) => { dispatch('receivePaperCopiesSuccess', data); })
    .catch(({ response }) => { dispatch('receivePaperCopiesError', response); });
};

export const requestPaperSizes = ({ commit }) => commit(types.GET_PAPER_SIZES);
export const receivePaperSizesSuccess = ({ commit }, data) => commit(types.SET_PAPER_SIZES, data);
export const receivePaperSizesError = ({ commit }, error) => commit(types.GET_PAPER_SIZES_ERROR, error);
export const fetchPaperSizes = ({ dispatch }) => {
  dispatch('requestPaperSizes');
  Api.get('sn/paper_sizes')
    .then(({ data }) => { dispatch('receivePaperSizesSuccess', data); })
    .catch(({ response }) => { dispatch('receivePaperSizesError', response); });
};

export const requestPaperLaminations = ({ commit }) => commit(types.GET_PAPER_LAMINATIONS);
export const receivePaperLaminationsSuccess = ({ commit }, data) => commit(types.SET_PAPER_LAMINATIONS, data);
export const receivePaperLaminationsError = ({ commit }, error) => commit(types.GET_PAPER_LAMINATIONS_ERROR, error);
export const fetchPaperLaminations = ({ dispatch }) => {
  dispatch('requestPaperLaminations');
  Api.get('sn/paper_laminations')
    .then(({ data }) => { dispatch('receivePaperLaminationsSuccess', data); })
    .catch(({ response }) => { dispatch('receivePaperLaminationsError', response); });
};

export const requestPaperTypes = ({ commit }) => commit(types.GET_PAPER_TYPES);
export const receivePaperTypesSuccess = ({ commit }, data) => commit(types.SET_PAPER_TYPES, data);
export const receivePaperTypesError = ({ commit }, error) => commit(types.GET_PAPER_TYPES_ERROR, error);
export const fetchPaperTypes = ({ dispatch }) => {
  dispatch('requestPaperTypes');
  Api.get('sn/paper_types')
    .then(({ data }) => { dispatch('receivePaperTypesSuccess', data); })
    .catch(({ response }) => { dispatch('receivePaperTypesError', response); });
};

export const requestPaperWeights = ({ commit }) => commit(types.GET_PAPER_WEIGHTS);
export const receivePaperWeightsSuccess = ({ commit }, data) => commit(types.SET_PAPER_WEIGHTS, data);
export const receivePaperWeightsError = ({ commit }, error) => commit(types.GET_PAPER_WEIGHTS_ERROR, error);
export const fetchPaperWeights = ({ dispatch }) => {
  dispatch('requestPaperWeights');
  Api.get('sn/paper_weights')
    .then(({ data }) => { dispatch('receivePaperWeightsSuccess', data); })
    .catch(({ response }) => { dispatch('receivePaperWeightsError', response); });
};

export const requestOrderPrices = ({ commit }) => commit(types.GET_PAPER_ORDER_PRICES);
export const receiveOrderPricesSuccess = ({ commit }, data) => commit(types.SET_PAPER_ORDER_PRICES, data);
export const receiveOrderPricesError = ({ commit }, error) => commit(types.GET_PAPER_ORDER_PRICES_ERROR, error);
export const fetchOrderPrices = ({ dispatch }, orderData) => {
  dispatch('requestOrderPrices');
  Api.get('sn/order_prices', orderData)
    .then(({ data }) => { dispatch('receiveOrderPricesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveOrderPricesError', response); });
};

export const requestSendOrder = ({ commit }) => commit(types.GET_SEND_ORDER);
export const receiveSendOrderSuccess = ({ commit }, data) => commit(types.SET_SEND_ORDER, data);
export const receiveSendOrderError = ({ commit }, error) => commit(types.GET_SEND_ORDER_ERROR, error);
export const fetchSendOrder = ({ dispatch }, orderData) => {
  dispatch('requestSendOrder');
  Api.post('sn/orders', orderData)
    .then(({ data }) => { dispatch('receiveSendOrderSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSendOrderError', response); });
};

/* HUNT: START */
export const requestSocialMediaAccounts = ({ commit }) => commit(types.GET_SOCIAL_MEDIA_ACCOUNTS);
export const receiveSocialMediaAccountsSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_ACCOUNTS, data);
export const receiveSocialMediaAccountsError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ACCOUNTS_ERROR, error);
export const fetchSocialMediaAccounts = ({ dispatch }) => {
  dispatch('requestSocialMediaAccounts');
  Api.get('/ht/adaccounts')
    .then(({ data }) => { dispatch('receiveSocialMediaAccountsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaAccountsError', response); });
};

export const fetchSyncSocialMediaAccounts = ({ dispatch }, payload) => {
  dispatch('requestSocialMediaAccounts');
  Api.get(`ht/adaccounts/sync/${payload.social_media_account_id}`)
    .then(({ data }) => { dispatch('receiveSocialMediaAccountsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSocialMediaAccountsError', response); });
};


export const requestUpdateAdAccount = ({ commit }) => commit(types.GET_UPDATE_AD_ACCOUNT);
export const receiveUpdateAdAccountSuccess = ({ commit }, data) => commit(types.SET_UPDATE_AD_ACCOUNT, data);
export const receiveUpdateAdAccountError = ({ commit }, error) => commit(types.GET_UPDATE_AD_ACCOUNT_ERROR, error);
export const fetchUpdateAdAccount = ({ dispatch }, payload) => {
  dispatch('requestUpdateAdAccount');
  Api.patch(`ht/adaccounts/${payload.ad_account_id}`, payload)
    .then(({ data }) => { dispatch('receiveUpdateAdAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateAdAccountError', response); });
};

export const requestConnectedAudiencesWithAdAccount = ({ commit }) => commit(types.GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT);
export const receiveConnectedAudiencesWithAdAccountSuccess = ({ commit }, data) => commit(types.SET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT, data);
export const receiveConnectedAudiencesWithAdAccountError = ({ commit }, error) => commit(types.GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT_ERROR, error);
export const fetchConnectedAudiencesWithAdAccount = ({ dispatch }, payload) => {
  dispatch('requestConnectedAudiencesWithAdAccount');
  Api.get(`/ht/audience/${payload.params.ad_account_id}${payload.request}`)
    .then(({ data }) => { dispatch('receiveConnectedAudiencesWithAdAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveConnectedAudiencesWithAdAccountError', response); });
};

export const requestConnectAudienceWithAdAccount = ({ commit }) => commit(types.GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT);
export const receiveConnectAudienceWithAdAccountSuccess = ({ commit }, data) => commit(types.SET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT, data);
export const receiveConnectAudienceWithAdAccountError = ({ commit }, error) => commit(types.GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT_ERROR, error);
export const connectAudienceWithAdAccount = ({ dispatch }, payload) => {
  dispatch('requestConnectAudienceWithAdAccount');
  Api.post('ht/audience', payload)
    .then(({ data }) => { dispatch('receiveConnectAudienceWithAdAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveConnectAudienceWithAdAccountError', response); });
};

export const requestUpdateConnectionAudienceWithAdAccount = ({ commit }) => commit(types.GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT);
export const receiveUpdateConnectionAudienceWithAdAccountSuccess = ({ commit }, data) => commit(types.SET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT, data);
export const receiveUpdateConnectionAudienceWithAdAccountError = ({ commit }, error) => commit(types.GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR, error);
export const updateConnectionAudienceWithAdAccount = ({ dispatch }, payload) => {
  dispatch('requestUpdateConnectionAudienceWithAdAccount');
  Api.patch(`ht/audience/${payload.custom_audience_id}`, payload)
    .then(({ data }) => { dispatch('receiveUpdateConnectionAudienceWithAdAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateConnectionAudienceWithAdAccountError', response); });
};

export const requestDeleteConnectionAudienceWithAdAccount = ({ commit }) => commit(types.GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT);
export const receiveDeleteConnectionAudienceWithAdAccountSuccess = ({ commit }, data) => commit(types.SET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT, data);
export const receiveDeleteConnectionAudienceWithAdAccountError = ({ commit }, error) => commit(types.GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR, error);
export const deleteConnectionAudienceWithAdAccount = ({ dispatch }, payload) => {
  dispatch('requestDeleteConnectionAudienceWithAdAccount');
  Api.delete(`ht/audience/${payload.custom_audience_id}`)
    .then(({ data }) => { dispatch('receiveDeleteConnectionAudienceWithAdAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveDeleteConnectionAudienceWithAdAccountError', response); });
};

export const fetchSyncAdAccount = ({ dispatch }, payload) => {
  dispatch('requestConnectedAudiencesWithAdAccount');
  Api.get(`ht/audience/sync/${payload.ad_account_id}`)
    .then(({ data }) => { dispatch('receiveConnectedAudiencesWithAdAccountSuccess', data); })
    .catch(({ response }) => { dispatch('receiveConnectedAudiencesWithAdAccountError', response); });
};
/* HUNT: END */
