export default [{
  name: 'Bulk Mail',
  path: '/bulk_mail',
  meta: {
    title: 'Bulk Mail',
    breadcrumb: [
      { name: 'Bulk Mail' },
    ],
  },
  component: () => import('../views/BulkMails.vue'),
},
{
  name: 'Bulk Mail Detail',
  path: '/bulk_mail_detail/:bulk_mail_id',
  meta: {
    title: 'Bulk Mail Details',
    breadcrumb: [
      { name: 'Bulk Mail', link: '/bulk_mail' },
      { name: 'Bulk Mail Details' },
    ],
  },
  component: () => import('../views/BulkMailsDetail.vue'),
}];
