import Api from '@/libraries/api';
import { ConfigsService } from '@/core/services/configs.service';
import * as types from './mutation_types';

export const resetSearch = ({ commit }) => commit(types.RESET_SEARCH);
export const requestSearch = ({ commit }) => commit(types.GET_SEARCH);
export const receiveSearchError = ({ commit }, error) => commit(types.GET_SEARCH_ERROR, error);
export const receiveSearchSuccess = ({ commit }, data) => commit(types.SET_SEARCH, data);
export const fetchSearch = ({ dispatch }, payload) => {
  dispatch('requestSearch');
  Api.get(`${payload.endpoint + payload.filter}`)
    .then(({ data }) => {
      dispatch('receiveSearchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSearchError', response);
    });
};

export const resetElasticSearch = ({ commit }) => commit(types.RESET_ELASTIC_SEARCH);
export const requestElasticSearch = ({ commit }) => commit(types.GET_ELASTIC_SEARCH);
export const receiveElasticSearchError = ({ commit }, error) => commit(types.GET_ELASTIC_SEARCH_ERROR, error);
export const receiveElasticSearchSuccess = ({ commit }, data) => commit(types.SET_ELASTIC_SEARCH, data);
export const fetchElasticSearch = ({ dispatch }, payload) => {
  dispatch('requestElasticSearch');
  Api.get(`${payload.endpoint}/filter?${payload.filters}`, payload.payload)
    .then(({ data }) => {
      dispatch('receiveElasticSearchSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveElasticSearchError', response);
    });
};

/**
 *
 * @param commit
 * @param config: Might be string just key or config object that has key property
 */
export const openLsModal = ({ commit }, config) => {
  commit(types.OPEN_LS_MODAL, config.key ? config : {
    key: config,
    data: null,
  });
};

export const closeLsModal = ({ commit }, key) => commit(types.CLOSE_LS_MODAL, key);

export const closeAllLsModals = ({ commit }) => commit(types.CLOSE_LS_MODAL);

export const fetchConfigs = async ({ commit }) => {
  try {
    const result = await new ConfigsService().getMeConfig();

    commit(types.SET_CONFIGS, result);
  } catch (e) {
    // don't throw
  }
};
