export default [
  {
    path: '/invoices',
    meta: {
      title: 'Invoices',
      breadcrumb: [{ name: 'Invoices' }],
    },
    name: 'Invoices',
    component: () => import('@/modules/invoices/views/Invoices.vue'),
  },
];
