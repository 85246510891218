import TaskSubcategoryServices from '@/modules/tasks/services/taskSubcategory.services';
import * as types from './mutation_types';

export const fetchTaskSubcategories = async ({ commit, state }) => {
  if (!state.taskSubcategories || (state.taskSubcategories && state.taskSubcategories.length === 0)) {
    const data = await new TaskSubcategoryServices().list();
    commit(types.SET_TASK_SUBCATEGORIES, data);
  }
};

export const resetTaskSubcategories = async ({ commit }) => {
  commit(types.RESET_TASK_SUBCATEGORIES);
};
