import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestConvertToBait = ({ commit }) => commit(types.GET_CONVERT_TO_BAIT);
export const receiveConvertToBaitSuccess = ({ commit }, data) => commit(types.SET_CONVERT_TO_BAIT, data);
export const receiveConvertToBaitError = ({ commit }, error) => commit(types.GET_CONVERT_TO_BAIT_ERROR, error);
export const fetchConvertToBait = ({ dispatch }, payload) => {
  dispatch('requestConvertToBait');
  Api.patch(`bt/baits/convert_to_bait/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveConvertToBaitSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveConvertToBaitError', response);
    });
};
