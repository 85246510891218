export const SET_ACTION = 'SET_ACTION';

export const SET_LIMIT = 'SET_LIMIT';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SORT = 'SET_SORT';
export const SET_STATIC_FILTER = 'SET_STATIC_FILTER';
export const SET_DYNAMIC_FILTER = 'SET_DYNAMIC_FILTER';


export const SET_TOTAL = 'SET_TOTAL';
export const SET_PER_PAGE = 'SET_PER_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';

export const SET_SORT_SEQUENCE = 'SET_SORT_SEQUENCE';
export const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const SET_SORT_TITLE = 'SET_SORT_TITLE';

export const SET_LOADER = 'SET_LOADER';
export const MANAGE_REQUEST = 'MANAGE_REQUEST';
