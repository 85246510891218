export const propertyProfile = state => state.propertyProfile;
export const propertyTypes = state => state.propertyTypes;
export const tenureTypes = state => state.tenureTypes;

export const profile = state => state.propertyProfile;
export const propertyPrices = state => state.propertyPrices;
export const propertyContacts = state => state.contacts;

export const transactionType = (state) => {
  if (state.propertyProfile) {
    return state.propertyProfile.transaction_type;
  }
  return false;
};
export const propertyStatus = (state) => {
  if (state.propertyProfile) {
    return state.propertyProfile.property_status;
  }
  return false;
};

export const propertyFullAddress = (state) => {
  if (state.propertyProfile) {
    const address = state.propertyProfile.property.property_address;
    const fullAddress = [];

    if (address.address_1 !== null) {
      fullAddress.push(address.address_1);
    }
    if (address.address_2 !== null) {
      fullAddress.push(address.address_2);
    }
    if (address.address_3 !== null) {
      fullAddress.push(address.address_3);
    }
    if (address.address_4 !== null) {
      fullAddress.push(address.address_4);
    }
    if (address.address_5 !== null) {
      fullAddress.push(address.address_5);
    }
    if (address.postcode !== null) {
      fullAddress.push(address.postcode);
    }

    return fullAddress.join(', ');
  }
  return '';
};

export const bait = (state) => {
  if (state.propertyProfile) {
    return state.propertyProfile.bait;
  }
  return false;
};
export const property = (state) => {
  if (state.propertyProfile) {
    return state.propertyProfile.property;
  }
  return false;
};

export const baitModuleEntries = state => state.baitModuleEntries;
export const baitModuleProcessed = state => state.baitModuleProcessed;
export const baitModuleProcessedSuccess = state => state.baitModuleProcessedSuccess;
export const baitModuleProcessedError = state => state.baitModuleProcessedError;

export const keySellingPointEntries = (state) => {
  if (state.baitModuleEntries) {
    let entries = state.baitModuleEntries;

    entries = entries.find((item) => {
      if (item.module_id === 33) {
        return item;
      }
      return false;
    });

    return entries.module_entries;
  }
  return false;
};
export const FaqEntries = (state) => {
  if (state.baitModuleEntries) {
    let entries = state.baitModuleEntries;

    entries = entries.find((item) => {
      if (item.module_id === 26) {
        return item;
      }
      return false;
    });

    return entries.module_entries;
  }
  return false;
};
export const comparablePropertiesEntries = (state) => {
  if (state.baitModuleEntries) {
    let entries = state.baitModuleEntries;

    entries = entries.find((item) => {
      if (item.module_id === 28) {
        return item;
      }
      return false;
    });

    return entries.module_entries;
  }
  return false;
};
export const overviewEntries = (state) => {
  if (state.baitModuleEntries) {
    let entries = state.baitModuleEntries;

    entries = entries.find((item) => {
      if (item.module_id === 31) {
        return item;
      }
      return false;
    });

    return entries.module_entries;
  }
  return false;
};
export const marketingPlanEntries = (state) => {
  if (state.baitModuleEntries) {
    let entries = state.baitModuleEntries;

    entries = entries.find((item) => {
      if (item.module_id === 34) {
        return item;
      }
      return false;
    });

    return entries.module_entries;
  }
  return false;
};
