export default [{
  name: 'Contacts',
  path: '/contacts',
  meta: {
    title: 'Contacts',
    breadcrumb: [
      { name: 'Contacts' },
    ],
  },
  props: { contactListType: 'normal' },
  component: () => import('../views/Contacts.vue'),
},
{
  name: 'ProactiveBookMoreViewings',
  path: '/proactive/book_more_viewings/:profile',
  meta: {
    title: 'Book More Viewings',
    breadcrumb: [
      { name: 'Proactive', link: '/proactive' },
      { name: 'Book More Viewings' },
    ],
  },
  props: { contactListType: 'proactiveBookMoreViewings', kind: 'proactive' },
  component: () => import('../views/Contacts.vue'),
},
{
  name: 'ProactiveBookMoreMarketAppraisals',
  path: '/proactive/book_more_market_appraisals/:profile',
  meta: {
    title: 'Book More MA\'s',
    breadcrumb: [
      { name: 'Proactive', link: '/proactive' },
      { name: 'Book More Market Appraisals' },
    ],
  },
  props: { contactListType: 'proactiveBookMoreMarketAppraisals', kind: 'proactive' },
  component: () => import('../views/Contacts.vue'),
},
{
  name: 'ProactiveBookMoreInstructions',
  path: '/proactive/book_more_instructions/:profile',
  meta: {
    title: 'Book More Instructions',
    breadcrumb: [
      { name: 'Proactive', link: '/proactive' },
      { name: 'Book More Instructions' },
    ],
  },
  props: { contactListType: 'proactiveBookMoreInstructions', kind: 'proactive' },
  component: () => import('../views/Contacts.vue'),
},
{
  name: 'ContactDetail',
  path: '/contact_detail/:contact_id',
  meta: {
    title: 'Contact Detail',
    breadcrumb: [
      { name: 'Contacts', link: '/contacts' },
      { name: 'Contact Detail' },
    ],
  },
  component: () => import('../views/ContactDetail.vue'),
},
{
  name: 'BaitStatusContacts',
  path: '/dashboard/details/:transaction_type/ma_status_contacts/:bait_status_id',
  meta: {
    title: 'MA Status Contacts',
    breadcrumb: [
      { name: 'Contacts by MA Status' },
    ],
  },
  props: { contactListType: 'dashboardMaStatus', kind: 'dashboardMaStatusContacts' },
  component: () => import('../views/Contacts.vue'),
},
{
  name: 'BlogContactVisited',
  path: '/blog/contacts/visit_blogs/:article_id',
  meta: {
    title: 'Blog Contact Visited',
    breadcrumb: [
      { name: 'Content', link: '/blog' },
      { name: 'Blog Contact Visited' },
    ],
  },
  props: { contactListType: 'blogVisits', kind: 'blogContactVisited' },
  component: () => import('../views/Contacts.vue'),
},
{
  path: '/statistics/autopilot-insights/reports/:activity_group',
  meta: {
    title: 'Autopilot Insight Contacts',
    breadcrumb: [
      { name: 'Statistics', link: '/statistics' },
      { name: 'Autopilot Insights', link: '/statistics/autopilot-insights' },
      { name: 'Autopilot Insight Contacts' },
    ],
  },
  name: 'contactsByActivities',
  props: { contactListType: 'contactsByActivities', kind: 'contactsByActivities' },
  component: () => import('@/modules/contacts/views/Contacts.vue'),
},
{
  name: 'JourneyListAppliedContacts',
  path: '/journey/step_applied_to_contacts/:drip_step_id',
  meta: {
    title: 'Journey Step Applied To Contacts',
    breadcrumb: [
      { name: 'Journey', link: '/journey-list' },
      { name: 'Journey Step Applied To Contacts' },
    ],
  },
  props: { contactListType: 'journeyListAppliedContacts', kind: 'journeyListAppliedContacts' },
  component: () => import('@/modules/contacts/views/Contacts.vue'),
},
{
  name: 'AdvancedSearch',
  path: '/advanced_search',
  meta: {
    title: 'Advanced Search',
    breadcrumb: [
      { name: 'Contacts', link: '/contacts' },
      { name: 'Advanced Search' },
    ],
  },
  component: () => import('../../audiences/views/DynamicAudienceBuilder.vue'),
}];
