export default {
  error: null,
  isLoading: false,
  form: null,
  forms: null,
  totalForms: 0,
  formDetail: null,
  createForm: null,
  createFormError: null,
  notifyUserTypes: null,
  formEntryAnswer: null,
  formIntegrations: null,
  formQuestions: null,
  formEntries: null,
  totalFormEntries: null,
  updateForm: null,
  updateFormError: null,
  formQuestionFields: null,
  createFormQuestion: null,
  updateFormQuestion: null,
  deleteFormQuestion: null,
  uploadFavicon: null,

  createFormProcessed: false,
  createFormProcessedSuccess: false,
  createFormProcessedError: false,

  updateFormProcessed: false,
  updateFormProcessedSuccess: false,
  updateFormProcessedError: false,

  deleteFormSuccess: false,
  deleteFormError: false,

  updateFormQuestionProcess: false,
  updateFormQuestionSuccess: false,
  updateFormQuestionError: false,
  cloneFormSuccess: false,
  cloneFormError: false,
};
