export const GET_ARTICLES = 'GET_ARTICLES';
export const SET_ARTICLES = 'SET_ARTICLES';
export const GET_ARTICLES_ERROR = 'GET_ARTICLES_ERROR';

export const GET_BLOG = 'GET_BLOG';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_ERROR = 'GET_BLOG_ERROR';
export const RESET_BLOG = 'RESET_BLOG';

export const CREATE_BLOG = 'CREATE_BLOG';
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_ERROR = 'CREATE_BLOG_ERROR';

export const DELETE_BLOG = 'DELETE_BLOG';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_ERROR = 'DELETE_BLOG_ERROR';

export const UPDATE_BLOG = 'UPDATE_BLOG';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_ERROR = 'UPDATE_BLOG_ERROR';

export const UPDATE_BLOG_ACTIVE = 'UPDATE_BLOG_ACTIVE';
export const UPDATE_BLOG_ACTIVE_SUCCESS = 'UPDATE_BLOG_ACTIVE_SUCCESS';
export const UPDATE_BLOG_ACTIVE_ERROR = 'UPDATE_BLOG_ACTIVE_ERROR';

export const GET_ARTICLE_CATEGORIES = 'GET_ARTICLE_CATEGORIES';
export const SET_ARTICLE_CATEGORIES = 'SET_ARTICLE_CATEGORIES';
export const GET_ARTICLE_CATEGORIES_ERROR = 'GET_ARTICLE_CATEGORIES_ERROR';

export const GET_SHARE_BLOG_ON_SOCIAL_MEDIAS = 'GET_SHARE_BLOG_ON_SOCIAL_MEDIAS';
export const SET_SHARE_BLOG_ON_SOCIAL_MEDIAS = 'SET_SHARE_BLOG_ON_SOCIAL_MEDIAS';
export const GET_SHARE_BLOG_ON_SOCIAL_MEDIAS_ERROR = 'GET_SHARE_BLOG_ON_SOCIAL_MEDIAS_ERROR';

export const GET_BLOG_WAITING_SHARES = 'GET_BLOG_WAITING_SHARES';
export const SET_BLOG_WAITING_SHARES = 'SET_BLOG_WAITING_SHARES';
export const GET_BLOG_WAITING_SHARES_ERROR = 'GET_BLOG_WAITING_SHARES_ERROR';

export const GET_SOCIAL_MEDIA_SHARE_INFO = 'GET_SOCIAL_MEDIA_SHARE_INFO';
export const SET_SOCIAL_MEDIA_SHARE_INFO = 'SET_SOCIAL_MEDIA_SHARE_INFO';
export const GET_SOCIAL_MEDIA_SHARE_INFO_ERROR = 'GET_SOCIAL_MEDIA_SHARE_INFO_ERROR';

export const GET_ARTICLES_MINIMAL = 'GET_ARTICLES_MINIMAL';
export const SET_ARTICLES_MINIMAL = 'SET_ARTICLES_MINIMAL';
export const GET_ARTICLES_MINIMAL_ERROR = 'GET_ARTICLES_MINIMAL_ERROR';

export const SET_SOCIAL_MEDIA_ALL_POSTS = 'SET_SOCIAL_MEDIA_ALL_POSTS';
export const GET_SOCIAL_MEDIA_ALL_POSTS_ERROR = 'GET_SOCIAL_MEDIA_ALL_POSTS_ERROR';
