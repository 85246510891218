import { produceKey } from '@/core/utils/helper';

export default {
  creditBalance: null,
  talks: [],
  talksMeta: null,
  uzairActions: [
    {
      title: 'Create a Property Description',
      name: 'propertyDescription',
      icon: 'far fa-home',
      description: 'Helps you make eye-catching property ads effortlessly. You can create attention-grabbing headlines, brief yet informative property summaries, and detailed descriptions. Perfect for getting your listings on your website or popular portals',
    },
    {
      title: 'Prepare Vendor/Landlord Care',
      name: 'vendorFeedback',
      icon: 'far fa-envelope',
      description: 'Keep your vendors/landlords in the loop. You can easily send them updates about the progress of their property sale. It\'s a great way to ensure they always know what\'s happening and have peace of mind as their property moves towards a successful sale.',
    },
    {
      title: 'Create Blog Content',
      name: 'content',
      icon: 'far fa-blog',
      description: 'Takes the hassle out of blog content creation. Just tell it your topic and some prompts, or let it suggest some based on your past blog performance. Then, watch it work its magic by crafting engaging content for your audience.',
    },
  ],

  activeTalkId: null,
  activeTalk: null,

  uzairPopup: {
    selector: produceKey(),
    show: false,
    data: false,
    is_visible: false,
    config: {},
  },

  responseTransferMemory: null,

  isUzairIconBig: false,
};
