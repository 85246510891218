export default [
  {
    name: 'JourneyList',
    path: '/journey-list',
    meta: {
      title: 'Journey List',
      breadcrumb: [
        { name: 'Marketing & Autopilot', link: '/marketing' },
        { name: 'Journey List' },
      ],
    },
    component: () => import('../views/JourneyLists.vue'),
  },
  {
    name: 'JourneyPreview',
    path: '/journey_preview/:drip_id',
    meta: {
      title: 'Journey',
      breadcrumb: [
        { name: 'Journey List', link: '/journey-list' },
        { name: 'Journey Preview' },
      ],
    },
    component: () => import('../views/NurtureJourney.vue'),
  }];
