export default [
  {
    path: '/statistics',
    meta: {
      title: 'Statistics',
      breadcrumb: [
        { name: 'Statistics' },
      ],
    },
    name: 'TheStats',
    component: () => import('@/modules/stats/views/TheStats.vue'),
  },
  {
    path: '/statistics/autopilot-insights',
    meta: {
      title: 'Autopilot Insights',
      breadcrumb: [
        { name: 'Statistics', link: '/statistics' },
        { name: 'Autopilot Insights' },
      ],
    },
    name: 'AutopilotInsights',
    component: () => import('@/modules/stats/components/AutopilotInsights.vue'),
  },
  {
    path: '/statistics/team-insights',
    meta: {
      title: 'Team Insights',
      breadcrumb: [
        { name: 'Statistics', link: '/statistics' },
        { name: 'Team Insights' },
      ],
    },
    name: 'TeamInsights',
    component: () => import('@/modules/stats/components/TeamInsights.vue'),
  },
  {
    path: '/statistics/property-insights',
    meta: {
      title: 'Insights By Property Type',
      breadcrumb: [
        { name: 'Statistics', link: '/statistics' },
        { name: 'Insights By Property Type' },
      ],
    },
    name: 'PropertyInsights',
    component: () => import('@/modules/stats/components/PropertyInsights.vue'),
  },
  {
    path: '/statistics/content-insights',
    meta: {
      title: 'Content Insights',
      breadcrumb: [
        { name: 'Statistics', link: '/statistics' },
        { name: 'Content Insights' },
      ],
    },
    name: 'ContentInsights',
    component: () => import('@/modules/stats/components/ContentInsights.vue'),
  },
  {
    path: '/statistics/vendor-funnel',
    meta: {
      title: 'Vendor Funnel',
      breadcrumb: [
        { name: 'Statistics', link: '/statistics' },
        { name: 'Vendor Funnel' },
      ],
    },
    name: 'VendorFunnel',
    component: () => import('@/modules/stats/components/VendorFunnel.vue'),
  },
  {
    path: '/statistics/landlord-funnel',
    meta: {
      title: 'Landlord Funnel',
      breadcrumb: [
        { name: 'Statistics', link: '/statistics' },
        { name: 'Landlord Funnel' },
      ],
    },
    name: 'LandlordFunnel',
    component: () => import('@/modules/stats/components/LandlordFunnel.vue'),
  },
];
