export default [
  {
    path: '/operations',
    meta: {
      title: 'Operations',
      breadcrumb: [
        { name: 'Operations' },
      ],
    },
    name: 'Operations',
    component: () => import('@/modules/operations/views/OperationsMenu.vue'),
  },
];
