import ApiService from '@/core/api/api.service';


export default class CalendarEventServices extends ApiService {
  setLoader() {
    this.loader = false;
  }

  async fetchEvents(params) {
    this.loader = false;
    this.url = 'search_engine/event/calendar/general';
    this.params = params;
    const { result } = await this.get();
    return result;
  }

  async fetchViewingEvents(params) {
    this.loader = false;
    this.url = 'search_engine/event/calendar/viewings';
    this.params = params;
    const { result } = await this.get();
    return result;
  }

  async fetchEventContacts(propertyProfileId) {
    this.url = `property_profiles/${propertyProfileId}/contacts`;
    const { result: { data } } = await this.get();
    return data;
  }

  async deleteEvent(eventId) {
    this.url = `calendar/events/${eventId}`;
    return this.baseRemitter('delete', 'message');
  }

  async fetchViewingEvent(eventId) {
    this.url = `search_engine/event/viewings/event/${eventId}`;
    const { result: { data } } = await this.get();
    return data;
  }
}
