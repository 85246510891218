import Vue from 'vue';
import * as types from './mutation_types';
import * as ErrorManager from '../../../mixins/ErrorManager';

export default {
  [types.GET_SOLNY](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOLNY](state, data) {
    Vue.set(state, 'solnyList', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOLNY_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SOLNY_DETAIL](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOLNY_DETAIL](state, data) {
    Vue.set(state, 'solnyDetail', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOLNY_DETAIL_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SOLNY_BROCHURE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOLNY_BROCHURE](state, data) {
    Vue.set(state, 'solnyBrochure', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOLNY_BROCHURE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PAPER_COPIES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PAPER_COPIES](state, data) {
    Vue.set(state, 'paperCopies', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PAPER_COPIES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PAPER_SIZES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PAPER_SIZES](state, data) {
    Vue.set(state, 'paperSizes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PAPER_SIZES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PAPER_LAMINATIONS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PAPER_LAMINATIONS](state, data) {
    Vue.set(state, 'paperLaminations', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PAPER_LAMINATIONS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PAPER_TYPES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PAPER_TYPES](state, data) {
    Vue.set(state, 'paperTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PAPER_TYPES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PAPER_WEIGHTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PAPER_WEIGHTS](state, data) {
    Vue.set(state, 'paperWeights', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PAPER_WEIGHTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PAPER_ORDER_PRICES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PAPER_ORDER_PRICES](state, data) {
    Vue.set(state, 'orderPrices', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PAPER_ORDER_PRICES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SEND_ORDER](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendOrderProcessed', true);
    Vue.set(state, 'sendOrderProcessedSuccess', false);
    Vue.set(state, 'sendOrderProcessedError', false);
  },
  [types.SET_SEND_ORDER](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sendOrderProcessedSuccess', 'Solny Order completed successfully');
  },
  [types.GET_SEND_ORDER_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sendOrderProcessedError', 'Error completing Solny Order');
  },

  [types.GET_SOCIAL_MEDIA_ACCOUNTS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'socialMediaAccounts', false);
  },
  [types.SET_SOCIAL_MEDIA_ACCOUNTS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaAccounts', data.result.data);
  },
  [types.GET_SOCIAL_MEDIA_ACCOUNTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaAccounts', []);
  },

  [types.GET_UPDATE_AD_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateAdAccountSuccess', false);
    Vue.set(state, 'updateAdAccountError', false);
  },
  [types.SET_UPDATE_AD_ACCOUNT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateAdAccountSuccess', data.message);
  },
  [types.GET_UPDATE_AD_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateAdAccountError', ErrorManager.getErrors(error)[0]);
  },

  [types.GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'connectedAudiencesWithAdAccount', false);
    Vue.set(state, 'adAccountDetail', false);
    Vue.set(state, 'connectedAudiencesWithAdAccountTotal', 0);
  },
  [types.SET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'connectedAudiencesWithAdAccountTotal', data.result.data.custom_audiences.length);
    Vue.set(state, 'adAccountDetail', { ...data.result.data });
    Vue.set(state, 'connectedAudiencesWithAdAccount', [...data.result.data.custom_audiences]);
  },
  [types.GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'adAccountDetail', {});
    Vue.set(state, 'connectedAudiencesWithAdAccount', []);
  },

  [types.GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'connectAudienceWithAdAccountSuccess', false);
    Vue.set(state, 'connectAudienceWithAdAccountError', false);
  },
  [types.SET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'connectAudienceWithAdAccountSuccess', data.message);
  },
  [types.GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'connectAudienceWithAdAccountError', ErrorManager.getErrors(error)[0]);
  },

  [types.GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateConnectAudienceWithAdAccountSuccess', false);
    Vue.set(state, 'connectAudienceWithAdAccountError', false);
  },
  [types.SET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateConnectAudienceWithAdAccountSuccess', data.message);
  },
  [types.GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateConnectAudienceWithAdAccountError', ErrorManager.getErrors(error)[0]);
  },

  [types.GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteConnectionAudienceWithAdAccountSuccess', false);
    Vue.set(state, 'deleteConnectionAudienceWithAdAccountError', false);
  },
  [types.SET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteConnectionAudienceWithAdAccountSuccess', data.message);
  },
  [types.GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteConnectionAudienceWithAdAccountError', ErrorManager.getErrors(error)[0]);
  },
};
