export default class ViewingStatuesConstants {
  static REQUIRES_FEEDBACK = 1;

  static REQUIRES_REPORTING = 2;

  static UPCOMING = 3;

  static FEEDBACK_REPORTED = 4;

  static VIEWING_APPOINTMENT_STATUS = {
    UNCONFIRMED: 1,
    CONFIRMED: 2,
    COMPLETED: 3,
    CANCELLED: 4,
    NO_SHOW: 5,
    FAILED_TO_CONFIRMED: 6,
    UPCOMING: 7,
  };
}
