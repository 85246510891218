export const SET_TOKEN = 'SET_TOKEN';

export const SET_REMEMBER = 'SET_REMEMBER';
export const SET_NOT_REMEMBER = 'SET_NOT_REMEMBER';

export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';
export const RESET_TOKEN_ERROR = 'RESET_TOKEN_ERROR';

export const RESET_RESET_PASSWORD = 'RESET_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_RESET_PASSWORD_SUCCESS = 'GET_RESET_PASSWORD_SUCCCES';
export const GET_RESET_PASSWORD_ERROR = 'GET_RESET_PASSWORD_ERROR';

export const RESET_UPDATE_PASSWORD = 'RESET_UPDATE_PASSWORD';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const GET_UPDATE_PASSWORD_SUCCESS = 'GET_UPDATE_PASSWORD_SUCCESS';
export const GET_UPDATE_PASSWORD_ERROR = 'GET_UPDATE_PASSWORD_ERROR';

export const MAKE_AUTO_BACKEND_LOGIN = 'MAKE_AUTO_BACKEND_LOGIN';
export const GET_MAKE_AUTO_BACKEND_LOGIN_SUCCESS = 'GET_MAKE_AUTO_BACKEND_LOGIN_SUCCESS';
export const GET_MAKE_AUTO_BACKEND_LOGIN_ERROR = 'GET_MAKE_AUTO_BACKEND_LOGIN_ERROR';
