import axios from 'axios';

const headers = {
  Accept: 'application/json',
  Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
};

export default type => axios.create({
  baseURL: process.env[type],
  headers,
});
