import Vue from 'vue';
import TransactionTypesConstants from '@/modules/common/constants/transactionTypes.constants';
import * as types from './mutation_types';

export default {
  [types.GET_DASHBOARD_STATS](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_DASHBOARD_STATS](state, data) {
    Vue.set(state, 'isLoading', false);
    if (data.transactionType === 'sales') {
      Vue.set(state, 'dashboardSalesStats', { ...data.newData });
    } else {
      Vue.set(state, 'dashboardLettingsStats', { ...data.newData });
    }
  },
  [types.GET_DASHBOARD_STATS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_MAP_STATS](state, data) {
    Vue.set(state, 'isLoading', false);
    if (data.transactionType === 'sales') {
      Vue.set(state, 'mapSalesStats', [...data.mapData]);
    } else {
      Vue.set(state, 'mapLettingsStats', [...data.mapData]);
    }
  },

  [types.SET_DASHBOARD_LEADS](state, { data, transactionTypeId }) {
    Vue.set(state, 'dashboardLeads', {
      ...state.dashboardLeads,
      [TransactionTypesConstants.computeTypeName(transactionTypeId)]: data,
    });
  },
};
