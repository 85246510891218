import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestSubs = ({ commit }) => commit(types.GET_SUBS);
export const receiveSubsSuccess = ({ commit }, data) => commit(types.SET_SUBS, data);
export const receiveSubsError = ({ commit }, error) => commit(types.GET_SUBS_ERROR, error);
export const fetchSubs = ({ dispatch }) => {
  dispatch('requestSubs');
  Api.get('subscription_types?__order_by=name')
    .then(({ data }) => {
      dispatch('receiveSubsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSubsError', response);
    });
};
