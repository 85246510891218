export default [{
  name: 'Newsletters',
  path: '/newsletters',
  meta: {
    title: 'Newsletters',
    breadcrumb: [
      { name: 'Newsletters' },
    ],
  },
  component: () => import('../views/SentCampaigns.vue'),
},
{
  name: 'SentNewsletterDetail',
  path: '/newsletter_detail/:campaign_id',
  meta: {
    title: 'Newsletter Detail',
    breadcrumb: [
      { name: 'Newsletters', link: '/newsletters' },
      { name: 'Newsletter Detail' },
    ],
  },
  component: () => import('../views/SentCampaignDetail.vue'),
},
{
  name: 'NewsletterManagement',
  path: '/newsletter_management',
  meta: {
    title: 'Newsletter Management',
    breadcrumb: [
      { name: 'Newsletter Management' },
    ],
  },
  component: () => import('../views/CampaignManagement.vue'),
}];
