export default {
  isLoading: false,
  sentCampaigns: false,
  totalSentCampaigns: 0,
  sentCampaignDetail: false,
  totalCampaignDetailList: 0,
  sentCampaignDetailListData: false,
  sentCampaignDetailListTotal: 0,
  sentCampaignEmailTemplate: false,
  nextMonthCampaignBlogs: false,
  removeBlogFromCampaignSuccess: false,
  removeBlogFromCampaignError: false,
  sortNewsletterBlogsSuccess: false,
  sortNewsletterBlogsError: false,
  sendNewsletterTestEmailSuccess: false,
  campaignSettings: false,
};
