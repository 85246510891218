import axios from 'axios';
import * as ErrorManager from '@/mixins/ErrorManager';

const akya = axios.create({
  baseURL: process.env.VUE_APP_API_AKYA_URL,
});
export default {
  get(url, params) {
    return akya.get(url, {
      params,
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
      },
    });
  },
  post(url, data) {
    return akya.post(url, data, {
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
      },
    });
  },
  postFile(url, data) {
    const post = akya.post(url, data, {
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    post.then(() => {
    }).catch((error) => {
      ErrorManager.controlConnectionProblems(error);
    });
    return post;
  },
};
