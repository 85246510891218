import Vue from 'vue';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';

export default {
  /* Contacts */
  [types.GET_CONTACTS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'isLoadingContacts', true);
  },
  [types.SET_CONTACTS](state, data) {
    if (data.result.meta.total > 0) {
      Vue.set(state, 'totalContacts', data.result.meta.total);
    } else {
      Vue.set(state, 'totalContacts', data.result.data[0].total_count);
    }
    Vue.set(state, 'contacts', [...data.result.data]);
    Vue.set(state, 'isLoadingContacts', false);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_CONTACTS_LIST](state, data) {
    Vue.set(state, 'contactList', data);
  },
  [types.GET_CONTACTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'isLoadingContacts', false);
    Vue.set(state, 'contacts', []);
  },
  [types.GET_WILL_BE_UPDATE_CONTACT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateContactSuccess', null);
    Vue.set(state, 'updateContactError', null);
  },
  [types.SET_UPDATED_CONTACT](state, data) {
    Vue.set(state, 'updateContactSuccess', {
      data: data.result.data.contact_id,
      message: data.message,
    });
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_UPDATE_CONTACT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateContactError', getErrors(error)[0]);
  },

  [types.GET_LAST_CREATED_CONTACT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createdContactInfo', null);
    Vue.set(state, 'createContactError', false);
  },
  [types.SET_LAST_CREATED_CONTACT](state, data) {
    Vue.set(state, 'createdContactInfo', {
      data: data.result.data.contact_id,
      message: data.message,
      contact: data.result.data,
    });
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_LAST_CREATED_CONTACT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createContactError', getErrors(error)[0]);
  },
  /* Select All Contact */
  [types.SET_ALL_CONTACT](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedContacts', Array.from(new Set(state.selectedContacts.concat(data))));
    Vue.set(state, 'isLoading', false);
  },
  [types.UNSET_ALL_CONTACT](state, data) {
    Vue.set(state, 'isLoading', true);
    for (let i = 0; i < data.length; i += 1) {
      const item = state.selectedContacts.findIndex(c => c.contact_id === data[i].contact_id);
      state.selectedContacts.splice(item, 1);
    }
    Vue.set(state, 'isLoading', false);
  },
  /* Select Single Contact */
  [types.SET_SELECTED_CONTACT](state, data) {
    Vue.set(state, 'isLoading', true);
    state.selectedContacts.push(data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UNSET_SELECTED_CONTACT](state, data) {
    Vue.set(state, 'isLoading', true);
    const item = state.selectedContacts.findIndex(c => c.contact_id === data.contact_id);
    state.selectedContacts.splice(item, 1);
    Vue.set(state, 'isLoading', false);
  },
  [types.CLEAR_SELECTED_CONTACTS](state) {
    Vue.set(state, 'isLoading', true);

    const contactListIds = state.contactList.map(o => o.contact_id);

    const selectedContacts = state.selectedContacts.filter(
      o => !contactListIds.includes(o.contact_id),
    );

    Vue.set(state, 'selectedContacts', selectedContacts);
    Vue.set(state, 'isLoading', false);
  },
  [types.CLEAR_ALL_SELECTED_CONTACTS](state) {
    Vue.set(state, 'selectedContacts', []);
  },
  // GET CONTACT DATA AND SET: START
  [types.GET_CONTACT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactFoundError', null);
  },
  [types.SET_CONTACT](state, data) {
    Vue.set(state, 'contact', { ...data.result.data });
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contactFoundError', message);
  },
  [types.RESET_CONTACT](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contact', null);
  },
  // GET CONTACT DATA AND SET: FINISH
  [types.SET_CORRESPONDANCE_ADDRESS](state, address) {
    Vue.set(state, 'correspondanceAddress', address);
  },

  [types.SET_EXPORT_CONTACTS](state, data) {
    Vue.set(state, 'exportContacts', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EXPORT_CONTACTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_EXPORT_CONTACTS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.GET_PROFILES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'profiles', null);
  },
  [types.SET_PROFILES](state, data) {
    Vue.set(state, 'profiles', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROFILES_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', message);
  },

  /* SEND SMS */
  [types.GET_SMS_SENDERS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'smsSenders', null);
  },
  [types.SET_SMS_SENDERS](state, data) {
    Vue.set(state, 'smsSenders', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SMS_SENDERS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', message);
  },

  [types.GET_CONTACT_SOURCES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CONTACT_SOURCES](state, data) {
    Vue.set(state, 'contactSources', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_SOURCES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CONTACT_LEAD_SOURCES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CONTACT_LEAD_SOURCES](state, data) {
    Vue.set(state, 'contactLeadSources', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_LEAD_SOURCES_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    });
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendSMSSuccess', null);
    Vue.set(state, 'sendSMSError', null);
  },
  [types.SEND_SMS_SUCCESS](state, success) {
    Vue.set(state, 'sendSMSSuccess', success);
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_SMS_ERROR](state, error) {
    const errorData = {};
    if (error && error.data && error.data.result && error.data.result.data) {
      errorData.success = [...error.data.result.data.success];
      errorData.errors = [...error.data.result.data.errors];
      errorData.type = 'contact';
    } else if (error && error.data && error.data.result && error.data.result) {
      let messages = null;
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [errorData.message] = messages;
      errorData.type = 'validator';
    } else {
      errorData.message = error.message;
      errorData.type = 'general';
    }
    Vue.set(state, 'sendSMSError', errorData);
    Vue.set(state, 'isLoading', false);
  },

  [types.SEND_CSV](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SEND_CSV_SUCCESS](state, data) {
    Vue.set(state, 'message', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_CSV_ERROR](state, message) {
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    });
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_STICKY_NOTE](state) {
    Vue.set(state, 'stickyNoteisLoading', true);
  },
  [types.SET_STICKY_NOTE](state, data) {
    Vue.set(state, 'stickyNote', data.result.data[0]);
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'stickyNoteisLoading', false);
  },
  [types.GET_STICKY_NOTE_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    });
    Vue.set(state, 'stickyNoteisLoading', false);
  },

  [types.UPDATE_STICKY_NOTE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'setStickyNoteSuccess', null);
    Vue.set(state, 'setStickyNoteError', null);
  },
  [types.UPDATE_STICKY_NOTE_ERROR](state, error) {
    Vue.set(state, 'setStickyNoteError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_STICKY_NOTE_SUCCESS](state) {
    Vue.set(state, 'setStickyNoteSuccess', true);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_PROFILE_PHOTO](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateContactPhotoSuccess', null);
    Vue.set(state, 'updateContactPhotoError', null);
  },
  [types.SET_UPDATED_PROFILE_PHOTO](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateContactPhotoSuccess', data.message);
  },
  [types.SET_UPDATE_PROFILE_PHOTO_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateContactPhotoError', error.data.message);
  },

  /* CUSTOM FIELD: START */
  [types.GET_CUSTOM_FIELD_TYPES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'customFieldTypes', null);
    Vue.set(state, 'customFieldTypesError', null);
  },
  [types.SET_CUSTOM_FIELD_TYPES](state, data) {
    Vue.set(state, 'customFieldTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CUSTOM_FIELD_TYPES_ERROR](state, error) {
    Vue.set(state, 'customFieldTypesError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CUSTOM_FIELD_GROUPS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'customFieldGroupsError', null);
  },
  [types.SET_CUSTOM_FIELD_GROUPS](state, data) {
    Vue.set(state, 'customFieldGroups', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CUSTOM_FIELD_GROUPS_ERROR](state, error) {
    Vue.set(state, 'customFieldGroupsError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_CUSTOM_FIELD_GROUP](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createCustomFieldGroupSuccess', null);
    Vue.set(state, 'createCustomFieldGroupError', null);
  },
  [types.CREATE_CUSTOM_FIELD_GROUP_SUCCESS](state, success) {
    Vue.set(state, 'createCustomFieldGroupSuccess', success);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_CUSTOM_FIELD_GROUP_ERROR](state, error) {
    Vue.set(state, 'createCustomFieldGroupError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_CUSTOM_FIELD_GROUP](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteCustomFieldGroupSuccess', null);
    Vue.set(state, 'deleteCustomFieldGroupError', null);
  },
  [types.DELETE_CUSTOM_FIELD_GROUP_SUCCESS](state, success) {
    Vue.set(state, 'deleteCustomFieldGroupSuccess', success.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_CUSTOM_FIELD_GROUP_ERROR](state, error) {
    Vue.set(state, 'deleteCustomFieldGroupError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_CUSTOM_FIELD](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createCustomFieldSuccess', null);
    Vue.set(state, 'createCustomFieldError', null);
  },
  [types.CREATE_CUSTOM_FIELD_SUCCESS](state, success) {
    Vue.set(state, 'createCustomFieldSuccess', success);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_CUSTOM_FIELD_ERROR](state, error) {
    Vue.set(state, 'createCustomFieldError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CUSTOM_FIELDS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'customFields', null);
    Vue.set(state, 'customFieldsError', null);
    Vue.set(state, 'totalCustomFields', null);
  },
  [types.SET_CUSTOM_FIELDS](state, data) {
    Vue.set(state, 'customFields', data.result.data);
    Vue.set(state, 'totalCustomFields', data.result.meta.total);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CUSTOM_FIELDS_ERROR](state, error) {
    Vue.set(state, 'customFieldsError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_CUSTOM_FIELD](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateCustomFieldSuccess', null);
    Vue.set(state, 'updateCustomFieldError', null);
  },
  [types.UPDATE_CUSTOM_FIELD_ERROR](state, error) {
    Vue.set(state, 'updateCustomFieldError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_CUSTOM_FIELD_SUCCESS](state, success) {
    Vue.set(state, 'updateCustomFieldSuccess', success.message);
    Vue.set(state, 'isLoading', false);
  },
  /* CUSTOM FIELD: FINISH */
  /* GET TAGS RELATED TO CONTACT  */
  [types.GET_TAGS_BY_CONTACT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactTags', null);
  },
  [types.GET_TAGS_BY_CONTACT_SUCCESS](state, data) {
    Vue.set(state, 'contactTags', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_TAGS_BY_CONTACT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  /* INSERT NEW TAG TO CONTACT */
  [types.INSERT_NEW_TAG_TO_CONTACT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'insertTagToContactSuccess', null);
    Vue.set(state, 'insertTagToContactError', null);
  },
  [types.INSERT_NEW_TAG_TO_CONTACT_SUCCESS](state, data) {
    Vue.set(state, 'insertTagToContactSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.INSERT_NEW_TAG_TO_CONTACT_ERROR](state, error) {
    Vue.set(state, 'insertTagToContactError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  /* DELETE TAG FROM CONTACT */
  [types.DELETE_TAG_FROM_CONTACT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteTagFromContactSuccess', null);
    Vue.set(state, 'deleteTagFromContactError', null);
  },
  [types.DELETE_TAG_FROM_CONTACT_SUCCESS](state, data) {
    Vue.set(state, 'deleteTagFromContactSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_TAG_FROM_CONTACT_ERROR](state, error) {
    Vue.set(state, 'deleteTagFromContactError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  /* INSERT NEW TAG TO COMPANY TAGS */
  [types.INSERT_NEW_TAG_TO_COMPANY_TAGS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.INSERT_NEW_TAG_TO_COMPANY_TAGS_SUCCESS](state, data) {
    Vue.set(state, 'insertTagToCompanyTags', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.INSERT_NEW_TAG_TO_COMPANY_TAGS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  /* Contact Detail Mail List */
  [types.GET_CONTACT_MAIL_LISTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CONTACT_MAIL_LISTS](state, data) {
    Vue.set(state, 'contactMailLists', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_MAIL_LISTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CONTACT_SUBSCRIPTIONS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_CONTACT_SUBSCRIPTIONS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_CONTACT_SUBSCRIPTIONS](state, data) {
    Vue.set(state, 'contactSubscriptions', data.result.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SAVE_CONTACT_SUBSCRIPTIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactSubscriptionsSuccess', null);
    Vue.set(state, 'contactSubscriptionsError', null);
  },
  [types.SET_SAVE_CONTACT_SUBSCRIPTIONS](state, data) {
    Vue.set(state, 'contactSubscriptionsSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SAVE_CONTACT_SUBSCRIPTIONS_ERROR](state, error) {
    Vue.set(state, 'contactSubscriptionsError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_FORM_ENTRIES](state, data) {
    Vue.set(state, 'formEntries', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FORM_ENTRIES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_FORM_ENTRIES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'formEntries', null);
  },

  /* CONTACT DETAIL RELATED PROPERTIES */

  [types.GET_CONTACT_DETAIL_RELATED_PROPERTIES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'relatedProperties', null);
  },
  [types.GET_CONTACT_DETAIL_RELATED_PROPERTIES_SUCCESS](state, data) {
    Vue.set(state, 'relatedProperties', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_DETAIL_RELATED_PROPERTIES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  /* Contact Detail Blog Reads */
  [types.GET_CONTACT_BLOG_READS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactBlogReadsTotal', 0);
    Vue.set(state, 'contactBlogReads', null);
    Vue.set(state, 'contactBlogReadsIsEnd', false);
  },
  [types.SET_CONTACT_BLOG_READS](state, data) {
    Vue.set(state, 'contactBlogReads', data.result.data);
    Vue.set(state, 'contactBlogReadsTotal', data.result.meta.total);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTACT_BLOG_READS_ERROR](state) {
    Vue.set(state, 'contactBlogReadsTotal', 0);
    Vue.set(state, 'contactBlogReads', null);
    Vue.set(state, 'contactBlogReadsIsEnd', true);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EMAIL_SENDERS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'emailSenders', null);
  },
  [types.SET_EMAIL_SENDERS](state, data) {
    Vue.set(state, 'emailSenders', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EMAIL_SENDERS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', message);
  },

  [types.SEND_EMAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendEmailSuccess', null);
    Vue.set(state, 'sendEmailError', null);
  },
  [types.SEND_EMAIL_SUCCESS](state, success) {
    Vue.set(state, 'sendEmailSuccess', success);
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_EMAIL_ERROR](state, error) {
    const errorData = {};
    if (error && error.data && error.data.result && error.data.result.data) {
      errorData.success = [...error.data.result.data.success];
      errorData.errors = [...error.data.result.data.errors];
      errorData.type = 'contact';
    } else if (error && error.data && error.data.result && error.data.result) {
      let messages = null;
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [errorData.message] = messages;
      errorData.type = 'validator';
    } else {
      errorData.message = error.message;
      errorData.type = 'general';
    }
    Vue.set(state, 'sendEmailError', errorData);
    Vue.set(state, 'isLoading', false);
  },

  /* Contact Detail Crm Notes Get Activities */
  [types.GET_ACTIVITIES_CRM_NOTES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_ACTIVITIES_CRM_NOTES_SUCCESS](state, data) {
    Vue.set(state, 'activitiesCrmNotes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ACTIVITIES_CRM_NOTES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  /* Contact Detail Add Crm Notes To Contact */
  [types.SET_CRM_NOTES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CRM_NOTES_SUCCESS](state, data) {
    Vue.set(state, 'sendCrmNotesSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_LAST_CONTACT_DATA](state, data) {
    Vue.set(state, 'lastContactTimelineData', data);
  },
  [types.SET_CRM_NOTES_ERROR](state, error) {
    Vue.set(state, 'sendCrmNotesError', error);
    /* Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    }); */
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_COUNTRIES](state, data) {
    Vue.set(state, 'countries', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_COUNTRIES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_COUNTRIES_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  /* Contact Detail Page Bait Status of Market Appraisal */
  [types.GET_BAIT_STATUS_OF_MARKET_APPRAISAL](state) {
    Vue.set(state, 'baitStatusOfMarketAppraisalIsLoading', true);
    Vue.set(state, 'baitStatusOfMarketAppraisal', null);
  },
  [types.GET_BAIT_STATUS_OF_MARKET_APPRAISAL_SUCCESS](state, data) {
    Vue.set(state, 'baitStatusOfMarketAppraisalIsLoading', false);
    Vue.set(state, 'baitStatusOfMarketAppraisal', data.result.data);
  },
  [types.GET_BAIT_STATUS_OF_MARKET_APPRAISAL_ERROR](state) {
    Vue.set(state, 'baitStatusOfMarketAppraisalIsLoading', false);
  },

  [types.SET_GDPR](state, data) {
    Vue.set(state, 'Gdpr.vue', data.result);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_GDPR](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_GDPR_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.GET_ACTIVITIES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'activities', null);
    Vue.set(state, 'activitiesError', null);
  },
  [types.SET_ACTIVITIES](state, data) {
    const activities = data.result.data;
    const activitiesTotal = {};
    const activitiesData = {};

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const o in activities) {
      activitiesData[o] = activities[o].data;
      activitiesTotal[o] = activities[o].info.total;
    }

    return new Promise(() => {
      Vue.set(state, 'activities', activitiesData);
      Vue.set(state, 'activitiesTotal', activitiesTotal);
      Vue.set(state, 'isLoading', false);
      return data;
    });
  },

  [types.SET_SELECTED_ACTIVITY_IDS](state, data) {
    Vue.set(state, 'selectedActivitiesIds', data);
  },
  [types.GET_ACTIVITIES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'activitiesError', error.data);
  },

  [types.GET_UPLOAD_CONTACT_FILE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'uploadContactFileSuccess', null);
    Vue.set(state, 'uploadContactFileError', null);
  },
  [types.SET_UPLOADED_CONTACT_FILE](state, data) {
    Vue.set(state, 'uploadContactFileSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_UPLOAD_CONTACT_FILE_ERROR](state, error) {
    Vue.set(state, 'uploadContactFileError', error.data.result.file[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CONTACT_FILES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactFiles', null);
    Vue.set(state, 'contactFilesError', null);
  },
  [types.SET_CONTACT_FILES](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contactFiles', data.result.data);
  },
  [types.GET_CONTACT_FILES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contactFilesError', error.data.message);
  },

  [types.GET_DELETE_CONTACT_FILE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteContactFileSuccess', null);
    Vue.set(state, 'deleteContactFileError', null);
  },
  [types.SET_DELETED_CONTACT_FILE](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteContactFileSuccess', data.message);
  },
  [types.SET_DELETE_CONTACT_FILE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteContactFileError', error.data.message);
  },

  [types.GET_UPDATE_CONTACT_FILE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateContactFileSuccess', null);
    Vue.set(state, 'updateContactFileError', null);
  },
  [types.SET_UPDATED_CONTACT_FILE](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateContactFileSuccess', data.message);
  },
  [types.SET_UPDATE_CONTACT_FILE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateContactFileError', error.data.message);
  },

  [types.GET_COMPANY_SUBSCRIPTIONS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_COMPANY_SUBSCRIPTIONS_ERROR](state, message) {
    Vue.set(state, 'contactCompanySubscriptionsError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_COMPANY_SUBSCRIPTIONS](state, data) {
    Vue.set(state, 'contactCompanySubscriptions', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_MULTIPLE_CONTACTS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'multipleContacts', []);
    Vue.set(state, 'multipleContactsError', null);
  },
  [types.GET_MULTIPLE_CONTACTS_ERROR](state, message) {
    Vue.set(state, 'multipleContactsError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_MULTIPLE_CONTACTS](state) {
    Vue.set(state, 'multipleContacts', []);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_MULTIPLE_CONTACTS](state, data) {
    const contacts = [];
    const index = contacts.find(item => item.contact_id === data.contact_id);
    if (!index) {
      contacts.push(...state.multipleContacts, data.result.data);
    }
    Vue.set(state, 'multipleContacts', contacts);
    Vue.set(state, 'isLoading', false);
  },

  [types.RESET_CONTACT_TIMELINE_DATA](state) {
    Vue.set(state, 'contactTimelineData', null);
  },
  [types.GET_CONTACT_TIMELINE_DATA](state) {
    Vue.set(state, 'isLoading', true);
    // Vue.set(state, 'contactTimelineData', null);
    Vue.set(state, 'contactTimelineDataError', null);
    // Vue.set(state, 'totalContactTimelineData', 0);
  },
  [types.GET_CONTACT_TIMELINE_DATA_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contactTimelineDataError', message);
    Vue.set(state, 'totalContactTimelineData', 0);
  },
  [types.SET_CONTACT_TIMELINE_DATA](state, data) {
    return new Promise((resolve) => {
      Vue.set(state, 'isLoading', false);
      Vue.set(state, 'contactTimelineData', data.data);
      Vue.set(state, 'totalContactTimelineData', data.total);
      resolve(data);
    });
  },

  [types.GET_CONTACT_ACTIVITY_DETAIL](state) {
    Vue.set(state, 'activityDetail', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_CONTACT_ACTIVITY_DETAIL_ERROR](state) {
    Vue.set(state, 'activityDetail', null);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_CONTACT_ACTIVITY_DETAIL](state, data) {
    Vue.set(state, 'activityDetail', data.result.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CONTACT_LEAD_DATA](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactLeadData', false);
    Vue.set(state, 'contactLeadDataError', false);
  },
  [types.GET_CONTACT_LEAD_DATA_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contactLeadData', []);
    Vue.set(state, 'contactLeadDataError', getErrors(error)[0]);
  },
  [types.SET_CONTACT_LEAD_DATA](state, data) {
    Vue.set(state, 'contactLeadData', data.result.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CONTACT_ONLINE_VALUATION_DATA](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'contactOnlineValuationData', false);
    Vue.set(state, 'contactOnlineValuationDataError', false);
  },
  [types.GET_CONTACT_ONLINE_VALUATION_DATA_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contactOnlineValuationData', []);
    Vue.set(state, 'contactOnlineValuationDataError', getErrors(error)[0]);
  },
  [types.SET_CONTACT_ONLINE_VALUATION_DATA](state, data) {
    Vue.set(state, 'contactOnlineValuationData', data.result.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SELECTED_APPLICANT](state) {
    Vue.set(state, 'applicant', false);
  },
  [types.GET_SELECTED_APPLICANT_ERROR](state) {
    Vue.set(state, 'applicant', []);
  },
  [types.SET_SELECTED_APPLICANT](state, data) {
    Vue.set(state, 'applicant', data);
  },

  [types.SET_APPLICANT_QUESTIONS](state, { data, type }) {
    Vue.set(state.applicantQuestions, type, data);
  },

  [types.SET_ACTIVITIES_CONTENT_GROUP](state, data) {
    Vue.set(state, 'activitiesContentGroup', data);
  },
};
