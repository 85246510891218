import Vue from 'vue';
import router from '@/router';
import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';
// eslint-disable-next-line
import vueApp from '../../../main';

export default {
  [types.GET_SENT_CAMPAIGNS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'totalSentCampaigns', 0);
  },
  [types.SET_SENT_CAMPAIGNS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sentCampaigns', data.result.data);
    Vue.set(state, 'totalSentCampaigns', new TotalCountAdapter(data.result).count);
  },
  [types.GET_SENT_CAMPAIGNS_ERROR](state) {
    Vue.set(state, 'sentCampaigns', []);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SENT_CAMPAIGN_DETAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sentCampaignDetail', false);
  },
  [types.SET_SENT_CAMPAIGN_DETAIL](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sentCampaignDetail', data.result.data);
  },
  [types.GET_SENT_CAMPAIGN_DETAIL_ERROR](state, error) {
    vueApp.$root.errorMessage(error.data.message);
    router.push({
      name: 'Newsletters',
    });
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SENT_CAMPAIGN_DETAIL_LIST](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'totalCampaignDetailList', 0);
  },
  [types.SET_SENT_CAMPAIGN_DETAIL_LIST](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sentCampaignDetailListData', data.result.data);
    if (data.result && data.result.meta && data.result.meta.total) {
      Vue.set(state, 'totalCampaignDetailList', data.result.meta.total);
    }
  },
  [types.GET_SENT_CAMPAIGN_DETAIL_LIST_ERROR](state) {
    Vue.set(state, 'sentCampaignDetailListData', []);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_RESET_SENT_CAMPAIGN_DETAIL_LIST](state) {
    Vue.set(state, 'sentCampaignDetailListData', false);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SENT_CAMPAIGN_EMAIL_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sentCampaignEmailTemplate', false);
  },
  [types.SET_SENT_CAMPAIGN_EMAIL_TEMPLATE](state, data) {
    Vue.set(state, 'isLoading', false);
    if (data && data.result && data.result.data && data.result.data.content) {
      Vue.set(state, 'sentCampaignEmailTemplate', data.result.data.content);
    } else {
      Vue.set(state, 'sentCampaignEmailTemplate', null);
    }
  },
  [types.GET_SENT_CAMPAIGN_EMAIL_TEMPLATE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sentCampaignEmailTemplate', null);
  },

  [types.GET_NEXT_MONTH_CAMPAIGN_BLOGS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_NEXT_MONTH_CAMPAIGN_BLOGS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'nextMonthCampaignBlogs', data.result.data);
  },
  [types.GET_NEXT_MONTH_CAMPAIGN_BLOGS_ERROR](state) {
    Vue.set(state, 'nextMonthCampaignBlogs', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_REMOVE_BLOG_FROM_CAMPAIGN](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'removeBlogFromCampaignSuccess', false);
    Vue.set(state, 'removeBlogFromCampaignError', false);
  },
  [types.SET_REMOVE_BLOG_FROM_CAMPAIGN](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'removeBlogFromCampaignSuccess', data.message);
  },
  [types.GET_REMOVE_BLOG_FROM_CAMPAIGN_ERROR](state, error) {
    Vue.set(state, 'removeBlogFromCampaignError', getErrors(error)[0]);
    vueApp.$root.errorMessage(getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SORT_NEWSLETTER_BLOGS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sortNewsletterBlogsSuccess', false);
    Vue.set(state, 'sortNewsletterBlogsError', false);
  },
  [types.SET_SORT_NEWSLETTER_BLOGS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sortNewsletterBlogsSuccess', data.message);
  },
  [types.GET_SORT_NEWSLETTER_BLOGS_ERROR](state, error) {
    Vue.set(state, 'sortNewsletterBlogsError', getErrors(error)[0]);
    vueApp.$root.errorMessage(getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SEND_NEWSLETTER_TEST_EMAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendNewsletterTestEmailSuccess', false);
  },
  [types.SET_SEND_NEWSLETTER_TEST_EMAIL](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sendNewsletterTestEmailSuccess', true);
    vueApp.$root.successMessage(data.message);
  },
  [types.GET_SEND_NEWSLETTER_TEST_EMAIL_ERROR](state, error) {
    vueApp.$root.errorMessage(getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CAMPAIGN_SETTINGS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'campaignSettings', false);
  },
  [types.SET_CAMPAIGN_SETTINGS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'campaignSettings', data.result.data);
  },
  [types.GET_CAMPAIGN_SETTINGS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
};
