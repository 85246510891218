import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestFunnelStats = ({ commit }) => commit(types.GET_FUNNEL_STATS);
export const recieveFunnelStatsSuccess = ({ commit }, data) => commit(types.SET_FUNNEL_STATS, data);
export const recieveFunnelStatsError = ({ commit }, error) => commit(types.GET_FUNNEL_STATS_ERROR, error);
export const fetchFunnelStats = ({ dispatch }, contactProfile) => {
  dispatch('requestFunnelStats');
  Api.get('reports/funnel', contactProfile)
    .then(({ data }) => {
      const newData = { ...data };
      newData.contactProfile = contactProfile;
      dispatch('recieveFunnelStatsSuccess', newData);
    })
    .catch(({ response }) => {
      dispatch('recieveFunnelStatsError', response);
    });
};

export const requestFunnelStatsContacts = ({ commit }) => commit(types.GET_FUNNEL_STATS_CONTACTS);
export const receiveFunnelStatsContactsSuccess = ({ commit }, data) => commit(types.SET_FUNNEL_STATS_CONTACTS, data);
export const receiveFunnelStatsContactsError = ({ commit }, error) => commit(types.GET_FUNNEL_STATS_CONTACTS_ERROR, error);
export const fetchFunnelStatsContacts = ({ dispatch }, payload) => {
  dispatch('requestFunnelStatsContacts');
  Api.get(`/reports/funnel/contacts/${payload.params.type}${payload.request}&profile=${payload.params.profile}`)
    .then(({ data }) => {
      dispatch('receiveFunnelStatsContactsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFunnelStatsContactsError', response);
    });
};

export const requestContentStats = ({ commit }) => commit(types.GET_CONTENT_STATS);
export const recieveContentStatsSuccess = ({ commit }, data) => commit(types.SET_CONTENT_STATS, data);
export const recieveContentStatsError = ({ commit }, error) => commit(types.GET_CONTENT_STATS_ERROR, error);
export const fetchContentStats = ({ dispatch }) => {
  dispatch('requestContentStats');
  Api.get('reports/content_insights')
    .then(({ data }) => {
      dispatch('recieveContentStatsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recieveContentStatsError', response);
    });
};

export const requestTeamStats = ({ commit }) => commit(types.GET_TEAM_STATS);
export const recieveTeamStatsSuccess = ({ commit }, data) => commit(types.SET_TEAM_STATS, data);
export const recieveTeamStatsError = ({ commit }, error) => commit(types.GET_TEAM_STATS_ERROR, error);
export const fetchTeamStats = ({ dispatch }, filter) => {
  dispatch('requestTeamStats');
  Api.get('reports/team_insights', filter)
    .then(({ data }) => {
      dispatch('recieveTeamStatsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recieveTeamStatsError', response);
    });
};

export const requestPropertyStats = ({ commit }) => commit(types.GET_PROPERTY_STATS);
export const recievePropertyStatsSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_STATS, data);
export const recievePropertyStatsError = ({ commit }, error) => commit(types.GET_PROPERTY_STATS_ERROR, error);
export const fetchPropertyStats = ({ dispatch }, filter) => {
  dispatch('requestPropertyStats');
  Api.get('reports/property_insights', filter)
    .then(({ data }) => {
      dispatch('recievePropertyStatsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recievePropertyStatsError', response);
    });
};

export const requestAutopilotStats = ({ commit }) => commit(types.GET_AUTOPILOT_STATS);
export const recieveAutopilotStatsSuccess = ({ commit }, data) => commit(types.SET_AUTOPILOT_STATS, data);
export const recieveAutopilotStatsError = ({ commit }, error) => commit(types.GET_AUTOPILOT_STATS_ERROR, error);
export const fetchAutopilotStats = ({ dispatch }, payload) => {
  dispatch('requestAutopilotStats');
  Api.get('reports/autopilot_insights')
    .then(({ data }) => {
      const newData = { ...data };
      const { parts } = payload;
      newData.parts = parts;
      dispatch('recieveAutopilotStatsSuccess', newData);
    })
    .catch(({ response }) => {
      dispatch('recieveAutopilotStatsError', response);
    });
};
