export default [
  {
    path: '/cdn_shortener',
    meta: {
      title: 'CDN Shortener',
      breadcrumb: [
        { name: 'CDN' },
      ],
    },
    name: 'CdnShortener',
    component: () => import('@/modules/shortener/views/CdnShortener.vue'),
  },
];
