export default {
  isLoading: false,
  error: null,
  success: null,
  mailLists: null,
  drip: null,
  updateDripProcessed: false,
  updateDripProcessedSuccess: false,
  updateDripProcessedError: false,
  totalMailList: null,
  sendSupportMailProcessed: false,
  sendSupportMailProcessedSuccess: false,
  sendSupportMailProcessedError: false,
};
