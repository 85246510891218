import EmailServices from '@/modules/send_mail_and_sms/services/email.services';
import MailTemplatesServices from '@/modules/mail_templates/services/mailTemplates.services';
import * as types from './mutation_types';

export const fetchEmailSenders = async ({ commit, state }) => {
  if (!state.emailSenders || state.emailSenders.length === 0) {
    const data = await new EmailServices().fetchSenders();
    commit(types.SET_EMAIL_SENDERS, data);
  }
};

export const fetchDefaultEmailTemplate = async ({ commit, state }) => {
  if (!state.defaultEmailTemplate) {
    const data = await new MailTemplatesServices().retrieveCompanyDefault();
    commit(types.SET_DEFAULT_EMAIL_TEMPLATE, data);
  }
};
