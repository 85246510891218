// eslint-disable-next-line
import vueApp from '../main';
import router from '@/router';
import logoutService from '@/modules/users/services/logout.service';


const noResponse = () => {
  vueApp.$root.errorMessage('Something went wrong. Please, try again later.');
  $('.life-loader').remove();
};

export const getErrors = (errors) => {
  let messages = [];
  if (errors && errors.data && ([400, 404, 423, 422, 412].some(code => code === errors.data.code))) {
    if (errors && errors.data && errors.data.code === 422) {
      messages = Object.keys(errors.data.errors).map(el => errors.data.errors[el]);
    }
    if (errors.data.result && Object.prototype.hasOwnProperty.call(errors.data.result, 'data') && errors.data.code === 400) {
      messages = errors.data.result.data;
    } else if (errors.data.result) {
      messages = Object.keys(errors.data.result).map(el => errors.data.result[el]);
      messages = messages.map(item => item.join());
    } else {
      messages.push(errors.data.message);
    }
  } else if (errors && errors.data && ([409, 403].some(code => code === errors.data.code))) {
    if (errors.data.message_detail) {
      messages.push(errors.data.message_detail[0]);
    } else {
      messages.push(errors.data.message);
    }
  } else if (errors && errors.data && errors.data.code === 401) {
    messages.push(errors.data.message);
  } else if (errors && errors.data && errors.data.code === 422) {
    messages.push(errors.data.message);
  } else {
    // to control unknown errors.
    messages.push('Something went wrong. Please try again later.');
  }
  return messages;
};

export const controlConnectionProblems = (error) => {
  if (error.response && error.response.data && !error.response.data.status) {
    if (error.response.data.code === 500) {
      if (vueApp.$store.state.Users.user) {
        vueApp.$root.errorMessage('Connection problem. Please try again later.');
        $('.life-loader').remove();
      }
    } else if (error.response.data.code === 401) {
      /**
       * clear local storage and redirect to auth page, when api request returns 401.
       */
      if (!router || !router.history || !router.history.current || (router.history.current.name !== 'TheForgotPassword' && router.history.current.name !== 'TheResetPassword' && router.history.current.path !== '/auth')) {
        logoutService();
      }
    }
  } else if (!error.response) {
    noResponse();
  }
};
