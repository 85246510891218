import Vue from 'vue';
// eslint-disable-next-line
import { getErrors } from '@/mixins/ErrorManager';
import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';
import * as types from './mutation_types';


export default {
  [types.GET_ARTICLES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_ARTICLES](state, data) {
    Vue.set(state, 'totalBlogs', new TotalCountAdapter(data.result).count);
    Vue.set(state, 'articles', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ARTICLES_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message, type: 'is-danger', position: 'is-bottom-right', duration: 10000,
    });
    Vue.set(state, 'articles', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BLOG](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.RESET_BLOG](state) {
    Vue.set(state, 'getBlogSuccess', null);
  },
  [types.GET_BLOG_SUCCESS](state, data) {
    Vue.set(state, 'getBlogSuccess', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BLOG_ERROR](state, error) {
    Vue.set(state, 'getBlogError', error);
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_BLOG](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createBlogError', null);
    Vue.set(state, 'createBlogSuccess', null);
  },
  [types.CREATE_BLOG_SUCCESS](state, data) {
    Vue.set(state, 'createBlogSuccess', data.result);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_BLOG_ERROR](state, error) {
    Vue.set(state, 'createBlogError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.DELETE_BLOG](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteBlogError', null);
  },
  [types.DELETE_BLOG_SUCCESS](state, data) {
    Vue.set(state, 'deleteBlogSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_BLOG_ERROR](state, error) {
    Vue.set(state, 'deleteBlogError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.UPDATE_BLOG](state) {
    Vue.set(state, 'updateBlogSuccess', null);
    Vue.set(state, 'updateBlogError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.UPDATE_BLOG_SUCCESS](state, data) {
    Vue.set(state, 'updateBlogSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_BLOG_ERROR](state, error) {
    Vue.set(state, 'updateBlogError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.UPDATE_BLOG_ACTIVE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.UPDATE_BLOG_ACTIVE_SUCCESS](state, data) {
    Vue.set(state, 'updateBlogActive', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_BLOG_ACTIVE_ERROR](state, error) {
    Vue.set(state, 'updateBlogActiveError', error.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_ARTICLE_CATEGORIES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_ARTICLE_CATEGORIES](state, data) {
    Vue.set(state, 'articleCategories', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ARTICLE_CATEGORIES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SHARE_BLOG_ON_SOCIAL_MEDIAS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'shareOnSocialMediaSuccess', false);
    Vue.set(state, 'shareOnSocialMediaError', false);
  },
  [types.SET_SHARE_BLOG_ON_SOCIAL_MEDIAS](state, data) {
    Vue.set(state, 'shareOnSocialMediaSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SHARE_BLOG_ON_SOCIAL_MEDIAS_ERROR](state, error) {
    Vue.set(state, 'shareOnSocialMediaError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BLOG_WAITING_SHARES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'blogWaitingShares', false);
  },
  [types.SET_BLOG_WAITING_SHARES](state, data) {
    Vue.set(state, 'blogWaitingShares', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BLOG_WAITING_SHARES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SOCIAL_MEDIA_SHARE_INFO](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'blogSharedInfo', null);
  },
  [types.SET_SOCIAL_MEDIA_SHARE_INFO](state, data) {
    Vue.set(state, 'blogSharedInfo', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_SHARE_INFO_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'blogSharedInfo', null);
  },

  [types.GET_ARTICLES_MINIMAL](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_ARTICLES_MINIMAL](state, data) {
    Vue.set(state, 'articlesMinimal', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ARTICLES_MINIMAL_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message, type: 'is-danger', position: 'is-bottom-right', duration: 10000,
    });
    Vue.set(state, 'articles', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_SOCIAL_MEDIA_ALL_POSTS](state, data) {
    Vue.set(state, 'socialMediaAllPosts', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_ALL_POSTS_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message, type: 'is-danger', position: 'is-bottom-right', duration: 10000,
    });
    Vue.set(state, 'isLoading', false);
  },
};
