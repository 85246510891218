import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestPropertyTypes = ({ commit }) => commit(types.GET_PROPERTY_TYPES);
export const receivePropertyTypesSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_TYPES, data);
export const receivePropertyTypesError = ({ commit }, error) => commit(types.GET_PROPERTY_TYPES_ERROR, error);
export const fetchPropertyTypes = ({ dispatch }) => {
  dispatch('requestPropertyTypes');
  Api.get('property_types?__limit=250')
    .then(({ data }) => { dispatch('receivePropertyTypesSuccess', data); })
    .catch(({ response }) => { dispatch('receivePropertyTypesError', response); });
};
