export default [
  {
    path: '/tasks',
    meta: {
      title: 'Taskforce',
      breadcrumb: [
        { name: 'Taskforce' },
      ],
    },
    name: 'Tasks',
    component: () => import('@/modules/tasks/views/Tasks.vue'),
  },
];
