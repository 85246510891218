import ApiService from '@/core/api/api.service';

export default class CalendarEventSearchServices extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async searchEvents(searchByKeyword) {
    this.url = `event/calendar/query?searchByKeyword=${searchByKeyword}`;
    const { result: { data } } = await this.get();
    return data;
  }
}
