import ContractTemplatesServices from '@/modules/e_signatures/services/contractTemplates.services';
import ContractsServices from '@/modules/e_signatures/services/contracts.services';
import ContractsTagsServices from '@/modules/e_signatures/services/contractsTags.services';


export const setContractTemplates = ({ commit }, data) => commit('SET_CONTRACT_TEMPLATES', data);


export const fetchContractTemplates = async ({ dispatch }, payload) => {
  try {
    const data = await new ContractTemplatesServices().list(payload.request);
    await dispatch('setContractTemplates', data);
  } catch (response) {
    await dispatch('setContractTemplates', { data: [], meta: { total: 0 } });
  }
};

export const fetchContractSettings = async ({ commit }) => {
  const data = await new ContractsServices().fetchSettings();
  commit('SET_CONTRACT_SETTINGS', data);
};

export const setContract = ({ commit }, data) => {
  commit('SET_CONTRACT', data);
};

export const fetchContractTags = async ({ commit }) => {
  try {
    const data = await new ContractsTagsServices().fetch();
    await commit('SET_CONTRACT_TAGS', data);
  } catch (e) {
    await commit('SET_CONTRACT_TAGS', []);
  }
};
