import Api from '@/libraries/api';
import * as types from './mutation_types';

export const resetAddress = ({ commit }) => {
  commit(types.RESET_ADDRESS);
};

export const requestContactAddress = ({ commit }) => {
  commit(types.GET_CONTACT_ADDRESS);
};
export const receiveContactAddressError = ({ commit }, error) => {
  commit(types.GET_CONTACT_ADDRESS_ERROR, error);
};
export const receiveContactAddressSuccess = ({ commit }, data) => {
  commit(types.SET_CONTACT_ADDRESS, data);
};
export const fetchContactAddress = ({ dispatch }, payload) => {
  dispatch('requestContactAddress');
  Api.autocompletePostCode(payload)
    .then(({ data }) => {
      dispatch('receiveContactAddressSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactAddressError', response);
    });
};

export const requestContactAddressDetails = ({ commit }) => {
  commit(types.GET_CONTACT_ADDRESS_DETAILS);
};
export const receiveContactAddressDetailsError = ({ commit }, error) => {
  commit(types.GET_CONTACT_ADDRESS_DETAILS_ERROR, error);
};
export const receiveContactAddressDetailsSuccess = ({ commit }, data) => {
  commit(types.SET_CONTACT_ADDRESS_DETAILS, data);
};
export const fetchContactAddressDetails = ({ dispatch }, payload) => {
  dispatch('requestContactAddressDetails');
  Api.findAddressFromPostCode(payload)
    .then(({ data }) => {
      dispatch('receiveContactAddressDetailsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContactAddressDetailsError', response);
    });
};

export const requestPropertyAddress = ({ commit }) => {
  commit(types.GET_PROPERTY_ADDRESS);
};
export const receivePropertyAddressError = ({ commit }, error) => {
  commit(types.GET_PROPERTY_ADDRESS_ERROR, error);
};
export const receivePropertyAddressSuccess = ({ commit }, data) => {
  commit(types.SET_PROPERTY_ADDRESS, data);
};
export const fetchPropertyAddress = ({ dispatch }, payload) => {
  dispatch('requestPropertyAddress');
  Api.autocompletePostCode(payload)
    .then(({ data }) => {
      dispatch('receivePropertyAddressSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyAddressError', response);
    });
};

export const requestPropertyAddressDetails = ({ commit }) => {
  commit(types.GET_PROPERTY_ADDRESS_DETAILS);
};
export const receivePropertyAddressDetailsError = ({ commit }, error) => {
  commit(types.GET_PROPERTY_ADDRESS_DETAILS_ERROR, error);
};
export const receivePropertyAddressDetailsSuccess = ({ commit }, data) => {
  commit(types.SET_PROPERTY_ADDRESS_DETAILS, data);
};
export const fetchPropertyAddressDetails = ({ dispatch }, payload) => {
  dispatch('requestPropertyAddressDetails');
  Api.findAddressFromPostCode(payload)
    .then(({ data }) => {
      dispatch('receivePropertyAddressDetailsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyAddressDetailsError', response);
    });
};
export const setSelectedPropertyAddress = ({ commit }, data) => {
  commit(types.SET_SELECTED_PROPERTY_ADDRESS, data);
};

export const requestEventAddress = ({ commit }) => {
  commit(types.GET_EVENT_ADDRESS);
};
export const receiveEventAddressError = ({ commit }, error) => {
  commit(types.GET_EVENT_ADDRESS_ERROR, error);
};
export const receiveEventAddressSuccess = ({ commit }, data) => {
  commit(types.SET_EVENT_ADDRESS, data);
};
export const fetchEventAddress = ({ dispatch }, payload) => {
  dispatch('requestEventAddress');
  Api.autocompletePostCode(payload)
    .then(({ data }) => {
      dispatch('receiveEventAddressSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveEventAddressError', response);
    });
};

export const requestEventAddressDetails = ({ commit }) => {
  commit(types.GET_EVENT_ADDRESS_DETAILS);
};
export const receiveEventAddressDetailsError = ({ commit }, error) => {
  commit(types.GET_EVENT_ADDRESS_DETAILS_ERROR, error);
};
export const receiveEventAddressDetailsSuccess = ({ commit }, data) => {
  commit(types.SET_EVENT_ADDRESS_DETAILS, data);
};
export const fetchEventAddressDetails = ({ dispatch }, payload) => {
  dispatch('requestEventAddressDetails');
  Api.findAddressFromPostCode(payload)
    .then(({ data }) => {
      dispatch('receiveEventAddressDetailsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveEventAddressDetailsError', response);
    });
};
export const setSelectedEventAddress = ({ commit }, data) => {
  commit(types.SET_SELECTED_EVENT_ADDRESS, data);
};


export const requestAddresses = ({ commit }) => {
  commit(types.GET_ADDRESSES);
};
export const receiveAddressesError = ({ commit }, error) => {
  commit(types.GET_ADDRESSES_ERROR, error);
};
export const receiveAddressesSuccess = ({ commit }, data) => {
  commit(types.SET_ADDRESSES, data);
};
export const fetchAddresses = ({ dispatch }, payload) => {
  dispatch('requestAddresses');
  Api.autocompletePostCode(payload)
    .then(({ data }) => {
      if (data.status === true) {
        dispatch('receiveAddressesSuccess', { data, type: payload.type });
      } else {
        dispatch('receiveAddressesError', { data, type: payload.type });
      }
    })
    .catch(({ response }) => {
      dispatch('receiveAddressesError', { response, type: payload.type });
    });
};

export const resetAddresses = ({ commit }, data) => {
  commit(types.RESET_ADDRESSES, data);
};


export const requestAddressDetails = ({ commit }) => {
  commit(types.GET_ADDRESS_DETAILS);
};
export const receiveAddressDetailsError = ({ commit }, error) => {
  commit(types.GET_ADDRESS_DETAILS_ERROR, error);
};
export const receiveAddressDetailsSuccess = ({ commit }, data) => {
  commit(types.SET_ADDRESS_DETAILS, data);
};
export const fetchAddressDetails = ({ dispatch }, payload) => {
  dispatch('requestAddressDetails');
  Api.findAddressFromPostCode(payload.postcode)
    .then(({ data }) => {
      dispatch('receiveAddressDetailsSuccess', { data, type: payload.type });
    })
    .catch(({ response }) => {
      dispatch('receiveAddressDetailsError', { response, type: payload.type });
    });
};
