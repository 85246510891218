import { UzairServices } from '@/modules/uzair/services/uzair.services';
import { hideModal, runModal } from '@/core/utils/helper';
import { parseLocalSItem, setLocalSItem } from '@/core/providers/local_storage/localStorage.provider';
import * as types from './mutation_types';

export const getTalks = async ({ commit, state }, {
  refresh = false,
  params = {},
}) => {
  if (!state.creditBalance || refresh) {
    const { data, meta } = await new UzairServices().getTalks(params);
    commit(types.SET_CREDIT_BALANCE, meta.credit_balance);
    commit(types.SET_TALKS, data);
    commit(types.SET_TALKS_META, meta);
  } else if (params.__offset) {
    if (state.talksMeta.is_more) {
      const { data, meta } = await new UzairServices().getTalks(params);
      commit(types.SET_CREDIT_BALANCE, meta.credit_balance);
      commit(types.LOAD_MORE_TALKS, data);
      commit(types.SET_TALKS_META, meta);
    }
  }
};

export const deleteTalk = async ({ commit }, id) => {
  await new UzairServices().deleteTalk(id);
  commit(types.DELETE_TALK, id);
};


export const getTalk = async ({ commit }, id) => {
  const talk = await new UzairServices().getTalk(id);
  commit(types.SET_ACTIVE_TALK, talk);
};

export const setActiveTalkId = ({ commit }, id) => commit(types.SET_ACTIVE_TALK_ID, id);

export const updateTalk = async ({ commit }, {
  id, payload,
}) => {
  const talk = await new UzairServices().updateTalk(id, payload);
  commit(types.UPDATE_TALK, talk);
};

export const getContentSuggestions = (context, params = {}) => new UzairServices().getContentSuggestions(params);

export const buyCredit = async (context, payload = {}) => {
  const newBalances = await new UzairServices().buyCredit(payload);
  if (newBalances && newBalances.length > 0) {
    const currentUserNewBalance = newBalances.find(item => item.branch_id === context.rootGetters['Users/me'].branch_id);
    if (currentUserNewBalance) {
      context.commit(types.SET_CREDIT_BALANCE, {
        ...context.state.creditBalance,
        ...currentUserNewBalance,
      });
    }
  }
};

/**
 * @typedef UzairPopupConfig
 * @type object
 * @property inital_operation {'propertyDescription'|'vendorFeedback'|'content'}
 * @property trigger {'magicButton'|'icon'}
 */

/**
 * @param commit
 * @param state
 * @param nextStatus {'open'|'close'|'hardClose'}
 * @param config {UzairPopupConfig}
 */
export const toggleUzairPopup = ({ commit, state }, { nextStatus, config }) => {
  const id = `#${state.uzairPopup.selector}`;
  if (nextStatus === 'open') {
    commit(types.OPEN_UZAIR, config || {});
    runModal(id);
  } else if (nextStatus === 'close') {
    hideModal(id);
  } else if (nextStatus === 'hardClose') {
    commit(types.HARD_CLOSE_UZAIR);
    hideModal(id);
  } else if (nextStatus === 'minimize') {
    commit(types.MINIMIZE_UZAIR);
  }
};

export const clearUzairPopupConfig = ({ commit }) => {
  commit(types.CLEAR_UZAIR_POPUP_CONFIG);
};

export const addResponseToTransferMemory = ({ commit }, transferResults) => {
  commit(types.ADD_RESPONSE_TRANSFER_MEMORY, transferResults);
};

/**
 *
 */
export const toggleUzairIcon = ({ commit }, { nextStatus }) => {
  setLocalSItem('isShowUzair', 'boolean', nextStatus);
  commit(types.TOGGLE_UZAIR_ICON, { nextStatus });
};

export const initUzairIconState = ({ dispatch }) => {
  const isShowUzair = parseLocalSItem('isShowUzair', 'boolean');

  dispatch('toggleUzairIcon', { nextStatus: isShowUzair === false ? isShowUzair : true });
};

export const startSetupProcess = async ({ dispatch }, payload) => {
  await new UzairServices().startSetupProcess(payload);
  await dispatch('Common/fetchConfigs', {}, { root: true });
};

export const launchUzair = async ({ dispatch }, payload) => {
  await new UzairServices().launchUzair(payload);
  await dispatch('Common/fetchConfigs', {}, { root: true });
};
