export const GET_MAIL_TEMPLATES = 'GET_MAIL_TEMPLATES';
export const SET_MAIL_TEMPLATES = 'SET_MAIL_TEMPLATES';
export const GET_MAIL_TEMPLATES_ERROR = 'GET_MAIL_TEMPLATES_ERROR';

export const GET_E_MAIL_TEMPLATES = 'GET_E_MAIL_TEMPLATES';
export const SET_E_MAIL_TEMPLATES = 'SET_E_MAIL_TEMPLATES';

export const GET_COMPANYS_DEFAULT_MAIL_TEMPLATE = 'GET_COMPANYS_DEFAULT_MAIL_TEMPLATE';
export const SET_COMPANYS_DEFAULT_MAIL_TEMPLATE = 'SET_COMPANYS_DEFAULT_MAIL_TEMPLATE';
export const GET_COMPANYS_DEFAULT_MAIL_TEMPLATE_ERROR = 'GET_COMPANYS_DEFAULT_MAIL_TEMPLATE_ERROR';

export const GET_PREVIEW_TEMPLATE = 'GET_PREVIEW_TEMPLATE';
export const SET_PREVIEW_TEMPLATE = 'SET_PREVIEW_TEMPLATE';
export const GET_PREVIEW_TEMPLATE_ERROR = 'GET_PREVIEW_TEMPLATE_ERROR';

export const DELETE_MAIL_TEMPLATE = 'DELETE_MAIL_TEMPLATE';
export const DELETE_MAIL_TEMPLATE_SUCCESS = 'DELETE_MAIL_TEMPLATE_SUCCESS';
export const DELETE_MAIL_TEMPLATE_ERROR = 'DELETE_MAIL_TEMPLATE_ERROR';

export const CLONE_MAIL_TEMPLATE = 'CLONE_MAIL_TEMPLATE';
export const CLONE_MAIL_TEMPLATE_SUCCESS = 'CLONE_MAIL_TEMPLATE_SUCCESS';
export const CLONE_MAIL_TEMPLATE_ERROR = 'CLONE_MAIL_TEMPLATE_ERROR';

export const CREATE_MAIL_TEMPLATE = 'CREATE_MAIL_TEMPLATE';
export const CREATE_MAIL_TEMPLATE_SUCCESS = 'CREATE_MAIL_TEMPLATE_SUCCESS';
export const CREATE_MAIL_TEMPLATE_ERROR = 'CREATE_MAIL_TEMPLATE_ERROR';

export const GET_MAIL_TEMPLATE = 'GET_MAIL_TEMPLATE';
export const GET_MAIL_TEMPLATE_SUCCESS = 'GET_MAIL_TEMPLATE_SUCCESS';
export const GET_MAIL_TEMPLATE_ERROR = 'GET_MAIL_TEMPLATE_ERROR';

export const UPDATE_MAIL_TEMPLATE = 'UPDATE_MAIL_TEMPLATE';
export const UPDATE_MAIL_TEMPLATE_SUCCESS = 'UPDATE_MAIL_TEMPLATE_SUCCESS';
export const UPDATE_MAIL_TEMPLATE_ERROR = 'UPDATE_MAIL_TEMPLATE_ERROR';

export const GET_NEW_EDIT_SESSION = 'GET_NEW_EDIT_SESSION';
export const SET_NEW_EDIT_SESSION_SUCCESS = 'SET_NEW_EDIT_SESSION_SUCCESS';
export const GET_NEW_EDIT_SESSION_ERROR = 'GET_NEW_EDIT_SESSION_ERROR';
