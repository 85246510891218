export default [{
  name: 'FollowUps',
  path: '/calendar/events/follow_ups',
  meta: {
    title: 'Follow Ups',
    breadcrumb: [
      { name: 'Follow Ups' },
    ],
  },
  component: () => import('../views/FollowUps.vue'),
}];
