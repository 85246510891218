export default {
  methods: {
    inputValidatorControl(data) {
      // Only we have a one 'false'
      const status = Object.keys(data).some((key) => {
        if (key === 'validator') {
          /*
          * validator data control should be active when clicked action button.
          * So, we do it false, when action button is triggered.
          * In other cases, It may be null or ''
           */
          return data[key] !== false;
        }
        return !!data[key];
      });
      return !status ? 'lsinput-false' : '';
    },
    validator(fields, validator, message, condition) {
      let result = null;
      if (condition === 'or') {
        fields.forEach((item) => {
          if (result !== true) {
            result = !!(item && item !== '' && item !== ' ');
          }
        });
      } else if (condition === 'and') {
        fields.forEach((item) => {
          result = !!(item && item !== '' && item !== ' ');
        });
      } else if (condition === 'hugeAnd') {
        result = fields.every(x => this.checkValue(x));
      } else if (condition === 'emailRegex') {
        fields.forEach((item) => {
          const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
          result = pattern.test(item);
        });
      }

      this.ui.validator[`${validator}.message`] = result === true ? null : message;
      this.ui.validator[`${validator}.value`] = result;

      if (!result) {
        this.ui.validator.message = result === true ? null : message;
      }

      return result;
    },
  },
};
