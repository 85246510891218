import {
  TWITTER, LINKEDIN, FACEBOOK, INSTAGRAM,
} from '../../settings/constants/socialMedia.constants';

export const getBlogSuccess = state => state.getBlogSuccess;
export const getBlogError = state => state.getBlogError;
export const createBlogSuccess = state => state.createBlogSuccess;
export const createBlogError = state => state.createBlogError;
export const updateBlogSuccess = state => state.updateBlogSuccess;
export const updateBlogError = state => state.updateBlogError;
export const deleteBlogSuccess = state => state.deleteBlogSuccess;
export const deleteBlogError = state => state.deleteBlogError;
export const totalBlogs = state => state.totalBlogs;
export const updateBlogActive = state => state.updateBlogActive;
export const updateBlogActiveError = state => state.updateBlogActiveError;
export const isLoading = state => state.isLoading;

export const socialMediaAllPosts = (state) => {
  const socialMediaPosts = state.socialMediaAllPosts.filter(o => o.shared_social_media_content_type_id === 1);
  const postsList = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const post of socialMediaPosts) {
    if (!postsList[post.related_id]) postsList[post.related_id] = [];
    postsList[post.related_id].push(post);
  }

  return postsList;
};

export const getArticleSocialMediaPosts = (state, getters) => relatedId => (getters.socialMediaAllPosts[relatedId] ? getters.socialMediaAllPosts[relatedId] : []);

export const getSocialMediaArticlesCount = (state, getters) => (relatedId) => {
  const posts = getters.getArticleSocialMediaPosts(relatedId);

  const socialNames = {
    [TWITTER]: 'x',
    [LINKEDIN]: 'linkedin',
    [FACEBOOK]: 'facebook',
    [INSTAGRAM]: 'instagram',
  };
  const articlesCount = {};

  articlesCount.total_shares = {};
  // set property per social media
  articlesCount.twitter = [];
  articlesCount.linkedin = [];
  articlesCount.facebook = [];
  articlesCount.instagram = [];

  if (!posts.length) return articlesCount;

  // set total shares
  Object.keys(socialNames).forEach((socialId) => {
    articlesCount.total_shares[socialNames[socialId]] = {
      total: posts.filter(post => post.social_media_id === Number(socialId)).length,
    };
  });

  // set social shares
  posts.forEach((post) => {
    const socialId = post.social_media_id;

    articlesCount[socialNames[socialId]].push({
      share_status: post.share_status,
      total: posts.filter(p => p.social_media_id === Number(socialId)).length,
    });
  });

  return articlesCount;
};
