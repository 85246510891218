export default {
  isLoading: false,
  error: null,
  csvImportFields: null,
  mailLists: null,
  csvList: null,
  sendCsvSuccess: null,
  sendCsvError: null,
  importCsvSuccess: null,
  importCsvError: null,
  csvImportFieldsError: null,
};
