import Vue from 'vue';
import * as types from './mutation_types';


export default {
  [types.SET_ACTION](state, data) {
    Vue.set(state.request, 'action', data);
  },
  [types.SET_LIMIT](state, data) {
    Vue.set(state.payload, 'limit', data);
  },
  [types.SET_SEARCH](state, data) {
    Vue.set(state.payload, 'search', data);
  },
  [types.SET_SEARCH_TYPE](state, data) {
    Vue.set(state.payload, 'searchType', data);
  },
  [types.SET_SORT](state, data) {
    Vue.set(state.payload, 'sort', data);
  },
  [types.SET_STATIC_FILTER](state, data) {
    Vue.set(state.payload, 'staticFilter', data);
  },
  [types.SET_DYNAMIC_FILTER](state, data) {
    Vue.set(state.payload, 'dynamicFilter', data);
  },

  [types.SET_TOTAL](state, data) {
    Vue.set(state.pagination, 'total', data);
  },
  [types.SET_PER_PAGE](state, data) {
    Vue.set(state.pagination, 'perPage', data);
  },
  [types.SET_TOTAL_PAGES](state, data) {
    Vue.set(state.pagination, 'totalPages', data);
  },
  [types.SET_ACTIVE_PAGE](state, data) {
    Vue.set(state.pagination, 'activePage', data);
  },

  [types.SET_SORT_SEQUENCE](state, data) {
    Vue.set(state.sort, 'sequence', data);
  },
  [types.SET_SORT_FIELD](state, data) {
    Vue.set(state.sort, 'field', data);
  },
  [types.SET_SORT_TITLE](state, data) {
    Vue.set(state.sort, 'title', data);
  },

  [types.SET_LOADER](state, data) {
    Vue.set(state, 'centralLoader', data);
  },
  [types.MANAGE_REQUEST](state, { request, status }) {
    if (status) {
      state.runningRequests.push(request);
    } else {
      const index = state.runningRequests.findIndex(r => r.unique_id === request.unique_id);
      state.runningRequests.splice(index, 1);
    }
  },
};
