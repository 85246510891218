import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestSalutations = ({ commit }) => commit(types.GET_SALUTATIONS);
export const receiveSalutationsSuccess = ({ commit }, data) => commit(types.SET_SALUTATIONS, data);
export const receiveSalutationsError = ({ commit }, error) => commit(types.GET_SALUTATIONS_ERROR, error);
export const fetchSalutations = ({ dispatch }) => {
  dispatch('requestSalutations');
  return Api.get('salutations?__limit=250')
    .then(({ data }) => { dispatch('receiveSalutationsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSalutationsError', response); });
};
