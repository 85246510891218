import MosEmailSettingsServices from '@/modules/offers/services/mosEmailSettings.services';
import OffersServices from '@/modules/offers/services/offers.services';
import * as types from './mutation_types';

// eslint-disable-next-line import/prefer-default-export
export const fetchMosEmailSettings = async ({ commit, state }) => {
  if (!state.mosEmailSettings) {
    const data = await new MosEmailSettingsServices().fetch();
    commit(types.SET_MOS_EMAIL_SETTINGS, data);
  }
};

export const fetchChainData = async ({ commit }, payload) => {
  const { data } = await new OffersServices().getOfferChain(payload.offerId);
  commit(types.SET_CHAIN_DATA, data);
  return data;
};

export const fetchOfferData = async ({ commit }, payload) => {
  const { data } = await new OffersServices().getNewOffer(payload);
  commit(types.SET_OFFER_DATA, data);
  return data;
};

export const fetchOfferDataWithOfferId = async ({ commit }, payload) => {
  const { data } = await new OffersServices().getNewOfferFromWithOfferId(payload);
  commit(types.SET_OFFER_DATA, data);
  return data;
};

export const resetOfferData = ({ commit }) => commit(types.RESET_OFFER_DATA);
