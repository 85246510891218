import Api from '@/libraries/api';
import * as types from './mutation_types';
import { getErrors } from '../../../mixins/ErrorManager';

export const requestBait = ({ commit }) => commit(types.GET_BAIT);
export const receiveBaitSuccess = ({ commit }, data) => commit(types.SET_BAIT, data);
export const receiveBaitError = ({ commit }, error) => commit(types.GET_BAIT_ERROR, error);
export const fetchBait = ({ dispatch }, baitId) => {
  dispatch('requestBait');
  Api.get(`bt/baits/${baitId}`)
    .then(({ data }) => {
      dispatch('receiveBaitSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBaitError', response);
    });
};

export const requestLengthOfAgreements = ({ commit }) => commit(types.GET_LENGTH_OF_AGREEMENTS);
export const receiveLengthOfAgreementsSuccess = ({ commit }, data) => commit(types.SET_LENGTH_OF_AGREEMENTS, data);
export const receiveLengthOfAgreementsError = ({ commit }, error) => commit(types.GET_LENGTH_OF_AGREEMENTS_ERROR, error);
export const resetReceiveLengthOfAgreements = ({ commit }) => commit(types.RESET_LENGTH_OF_AGREEMENTS);
export const fetchLengthOfAgreements = ({ dispatch }, baitId) => {
  dispatch('requestLengthOfAgreements');
  Api.get(`bt/length_of_agreements/${baitId}`)
    .then(({ data }) => {
      dispatch('receiveLengthOfAgreementsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveLengthOfAgreementsError', response);
    });
};

export const requestCreateLengthOfAgreement = ({ commit }) => commit(types.UPDATE_LENGTH_OF_AGREEMENT);
export const receiveCreateLengthOfAgreementSuccess = ({ commit }, data) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_SUCCESS, data);
export const receiveCreateLengthOfAgreementError = ({ commit }, error) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_ERROR, error);
export const createLengthOfAgreement = ({ dispatch }, lengthOfAgreementData) => {
  dispatch('requestCreateLengthOfAgreement');
  Api.post('bt/length_of_agreements', lengthOfAgreementData)
    .then(({ data }) => {
      dispatch('receiveCreateLengthOfAgreementSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateLengthOfAgreementError', response);
    });
};

export const requestUpdateLengthOfAgreement = ({ commit }) => commit(types.UPDATE_LENGTH_OF_AGREEMENT);
export const receiveUpdateLengthOfAgreementSuccess = ({ commit }, data) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_SUCCESS, data);
export const receiveUpdateLengthOfAgreementError = ({ commit }, error) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_ERROR, error);
export const updateLengthOfAgreement = ({ dispatch }, lengthOfAgreementData) => {
  dispatch('requestUpdateLengthOfAgreement');
  Api.patch(`bt/length_of_agreements/${lengthOfAgreementData.bait_id}`, lengthOfAgreementData)
    .then(({ data }) => {
      dispatch('receiveUpdateLengthOfAgreementSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateLengthOfAgreementError', response);
    });
};

export const requestDefaultSettings = ({ commit }) => commit(types.GET_BAIT_DEFAULT_SETTINGS);
export const receiveDefaultSettingsSuccess = ({ commit }, data) => commit(types.SET_BAIT_DEFAULT_SETTINGS, data);
export const receiveDefaultSettingsError = ({ commit }, data) => commit(types.GET_BAIT_DEFAULT_SETTINGS_ERROR, data);
export const fetchDefaultSettings = ({ dispatch }, filters = []) => {
  dispatch('requestDefaultSettings');
  return Api.get(`bt/default_settings?${filters.join('&')}`).then(({ data }) => {
    dispatch('receiveDefaultSettingsSuccess', data);
  });
};

export const resetDefaultSettings = ({ commit }) => commit(types.RESET_BAIT_DEFAULT_SETTINGS);

export const requestFormAnswers = ({ commit }) => commit(types.GET_BAIT_FORM_ANSWERS);
export const receiveFormAnswersSuccess = ({ commit }, data) => commit(types.SET_BAIT_FORM_ANSWERS, data);
export const resetReceiveFormAnswers = ({ commit }) => commit(types.RESET_BAIT_FORM_ANSWERS);
export const fetchFormAnswers = ({ dispatch }, baitId) => {
  dispatch('requestFormAnswers');
  return Api.get(`bt/question/${baitId}?__limit=250`).then(({ data }) => {
    dispatch('receiveFormAnswersSuccess', data);
  });
};
export const requestFees = ({ commit }) => commit(types.GET_FEES);
export const receiveFeesSuccess = ({ commit }, data) => commit(types.SET_FEES, data);
export const receiveFeesError = ({ commit }, error) => commit(types.GET_FEES_ERROR, error);
export const resetReceiveFees = ({ commit }) => commit(types.RESET_FEES);
export const fetchFees = ({ dispatch }, baitId) => {
  dispatch('requestFees');
  Api.get(`bt/fees?bait_id=${baitId}&__order_by=created_at`)
    .then(({ data }) => {
      dispatch('receiveFeesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFeesError', response);
    });
};

export const requestCreateFee = ({ commit }) => commit(types.UPDATE_FEE);
export const receiveCreateFeeSuccess = ({ commit }, data) => commit(types.UPDATE_FEE_SUCCESS, data);
export const receiveCreateFeeError = ({ commit }, error) => commit(types.UPDATE_FEE_ERROR, error);
export const createFee = ({ dispatch }, feeData) => {
  dispatch('requestCreateFee');
  Api.post('bt/fees', feeData)
    .then(({ data }) => {
      dispatch('receiveCreateFeeSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateFeeError', response);
    });
};

export const requestUpdateFee = ({ commit }) => commit(types.UPDATE_FEE);
export const receiveUpdateFeeSuccess = ({ commit }, data) => commit(types.UPDATE_FEE_SUCCESS, data);
export const receiveUpdateFeeError = ({ commit }, error) => commit(types.UPDATE_FEE_ERROR, error);
export const updateFee = ({ dispatch }, feeData) => {
  dispatch('requestUpdateFee');
  Api.patch(`bt/fees/${feeData.fee_id}`, feeData)
    .then(({ data }) => {
      dispatch('receiveUpdateFeeSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateFeeError', response);
    });
};

export const requestSaveFees = ({ commit }) => commit(types.SAVE_FEES);
export const receiveSaveFeesSuccess = ({ commit }, data) => commit(types.SAVE_FEES_SUCCESS, data);
export const receiveSaveFeesError = ({ commit }, error) => commit(types.SAVE_FEES_ERROR, error);
export const saveAllFees = ({ dispatch }, feesData) => {
  dispatch('requestSaveFees');
  Api.post('bt/fees/bulk', { fees: feesData })
    .then(({ data }) => {
      dispatch('receiveSaveFeesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSaveFeesError', response);
    });
};

export const requestDeleteFee = ({ commit }) => commit(types.DELETE_FEE);
export const receiveDeleteFeeSuccess = ({ commit }, data) => commit(types.DELETE_FEE_SUCCESS, data);
export const receiveDeleteFeeError = ({ commit }, error) => commit(types.DELETE_FEE_ERROR, error);
export const deleteFee = ({ dispatch }, payload) => {
  dispatch('requestDeleteFee');
  Api.delete(`bt/fees/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteFeeSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteFeeError', response);
    });
};

export const requestUpdateBait = ({ commit }) => commit(types.UPDATE_BAIT);
export const receiveUpdateBaitSuccess = ({ commit }, data) => commit(types.UPDATE_BAIT_SUCCESS, data);
export const receiveUpdateBaitError = ({ commit }, error) => commit(types.UPDATE_BAIT_ERROR, error);
export const updateBait = ({ dispatch }, baitData) => {
  dispatch('requestUpdateBait');
  Api.patch(`bt/baits/${baitData.bait_id}`, baitData)
    .then(({ data }) => {
      dispatch('receiveUpdateBaitSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateBaitError', response);
    });
};


export const requestMapCoordinates = ({ commit }) => commit(types.GET_LENGTH_OF_AGREEMENTS);
export const receiveMapCoordinatesSuccess = ({ commit }, data) => commit(types.SET_LENGTH_OF_AGREEMENTS, data);
export const receiveMapCoordinatesError = ({ commit }, error) => commit(types.GET_LENGTH_OF_AGREEMENTS_ERROR, error);
export const fetchMapCoordinates = ({ dispatch }, baitId) => {
  dispatch('requestMapCoordinates');
  Api.get(`bt/map_coordinates/${baitId}`)
    .then(({ data }) => {
      dispatch('receiveMapCoordinatesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveMapCoordinatesError', response);
    });
};

export const requestCreateMapCoordinate = ({ commit }) => commit(types.UPDATE_LENGTH_OF_AGREEMENT);
export const receiveCreateMapCoordinateSuccess = ({ commit }, data) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_SUCCESS, data);
export const receiveCreateMapCoordinateError = ({ commit }, error) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_ERROR, error);
export const createMapCoordinate = ({ dispatch }, lengthOfAgreementData) => {
  dispatch('requestCreateMapCoordinate');
  Api.post('bt/map_coordinates', lengthOfAgreementData)
    .then(({ data }) => {
      dispatch('receiveCreateMapCoordinateSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateMapCoordinateError', response);
    });
};

export const requestUpdateMapCoordinate = ({ commit }) => commit(types.UPDATE_LENGTH_OF_AGREEMENT);
export const receiveUpdateMapCoordinateSuccess = ({ commit }, data) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_SUCCESS, data);
export const receiveUpdateMapCoordinateError = ({ commit }, error) => commit(types.UPDATE_LENGTH_OF_AGREEMENT_ERROR, error);
export const updateMapCoordinate = ({ dispatch }, lengthOfAgreementData) => {
  dispatch('requestUpdateMapCoordinate');
  Api.patch(`bt/map_coordinates/${lengthOfAgreementData.bait_id}`, lengthOfAgreementData)
    .then(({ data }) => {
      dispatch('receiveUpdateMapCoordinateSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateMapCoordinateError', response);
    });
};

export const requestSendAppointmentMail = ({ commit }) => commit(types.SEND_APPOINTMENT_MAIL);
export const receiveSendAppointmentMailSuccess = ({ commit }, data) => commit(types.SEND_APPOINTMENT_MAIL_SUCCESS, data);
export const receiveSendAppointmentMailError = ({ commit }, error) => commit(types.SEND_APPOINTMENT_MAIL_ERROR, error);
export const sendAppointmentMail = ({ dispatch }, data) => {
  dispatch('requestSendAppointmentMail');
  Api.get(`mail_templates/edit_session/${data.edit_session}`).then((respo) => {
    const payload = { ...data };
    payload.mail_template_id = respo.data.result.data.mail_template_id;
    Api.post('bt/baits/send_mail', payload).then((response) => {
      dispatch('receiveSendAppointmentMailSuccess', response.data);
    }).catch((error) => {
      dispatch('receiveSendAppointmentMailError', error);
    });
  });
};

export const requestBaitStatuses = ({ commit }) => commit(types.GET_BAIT_STATUSES);
export const receiveBaitStatusesSuccess = ({ commit }, data) => commit(types.SET_BAIT_STATUSES, data);
export const receiveBaitStatusesError = ({ commit }, error) => commit(types.GET_BAIT_STATUSES_ERROR, error);
export const fetchBaitStatuses = ({ dispatch }) => {
  dispatch('requestBaitStatuses');
  Api.get('bt/bait_statuses')
    .then(({ data }) => {
      dispatch('receiveBaitStatusesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBaitStatusesError', response);
    });
};

export const requestUpdateBaitStatus = ({ commit }) => commit(types.UPDATE_BAIT_STATUS);
export const receiveUpdateBaitStatusSuccess = ({ commit }, data) => commit(types.UPDATE_BAIT_STATUS_SUCCESS, data);
export const receiveUpdateBaitStatusError = ({ commit }, error) => commit(types.UPDATE_BAIT_STATUS_ERROR, error);
export const updateBaitStatus = ({ dispatch }, baitData) => {
  dispatch('requestUpdateBaitStatus');
  Api.patch(`bt/baits/change_status/${baitData.bait_id}`, baitData)
    .then(({ data }) => {
      dispatch('receiveUpdateBaitStatusSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateBaitStatusError', response);
    });
};

export const requestRevertBaitLastStatus = ({ commit }) => commit(types.REVERT_BAIT_LAST_STATUS);
export const receiveRevertBaitLastStatusSuccess = ({ commit }, data) => commit(types.REVERT_BAIT_LAST_STATUS_SUCCESS, data);
export const receiveRevertBaitLastStatusError = ({ commit }, error) => commit(types.REVERT_BAIT_LAST_STATUS_ERROR, error);
export const revertBaitLastStatus = ({ dispatch }, payload) => {
  dispatch('requestRevertBaitLastStatus');
  Api.patch(`bt/baits/revert_status/${payload.bait_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveRevertBaitLastStatusSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveRevertBaitLastStatusError', response);
    });
};

export const requestFaqsByBaitId = ({ commit }) => commit(types.GET_FAQS_BY_BAIT_ID);
export const receiveFaqsByBaitIdSuccess = ({ commit }, data) => commit(types.SET_FAQS_BY_BAIT_ID, data);
export const receiveFaqsByBaitIdError = ({ commit }, error) => commit(types.GET_FAQS_BY_BAIT_ID_ERROR, error);
export const resetReceiveFaqsByBaitId = ({ commit }) => commit(types.RESET_FAQS_BY_BAIT_ID);
export const fetchFaqsByBaitId = ({ dispatch }, payload) => {
  dispatch('requestFaqsByBaitId');
  Api.get(`/bt/frequently_asked_questions/bait/${payload.bait_id}?transaction_type_id=${payload.transaction_type_id}`)
    .then(({ data }) => {
      dispatch('receiveFaqsByBaitIdSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFaqsByBaitIdError', response);
    });
};

export const requestCreateFaq = ({ commit }) => commit(types.CREATE_FAQ);
export const receiveCreateFaqSuccess = ({ commit }, data) => commit(types.CREATE_FAQ_SUCCESS, data);
export const receiveCreateFaqError = ({ commit }, error) => commit(types.CREATE_FAQ_ERROR, error);
export const fetchCreateFaq = ({ dispatch }, faqData) => {
  dispatch('requestCreateFaq');
  Api.post('/bt/frequently_asked_questions', faqData)
    .then(({ data }) => {
      dispatch('receiveCreateFaqSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateFaqError', response);
    });
};

export const requestUpdateFaq = ({ commit }) => commit(types.UPDATE_FAQ);
export const receiveUpdateFaqSuccess = ({ commit }, data) => commit(types.UPDATE_FAQ_SUCCESS, data);
export const receiveUpdateFaqError = ({ commit }, error) => commit(types.UPDATE_FAQ_ERROR, error);
export const fetchUpdateFaq = ({ dispatch }, faqData) => {
  dispatch('requestUpdateFaq');
  Api.patch(`/bt/frequently_asked_questions/${faqData.faq_id}`, faqData)
    .then(({ data }) => {
      dispatch('receiveUpdateFaqSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateFaqError', response);
    });
};

export const requestDeleteFaq = ({ commit }) => commit(types.DELETE_FAQ);
export const receiveDeleteFaqSuccess = ({ commit }, data) => commit(types.DELETE_FAQ_SUCCESS, data);
export const receiveDeleteFaqError = ({ commit }, error) => commit(types.DELETE_FAQ_ERROR, error);
export const fetchDeleteFaq = ({ dispatch }, payload) => {
  dispatch('requestDeleteFaq');
  if (payload.bait_id) {
    Api.delete(`/bt/frequently_asked_questions/${payload.id}?force_delete=${payload.force_delete}&bait_id=${payload.bait_id}`)
      .then(({ data }) => {
        dispatch('receiveDeleteFaqSuccess', data);
      })
      .catch(({ response }) => {
        dispatch('receiveDeleteFaqError', response);
      });
  } else {
    Api.delete(`/bt/frequently_asked_questions/${payload.id}?force_delete=${payload.force_delete}`)
      .then(({ data }) => {
        dispatch('receiveDeleteFaqSuccess', data);
      })
      .catch(({ response }) => {
        dispatch('receiveDeleteFaqError', response);
      });
  }
};


export const requestComparablePropertiesByBaitId = ({ commit }) => commit(types.GET_COMPARABLE_PROPERTIES_BY_BAIT_ID);
export const receiveComparablePropertiesByBaitIdSuccess = ({ commit }, data) => commit(types.SET_COMPARABLE_PROPERTIES_BY_BAIT_ID, data);
export const receiveComparablePropertiesByBaitIdError = ({ commit }, error) => commit(types.GET_COMPARABLE_PROPERTIES_BY_BAIT_ID_ERROR, error);
export const receiveComparablePropertiesByBaitIdReset = ({ commit }) => commit(types.RESET_COMPARABLE_PROPERTIES_BY_BAIT_ID);
export const fetchComparablePropertiesByBaitId = ({ dispatch }, baitId) => {
  dispatch('requestComparablePropertiesByBaitId');
  Api.get(`/bt/comparable_properties/bait/${baitId}`)
    .then(({ data }) => {
      dispatch('receiveComparablePropertiesByBaitIdSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveComparablePropertiesByBaitIdError', response);
    });
};

export const requestCreateComparableProperty = ({ commit }) => commit(types.CREATE_COMPARABLE_PROPERTY);
export const receiveCreateComparablePropertySuccess = ({ commit }, data) => commit(types.CREATE_COMPARABLE_PROPERTY_SUCCESS, data);
export const receiveCreateComparablePropertyError = ({ commit }, error) => commit(types.CREATE_COMPARABLE_PROPERTY_ERROR, error);
export const fetchCreateComparableProperty = ({ dispatch }, comparablePropertyData) => {
  dispatch('requestCreateComparableProperty');
  Api.postFile('/bt/comparable_properties', comparablePropertyData)
    .then(({ data }) => {
      dispatch('receiveCreateComparablePropertySuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateComparablePropertyError', response);
    });
};

export const requestUpdateComparableProperty = ({ commit }) => commit(types.UPDATE_COMPARABLE_PROPERTY);
export const receiveUpdateComparablePropertySuccess = ({ commit }, data) => commit(types.UPDATE_COMPARABLE_PROPERTY_SUCCESS, data);
export const receiveUpdateComparablePropertyError = ({ commit }, error) => commit(types.UPDATE_COMPARABLE_PROPERTY_ERROR, error);
export const fetchUpdateComparableProperty = ({ dispatch }, comparablePropertyData) => {
  dispatch('requestUpdateComparableProperty');
  Api.postFile(`/bt/comparable_properties/${comparablePropertyData.get('comparable_property_id')}`, comparablePropertyData)
    .then(({ data }) => {
      dispatch('receiveUpdateComparablePropertySuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateComparablePropertyError', response);
    });
};

export const requestDeleteComparableProperty = ({ commit }) => commit(types.DELETE_COMPARABLE_PROPERTY);
export const receiveDeleteComparablePropertySuccess = ({ commit }, data) => commit(types.DELETE_COMPARABLE_PROPERTY_SUCCESS, data);
export const receiveDeleteComparablePropertyError = ({ commit }, error) => commit(types.DELETE_COMPARABLE_PROPERTY_ERROR, error);
export const fetchDeleteComparableProperty = ({ dispatch }, payload) => {
  dispatch('requestDeleteComparableProperty');
  Api.delete(`/bt/comparable_properties/${payload.id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteComparablePropertySuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteComparablePropertyError', response);
    });
};

export const requestSendAppointmentOrBaitSms = ({ commit }) => commit(types.SEND_APPOINTMENT_OR_BAIT_SMS);
export const receiveSendAppointmentOrBaitSmsSuccess = ({ commit }, data) => commit(types.SEND_APPOINTMENT_OR_BAIT_SMS_SUCCESS, data);
export const receiveSendAppointmentOrBaitSmsError = ({ commit }, error) => commit(types.SEND_APPOINTMENT_OR_BAIT_SMS_ERROR, error);
export const sendAppointmentOrBaitSms = ({ dispatch }, payload) => {
  dispatch('requestSendAppointmentOrBaitSms');
  Api.post('bt/baits/send_sms', payload)
    .then(({ data }) => {
      dispatch('receiveSendAppointmentOrBaitSmsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSendAppointmentOrBaitSmsError', response);
    });
};

export const requestContentsByBaitId = ({ commit }) => commit(types.GET_CONTENTS_BY_BAIT_ID);
export const receiveContentsByBaitIdSuccess = ({ commit }, data) => commit(types.SET_CONTENTS_BY_BAIT_ID, data);
export const receiveContentsByBaitIdError = ({ commit }, error) => commit(types.GET_CONTENTS_BY_BAIT_ID_ERROR, error);
export const resetReceiveContentsByBaitId = ({ commit }) => commit(types.RESET_CONTENTS_BY_BAIT_ID);
export const fetchContentsByBaitId = ({ dispatch }, baitId) => {
  dispatch('requestContentsByBaitId');
  Api.get(`/bt/content_has_baits/bait/${baitId}`)
    .then(({ data }) => {
      dispatch('receiveContentsByBaitIdSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveContentsByBaitIdError', response);
    });
};

export const requestCreateBaitContent = ({ commit }) => commit(types.CREATE_BAIT_CONTENT);
export const receiveCreateBaitContentSuccess = ({ commit }, data) => commit(types.CREATE_BAIT_CONTENT_SUCCESS, data);
export const receiveCreateBaitContentError = ({ commit }, error) => commit(types.CREATE_BAIT_CONTENT_ERROR, error);
export const fetchCreateBaitContent = ({ dispatch }, baitContent) => {
  dispatch('requestCreateBaitContent');
  Api.post('/bt/content_has_baits', baitContent)
    .then(({ data }) => {
      dispatch('receiveCreateBaitContentSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateBaitContentError', response);
    });
};

export const requestSendAppointmentOrBaitEmail = ({ commit }) => commit(types.SEND_APPOINTMENT_OR_BAIT_EMAIL);
export const receiveSendAppointmentOrBaitEmailSuccess = ({ commit }, data) => commit(types.SEND_APPOINTMENT_OR_BAIT_EMAIL_SUCCESS, data);
export const receiveSendAppointmentOrBaitEmailError = ({ commit }, error) => commit(types.SEND_APPOINTMENT_OR_BAIT_EMAIL_ERROR, error);
export const sendAppointmentOrBaitEmail = ({ dispatch }, payload) => {
  dispatch('requestSendAppointmentOrBaitEmail');

  Api.get(`mail_templates/edit_session/${payload.edit_session}`).then((newMailTemplate) => {
    const data = { ...payload };
    delete data.edit_session;
    data.mail_template_id = newMailTemplate.data.result.data.mail_template_id;
    Api.post('bt/baits/send_mail', data)
      .then(({ data: dataSuccess }) => {
        dispatch('receiveSendAppointmentOrBaitEmailSuccess', dataSuccess);
      })
      .catch(({ response }) => {
        dispatch('receiveSendAppointmentOrBaitEmailError', response);
      });
  });
};

export const requestBaitContents = ({ commit }) => commit(types.GET_BAIT_CONTENTS);
export const receiveBaitContentsSuccess = ({ commit }, data) => commit(types.SET_BAIT_CONTENTS, data);
export const receiveBaitContentsError = ({ commit }, error) => commit(types.GET_BAIT_CONTENTS_ERROR, error);
export const fetchBaitContents = ({ dispatch }) => {
  dispatch('requestBaitContents');
  Api.get('/bt/contents')
    .then(({ data }) => {
      dispatch('receiveBaitContentsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBaitContentsError', response);
    });
};

export const requestcreateCompletionTool = ({ commit }) => commit(types.CREATE_COMPLETION_TOOL);
export const receivecreateCompletionToolSuccess = ({ commit }, data) => commit(types.CREATE_COMPLETION_TOOL_SUCCESS, data);
export const receivecreateCompletionToolError = ({ commit }, error) => commit(types.CREATE_COMPLETION_TOOL_ERROR, error);
export const createCompletionTool = ({ dispatch }, payload) => {
  dispatch('requestcreateCompletionTool');
  Api.post('/bt/completion_tool', payload)
    .then(({ data }) => {
      dispatch('receivecreateCompletionToolSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivecreateCompletionToolError', response);
    });
};

export const requestBaitAskedQuestions = ({ commit }) => commit(types.GET_BAIT_ASKED_QUESTIONS);
export const receiveBaitAskedQuestionsSuccess = ({ commit }, data) => commit(types.SET_BAIT_ASKED_QUESTIONS, data);
export const receiveBaitAskedQuestionsError = ({ commit }, error) => commit(types.GET_BAIT_ASKED_QUESTIONS_ERROR, error);
export const resetReceiveBaitAskedQuestions = ({ commit }) => commit(types.RESET_BAIT_ASKED_QUESTIONS);
export const fetchBaitAskedQuestions = ({ dispatch }, payload) => {
  dispatch('requestBaitAskedQuestions');
  Api.get(`/bt/baits/${payload}/questions`)
    .then(({ data }) => {
      dispatch('receiveBaitAskedQuestionsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBaitAskedQuestionsError', response);
    });
};


export const resetSendQuickEmailAndSms = ({ commit }) => commit(types.RESET_SEND_QUICK_EMAIL_AND_SMS);
export const requestSendQuickEmailAndSms = ({ commit }) => commit(types.GET_SEND_QUICK_EMAIL_AND_SMS);
export const receiveSendQuickEmailAndSmsError = ({ commit }, data) => commit(types.SET_SEND_QUICK_EMAIL_AND_SMS_ERROR, data);
export const receiveSendQuickEmailAndSms = ({ commit }, data) => commit(types.SET_SEND_QUICK_EMAIL_AND_SMS, data);
export const sendQuickEmailAndSms = ({ dispatch }, payload) => {
  dispatch('requestSendQuickEmailAndSms');
  const payloadEmail = { ...payload };
  const payloadSMS = { ...payload };
  delete payloadEmail.message;
  delete payloadSMS.mail_template_id;
  Api.post('bt/baits/send_mail', payloadEmail).then(({ data: sendEmailSuccess }) => {
    dispatch('sendQuickSMS', { payloadSMS, emailResult: sendEmailSuccess.result.data });
  }).catch(({ response: sendEmailError }) => {
    if (sendEmailError.data.code === 500) {
      dispatch('receiveSendQuickEmailAndSmsError', 'Connection problem!');
    } else {
      const errors = {};
      if (sendEmailError && sendEmailError.data && sendEmailError.data.result && sendEmailError.data.result.data) {
        errors.emailResult = sendEmailError.data.result.data;
      } else {
        [errors.emailResult] = getErrors(sendEmailError);
        dispatch('receiveSendQuickEmailAndSmsError', errors.emailResult);
      }
      dispatch('sendQuickSMS', { payloadSMS, emailResult: errors.emailResult });
    }
  });
};

export const sendQuickSMS = ({ dispatch }, payload) => {
  Api.post('bt/baits/send_sms', payload.payloadSMS).then(({ data: sendSmsSuccess }) => {
    const data = {
      smsResult: sendSmsSuccess.result.data,
      emailResult: payload.emailResult,
    };
    dispatch('receiveSendQuickEmailAndSms', data);
  }).catch(({ response: sendSmsError }) => {
    const errors = {};
    if (sendSmsError && sendSmsError.data && sendSmsError.data.result && sendSmsError.data.result.data) {
      errors.smsResult = sendSmsError.data.result.data;
    } else {
      [errors.smsResult] = getErrors(sendSmsError);
      dispatch('receiveSendQuickEmailAndSmsError', errors.smsResult);
    }
    errors.emailResult = payload.emailResult;
    const data = { ...errors };
    dispatch('receiveSendQuickEmailAndSms', data);
  });
};

export const requestCheckBaitRequirements = ({ commit }) => commit(types.GET_CHECK_BAIT_REQUIREMENTS);
export const receiveCheckBaitRequirementsSuccess = ({ commit }, data) => commit(types.SET_CHECK_BAIT_REQUIREMENTS, data);
export const receiveCheckBaitRequirementsError = ({ commit }, error) => commit(types.GET_CHECK_BAIT_REQUIREMENTS_ERROR, error);
export const checkBaitRequirements = ({ dispatch }, payload) => {
  dispatch('requestCheckBaitRequirements');
  Api.get(`bt/baits/check_list/${payload.bait_id}`)
    .then(({ data }) => {
      dispatch('receiveCheckBaitRequirementsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCheckBaitRequirementsError', response);
    });
};

export const requestChangeBaitPublishStatus = ({ commit }) => commit(types.GET_CHANGE_BAIT_PUBLISH_STATUS);
export const receiveChangeBaitPublishStatusSuccess = ({ commit }, data) => commit(types.SET_CHANGE_BAIT_PUBLISH_STATUS_SUCCESS, data);
export const receiveChangeBaitPublishStatusError = ({ commit }, error) => commit(types.GET_CHANGE_BAIT_PUBLISH_STATUS_ERROR, error);
export const changeBaitPublishStatus = ({ dispatch }, payload) => {
  dispatch('requestChangeBaitPublishStatus');
  // This endpoint is also used in updateBait action. Use this one only to change bait's publish status.
  Api.patch(`bt/baits/${payload.bait_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveChangeBaitPublishStatusSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveChangeBaitPublishStatusError', response);
    });
};
