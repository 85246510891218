import ApiService from '@/core/api/api.service';

export default class TaskSubcategoryServices extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async list(payload, params) {
    this.payload = payload;
    this.params = params;
    this.url = 'tasks/subcategories?__limit=25';
    this.loader = false;
    this.name = 'TASK_SUBCATEGORIES';
    const { result } = await this.get();
    return {
      data: result.data,
      meta: { total: result.data.length },
    };
  }

  async update(id, payload) {
    this.payload = payload;
    this.url = `tasks/subcategories/${id}`;
    return this.baseRemitter('patch', 'data');
  }

  async create(payload) {
    this.payload = payload;
    this.url = 'tasks/subcategories';
    return this.baseRemitter('post', 'data');
  }

  async delete(payload) {
    this.url = `tasks/subcategories/${payload}`;
    return this.baseRemitter('delete', 'message');
  }
}
