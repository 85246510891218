import Api from '@/libraries/api';
import { ValuationAppointmentServices } from '@/modules/valuation_appointment/services/valuationAppointment.services';
import * as types from './mutation_types';

export const requestCreateValuationAppointment = ({ commit }) => {
  commit(types.GET_CREATE_VALUATION_APPOINTMENT);
};
export const resetCreateValuationAppointment = ({ commit }) => {
  commit(types.RESET_CREATE_VALUATION_APPOINTMENT);
};
export const receiveCreateValuationAppointmentSuccess = ({ commit }, data) => {
  commit(types.SET_CREATE_VALUATION_APPOINTMENT, data);
};
export const receiveCreateValuationAppointmentError = ({ commit }, error) => {
  commit(types.GET_CREATE_VALUATION_APPOINTMENT_ERROR, error);
};
export const createValuationAppointment = async ({ dispatch }, valuation) => {
  dispatch('requestCreateValuationAppointment');
  try {
    const data = await new ValuationAppointmentServices().createAppointment(valuation);
    await dispatch('receiveCreateValuationAppointmentSuccess', data);
  } catch (response) {
    await dispatch('receiveCreateValuationAppointmentError', response);
  }
};


export const requestUpdateValuationAppointment = ({ commit }) => {
  commit(types.GET_UPDATE_VALUATION_APPOINTMENT);
};
export const receiveUpdateValuationAppointmentSuccess = ({ commit }, data) => {
  commit(types.SET_UPDATE_VALUATION_APPOINTMENT, data);
};
export const receiveUpdateValuationAppointmentError = ({ commit }, error) => {
  commit(types.GET_UPDATE_VALUATION_APPOINTMENT_ERROR, error);
};
export const updateValuationAppointment = ({ dispatch }, valuation) => {
  dispatch('requestUpdateValuationAppointment');
  Api.patch(`calendar/events/valuation_appointment/${valuation.event.event_id}`, valuation)
    .then((data) => {
      dispatch('receiveUpdateValuationAppointmentSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateValuationAppointmentError', response);
    });
};


export const requestCheckUserAvailability = ({ commit }) => {
  commit(types.GET_CHECK_USER_AVAILABILITY);
};
export const receiveCheckUserAvailabilitySuccess = ({ commit }, data) => {
  commit(types.SET_CHECK_USER_AVAILABILITY, data);
};
export const receiveCheckUserAvailabilityError = ({ commit }, error) => {
  commit(types.GET_CHECK_USER_AVAILABILITY_ERROR, error);
};
export const checkUserAvailability = ({ dispatch }, payload) => {
  dispatch('requestCheckUserAvailability');
  Api.get(`calendar/events/check_user_availability?${payload}`)
    .then((data) => {
      dispatch('receiveCheckUserAvailabilitySuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCheckUserAvailabilityError', response);
    });
};
export const fetchCommonTypesSales = async ({ commit }) => {
  const data = await new ValuationAppointmentServices().getCommonTypes(1);
  commit(types.SET_COMMON_TYPES, { data, transactionType: 'sales' });
};
export const fetchCommonTypesLettings = async ({ commit }) => {
  const data = await new ValuationAppointmentServices().getCommonTypes(2);
  commit(types.SET_COMMON_TYPES, { data, transactionType: 'lettings' });
};
