export const GET_CSV_IMPORT_FIELDS = 'GET_CSV_IMPORT_FIELDS';
export const SET_CSV_IMPORT_FIELDS = 'SET_CSV_IMPORT_FIELDS';
export const GET_CSV_IMPORT_FIELDS_ERROR = 'GET_CSV_IMPORT_FIELDS_ERROR';

export const GET_IMPORT_CSV = 'GET_IMPORT_CSV';
export const SET_IMPORT_CSV_SUCCESS = 'SET_IMPORT_CSV_SUCCESS';
export const GET_IMPORT_CSV_ERROR = 'GET_IMPORT_CSV_ERROR';

export const GET_MAIL_LISTS = 'GET_MAIL_LISTS';
export const SET_MAIL_LISTS = 'SET_MAIL_LISTS';
export const GET_MAIL_LISTS_ERROR = 'GET_MAIL_LISTS_ERROR';

export const GET_CSV = 'GET_CSV';
export const SET_CSV = 'SET_CSV';
export const GET_CSV_ERROR = 'GET_CSV_ERROR';

export const GET_SEND_CSV = 'GET_SEND_CSV';
export const SET_SEND_CSV_SUCCESS = 'SET_SEND_CSV_SUCCESS';
export const GET_SEND_CSV_ERROR = 'GET_SEND_CSV_ERROR';
