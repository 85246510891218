import Api from '@/libraries/api';
import router from '@/router';
import * as types from './mutation_types';


export const requestForms = ({ commit }) => commit(types.GET_FORMS);
export const receiveFormsSuccess = ({ commit }, data) => commit(types.SET_FORMS, data);
export const receiveFormsError = ({ commit }, data) => commit(types.GET_FORMS_ERROR, data);
export const fetchForms = ({ dispatch }, filter) => {
  dispatch('requestForms');
  Api.get(`forms${filter.request}`)
    .then(({ data }) => {
      dispatch('receiveFormsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFormsError', response);
    });
};

export const requestSendForms = ({ commit }) => commit(types.GET_SEND_FORMS);
export const receiveSendFormsSuccess = ({ commit }, data) => commit(types.SET_SEND_FORMS, data);
export const receiveSendFormsError = ({ commit }, data) => commit(types.GET_SEND_FORMS_ERROR, data);
export const fetchSendForms = ({ dispatch }, formData) => {
  dispatch('requestSendForms');
  Api.postFile('forms', formData)
    .then(({ data }) => {
      dispatch('receiveSendFormsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSendFormsError', response);
    });
};

export const requestUpdateForm = ({ commit }) => commit(types.GET_UPDATE_FORM);
export const receiveUpdateFormSuccess = ({ commit }, data) => commit(types.SET_UPDATE_FORM, data);
export const receiveUpdateFormError = ({ commit }, data) => commit(types.GET_UPDATE_FORM_ERROR, data);
export const fetchUpdateForm = ({ dispatch }, formData) => {
  dispatch('requestUpdateForm');
  Api.postFile(`forms/${formData.get('form_id')}`, formData)
    .then(({ data }) => {
      dispatch('receiveUpdateFormSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateFormError', response);
    });
};

export const requestForm = ({ commit }) => commit(types.GET_FORM);
export const receiveFormSuccess = ({ commit }, data) => commit(types.SET_FORM, data);
export const receiveFormError = ({ commit }, data) => commit(types.GET_FORM_ERROR, data);
export const fetchForm = ({ dispatch }, formId) => {
  dispatch('requestForm');
  Api.get(`forms/${formId}`)
    .then(({ data }) => {
      dispatch('receiveFormSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFormError', response);
    });
};

export const requestDeleteForm = ({ commit }) => commit(types.GET_DELETE_FORM);
export const receiveDeleteFormSuccess = ({ commit }, data) => commit(types.SET_DELETE_FORM, data);
export const receiveDeleteFormError = ({ commit }, data) => commit(types.GET_DELETE_FORM_ERROR, data);
export const deleteForm = ({ dispatch }, formId) => {
  dispatch('requestDeleteForm');
  Api.delete(`forms/${formId}`)
    .then(({ data }) => {
      dispatch('receiveDeleteFormSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteFormError', response);
    });
};

export const fetchFormWithoutEntries = ({ dispatch }, formId) => {
  dispatch('requestForm');
  Api.get(`forms/discovery_questions/${formId}`)
    .then(({ data }) => {
      dispatch('receiveFormSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFormError', response);
    });
};

export const requestFormDetail = ({ commit }) => commit(types.GET_FORM_DETAIL);
export const receiveFormDetailSuccess = ({ commit }, data) => commit(types.SET_FORM_DETAIL, data);
export const receiveFormDetailError = ({ commit }, data) => commit(types.GET_FORM_DETAIL_ERROR, data);
export const fetchFormDetail = ({ dispatch }, formId) => {
  dispatch('requestFormDetail');
  Api.get(`forms/${formId}`).then(({ data }) => {
    router.push({
      name: 'UpdateForm',
      params: { form_id: formId },
    });
    dispatch('receiveFormDetailSuccess', data);
  });
};

export const requestFormQuestionFields = ({ commit }) => commit(types.GET_FORM_QUESTION_FIELDS);
export const receiveFormQuestionFieldsSuccess = ({ commit }, data) => commit(types.SET_FORM_QUESTION_FIELDS, data);
export const receiveFormQuestionFieldsError = ({ commit }, data) => commit(types.GET_FORM_QUESTION_FIELDS, data);
export const fetchFormQuestionFields = ({ dispatch }) => {
  dispatch('requestFormQuestionFields');
  Api.get('form_field_types')
    .then(({ data }) => {
      dispatch('receiveFormQuestionFieldsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFormQuestionFieldsError', response);
    });
};

export const requestCloneForm = ({ commit }) => commit(types.CLONE_FORM);
export const receiveCloneFormSuccess = ({ commit }, data) => commit(types.CLONE_FORM_SUCCESS, data);
export const receiveCloneFormError = ({ commit }, error) => commit(types.CLONE_FORM_ERROR, error);
export const fetchCloneForm = async ({ dispatch }, payload) => {
  dispatch('requestCloneForm');
  try {
    const { data } = await Api.post(`forms/clone_form/${payload.id}`);
    dispatch('receiveCloneFormSuccess', data);
  } catch ({ response }) {
    dispatch('receiveCloneFormError', response);
  }
};

export const requestCreateFormQuestion = ({ commit }) => commit(types.CREATE_FORM_QUESTION);
export const fetchCreateFormQuestion = ({ dispatch }, formQuestionData) => {
  dispatch('requestCreateFormQuestion');
  return Api.post('form_fields', formQuestionData);
};

export const requestUpdateFormQuestion = ({ commit }) => commit(types.GET_UPDATE_FORM_QUESTION);
export const receiveUpdateFormQuestionSuccess = ({ commit }, data) => commit(types.SET_UPDATE_FORM_QUESTION, data);
export const receiveUpdateFormQuestionError = ({ commit }, data) => commit(types.GET_UPDATE_FORM_QUESTION_ERROR, data);
export const fetchUpdateFormQuestion = ({ dispatch }, formQuestionData) => {
  dispatch('requestUpdateFormQuestion');
  Api.patch(`form_fields/${formQuestionData.form_field_id}`, formQuestionData)
    .then(({ data }) => {
      dispatch('receiveUpdateFormQuestionSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdateFormQuestionError', response);
    });
};

export const requestDeleteFormQuestion = ({ commit }) => commit(types.DELETE_FORM_QUESTION);
export const fetchDeleteFormQuestion = ({ dispatch }, formFieldId) => {
  dispatch('requestDeleteFormQuestion');
  return Api.delete(`form_fields/${formFieldId}`);
};

export const requestFetchNotifyUserTypes = ({ commit }) => commit(types.GET_FETCH_NOTIFY_USER_TYPES);
export const receiveFetchNotifyUserTypesSuccess = ({ commit }, data) => commit(types.SET_FETCH_NOTIFY_USER_TYPES, data);
export const receiveFetchNotifyUserTypesError = ({ commit }, data) => commit(types.GET_FETCH_NOTIFY_USER_TYPES_ERROR, data);
export const fetchFetchNotifyUserTypes = ({ dispatch }) => {
  dispatch('requestFetchNotifyUserTypes');
  Api.get('notify_user_types')
    .then(({ data }) => {
      dispatch('receiveFetchNotifyUserTypesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFetchNotifyUserTypesError', response);
    });
};

export const requestFetchFormEntryAnswer = ({ commit }) => commit(types.GET_FETCH_FORM_ENTRY_ANSWER);
export const receiveFetchFormEntryAnswerSuccess = ({ commit }, data) => commit(types.SET_FETCH_FORM_ENTRY_ANSWER, data);
export const receiveFetchFormEntryAnswerError = ({ commit }, data) => commit(types.GET_FETCH_FORM_ENTRY_ANSWER_ERROR, data);
export const fetchFetchFormEntryAnswer = ({ dispatch }, formEntryId) => {
  dispatch('requestFetchFormEntryAnswer');
  Api.get(`form_entries/${formEntryId}/update_fields/answers`)
    .then(({ data }) => {
      dispatch('receiveFetchFormEntryAnswerSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFetchFormEntryAnswerError', response);
    });
};

export const requestFetchFormIntegration = ({ commit }) => commit(types.GET_FETCH_FORM_INTEGRATION);
export const receiveFetchFormIntegrationSuccess = ({ commit }, data) => commit(types.SET_FETCH_FORM_INTEGRATION, data);
export const receiveFetchFormIntegrationError = ({ commit }, data) => commit(types.GET_FETCH_FORM_INTEGRATION_ERROR, data);
export const fetchFetchFormIntegration = ({ dispatch }, formId) => {
  dispatch('requestFetchFormIntegration');
  Api.get(`forms/${formId}/developer_settings`)
    .then(({ data }) => {
      dispatch('receiveFetchFormIntegrationSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFetchFormIntegrationError', response);
    });
};

export const requestUploadFavicon = ({ commit }) => commit(types.GET_UPLOAD_FAVICON);
export const receiveUploadFaviconSuccess = ({ commit }, data) => commit(types.SET_UPLOAD_FAVICON, data);
export const receiveUploadFaviconError = ({ commit }, data) => commit(types.GET_UPLOAD_FAVICON_ERROR, data);
export const fetchUploadFavicon = ({ dispatch }, formData) => {
  dispatch('requestUploadFavicon');
  Api.postFile('custom_cdn_upload', formData)
    .then(({ data }) => {
      dispatch('receiveUploadFaviconSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUploadFaviconError', response);
    });
};

export const requestFetchFormQuestions = ({ commit }) => commit(types.GET_FETCH_FORM_QUESTIONS);
export const receiveFetchFormQuestionsSuccess = ({ commit }, data) => commit(types.SET_FETCH_FORM_QUESTIONS, data);
export const receiveFetchFormQuestionsError = ({ commit }, data) => commit(types.GET_FETCH_FORM_QUESTIONS_ERROR, data);
export const fetchFetchFormQuestions = ({ dispatch }, formId) => {
  dispatch('requestFetchFormQuestions');
  Api.get(`form_fields/form/${formId}`)
    .then(({ data }) => {
      dispatch('receiveFetchFormQuestionsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFetchFormQuestionsError', response);
    });
};

export const requestFetchFormEntries = ({ commit }) => commit(types.GET_FETCH_FORM_ENTRIES);
export const receiveFetchFormEntriesSuccess = ({ commit }, data) => commit(types.SET_FETCH_FORM_ENTRIES, data);
export const receiveFetchFormEntriesError = ({ commit }, data) => commit(types.GET_FETCH_FORM_ENTRIES_ERROR, data);
export const fetchFetchFormEntries = ({ dispatch }, filter) => {
  dispatch('requestFetchFormEntries');
  Api.get(`form_entries/form/${filter.params.form_id}${filter.request}&__order_by=-created_at`)
    .then(({ data }) => {
      dispatch('receiveFetchFormEntriesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveFetchFormEntriesError', response);
    });
};
