import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_MAIL_LISTS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'mailLists', null);
  },
  [types.SET_MAIL_LISTS](state, data) {
    Vue.set(state, 'mailLists', data.result.data);
    Vue.set(state, 'totalMailList', data.result.meta.total);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_MAIL_LISTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'mailLists', []);
    Vue.set(state, 'totalMailList', 0);
  },

  [types.GET_DRIP](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_DRIP](state, data) {
    Vue.set(state, 'drip', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DRIP_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_DRIPS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateDripProcessed', true);
    Vue.set(state, 'updateDripProcessedSuccess', false);
    Vue.set(state, 'updateDripProcessedError', false);
  },
  [types.SET_UPDATE_DRIPS](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateDripProcessed', false);
    Vue.set(state, 'updateDripProcessedSuccess', 'Update Drip successfully.');
  },
  [types.GET_UPDATE_DRIPS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateDripProcessed', false);
    Vue.set(state, 'updateDripProcessedError', 'Update Drip error.');
  },

  [types.GET_SEND_SUPPORT_MAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendSupportMailProcessed', true);
    Vue.set(state, 'sendSupportMailProcessedSuccess', false);
    Vue.set(state, 'sendSupportMailProcessedError', false);
  },
  [types.SET_SEND_SUPPORT_MAIL](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sendSupportMailProcessed', false);
    Vue.set(state, 'sendSupportMailProcessedSuccess', 'Your mail has been sent.');
  },
  [types.GET_SEND_SUPPORT_MAIL_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sendSupportMailProcessed', false);
    Vue.set(state, 'sendSupportMailProcessedError', 'Your mail could not be sent.');
  },


  [types.GET_UPDATE_DRIP](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateDripProcessed', true);
    Vue.set(state, 'updateDripProcessedSuccess', false);
    Vue.set(state, 'updateDripProcessedError', false);
  },
  [types.SET_UPDATE_DRIP](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateDripProcessed', false);
    Vue.set(state, 'updateDripProcessedSuccess', data.message);
  },
  [types.GET_UPDATE_DRIP_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateDripProcessed', false);
    Vue.set(state, 'updateDripProcessedError', error.message);
  },
};
