export const GET_FORM = 'GET_FORM';
export const SET_FORM = 'SET_FORM';
export const GET_FORM_ERROR = 'GET_FORM_ERROR';

export const GET_FORMS = 'GET_FORMS';
export const SET_FORMS = 'SET_FORMS';
export const GET_FORMS_ERROR = 'GET_FORMS_ERROR';

export const GET_SEND_FORMS = 'GET_SEND_FORMS';
export const SET_SEND_FORMS = 'SET_SEND_FORMS';
export const GET_SEND_FORMS_ERROR = 'GET_SEND_FORMS_ERROR';

export const GET_UPDATE_FORM = 'GET_UPDATE_FORM';
export const SET_UPDATE_FORM = 'SET_UPDATE_FORM';
export const GET_UPDATE_FORM_ERROR = 'GET_UPDATE_FORM_ERROR';

export const GET_DELETE_FORM = 'GET_DELETE_FORM';
export const SET_DELETE_FORM = 'SET_DELETE_FORM';
export const GET_DELETE_FORM_ERROR = 'GET_DELETE_FORM_ERROR';

export const GET_FORM_DETAIL = 'GET_FORM_DETAIL';
export const SET_FORM_DETAIL = 'SET_FORM_DETAIL';
export const GET_FORM_DETAIL_ERROR = 'GET_FORM_DETAIL_ERROR';

export const GET_FORM_QUESTION_FIELDS = 'GET_FORM_QUESTION_FIELDS';
export const SET_FORM_QUESTION_FIELDS = 'SET_FORM_QUESTION_FIELDS';
export const GET_FORM_QUESTION_FIELDS_ERROR = 'GET_FORM_QUESTION_FIELDS_ERROR';

export const CREATE_FORM_QUESTION = 'CREATE_FORM_QUESTION';
export const DELETE_FORM_QUESTION = 'DELETE_FORM_QUESTION';

export const GET_UPDATE_FORM_QUESTION = 'GET_UPDATE_FORM_QUESTION';
export const SET_UPDATE_FORM_QUESTION = 'SET_UPDATE_FORM_QUESTION';
export const GET_UPDATE_FORM_QUESTION_ERROR = 'GET_UPDATE_FORM_QUESTION_ERROR';

export const GET_FETCH_NOTIFY_USER_TYPES = 'GET_FETCH_NOTIFY_USER_TYPES';
export const SET_FETCH_NOTIFY_USER_TYPES = 'SET_FETCH_NOTIFY_USER_TYPES';
export const GET_FETCH_NOTIFY_USER_TYPES_ERROR = 'GET_FETCH_NOTIFY_USER_TYPES_ERROR';

export const GET_FETCH_FORM_ENTRY_ANSWER = 'GET_FETCH_FORM_ENTRY_ANSWER';
export const SET_FETCH_FORM_ENTRY_ANSWER = 'SET_FETCH_FORM_ENTRY_ANSWER';
export const GET_FETCH_FORM_ENTRY_ANSWER_ERROR = 'GET_FETCH_FORM_ENTRY_ANSWER_ERROR';

export const GET_FETCH_FORM_INTEGRATION = 'GET_FETCH_FORM_INTEGRATION';
export const SET_FETCH_FORM_INTEGRATION = 'SET_FETCH_FORM_INTEGRATION';
export const GET_FETCH_FORM_INTEGRATION_ERROR = 'GET_FETCH_FORM_INTEGRATION_ERROR';

export const GET_UPLOAD_FAVICON = 'GET_UPLOAD_FAVICON';
export const SET_UPLOAD_FAVICON = 'SET_UPLOAD_FAVICON';
export const GET_UPLOAD_FAVICON_ERROR = 'GET_UPLOAD_FAVICON_ERROR';

export const CLONE_FORM = 'CLONE_FORM';
export const CLONE_FORM_SUCCESS = 'CLONE_FORM_SUCCESS';
export const CLONE_FORM_ERROR = 'CLONE_FORM_ERROR';

export const GET_FETCH_FORM_QUESTIONS = 'GET_FETCH_FORM_QUESTIONS';
export const SET_FETCH_FORM_QUESTIONS = 'SET_FETCH_FORM_QUESTIONS';
export const GET_FETCH_FORM_QUESTIONS_ERROR = 'GET_FETCH_FORM_QUESTIONS_ERROR';

export const GET_FETCH_FORM_ENTRIES = 'GET_FETCH_FORM_ENTRIES';
export const SET_FETCH_FORM_ENTRIES = 'SET_FETCH_FORM_ENTRIES';
export const GET_FETCH_FORM_ENTRIES_ERROR = 'GET_FETCH_FORM_ENTRIES_ERROR';
