import ApiService from '@/core/api/api.service';

export default class DashboardStatisticsServices extends ApiService {
  setLoader() {
    this.loader = false;
  }

  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async fetchDashboardStats(transactionType) {
    this.url = `stats/ma_pipelines/${transactionType}`;
    const { result: { data } } = await this.get();
    return data;
  }

  async fetchMapProperties(companyId, userId, isRefresh) {
    this.url = `properties/map?company_id=${companyId}&user_id=${userId}&is_refresh=${isRefresh}`;
    const { result: { data } } = await this.get();
    return data;
  }
}
