export const GET_SOLNY = 'GET_SOLNY';
export const SET_SOLNY = 'SET_SOLNY';
export const GET_SOLNY_ERROR = 'GET_SOLNY_ERROR';

export const GET_SOLNY_DETAIL = 'GET_SOLNY_DETAIL';
export const SET_SOLNY_DETAIL = 'SET_SOLNY_DETAIL';
export const GET_SOLNY_DETAIL_ERROR = 'GET_SOLNY_DETAIL_ERROR';

export const GET_PAPER_COPIES = 'GET_PAPER_COPIES';
export const SET_PAPER_COPIES = 'SET_PAPER_COPIES';
export const GET_PAPER_COPIES_ERROR = 'GET_PAPER_COPIES_ERROR';

export const GET_SOLNY_BROCHURE = 'GET_SOLNY_BROCHURE';
export const SET_SOLNY_BROCHURE = 'SET_SOLNY_BROCHURE';
export const GET_SOLNY_BROCHURE_ERROR = 'GET_SOLNY_BROCHURE_ERROR';

export const GET_PAPER_SIZES = 'GET_PAPER_SIZES';
export const SET_PAPER_SIZES = 'SET_PAPER_SIZES';
export const GET_PAPER_SIZES_ERROR = 'GET_PAPER_SIZES_ERROR';

export const GET_PAPER_LAMINATIONS = 'GET_PAPER_LAMINATIONS';
export const SET_PAPER_LAMINATIONS = 'SET_PAPER_LAMINATIONS';
export const GET_PAPER_LAMINATIONS_ERROR = 'GET_PAPER_LAMINATIONS_ERROR';

export const GET_PAPER_TYPES = 'GET_PAPER_TYPES';
export const SET_PAPER_TYPES = 'SET_PAPER_TYPES';
export const GET_PAPER_TYPES_ERROR = 'GET_PAPER_TYPES_ERROR';

export const GET_PAPER_WEIGHTS = 'GET_PAPER_WEIGHTS';
export const SET_PAPER_WEIGHTS = 'SET_PAPER_WEIGHTS';
export const GET_PAPER_WEIGHTS_ERROR = 'GET_PAPER_WEIGHTS_ERROR';

export const GET_PAPER_ORDER_PRICES = 'GET_PAPER_ORDER_PRICES';
export const SET_PAPER_ORDER_PRICES = 'SET_PAPER_ORDER_PRICES';
export const GET_PAPER_ORDER_PRICES_ERROR = 'GET_PAPER_ORDER_PRICES_ERROR';

export const GET_SEND_ORDER = 'GET_SEND_ORDER';
export const SET_SEND_ORDER = 'SET_SEND_ORDER';
export const GET_SEND_ORDER_ERROR = 'GET_SEND_ORDER_ERROR';

export const GET_SOCIAL_MEDIA_ACCOUNTS = 'GET_SOCIAL_MEDIA_ACCOUNTS';
export const SET_SOCIAL_MEDIA_ACCOUNTS = 'SET_SOCIAL_MEDIA_ACCOUNTS';
export const GET_SOCIAL_MEDIA_ACCOUNTS_ERROR = 'GET_SOCIAL_MEDIA_ACCOUNTS_ERROR';

export const GET_UPDATE_AD_ACCOUNT = 'GET_UPDATE_AD_ACCOUNT';
export const SET_UPDATE_AD_ACCOUNT = 'SET_UPDATE_AD_ACCOUNT';
export const GET_UPDATE_AD_ACCOUNT_ERROR = 'GET_UPDATE_AD_ACCOUNT_ERROR';

export const GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT = 'GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT';
export const SET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT = 'SET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT';
export const GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT_ERROR = 'GET_CONNECTED_AUDIENCES_WITH_AD_ACCOUNT_ERROR';

export const GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT = 'GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT';
export const SET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT = 'SET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT';
export const GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT_ERROR = 'GET_CONNECT_AUDIENCE_WITH_AD_ACCOUNT_ERROR';

export const GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT = 'GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT';
export const SET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT = 'SET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT';
export const GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR = 'GET_UPDATE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR';

export const GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT = 'GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT';
export const SET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT = 'SET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT';
export const GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR = 'GET_DELETE_CONNECTION_AUDIENCE_WITH_AD_ACCOUNT_ERROR';
