export default {
  isLoading: false,
  searchProcessed: false,
  searchResults: null,
  searchError: null,
  searchTotalCount: 0,
  elasticSearchResults: null,
  elasticSearchError: null,
  elasticSearchResultCount: 0,
  contactProfilesInfo: [
    {
      name: 'Vendor',
      profile_id: 2,
      transaction_type_id: 1,
    },
    {
      name: 'Buyer',
      profile_id: 3,
      transaction_type_id: 1,
    },
    {
      name: 'Landlord',
      profile_id: 4,
      transaction_type_id: 2,
    },
    {
      name: 'Tenant',
      profile_id: 5,
      transaction_type_id: 2,
    },
  ],

  activeModals: {},

  appConfigs: null,
};
