import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_CONVERT_TO_BAIT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'changeStatusProcessed', true);
    Vue.set(state, 'changeStatusProcessedSuccess', false);
    Vue.set(state, 'changeStatusProcessedError', false);
  },
  [types.SET_CONVERT_TO_BAIT](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'changeStatusProcessed', false);
    Vue.set(state, 'changeStatusProcessedSuccess', 'Appointment converted to MA successfully.');
  },
  [types.GET_CONVERT_TO_BAIT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'changeStatusProcessed', false);
    Vue.set(state, 'changeStatusProcessedError', 'Appointment not converted to MA.');
  },

};
