export const GET_MAIL_LISTS = 'GET_MAIL_LISTS';
export const SET_MAIL_LISTS = 'SET_MAIL_LISTS';
export const GET_MAIL_LISTS_ERROR = 'GET_MAIL_LISTS_ERROR';

export const GET_SMS_INFO = 'GET_SMS_INFO';
export const SET_SMS_INFO = 'SET_SMS_INFO';
export const GET_SMS_INFO_ERROR = 'GET_SMS_INFO_ERROR';

export const SET_SELECTED_AUDIENCES = 'SET_SELECTED_AUDIENCES';
export const UNSET_SELECTED_AUDIENCES = 'UNSET_SELECTED_AUDIENCES';

export const GET_SEND_BULK_SMS = 'GET_SEND_BULK_SMS';
export const SET_SEND_BULK_SMS_SUCCESS = 'SET_SEND_BULK_SMS_SUCCESS';
export const GET_SEND_BULK_SMS_ERROR = 'GET_SEND_BULK_SMS_ERROR';

export const GET_ACCEPT_SMS_CREDIT = 'GET_ACCEPT_SMS_CREDIT';
export const SET_ACCEPT_SMS_CREDIT = 'SET_ACCEPT_SMS_CREDIT';
export const GET_ACCEPT_SMS_CREDIT_ERROR = 'GET_ACCEPT_SMS_CREDIT_ERROR';

export const GET_NOT_ACCEPT_SMS_CREDIT = 'GET_NOT_ACCEPT_SMS_CREDIT';
export const SET_NOT_ACCEPT_SMS_CREDIT = 'SET_NOT_ACCEPT_SMS_CREDIT';
export const GET_NOT_ACCEPT_SMS_CREDIT_ERROR = 'GET_NOT_ACCEPT_SMS_CREDIT_ERROR';
