import ApiService from '@/core/api/api.service';


export default class EmailServices extends ApiService {
  async fetchSenders() {
    this.url = 'sender_emails';
    this.params = {
      __limit: 250,
    };
    const { result: { data } } = await this.get();
    return data;
  }

  sendToContact(payload) {
    this.payload = payload;
    this.url = 'emails/contact';
    return this.post();
  }

  sendToSolicitor(payload) {
    this.payload = payload;
    this.url = '/cr/solicitor/email';
    return this.post();
  }

  sendToFormEntry(payload) {
    this.payload = payload;
    this.url = 'emails/form_entry';
    return this.post();
  }


  sendToAudienceBulk(payload) {
    this.payload = payload;
    this.url = 'bulk_mails';
    return this.baseRemitter('post', 'message');
  }

  sendViewingEmail(payload) {
    this.payload = payload;
    this.url = `cr/viewing_feedbacks/${payload.viewing_feedback_id}/report/email`;
    return this.baseRemitter('post', 'message');
  }

  sendOfferEmail(payload) {
    this.payload = payload;
    this.url = `cr/offers/${payload.offer_id}/${payload.profile_id}/report/email`;
    return this.__baseRemitter('post', 'data');
  }

  sendViewingsEmail(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/report/mail/${payload.receiver}/${payload.viewing_appointment_id}`;
    return this.baseRemitter('post', 'message');
  }

  sendRequestFeedbackEmail(payload) {
    this.payload = payload;
    this.url = `cr/viewing_appointments/report/applicant_feedback_email/${payload.viewing_appointment_id}`;
    return this.baseRemitter('post', 'message');
  }

  sendRequestOfferEmail(payload) {
    this.payload = payload;
    this.url = `cr/request_offer_form/${payload.viewing_appointment_id}/request/email`;
    return this.__baseRemitter('post', 'data', false);
  }


  sendMosEmail(memorandumId, payload) {
    this.payload = payload;
    this.url = `/cr/memorandum/${memorandumId}/memorandum_notify`;
    return this.baseRemitter('post', 'data', false);
  }

  sendSingleMosEmail(memorandumId, payload) {
    this.payload = payload;
    this.url = `/cr/memorandum/${memorandumId}/memorandum_individual_notify`;
    return this.baseRemitter('post', 'data', false);
  }

  sendPiqForm(payload) {
    this.payload = payload;
    this.url = `/properties/property_information_form/${payload.property_profile_id}/request/email`;
    return this.baseRemitter('post', 'data', false);
  }
}
