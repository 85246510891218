export default {
  request: {
    action: null,
  },
  sort: {
    sequence: null,
    field: null,
    title: null,
  },
  payload: {
    limit: 20,
    search: null,
    sort: null,
    staticFilter: null,
    dynamicFilter: null,
    searchType: null,
  },
  pagination: {
    total: 0,
    perPage: 20,
    totalPages: 5,
    activePage: 1,
  },
  centralLoader: false,
  runningRequests: [],
};
