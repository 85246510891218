import authNotRequired from '@/router/auth/authNotRequired';

export default [{
  path: '/auth',
  beforeEnter: authNotRequired,
  component: () => import('@/views/auth/Index.vue'),
  children: [{
    path: '/',
    meta: {
      title: 'Login',
    },
    component: () => import('@/views/auth/TheLogin.vue'),
  },
  {
    path: 'forgot',
    meta: {
      title: 'Forgot Password',
    },
    name: 'TheForgotPassword',
    component: () => import('@/views/auth/TheForgotPassword.vue'),
  },
  {
    path: 'backend-login',
    meta: {
      title: 'Auto Redirecting...',
    },
    name: 'BackendLogin',
    component: () => import('@/views/auth/BackendLogin.vue'),
  },
  {
    path: 'reset-password/',
    meta: {
      title: 'Reset Password',
    },
    name: 'TheResetPassword',
    component: () => import('@/views/auth/TheResetPassword.vue'),
  }],
}];
