export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_PROPERTY_PHOTOS = 'SET_PROPERTY_PHOTOS';
export const GET_PROPERTIES = 'GET_PROPERTIES';
export const GET_PROPERTIES_ERROR = 'GET_PROPERTIES_ERROR';

export const SET_AWAITING_VALUATIONS = 'SET_AWAITING_VALUATIONS';
export const GET_AWAITING_VALUATIONS = 'GET_AWAITING_VALUATIONS';
export const GET_AWAITING_VALUATIONS_ERROR = 'GET_AWAITING_VALUATIONS_ERROR';

export const SET_PROPERTY_TYPES = 'SET_PROPERTY_TYPES';
export const GET_PROPERTY_TYPES = 'GET_PROPERTY_TYPES';
export const GET_PROPERTY_TYPES_ERROR = 'GET_PROPERTY_TYPES_ERROR';

export const SET_PROPERTY_STYLES = 'SET_PROPERTY_STYLES';
export const GET_PROPERTY_STYLES = 'GET_PROPERTY_STYLES';
export const GET_PROPERTY_STYLES_ERROR = 'GET_PROPERTY_STYLES_ERROR';

export const SET_TENURE_TYPES = 'SET_TENURE_TYPES';
export const GET_TENURE_TYPES = 'GET_TENURE_TYPES';
export const GET_TENURE_TYPES_ERROR = 'GET_TENURE_TYPES_ERROR';

export const SET_UPDATED_PROPERTY = 'SET_UPDATED_PROPERTY';
export const GET_UPDATE_PROPERTY = 'GET_UPDATE_PROPERTY';
export const GET_UPDATE_PROPERTY_ERROR = 'GET_UPDATE_PROPERTY_ERROR';

export const SET_UPDATED_PROPERTY_PROFILE = 'SET_UPDATED_PROPERTY_PROFILE';
export const GET_UPDATE_PROPERTY_PROFILE = 'GET_UPDATE_PROPERTY_PROFILE';
export const GET_UPDATE_PROPERTY_PROFILE_ERROR = 'GET_UPDATE_PROPERTY_PROFILE_ERROR';

export const SET_PROPERTY_PROFILE = 'SET_PROPERTY_PROFILE';
export const GET_PROPERTY_PROFILE = 'GET_PROPERTY_PROFILE';
export const UPDATE_PROPERTY_PROFILE_PARTIALLY = 'UPDATE_PROPERTY_PROFILE_PARTIALLY';
export const GET_PROPERTY_PROFILE_ERROR = 'GET_PROPERTY_PROFILE_ERROR';

export const SET_PROPERTY_PRICES = 'SET_PROPERTY_PRICES';
export const GET_PROPERTY_PRICES = 'GET_PROPERTY_PRICES';
export const GET_PROPERTY_PRICES_ERROR = 'GET_PROPERTY_PRICES_ERROR';

export const SET_PROPERTY_CONTACTS = 'SET_PROPERTY_CONTACTS';
export const GET_PROPERTY_CONTACTS = 'GET_PROPERTY_CONTACTS';
export const RESET_PROPERTY_CONTACTS = 'RESET_PROPERTY_CONTACTS';
export const GET_PROPERTY_CONTACTS_ERROR = 'GET_PROPERTY_CONTACTS_ERROR';

export const SET_EXPORT_PROPERTIES = 'SET_EXPORT_PROPERTIES';
export const GET_EXPORT_PROPERTIES = 'GET_EXPORT_PROPERTIES';
export const GET_EXPORT_PROPERTIES_ERROR = 'GET_EXPORT_PROPERTIES_ERROR';

export const GET_ADVANCED_SEARCH = 'GET_ADVANCED_SEARCH';
export const SET_ADVANCED_SEARCH = 'SET_ADVANCED_SEARCH';
export const GET_ADVANCED_SEARCH_ERROR = 'GET_ADVANCED_SEARCH_ERROR';

export const GET_PROPERTY_FROM_ADDRESS = 'GET_PROPERTY_FROM_ADDRESS';
export const SET_PROPERTY_FROM_ADDRESS = 'SET_PROPERTY_FROM_ADDRESS';
export const GET_PROPERTY_FROM_ADDRESS_ERROR = 'GET_PROPERTY_FROM_ADDRESS_ERROR';

export const SET_ALL_PROPERTY = 'SET_ALL_PROPERTY';
export const UNSET_ALL_PROPERTY = 'UNSET_ALL_PROPERTY';
export const CLEAR_SELECTED_PROPERTIES = 'CLEAR_SELECTED_PROPERTIES';

export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const UNSET_SELECTED_PROPERTY = 'UNSET_SELECTED_PROPERTY';

export const GET_BAIT_MODULE_ENTRIES = 'GET_BAIT_MODULE_ENTRIES';
export const SET_BAIT_MODULE_ENTRIES = 'SET_BAIT_MODULE_ENTRIES';
export const GET_BAIT_MODULE_ENTRIES_ERROR = 'GET_BAIT_MODULE_ENTRIES_ERROR';

export const GET_DELETE_BAIT_MODULE_ENTRY = 'GET_DELETE_BAIT_MODULE_ENTRY';
export const SET_DELETE_BAIT_MODULE_ENTRY = 'SET_DELETE_BAIT_MODULE_ENTRY';
export const GET_DELETE_BAIT_MODULE_ENTRY_ERROR = 'GET_DELETE_BAIT_MODULE_ENTRY_ERROR';

export const GET_CREATE_GALLERY_PHOTO = 'GET_CREATE_GALLERY_PHOTO';
export const SET_CREATE_GALLERY_PHOTO = 'SET_CREATE_GALLERY_PHOTO';
export const GET_CREATE_GALLERY_PHOTO_ERROR = 'GET_CREATE_GALLERY_PHOTO_ERROR';

export const GET_GALLERY_PHOTOS = 'GET_GALLERY_PHOTOS';
export const SET_GALLERY_PHOTOS = 'SET_GALLERY_PHOTOS';
export const GET_GALLERY_PHOTOS_ERROR = 'GET_GALLERY_PHOTOS_ERROR';

export const GET_DELETE_GALLERY_PHOTOS = 'GET_DELETE_GALLERY_PHOTOS';
export const SET_DELETE_GALLERY_PHOTOS = 'SET_DELETE_GALLERY_PHOTOS';
export const GET_DELETE_GALLERY_PHOTOS_ERROR = 'GET_DELETE_GALLERY_PHOTOS_ERROR';

export const GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY = 'GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY';
export const SET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY = 'SET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY';
export const GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY_ERROR = 'GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY_ERROR';

export const SET_REVOKE_PROPERTY_CONTACT = 'SET_REVOKE_PROPERTY_CONTACT';
export const GET_REVOKE_PROPERTY_CONTACT = 'GET_REVOKE_PROPERTY_CONTACT';
export const GET_REVOKE_PROPERTY_CONTACT_ERROR = 'GET_REVOKE_PROPERTY_CONTACT_ERROR';

export const SET_ADD_CONTACT_TO_PROPERTY = 'SET_ADD_CONTACT_TO_PROPERTY';
export const GET_ADD_CONTACT_TO_PROPERTY = 'GET_ADD_CONTACT_TO_PROPERTY';
export const GET_ADD_CONTACT_TO_PROPERTY_ERROR = 'GET_ADD_CONTACT_TO_PROPERTY_ERROR';

export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_ERROR = 'GET_COMPANY_INFO_ERROR';

export const GET_UPDATE_PROPERTY_INFORMATION = 'GET_UPDATE_PROPERTY_INFORMATION';
export const SET_UPDATED_PROPERTY_INFORMATION = 'SET_UPDATED_PROPERTY_INFORMATION';
export const SET_UPDATE_PROPERTY_INFORMATION_ERROR = 'SET_UPDATE_PROPERTY_INFORMATION_ERROR';

export const GET_PROPERTY_PROFILE_TIMELINE_DATA = 'GET_PROPERTY_PROFILE_TIMELINE_DATA';
export const GET_PROPERTY_PROFILE_TIMELINE_DATA_ERROR = 'GET_PROPERTY_PROFILE_TIMELINE_DATA_ERROR';
export const SET_PROPERTY_PROFILE_TIMELINE_DATA = 'SET_PROPERTY_PROFILE_TIMELINE_DATA';
export const SET_PROPERTY_PROFILE_LAST_TIMELINE_DATA = 'SET_PROPERTY_PROFILE_LAST_TIMELINE_DATA';

export const GET_RELATED_PROPERTY_PROFILES = 'GET_RELATED_PROPERTY_PROFILES';
export const GET_RELATED_PROPERTY_PROFILES_ERROR = 'GET_RELATED_PROPERTY_PROFILES_ERROR';
export const SET_RELATED_PROPERTY_PROFILES = 'SET_RELATED_PROPERTY_PROFILES';

export const GET_PROPERTY_PROFILE_LIVE = 'GET_PROPERTY_PROFILE_LIVE';
export const GET_PROPERTY_PROFILE_LIVE_ERROR = 'GET_PROPERTY_PROFILE_LIVE_ERROR';
export const SET_PROPERTY_PROFILE_LIVE = 'SET_PROPERTY_PROFILE_LIVE';

export const GET_PRICE_QUALIFIERS = 'GET_PRICE_QUALIFIERS';
export const GET_PRICE_QUALIFIERS_ERROR = 'GET_PRICE_QUALIFIERS_ERROR';
export const SET_PRICE_QUALIFIERS = 'SET_PRICE_QUALIFIERS';

export const SET_CRM_NOTES_SUCCESS = 'SET_CRM_NOTES_SUCCESS';
export const SET_CRM_NOTES_ERROR = 'SET_CRM_NOTES_ERROR';

export const SET_PROPERTY_KEY_TIMELINE_LIST = 'SET_PROPERTY_KEY_TIMELINE_LIST';
export const SET_PROPERTY_KEY_TIMELINE_LIST_ITEM = 'SET_PROPERTY_KEY_TIMELINE_LIST_ITEM';
