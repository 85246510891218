export default [
  {
    path: 'statistics/dashboard',
    meta: {
      title: 'Dashboard',
      breadcrumb: [
        { name: 'Dashboard' },
      ],
      hidePageHeader: true,
    },
    name: 'TheDashboard',
    component: () => import('@/modules/dashboard/views/TheDashboard.vue'),
  },
  {
    path: '/ma_pipeline/details/sales/this_month_total_appointments',
    meta: {
      title: 'Sales Market Appraisals Booked',
    },
    name: 'ThisMonthSalesBooked',
    props: {
      search_type: 'Booked', transaction_type_id: 1, date_interval: 'month', ma_appointment_status: 'booked',
    },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/sales/this_month_total_appointments',
    meta: {
      title: 'Sales - Market Appraisals Booked',
    },
    name: 'ThisMonthSalesBookedDashboard',
    props: {
      search_type: 'Booked', transaction_type_id: 1, date_interval: 'month', ma_appointment_status: 'booked',
    },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/ma_pipeline/details/sales/this_month_total_attendeds',
    meta: {
      title: 'Sales - Market Appraisals Attended',
    },
    name: 'ThisMonthSalesAttends',
    props: {
      search_type: 'Attended', transaction_type_id: 1, date_interval: 'month', ma_appointment_status: 'attended',
    },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/sales/this_month_total_attendeds',
    meta: {
      title: 'Sales -  Market Appraisals Attended',
    },
    name: 'ThisMonthSalesAttendsDashboard',
    props: {
      search_type: 'Attended', transaction_type_id: 1, date_interval: 'month', ma_appointment_status: 'attended',
    },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/ma_pipeline/details/sales/this_month_total_instructions',
    meta: {
    // title: 'Sales Market Appraisals Instructed us This Month',
      title: 'Sales - Instructions This Month',
    },
    name: 'ThisMonthSalesInstruction',
    props: { search_type: 'Instructions', transaction_type_id: 1, date_interval: 'month' },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/sales/this_month_sstc_properties',
    meta: {
      title: 'Sales Market Appraisals SSTC This Month',
    },
    name: 'ThisMonthSalesSSTC',
    props: { type: 'bt/reports/dashboard/detail/this_month_sstc_properties', transaction_type_id: 1 },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/sales/this_month_exchanged_completed_properties',
    meta: {
      title: 'Sales Market Appraisals Exchanged Completed This Month',
    },
    name: 'ThisMonthSalesExchangedCompleted',
    props: { type: 'bt/reports/dashboard/detail/this_month_exchanged_completed_properties', transaction_type_id: 1 },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/ma_pipeline/details/lettings/this_month_total_appointments',
    meta: {
      title: 'Lettings -  Market Appraisals Booked',
    },
    name: 'ThisMonthLettingsBooked',
    props: { search_type: 'Booked', transaction_type_id: 2, ma_appointment_status: 'booked' },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/lettings/this_month_total_appointments',
    meta: {
      title: 'Lettings -  Market Appraisals Booked',
    },
    name: 'ThisMonthLettingsBookedDashboard',
    props: { search_type: 'Booked', transaction_type_id: 2, ma_appointment_status: 'booked' },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/ma_pipeline/details/lettings/this_month_total_attendeds',
    meta: {
      title: 'Lettings -  Market Appraisals Attended',
    },
    name: 'ThisMonthLettingsAttends',
    props: { search_type: 'Attended', transaction_type_id: 2, ma_appointment_status: 'attended' },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/lettings/this_month_total_attendeds',
    meta: {
      title: 'Lettings -  Market Appraisals Attended',
    },
    name: 'ThisMonthLettingsAttendsDashboard',
    props: { search_type: 'Attended', transaction_type_id: 2, ma_appointment_status: 'attended' },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/lettings/this_month_total_instructions',
    meta: {
    // title: 'Lettings Market Appraisals Instructed us This Month',
      title: 'Lettings -  Instructed',
    },
    name: 'ThisMonthLettingsInstruction',
    props: { search_type: 'Instructions', transaction_type_id: 2, date_interval: 'month' },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/lettings/this_month_sstc_properties',
    meta: {
      title: 'Lettings Market Appraisals SSTC This Month',
    },
    name: 'ThisMonthLettingsSSTC',
    props: { type: 'bt/reports/dashboard/detail/this_month_sstc_properties', transaction_type_id: 2 },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  },
  {
    path: '/dashboard/details/lettings/this_month_let_properties',
    meta: {
      title: 'LET',
    },
    name: 'ThisMonthLettingsLET',
    props: { type: 'bt/reports/dashboard/detail/this_month_let_properties', transaction_type_id: 2 },
    component: () => import('@/modules/properties/views/DashboardProperties.vue'),
  }];
