import Akya from '@/libraries/akya';
import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestUploadCustomCdn = ({ commit }) => commit(types.GET_UPLOAD_CUSTOM_CDN);
export const receiveUploadCustomCdnSuccess = ({ commit }, data) => commit(types.SET_UPLOAD_CUSTOM_CDN, data);
export const receiveUploadCustomCdnError = ({ commit }, error) => commit(types.GET_UPLOAD_CUSTOM_CDN_ERROR, error);
export const fetchUploadCustomCdn = ({ dispatch }, payload) => {
  dispatch('requestUploadCustomCdn');
  Api.postFile('custom_cdn_upload', payload)
    .then(({ data }) => { dispatch('receiveUploadCustomCdnSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUploadCustomCdnError', response); });
};

export const requestUploadShortener = ({ commit }) => commit(types.GET_UPLOAD_SHORTENER);
export const receiveUploadShortenerSuccess = ({ commit }, data) => commit(types.SET_UPLOAD_SHORTENER, data);
export const receiveUploadShortenerError = ({ commit }, error) => commit(types.GET_UPLOAD_SHORTENER_ERROR, error);
export const fetchUploadShortener = ({ dispatch }, payload) => {
  dispatch('requestUploadShortener');
  Akya.post('shortener', payload)
    .then(({ data }) => { dispatch('receiveUploadShortenerSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUploadShortenerError', response); });
};
