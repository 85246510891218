import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.SET_MOS_EMAIL_SETTINGS](state, data) {
    Vue.set(state, 'mosEmailSettings', data);
  },
  [types.SET_CHAIN_DATA](state, data) {
    Vue.set(state, 'chainData', data);
  },
  [types.RESET_OFFER_DATA](state) {
    Vue.set(state, 'offerData', {});
  },
  [types.SET_OFFER_DATA](state, data) {
    Vue.set(state, 'offerData', data);
  },
};
