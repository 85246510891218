import Vue from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import get from 'lodash/get';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import {
  addDate,
  backendDateFormat,
  dateDiff,
} from '@/core/services/dates/dates.service';
import ViewingStatuesConstants from '@/modules/offers/constants/viewingStatues.constants';
import { isDevelopment } from '@/modules/users/stores/getters';
import * as types from './mutation_types';

export default {
  [types.GET_EVENTS](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EVENTS_ERROR](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', data);
  },
  [types.SET_EVENTS](state, data) {
    Vue.set(state, 'isLoading', false);
    let incomingData = [];
    if (get(state, 'events.length')) {
      incomingData = [...state.events, ...data];
      const eventMap = incomingData.reduce((map, event) => {
        map[event.event_id] = event;
        return map;
      }, {});
      incomingData = Object.values(eventMap);
    } else {
      incomingData = data;
    }
    Vue.set(state, 'events', incomingData);
  },
  [types.SET_SKELETONS](state, data) {
    if (get(state, 'skeletons.length')) {
      if (!isEqual(state.skeletons, data)) {
        Vue.set(state, 'skeletons', data);
      }
    } else {
      Vue.set(state, 'skeletons', data);
    }
  },
  [types.GET_EVENT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'event', null);
    Vue.set(state, 'error', null);
  },
  [types.GET_EVENT_ERROR](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', data);
  },
  [types.SET_EVENT](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'event', data);
  },
  [types.UNSET_EVENT](state) {
    Vue.set(state, 'event', null);
  },
  [types.REMOVE_EVENT](state, data) {
    const clickedEventId = data.eventId;
    const clickedEventSkeletonId = data.recurringEventId;
    const clickedEventDate = data.start_date;
    if (data.actionType === 'single') {
      state.events = state.events.filter(event => event.event_id !== clickedEventId);
    } else if (data.actionType === 'all') {
      state.events = state.events.filter(event => event.recurring_event_id !== clickedEventSkeletonId);
    } else {
      state.events = state.events.filter(event => event.recurring_event_id !== clickedEventSkeletonId || backendDateFormat(event.start) < backendDateFormat(clickedEventDate));
    }
  },
  [types.GET_EVENT_TYPES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_EVENT_TYPES_ERROR](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', data);
  },
  [types.SET_EVENT_TYPES](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'eventTypes', data);
  },
  [types.GET_CALENDAR_EVENTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CALENDAR_EVENTS](state, data) {
    Vue.set(state, 'storeCalendarEvents', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CALENDAR_EVENTS_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    });
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CALENDAR_EVENTS_BY_STATUS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CALENDAR_EVENTS_BY_STATUS](state, data) {
    Vue.set(state, 'calendarEventsByStatus', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CALENDAR_EVENTS_BY_STATUS_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    });
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_EVENT_STATUSES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_EVENT_STATUSES](state, data) {
    Vue.set(state, 'eventStatuses', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EVENT_STATUSES_ERROR](state, error) {
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    });
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_CALENDAR_EVENT_STATUS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'calendarEventStatusUpdateSuccess', null);
    Vue.set(state, 'calendarEventStatusUpdateError', null);
  },
  [types.UPDATE_RECURRING_EVENT](state, data) {
    state.events = state.events.filter(event => event.recurring_event_id !== data.event.recurring_event_id);
  },
  [types.REMOVE_RECURRING_EVENTS](state) {
    const removedEvents = state.events.filter(event => event.event_type_id !== 11);
    Vue.set(state, 'events', removedEvents);
    Vue.set(state, 'fetchedRecurring', { start: null, end: null });
  },
  [types.PATCH_RECURRING_EVENT](state, data) {
    const clickedEventId = data.eventId;
    const clickedEventSkeletonId = data.event.recurring_event_id;
    const clickedEventStartDate = data.event.start;
    const clickedEventEndDate = data.event.end;
    const eventData = data.event;
    if (data.actionType === 'single') {
      state.events = state.events.map((event) => {
        if (event.event_id === clickedEventId) {
          return { ...event, ...eventData, isCustomized: true };
        }
        return event;
      });
    } else {
      const expiredState = state.events.find(item => item.event_id === clickedEventId);
      let relatedEvents = state.events.filter(item => item.recurring_event_id === clickedEventSkeletonId && !item.isCustomized);
      if (data.actionType === 'future') {
        relatedEvents = state.events.filter(item => item.recurring_event_id === clickedEventSkeletonId && backendDateFormat(item.start) >= backendDateFormat(clickedEventStartDate) && !item.isCustomized);
      }
      const ittirme = dateDiff(clickedEventStartDate.split(' ')[0], expiredState.start.split(' ')[0], 'day');
      relatedEvents.forEach((item) => {
        const dayDiff = Math.ceil(dateDiff(item.start, clickedEventStartDate, 'day', !eventData.allDay));
        item.start = backendDateFormat(addDate(clickedEventStartDate, dayDiff + ittirme, 'day'), !eventData.allDay);
        item.end = backendDateFormat(addDate(clickedEventEndDate, dayDiff + ittirme, 'day'), !eventData.allDay);
        item.allDay = eventData.allDay;
        const findIndex = state.events.findIndex(evnt => evnt.event_id === item.event_id);
        state.events[findIndex] = item;
      });
    }
  },
  [types.UPDATE_CALENDAR_EVENT_STATUS_SUCCESS](state, success) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'calendarEventStatusUpdateSuccess', success.message);
  },
  [types.UPDATE_CALENDAR_EVENT_STATUS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'calendarEventStatusUpdateError', error.data.message);
  },

  [types.CREATE_CALENDAR_EVENT](state) {
    Vue.set(state, 'calendarEventProcessed', true);
    Vue.set(state, 'calendarEventProcessedSuccess', false);
    Vue.set(state, 'calendarEventProcessedError', false);
  },
  [types.CREATE_CALENDAR_EVENT_SUCCESS](state) {
    Vue.set(state, 'calendarEventProcessed', false);
    Vue.set(state, 'calendarEventProcessedSuccess', 'General event created.');
  },
  [types.CREATE_CALENDAR_EVENT_ERROR](state, error) {
    Vue.set(state, 'calendarEventProcessed', false);
    let showingMessage = null;
    if (error.data.code === 400) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'calendarEventProcessedError', showingMessage);
    } else {
      Vue.set(state, 'calendarEventProcessedError', error.data.message);
    }
  },
  [types.UPDATE_CALENDAR_EVENT](state) {
    Vue.set(state, 'updateCalendarEventProcessed', true);
    Vue.set(state, 'updateCalendarEventError', null);
    Vue.set(state, 'updateCalendarEventSuccess', null);
  },
  [types.UPDATE_CALENDAR_EVENT_SUCCESS](state, success) {
    Vue.set(state, 'updateCalendarEventSuccess', success.data.result.data);
    Vue.set(state, 'updateCalendarEventProcessed', false);
  },
  [types.UPDATE_CALENDAR_EVENT_ERROR](state, error) {
    let showingMessage = null;
    if (error.data.code === 400) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'updateCalendarEventError', showingMessage);
    } else {
      Vue.set(state, 'updateCalendarEventError', error.data.message);
    }
    Vue.set(state, 'updateCalendarEventProcessed', false);
  },
  [types.CALENDAR_USER_SETTINGS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.CALENDAR_USER_SETTINGS_SUCCESS](state, success) {
    Vue.set(state, 'user_settings', success.data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CALENDAR_USER_SETTINGS_ERROR](state, error) {
    let message = '';
    if (error.data.code === 400) {
      message = Object.keys(error.data.result).map(el => error.data.result[el]);
      message = message.map(item => item.join());
      message = message.join();
    }
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 10000,
    });
    Vue.set(state, 'isLoading', false);
  },
  [types.SEARCH_CALENDAR_EVENT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'searchCalendarEvents', []);
  },
  [types.SEARCH_CALENDAR_EVENT_SUCCESS](state, success) {
    Vue.set(state, 'searchCalendarEvents', success);
    Vue.set(state, 'isLoading', false);
  },
  [types.SEARCH_CALENDAR_EVENT_ERROR](state) {
    Vue.set(state, 'searchCalendarEvents', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_COLLAPSES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'calendarCollapses', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_USERS](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedUsers', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_TRANSACTION_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedTransactionTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_VIEWING_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedViewingTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_CONFIRMATION_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedConfirmationTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_DIALOG_USERS](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedDialogUsers', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_DIALOG_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedDialogTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_DIALOG_TRANSACTION_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedDialogTransactionTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_DIALOG_VIEWING_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedDialogViewingTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_DIALOG_CONFIRMATION_TYPES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedDialogConfirmationTypes', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_SELECT_DATE](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedDatepicker', data);
    Vue.set(state, 'selectedDateToDayView', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.DROP_RESIZE_CALENDAR_EVENT](state, data) {
    Vue.set(state, 'dropResizeCalendarEvent', data);
  },

  [types.SET_FETCHED_RANGE](state, range) {
    Vue.set(state, 'isLoading', false);
    const dynamicRange = { start: range.start, end: range.end };
    if (state.fetchedRange.start && state.fetchedRange.end) {
      if (range.start < state.fetchedRange.start) {
        dynamicRange.end = state.fetchedRange.end;
      } else if (range.end > state.fetchedRange.end) {
        dynamicRange.start = state.fetchedRange.start;
      }
    }
    Vue.set(state, 'fetchedRange', dynamicRange);
  },

  [types.SET_FETCHED_RECURRING](state, range) {
    Vue.set(state, 'isLoading', false);
    const dynamicRange = { start: range.start, end: range.end };
    if (state.fetchedRecurring.start && state.fetchedRecurring.end) {
      if (range.start < state.fetchedRecurring.start) {
        dynamicRange.end = state.fetchedRecurring.end;
      } else if (range.end > state.fetchedRecurring.end) {
        dynamicRange.start = state.fetchedRecurring.start;
      }
    }
    Vue.set(state, 'fetchedRecurring', dynamicRange);
  },

  [types.SET_FOLLOW_UP](state, count) {
    Vue.set(state, 'isLoading', false);
    if (state.followUpCount === null) {
      Vue.set(state, 'followUpCount', count);
    }
  },

  [types.SET_MA_NOT_YET_SENT](state, count) {
    Vue.set(state, 'isLoading', false);
    if (state.maNotYetSentCount === null) {
      Vue.set(state, 'maNotYetSentCount', count);
    }
  },

  // !IMPORTANT please contact with Ege before modifying here.
  [types.ADD_EVENT](state, data) {
    Vue.set(state, 'isLoading', false);
    const isDuplicate = element => element.event_id === data.event_id;
    const duplicatedIndex = state.events.findIndex(isDuplicate);
    if (duplicatedIndex !== -1) {
      const redundant = state.events.splice(duplicatedIndex, 1);
      redundant[0] = data;
      state.events.push(redundant[0]);
    } else {
      state.events.push(data);
    }
  },

  [types.PATCH_EVENT](state, data) {
    Vue.set(state, 'isLoading', false);
    const eventToPatch = state.events.find(el => el.event_id === data.event_id);
    eventToPatch.after_buffer_date = data.after_buffer_date;
    eventToPatch.before_buffer_date = data.before_buffer_date;
    eventToPatch.start = data.start;
    eventToPatch.end = data.end;
  },

  [types.RESET_STATE](state) {
    Vue.set(state, 'events', []);
    Vue.set(state, 'fetchedRange', { start: null, end: null });
    Vue.set(state, 'fetchedRecurring', { start: null, end: null });
  },

  // Viewing operations
  [types.SEARCH_APPLICANT_OR_PROPERTY](state, type) {
    if (type === 'applicant') {
      state.show.searchApplicant = true;
      state.show.searchProperty = false;
      state.show.selectedApplicant = false;
      state.show.selectedProperty = false;
      state.show.viewing = false;
      state.show.isSearch = true;
    } else {
      state.show.searchProperty = true;
      state.show.viewing = false;
      state.show.selectedApplicant = false;
      state.show.selectedProperty = false;
      state.show.isSearch = true;
    }
  },
  [types.RESET_SEARCH_APPLICANT_OR_PROPERTY](state) {
    state.show.searchApplicant = false;
    state.show.searchProperty = false;
    state.show.searchProfiles = false;
    state.show.isSearch = false;
    state.show.viewing = true;
  },
  [types.REMOVE_APPLICANT](state) {
    Vue.set(state, 'selectedApplicant', { contact: null, applicant: null });
    state.show.selectedApplicant = false;
  },
  [types.REMOVE_PROPERTY](state) {
    Vue.set(state, 'selectedProperty', { property: null });
    state.show.selectedProperty = false;
    state.viewingData.title = 'Viewing';
  },
  [types.SET_SELECTED_PROPERTY](state, property) {
    Vue.set(state, 'selectedProperty', { property: null });
    state.selectedProperty.property = property;
    state.show.searchProperty = false;
    state.show.viewing = true;
    state.show.selectedProperty = true;
    state.show.isSearch = false;
    if (state.selectedApplicant.contact && state.selectedApplicant.applicant) {
      state.show.selectedApplicant = true;
    }
    if (property && state.viewingData.title === 'Viewing') {
      state.viewingData.title += ` ${property.property.property_address.full_address}`;
    }
  },
  [types.SET_SELECTED_APPLICANT](state, applicant) {
    state.selectedApplicant.applicant = null;
    state.selectedApplicant.applicant = applicant;
    state.show.searchProfiles = false;
    state.show.viewing = true;
    state.show.selectedApplicant = true;
    state.show.isSearch = false;
    if (state.selectedProperty.property) {
      state.show.selectedProperty = true;
    }
  },
  [types.UNSET_SELECTED_APPLICANT](state) {
    state.selectedApplicant.applicant = null;
    state.show.searchApplicant = true;
    state.show.searchProfiles = false;
  },
  async [types.SELECT_APPLICANT](state, data) {
    state.selectedApplicant.contact = null;
    Vue.set(state, 'searchProfiles', data.data);
    Vue.set(state, 'selectedApplicant', { applicant: data.data[0], contact: data.contact });
    state.show.searchProfiles = true;
    state.show.searchApplicant = false;
  },
  async [types.FIND_VIEWED_CONTACT](state, data) {
    if (state.selectedApplicant.applicant) {
      if (state.selectedApplicant.applicant.contacts.length === 1) {
        Vue.set(state, 'viewedContact', state.selectedApplicant.applicant.contacts[0]);
      } else {
        if (!data.filterBy) {
          if (data.contact_id) {
            data.filterBy = { contact_id: parseInt(data.contact_id, 10) };
          } else if (data.contact_applicant_contact_id) {
            data.filterBy = { contact_applicant_contact_id: data.contact_applicant_contact_id };
          }
        }
        if (data.filterBy) {
          const index = state.selectedApplicant.applicant.contacts.findIndex(contact => (data.filterBy.contact_id ? (contact.contact_id === data.filterBy.contact_id) : (contact.contact_applicant_contact_id === data.filterBy.contact_applicant_contact_id)));
          Vue.set(state, 'viewedContact', state.selectedApplicant.applicant.contacts.splice(index, 1)[0]);
          state.selectedApplicant.applicant.contacts.unshift(state.viewedContact);
        } else {
          Vue.set(state, 'viewedContact', state.selectedApplicant.applicant.contacts[0]);
        }
      }
    }
  },
  [types.RESET_VIEWING_STATE](state) {
    Vue.set(state, 'show', {
      searchApplicant: false,
      searchProperty: false,
      searchProfiles: false,
      viewing: true,
      selectedApplicant: false,
      selectedProperty: false,
      isSearch: false,
    });
    Vue.set(state, 'selectedApplicant', { contact: null, applicant: null });
    Vue.set(state, 'selectedProperty', { property: null });
    Vue.set(state, 'searchProfiles', null);
    Vue.set(state, 'viewedContact', {});
  },
  [types.HANDLE_VIEWING_DATA](state, config) {
    Vue.set(state, 'viewingData', { // Data reservation
      action: null,
      title: 'Viewing',
      contact_applicant_id: null,
      contact_applicant_contact_id: null,
      created_by: null,
      property_profile_id: null,
      branch_id: null,
      user_id: null,
      sticky_note: null,
      viewing_date: null,
      duration: 15,
      viewing_appointment_type_id: null,
      vendor_notified: false,
      applicant_notified: false,
      tenant_notified: false,
      viewing_appointment_status_id: null,
      transaction_type_id: null,
    });
    Vue.set(state, 'viewingData', {
      branch_id: config.data.branch_id,
      action: config.data.action,
      title: 'Viewing',
      user_id: config.data.user_id,
      created_by: config.data.created_by,
      viewing_date: config.data.viewing_date,
      transaction_type_id: config.data.transaction_type_id,
      viewing_appointment_status_id: config.data.viewing_appointment_status_id,
      viewing_appointment_type_id: config.data.viewing_appointment_type_id,
      sticky_note: config.data.sticky_note,
      users: config.data.users,
      duration: 15,
      vendor_notified: false,
      applicant_notified: false,
      tenant_notified: false,
      contact_applicant_id: null,
      contact_applicant_contact_id: null,
    });
    if (config.data && config.data.event && config.data.event.duration) {
      state.viewingData.duration = config.data.event.duration;
    }
    if (config.data.action !== 'create') {
      state.viewingData.applicant_notified = config.data.applicant_notified;
      state.viewingData.tenant_notified = config.data.tenant_notified;
      state.viewingData.vendor_notified = config.data.vendor_notified;
    }
    if (config.data.event) {
      if (get(config, 'data.event.title')) {
        state.viewingData.title = config.data.event.title;
        if (config.data.event.title === 'Viewing') {
          state.viewingData.title += ` ${config.data.property.property.property_address.full_address}`;
        }
      } else {
        state.viewingData.title += ` ${config.data.property.property.property_address.full_address}`;
      }
    }
  },
  async [types.SET_APPLICANT_LIST](state, { data, meta }) {
    if (state.applicantList) {
      Vue.set(state, 'applicantList', state.applicantList.concat(data));
    } else {
      Vue.set(state, 'applicantList', data);
    }
    Vue.set(state, 'applicantListMeta', Object.assign(state.applicantListMeta, meta));
  },
  async [types.RESET_APPLICANT_LIST](state) {
    Vue.set(state, 'searchApplicantKeyword', null);
    Vue.set(state, 'applicantList', null);
    Vue.set(state, 'applicantListMeta', {
      after_key: [],
      total: null,
    });
  },
  async [types.SET_APPLICANT_KEYWORD](state, keyword) {
    Vue.set(state, 'searchApplicantKeyword', keyword);
  },
  async [types.GET_TENANT](state, data) {
    Vue.set(state, 'tenant_contact', data);
  },
  async [types.CONTROL_STATUS_AVAILABILITY](state) {
    if (state.viewingData.viewing_appointment_status_id !== ViewingStatuesConstants.VIEWING_APPOINTMENT_STATUS.CANCELLED) {
      if (backendDateFormat(state.viewingData.viewing_date, true) < backendDateFormat(new Date(), true)) {
        if (state.viewingData.applicant_notified && state.viewingData.vendor_notified && state.viewingData.tenant_notified !== false) {
          state.viewingData.viewing_appointment_status_id = ViewingStatuesConstants.VIEWING_APPOINTMENT_STATUS.COMPLETED;
        } else {
          state.viewingData.viewing_appointment_status_id = ViewingStatuesConstants.VIEWING_APPOINTMENT_STATUS.FAILED_TO_CONFIRMED;
        }
      } else {
        state.viewingData.viewing_appointment_status_id = ViewingStatuesConstants.VIEWING_APPOINTMENT_STATUS.UPCOMING;
      }
    }
  },
  [types.OPEN_CALENDAR_MODAL](state, selector) {
    Vue.set(state, 'calendarModal', {
      show: true,
      selector,
      data: null,
    });
  },
  [types.SET_CANCELLED_EVENT_ITEM](state, data) {
    Vue.set(state.cancelledEvents, state.cancelledEvents.length, data);
  },
  [types.SET_EVENTS_LIST](state, data = []) {
    Vue.set(state, 'events', data);
  },
};
