export default [{
  name: 'CsvImport',
  path: '/csv-import/:csv_import_id',
  meta: {
    title: 'CSV Import',
  },
  component: () => import('../views/CsvImport.vue'),
  props: { mail_list_id: null },
},
];
