// eslint-disable-next-line camelcase
export const mail_templates_list = state => (state.mail_templates_list != null ? state.mail_templates_list : []);
// eslint-disable-next-line camelcase
export const companys_default_mail_template = state => state.companys_default_mail_template;
// eslint-disable-next-line camelcase
export const preview_template = state => state.preview_template;
export const success = state => state.success;
export const error = state => state.error;
// eslint-disable-next-line camelcase
export const error_preview_template = state => state.error_preview_template;
