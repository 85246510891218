import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.SET_PROPERTIES](state, data) {
    Vue.set(state, 'properties', [...data.result.data]);
    if (data.result.meta.total) {
      Vue.set(state, 'totalProperties', data.result.meta.total);
    } else {
      Vue.set(state, 'totalProperties', data.result.data[0].total_count);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTIES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'totalProperties', 0);
    Vue.set(state, 'properties', null);
  },
  [types.GET_PROPERTIES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'properties', []);
  },

  [types.GET_AWAITING_VALUATIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'totalAwaitingValuations', 0);
  },
  [types.SET_AWAITING_VALUATIONS](state, data) {
    Vue.set(state, 'awaitingValuations', data.result.data);
    if (data.result.meta.total) {
      Vue.set(state, 'totalAwaitingValuations', data.result.meta.total);
    } else if (data.result.data[0].total_count) {
      Vue.set(state, 'totalAwaitingValuations', data.result.data[0].total_count);
    } else {
      Vue.set(state, 'totalAwaitingValuations', 0);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_AWAITING_VALUATIONS_ERROR](state) {
    Vue.set(state, 'awaitingValuations', []);
    Vue.set(state, 'isLoading', false);
  },


  [types.SET_PROPERTY_TYPES](state, data) {
    Vue.set(state, 'propertyTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_TYPES](state) {
    Vue.set(state, 'propertyTypes', []);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_PROPERTY_TYPES_ERROR](state, message) {
    Vue.set(state, 'propertyTypesError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_PROPERTY_STYLES](state, data) {
    Vue.set(state, 'propertyTypeStyles', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_STYLES](state) {
    Vue.set(state, 'propertyTypeStyles', []);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_PROPERTY_STYLES_ERROR](state, message) {
    Vue.set(state, 'propertyTypeStylesError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_TENURE_TYPES](state, data) {
    Vue.set(state, 'tenureTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_TENURE_TYPES](state) {
    Vue.set(state, 'tenureTypes', []);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_TENURE_TYPES_ERROR](state, message) {
    Vue.set(state, 'tenureTypesError', message);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_EXPORT_PROPERTIES](state, data) {
    Vue.set(state, 'exportProperties', data.result.data);
  },
  [types.SET_UPDATED_PROPERTY](state, data) {
    Vue.set(state, 'property', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_PROPERTY](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_UPDATE_PROPERTY_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_PROPERTY_PHOTOS](state, data) {
    Vue.set(state, 'propertyPhotos', data);
  },

  [types.SET_PROPERTY_PROFILE](state, data) {
    Vue.set(state, 'propertyProfile', data.result.data);
  },

  [types.UPDATE_PROPERTY_PROFILE_PARTIALLY](state, data) {
    const propertyProfile = { ...state.propertyProfile, ...data };
    Vue.set(state, 'propertyProfile', propertyProfile);
  },

  [types.GET_PROPERTY_PROFILE](state) {
    Vue.set(state, 'propertyProfileError', null);
  },
  [types.GET_PROPERTY_PROFILE_ERROR](state, error) {
    Vue.set(state, 'propertyProfileError', error);
  },


  [types.GET_UPDATE_PROPERTY](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'propertyUpdateProcessed', true);
    Vue.set(state, 'propertyUpdateSuccess', false);
    Vue.set(state, 'propertyUpdateError', false);
  },
  [types.SET_UPDATED_PROPERTY](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyUpdateProcessed', false);
    Vue.set(state, 'propertyUpdateSuccess', data.message);
  },
  [types.GET_UPDATE_PROPERTY_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyUpdateProcessed', false);
    Vue.set(state, 'propertyUpdateError', error.message.data);
  },

  [types.GET_UPDATE_PROPERTY_PROFILE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'propertyProfileUpdateProcessed', true);
    Vue.set(state, 'propertyProfileUpdateSuccess', false);
    Vue.set(state, 'propertyProfileUpdateError', false);
  },
  [types.SET_UPDATED_PROPERTY_PROFILE](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyProfileUpdateProcessed', false);
    Vue.set(state, 'propertyProfileUpdateSuccess', data.message);
  },
  [types.GET_UPDATE_PROPERTY_PROFILE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyProfileUpdateProcessed', false);
    Vue.set(state, 'propertyProfileUpdateError', error.message.data);
  },

  [types.SET_PROPERTY_PRICES](state, data) {
    Vue.set(state, 'propertyPrices', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_PRICES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_PROPERTY_PRICES_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_PROPERTY_CONTACTS](state, data) {
    Vue.set(state, 'contacts', data.result.data);
  },
  [types.GET_PROPERTY_CONTACTS](state) {
    Vue.set(state, 'contacts', null);
    Vue.set(state, 'contactsError', null);
  },
  [types.RESET_PROPERTY_CONTACTS](state) {
    Vue.set(state, 'contacts', null);
    Vue.set(state, 'contactsError', null);
  },
  [types.GET_PROPERTY_CONTACTS_ERROR](state, error) {
    Vue.set(state, 'contactsError', error.data.message);
  },

  [types.SET_EXPORT_PROPERTIES](state, data) {
    Vue.set(state, 'exportProperties', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_EXPORT_PROPERTIES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_EXPORT_PROPERTIES_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_ADVANCED_SEARCH](state, data) {
    Vue.set(state, 'properties', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ADVANCED_SEARCH](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_ADVANCED_SEARCH_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      icon: 'error',
      title: 'No Result!',
      message: 'No Results',
    });
  },

  [types.SET_PROPERTY_FROM_ADDRESS](state, data) {
    Vue.set(state, 'property', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_FROM_ADDRESS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_PROPERTY_FROM_ADDRESS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      icon: 'error',
      title: 'No Result!',
      message: 'No Results',
    });
  },
  /* Select All Contact */
  [types.SET_ALL_PROPERTY](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedProperties', Array.from(new Set(state.selectedProperties.concat(data))));
    Vue.set(state, 'isLoading', false);
  },
  [types.UNSET_ALL_PROPERTY](state, data) {
    Vue.set(state, 'isLoading', true);
    for (let i = 0; i < data.length; i += 1) {
      const item = state.selectedProperties.findIndex(c => c.property_profile_id === data[i].property_profile_id);
      state.selectedProperties.splice(item, 1);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.CLEAR_SELECTED_PROPERTIES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedProperties', []);
    Vue.set(state, 'isLoading', false);
  },
  /* Select Single Contact */
  [types.SET_SELECTED_PROPERTY](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state.selectedProperties, state.selectedProperties.length, data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UNSET_SELECTED_PROPERTY](state, data) {
    Vue.set(state, 'isLoading', true);
    const item = state.selectedProperties.findIndex(c => c.property_profile_id === data.property_profile_id);
    state.selectedProperties.splice(item, 1);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_BAIT_MODULE_ENTRIES](state, data) {
    Vue.set(state, 'baitModuleEntries', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_MODULE_ENTRIES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BAIT_MODULE_ENTRIES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      icon: 'error',
      title: 'No Result!',
      message: 'No Results',
    });
  },

  [types.SET_DELETE_BAIT_MODULE_ENTRY](state) {
    Vue.set(state, 'baitModuleProcessed', true);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DELETE_BAIT_MODULE_ENTRY](state) {
    Vue.set(state, 'baitModuleProcessed', false);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_DELETE_BAIT_MODULE_ENTRY_ERROR](state) {
    Vue.set(state, 'baitModuleProcessed', false);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      icon: 'error',
      title: 'No Result!',
      message: 'No Results',
    });
  },

  [types.GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY](state) {
    Vue.set(state, 'baitModuleProcessed', true);
    Vue.set(state, 'baitModuleProcessedSuccess', false);
    Vue.set(state, 'baitModuleProcessedError', false);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY](state) {
    Vue.set(state, 'baitModuleProcessed', false);
    Vue.set(state, 'baitModuleProcessedSuccess', 'Module entry saved');
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_CREATE_OR_UPDATE_BAIT_MODULE_ENTRY_ERROR](state) {
    Vue.set(state, 'baitModuleProcessed', false);
    Vue.set(state, 'baitModuleProcessedError', 'Module entry not saved');
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      icon: 'error',
      title: 'No Result!',
      message: 'No Results',
    });
  },


  [types.GET_CREATE_GALLERY_PHOTO](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CREATE_GALLERY_PHOTO](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_GALLERY_PHOTO_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },


  [types.SET_GALLERY_PHOTOS](state, data) {
    Vue.set(state, 'galleryPhotos', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_GALLERY_PHOTOS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_GALLERY_PHOTOS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },

  [types.SET_DELETE_GALLERY_PHOTOS](state) {
    Vue.set(state, 'error', false);
  },
  [types.GET_DELETE_GALLERY_PHOTOS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_DELETE_GALLERY_PHOTOS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },
  [types.SET_REVOKE_PROPERTY_CONTACT](state, data) {
    Vue.set(state, 'revokePropertyContactSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_REVOKE_PROPERTY_CONTACT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'revokePropertyContactSuccess', null);
    Vue.set(state, 'revokePropertyContactError', null);
  },
  [types.GET_REVOKE_PROPERTY_CONTACT_ERROR](state, error) {
    Vue.set(state, 'revokePropertyContactError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ADD_CONTACT_TO_PROPERTY](state, data) {
    Vue.set(state, 'addPropertyProfileContactSuccess', data.data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ADD_CONTACT_TO_PROPERTY](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'addPropertyProfileContactSuccess', null);
    Vue.set(state, 'addPropertyProfileContactError', null);
  },
  [types.GET_ADD_CONTACT_TO_PROPERTY_ERROR](state, error) {
    Vue.set(state, 'addPropertyProfileContactError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_COMPANY_INFO](state) {
    Vue.set(state, 'getCompanyInfoSuccess', null);
    Vue.set(state, 'getCompanyInfoError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_COMPANY_INFO_SUCCESS](state, data) {
    Vue.set(state, 'getCompanyInfoSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_COMPANY_INFO_ERROR](state, message) {
    Vue.set(state, 'getCompanyInfoError', message);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_PROPERTY_INFORMATION](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'propertyInformationProcessed', true);
    Vue.set(state, 'propertyInformationSuccess', null);
    Vue.set(state, 'propertyInformationError', null);
  },
  [types.SET_UPDATED_PROPERTY_INFORMATION](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyInformationProcessed', false);
    if (data.propertyData.status && data.propertyProfileData.status) {
      Vue.set(state, 'propertyInformationSuccess', 'Updated successfully.');
    }
  },
  [types.SET_UPDATE_PROPERTY_INFORMATION_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyInformationProcessed', false);
    let showingMessage = null;
    if (error && error.data && error.data.code === 400) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'propertyInformationError', showingMessage);
    } else {
      Vue.set(state, 'propertyInformationError', error.data.message);
    }
  },

  [types.GET_PROPERTY_PROFILE_TIMELINE_DATA](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'propertyProfileTimelineData', null);
    Vue.set(state, 'propertyProfileTimelineDataError', null);
    Vue.set(state, 'totalPropertyProfileTimelineData', 0);
  },
  [types.GET_PROPERTY_PROFILE_TIMELINE_DATA_ERROR](state, message) {
    Vue.set(state, 'propertyProfileTimelineDataError', message);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'totalPropertyProfileTimelineData', 0);
  },
  [types.SET_PROPERTY_PROFILE_TIMELINE_DATA](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyProfileTimelineData', data.result.data);
    Vue.set(state, 'totalPropertyProfileTimelineData', data.result.meta.total);
  },
  [types.SET_PROPERTY_PROFILE_LAST_TIMELINE_DATA](state, data) {
    Vue.set(state, 'lastPropertyTimelineData', data);
  },

  [types.GET_RELATED_PROPERTY_PROFILES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'relatedPropertyProfiles', null);
    Vue.set(state, 'relatedPropertyProfilesError', null);
  },
  [types.GET_RELATED_PROPERTY_PROFILES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'relatedPropertyProfilesError', error.data.message);
  },
  [types.SET_RELATED_PROPERTY_PROFILES](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'relatedPropertyProfiles', data.result.data);
  },


  [types.GET_PROPERTY_PROFILE_LIVE](state) {
    Vue.set(state, 'propertyProfileLive', false);
    Vue.set(state, 'propertyProfileLiveError', false);
  },
  [types.GET_PROPERTY_PROFILE_LIVE_ERROR](state) {
    Vue.set(state, 'propertyProfileLiveError', null);
  },
  [types.SET_PROPERTY_PROFILE_LIVE](state, data) {
    Vue.set(state, 'propertyProfileLive', data);
  },

  [types.GET_PRICE_QUALIFIERS](state) {
    Vue.set(state, 'priceQualifiers', null);
    Vue.set(state, 'priceQualifiersError', null);
  },
  [types.GET_PRICE_QUALIFIERS_ERROR](state, message) {
    Vue.set(state, 'priceQualifiersError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_PRICE_QUALIFIERS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'priceQualifiers', data.result.data);
  },

  [types.SET_CRM_NOTES_SUCCESS](state, data) {
    Vue.set(state, 'sendCrmNotesSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_CRM_NOTES_ERROR](state, error) {
    Vue.set(state, 'sendCrmNotesError', error);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_PROPERTY_KEY_TIMELINE_LIST](state, timelineData) {
    Vue.set(state.propertyKeyTimelineList, timelineData.keyCodeId, timelineData.data);
  },
  [types.SET_PROPERTY_KEY_TIMELINE_LIST_ITEM](state, timelineItem) {
    if (Object.prototype.hasOwnProperty.call(state.propertyKeyTimelineList, timelineItem.keyCodeId)) {
      state.propertyKeyTimelineList[timelineItem.keyCodeId].unshift(timelineItem.data);
    }
  },
};
