import Vue from 'vue';

import { getErrors } from '@/mixins/ErrorManager';
import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';
import CloneDeep from '@/core/utils/cloneDeep';
import * as types from './mutation_types';

export default {
  [types.GET_AUDIENCES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_AUDIENCES](state, data) {
    Vue.set(state, 'totalAudiences', new TotalCountAdapter(data.result).count);
    Vue.set(state, 'audiences', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_LOAD_MORE_AUDIENCES](state, data) {
    let loadedAudiences = [...state.audiences];
    loadedAudiences = [...loadedAudiences, ...data.result.data];
    Vue.set(state, 'audiences', loadedAudiences);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_AUDIENCES_ERROR](state) {
    Vue.set(state, 'audiences', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_STATIC_AUDIENCES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'staticAudiences', null);
  },
  [types.SET_STATIC_AUDIENCES](state, data) {
    Vue.set(state, 'staticAudiences', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_STATIC_AUDIENCES_ERROR](state) {
    Vue.set(state, 'staticAudiences', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_ALL_AUDIENCES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedAudiences', Array.from(new Set(state.selectedAudiences.concat(data))));
    Vue.set(state, 'isLoading', false);
  },
  [types.UNSET_ALL_AUDIENCES](state, data) {
    Vue.set(state, 'isLoading', true);
    for (let i = 0; i < data.length; i += 1) {
      const item = state.selectedAudiences.findIndex(c => c.mail_list_id === data[i].mail_list_id);
      state.selectedAudiences.splice(item, 1);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.SET_SELECTED_AUDIENCES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state.selectedAudiences, state.selectedAudiences.length, data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UNSET_SELECTED_AUDIENCES](state, data) {
    Vue.set(state, 'isLoading', true);
    const item = state.selectedAudiences.findIndex(c => c.mail_list_id === data.mail_list_id);
    state.selectedAudiences.splice(item, 1);
    Vue.set(state, 'isLoading', false);
  },
  [types.CLEAR_SELECTED_AUDIENCES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'selectedAudiences', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_DELETE_AUDIENCE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteAudienceSuccess', null);
    Vue.set(state, 'deleteAudienceError', null);
  },
  [types.SET_DELETE_AUDIENCE_SUCCESS](state, data) {
    Vue.set(state, 'deleteAudienceSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DELETE_AUDIENCE_ERROR](state, error) {
    Vue.set(state, 'deleteAudienceError', error[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_ADD_STATIC_AUDIENCE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'addStaticAudienceSuccess', null);
    Vue.set(state, 'addStaticAudienceError', null);
  },
  [types.SET_ADD_STATIC_AUDIENCE_SUCCESS](state, data) {
    Vue.set(state, 'addStaticAudienceSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ADD_STATIC_AUDIENCE_ERROR](state, error) {
    Vue.set(state, 'addStaticAudienceError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CLONE_AUDIENCE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'cloneAudienceSuccess', null);
    Vue.set(state, 'cloneAudienceError', null);
  },
  [types.SET_CLONE_AUDIENCE_SUCCESS](state, data) {
    Vue.set(state, 'cloneAudienceSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CLONE_AUDIENCE_ERROR](state, error) {
    Vue.set(state, 'cloneAudienceError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_AUDIENCE_DETAILS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'audienceDetails', null);
    Vue.set(state, 'audienceDetailsErrors', null);
  },
  [types.SET_AUDIENCE_DETAILS](state, data) {
    Vue.set(state, 'audienceDetails', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_AUDIENCE_DETAILS_ERROR](state, error) {
    Vue.set(state, 'audienceDetailsErrors', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'addContactsToAudienceWithEmailsSuccess', null);
    Vue.set(state, 'addContactsToAudienceWithEmailsError', null);
  },
  [types.SET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_SUCCESS](state, data) {
    Vue.set(state, 'addContactsToAudienceWithEmailsSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_ERROR](state, error) {
    Vue.set(state, 'addContactsToAudienceWithEmailsError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_AUDIENCE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createAudienceSuccess', null);
    Vue.set(state, 'createAudienceError', null);
  },
  [types.CREATE_AUDIENCE_SUCCESS](state, data) {
    Vue.set(state, 'createAudienceSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_AUDIENCE_ERROR](state, error) {
    Vue.set(state, 'createAudienceError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'dynamicAudienceBuilderSchema', null);
    Vue.set(state, 'availableForms', []);
  },
  [types.SET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA](state, data) {
    const schema = data.result.data;
    state.schemaBase = new CloneDeep().clone(schema);
    schema.applicant_criteria = {
      group_name: 'Applicant Criteria',
      icon: 'fa-user',
      fields: [
        {
          label: 'Search Profile',
          type: 'option',
          name: 'applicant_criteria.profile_id',
          options: [
            {
              label: 'Buyer',
              value: 3,
            },
            {
              label: 'Tenant',
              value: 5,
            },
          ],
        },
        {
          label: 'Applicant Status',
          type: 'option',
          name: 'applicant_criteria.contact_applicant_status_id',
          defaultOptionLabel: 'All',
          options: [
            {
              label: 'On',
              value: 1,
            },
            {
              label: 'Off',
              value: 2,
            },
          ],
        },
        {
          label: 'Locations',
          type: 'location',
          name: 'applicant_criteria.locations',
          value: [],
        },
        {
          label: 'Property Type',
          type: 'multi_property_type_selection',
          name: 'applicant_criteria.property_types',
          value: {
            property_types: [],
          },
          identifier: 'applicant_profile_criteria',
          show: false,
        },
        {
          label: 'Bedroom',
          type: 'specific_range_number',
          name: 'applicant_criteria.bedroom',
          identifier: 'applicant_profile_criteria',
          show: false,
          find_type: 2,
        },
        {
          label: 'Bathroom',
          type: 'specific_range_number',
          name: 'applicant_criteria.bathroom',
          identifier: 'applicant_profile_criteria',
          show: false,
          find_type: 2,
        },
        {
          label: 'Reception',
          type: 'specific_range_number',
          name: 'applicant_criteria.reception',
          identifier: 'applicant_profile_criteria',
          show: false,
          find_type: 2,
        },
        {
          label: 'Price',
          type: 'specific_range_price',
          name: 'applicant_criteria.price',
          identifier: 'applicant_profile_criteria',
          show: false,
          find_type: 2,
        },
        {
          label: 'Questions',
          type: 'applicant_questions',
          name: 'applicant_criteria.applicant_question_answers',
          identifier: 'applicant_profile_criteria',
          defaultProfileId: 3,
          value: {},
          show: false,
        },
      ],
    };

    schema.contact_activity_related.fields = schema.contact_activity_related.fields.map((field) => {
      if (field.name === 'form_answers') {
        return {
          defaultOptionLabel: 'None',
          ...field,
        };
      }
      return field;
    });

    schema.contact_activity_related = {
      ...schema.contact_activity_related,
      fields: [
        ...schema.contact_activity_related.fields,
        {
          label: 'Marketing Unsubscription',
          type: 'option',
          name: 'marketing_notification',
          defaultOptionLabel: 'None',
          options: [
            {
              label: 'Only SMS',
              value: 1,
            },
            {
              label: 'Only E-Mail',
              value: 2,
            },
            {
              label: 'SMS or E-Mail',
              value: 3,
            },
            {
              label: 'SMS and E-Mail',
              value: 4,
            },
          ],
        },
      ],
    };
    schema.contact_activity_related = {
      ...schema.contact_activity_related,
      fields: [
        ...schema.contact_activity_related.fields,
        {
          label: 'Property Alert Unsubscription',
          type: 'option',
          name: 'property_alert',
          defaultOptionLabel: 'None',
          options: [
            {
              label: 'Yes',
              value: 1,
            },
            {
              label: 'No',
              value: 2,
            },
          ],
        },
      ],
    };


    schema.contact_related.fields = schema.contact_related.fields.filter(field => !['contact_addresses.postcode',
      'contact_addresses.address_5',
      'contact_addresses.address_4',
      'contact_addresses.address_3',
      'contact_addresses.address_2',
      'contact_addresses.address_1'].includes(field.name));

    schema.property_related.fields = schema.property_related.fields.filter(field => !['property_profiles.property_profile.property_status_id'].includes(field.name));

    schema.property_related.fields = [
      ...schema.property_related.fields.slice(0, 3),
      {
        label: 'Property Address',
        type: 'location',
        name: 'location_property_address',
        value: [],
      },
      ...schema.property_related.fields.slice(3, schema.property_related.fields.length),
    ];

    schema.contact_related.fields = [
      ...schema.contact_related.fields.slice(0, 3),
      {
        label: 'Correspondence Address',
        type: 'location',
        name: 'location_correspondence_address',
        value: [],
      },
      ...schema.contact_related.fields.slice(3, schema.contact_related.fields.length),
    ];

    schema.contact_activity_related.fields = schema.contact_activity_related.fields.map((field) => {
      if (field.name === 'form_answers') {
        return {
          ...field,
          options: field.options.map(option => ({
            ...option,
            form_entry_date: {
              label: 'Form Entry Date',
              name: 'form_entry_date',
              type: 'date',
              find_type: 1,
              value: null,
            },
          })),
        };
      }
      return field;
    });

    Vue.set(state, 'dynamicAudienceBuilderSchema', schema);
    Vue.set(state, 'availableForms', schema.contact_activity_related.fields.find(field => field.name === 'form_answers').options);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA_ERROR](state) {
    Vue.set(state, 'audienceDetails', null);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA](state) {
    Vue.set(state, 'dynamicAudienceBuilderSchema', false);
    Vue.set(state, 'availableForms', []);
  },
  [types.UPDATE_AVAILABLE_FORMS](state, data) {
    let availableForms;
    if (!data.add) {
      availableForms = state.availableForms.filter(form => form.form_id !== data.form_id);
      Vue.set(state, 'availableForms', availableForms);
    } else {
      state.availableForms.unshift(data.field);
    }
  },
  [types.SET_BUILDER_DATA](state, data) {
    Vue.set(state, 'builderMemoryData', data);
  },

  [types.SEND_BULK_EMAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendBulkEmailSuccess', null);
    Vue.set(state, 'sendBulkEmailError', null);
  },
  [types.SEND_BULK_EMAIL_SUCCESS](state, data) {
    Vue.set(state, 'sendBulkEmailSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_BULK_EMAIL_ERROR](state, error) {
    Vue.set(state, 'sendBulkEmailError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_DYNAMIC_AUDIENCE_SEARCH](state) {
    Vue.set(state, 'audienceSearchProcess', true);
    Vue.set(state, 'audienceSearchResult', []);
  },
  [types.SET_DYNAMIC_AUDIENCE_SEARCH](state, data) {
    Vue.set(state, 'totalAudienceSearchResult', new TotalCountAdapter(data.result).count);
    Vue.set(state, 'audienceSearchResult', data.result.data);
    Vue.set(state, 'audienceSearchProcess', false);
  },
  [types.GET_DYNAMIC_AUDIENCE_SEARCH_ERROR](state) {
    Vue.set(state, 'totalAudienceSearchResult', 0);
    Vue.set(state, 'audienceSearchResult', []);
    Vue.set(state, 'audienceSearchProcess', false);
  },
};
