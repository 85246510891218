import axios from 'axios';
import * as ErrorManager from '../mixins/ErrorManager';
import interceptor from '../core/api/authInterceptor';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  get(url, params) {
    api.interceptors.response.use(undefined, interceptor(api));
    const get = api.get(url, {
      params,
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
      },
    });
    get.then(() => {
    }).catch((error) => {
      ErrorManager.controlConnectionProblems(error);
    });
    return get;
  },
  post(url, data) {
    api.interceptors.response.use(undefined, interceptor(api));
    const post = api.post(url, data, {
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
      },
    });
    post.then(() => {
    }).catch((error) => {
      ErrorManager.controlConnectionProblems(error);
    });
    return post;
  },
  postFile(url, data) {
    api.interceptors.response.use(undefined, interceptor(api));
    const post = api.post(url, data, {
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    post.then(() => {
    }).catch((error) => {
      ErrorManager.controlConnectionProblems(error);
    });
    return post;
  },
  patch(url, data) {
    api.interceptors.response.use(undefined, interceptor(api));
    const patch = api.patch(url, data, {
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
      },
    });
    patch.then(() => {
    }).catch((error) => {
      ErrorManager.controlConnectionProblems(error);
    });
    return patch;
  },
  patchFile(url, data) {
    api.interceptors.response.use(undefined, interceptor(api));
    return api.patch(url, data, {
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  delete(url) {
    api.interceptors.response.use(undefined, interceptor(api));
    return api.delete(url, {
      headers: {
        Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
        Accept: 'application/json',
      },
    });
  },
  getToken() {
    return localStorage.getItem(process.env.VUE_APP_TOKEN_NAME).substr(7);
  },
  autocompletePostCode(data) {
    axios.interceptors.response.use(undefined, interceptor(axios));
    return axios.get(`${process.env.VUE_APP_API_URL}fps?key=FuI9QqufBott2UeJUNJw4mBiUGHti2FZQyVkLqsGuco&postcode=${data.code}&identifier=${data.identifier}`,
      {
        headers: {
          Accept: 'application/json',
        },
      });
  },
  findAddressFromPostCode(postcode) {
    axios.interceptors.response.use(undefined, interceptor(axios));
    return axios.post(`${process.env.VUE_APP_API_URL}search/postcode`,
      {
        postcode,
      },
      {
        headers: {
          Authorization: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME),
          Accept: 'application/json',
        },
      });
  },
};
