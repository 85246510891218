export default [
  {
    path: '/leads',
    meta: {
      title: 'Leads',
      breadcrumb: [
        { name: 'Leads' },
      ],
    },
    name: 'LeadsNext',
    component: () => import('@/modules/leads_next/views/Leads.vue'),
  },
  {
    path: '/lead_detail/:form_entry_id',
    meta: {
      title: 'Lead Detail',
      breadcrumb: [
        { name: 'Lead Detail' },
      ],
    },
    name: 'LeadDetail',
    component: () => import('@/modules/leads_next/views/Leads.vue'),
  },
  {
    path: '/leads_menu',
    meta: {
      title: 'Leads',
      breadcrumb: [
        { name: 'Leads' },
      ],
    },
    name: 'Leads',
    component: () => import('@/modules/leads_next/views/LeadsMenu.vue'),
  },
];
