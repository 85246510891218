import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestPropertyProfilePrices = ({ commit }) => commit(types.GET_PROPERTY_PROFILE_PRICES);
export const receivePropertyProfilePricesSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_PROFILE_PRICES, data);
export const receivePropertyProfilePricesError = ({ commit }, error) => commit(types.GET_PROPERTY_PROFILE_PRICES_ERROR, error);
export const fetchPropertyProfilePrices = ({ dispatch }, propertyProfileId) => {
  dispatch('requestPropertyProfilePrices');
  Api.get(`property_profiles/${propertyProfileId}/prices`)
    .then(({ data }) => { dispatch('receivePropertyProfilePricesSuccess', data); })
    .catch(({ response }) => { dispatch('receivePropertyProfilePricesError', response); });
};

export const requestCreatePropertyProfilePrice = ({ commit }) => commit(types.CREATE_PROPERTY_PROFILE_PRICE);
export const receiveCreatePropertyProfilePriceSuccess = ({ commit }, data) => commit(types.CREATE_PROPERTY_PROFILE_PRICE_SUCCESS, data);
export const receiveCreatePropertyProfilePriceError = ({ commit }, error) => commit(types.CREATE_PROPERTY_PROFILE_PRICE_ERROR, error);
export const createPropertyProfilePrice = ({ dispatch }, priceData) => {
  dispatch('requestCreatePropertyProfilePrice');
  Api.post(`property_profiles/${priceData.property_profile_id}/prices`, priceData)
    .then(({ data }) => {
      dispatch('receiveCreatePropertyProfilePriceSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreatePropertyProfilePriceError', response);
    });
};

export const requestUpdatePropertyProfilePrice = ({ commit }) => commit(types.UPDATE_PROPERTY_PROFILE_PRICE);
export const receiveUpdatePropertyProfilePriceSuccess = ({ commit }, data) => commit(types.UPDATE_PROPERTY_PROFILE_PRICE_SUCCESS, data);
export const receiveUpdatePropertyProfilePriceError = ({ commit }, error) => commit(types.UPDATE_PROPERTY_PROFILE_PRICE_ERROR, error);
export const updatePropertyProfilePrice = ({ dispatch }, priceData) => {
  dispatch('requestUpdatePropertyProfilePrice');
  Api.patch(`property_profiles/${priceData.property_profile_id}/prices/${priceData.property_profile_price_id}`, priceData)
    .then(({ data }) => {
      dispatch('receiveUpdatePropertyProfilePriceSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUpdatePropertyProfilePriceError', response);
    });
};
