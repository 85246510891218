export default [{
  path: '/marketing',
  meta: {
    title: 'Marketing & Autopilot',
    breadcrumb: [
      { name: 'Marketing & Autopilot' },
    ],
  },
  name: 'Marketing',
  component: () => import('@/modules/marketing/views/Marketing.vue'),
},
{
  path: '/automation',
  meta: {
    title: 'Automations',
    breadcrumb: [
      { name: 'Marketing & Autopilot', link: '/marketing' },
      { name: 'Automations' },
    ],
  },
  name: 'Automation',
  component: () => import('@/modules/marketing/views/Automation.vue'),
},
{
  path: '/solny-list',
  meta: {
    title: 'Solny List',
    breadcrumb: [
      { name: 'Solny List' },
    ],
  },
  name: 'Solny List',
  component: () => import('@/modules/marketing/views/SolnyList.vue'),
},
{
  path: '/solny-detail/:solny_id',
  meta: {
    title: 'Solny Detail',
    breadcrumb: [
      { name: 'Solny List', link: '/solny-list' },
      { name: 'Solny Detail' },
    ],
  },
  name: 'Solny',
  component: () => import('@/modules/marketing/views/Solny.vue'),
},
{
  path: '/ad',
  meta: {
    title: 'Ad',
    breadcrumb: [
      { name: 'Marketing & Autopilot', link: '/marketing' },
      { name: 'Ad' },
    ],
  },
  name: 'Ad',
  component: () => import('@/modules/marketing/views/Ad.vue'),
},
{
  path: '/ad_account_detail/:ad_account_id',
  meta: {
    title: 'Ad Account Detail',
    breadcrumb: [
      { name: 'Ad', link: '/ad' },
      { name: 'Ad Account Detail' },
    ],
  },
  name: 'AdAccountDetail',
  component: () => import('@/modules/marketing/views/AdAccountDetail.vue'),
},
{
  path: '/proactive',
  meta: {
    title: 'Proactive',
    breadcrumb: [
      { name: 'Leads', link: '/leads' },
      { name: 'Proactive' },
    ],
  },
  name: 'Proactive',
  component: () => import('@/modules/marketing/views/Proactive.vue'),
}];
