export const RESET_ADDRESS = 'RESET_ADDRESS';

export const GET_CONTACT_ADDRESS = 'GET_CONTACT_ADDRESS';
export const SET_CONTACT_ADDRESS = 'SET_CONTACT_ADDRESS';
export const GET_CONTACT_ADDRESS_ERROR = 'GET_CONTACT_ADDRESS_ERROR';

export const GET_CONTACT_ADDRESS_DETAILS = 'GET_CONTACT_ADDRESS_DETAILS';
export const SET_CONTACT_ADDRESS_DETAILS = 'SET_CONTACT_ADDRESS_DETAILS';
export const GET_CONTACT_ADDRESS_DETAILS_ERROR = 'GET_CONTACT_ADDRESS_DETAILS_ERROR';

export const GET_PROPERTY_ADDRESS = 'GET_PROPERTY_ADDRESS';
export const SET_PROPERTY_ADDRESS = 'SET_PROPERTY_ADDRESS';
export const GET_PROPERTY_ADDRESS_ERROR = 'GET_PROPERTY_ADDRESS_ERROR';

export const GET_PROPERTY_ADDRESS_DETAILS = 'GET_PROPERTY_ADDRESS_DETAILS';
export const SET_PROPERTY_ADDRESS_DETAILS = 'SET_PROPERTY_ADDRESS_DETAILS';
export const GET_PROPERTY_ADDRESS_DETAILS_ERROR = 'GET_PROPERTY_ADDRESS_DETAILS_ERROR';

export const GET_EVENT_ADDRESS = 'GET_EVENT_ADDRESS';
export const SET_EVENT_ADDRESS = 'SET_EVENT_ADDRESS';
export const SET_SELECTED_EVENT_ADDRESS = 'SET_SELECTED_EVENT_ADDRESS';
export const GET_EVENT_ADDRESS_ERROR = 'GET_EVENT_ADDRESS_ERROR';

export const GET_EVENT_ADDRESS_DETAILS = 'GET_EVENT_ADDRESS_DETAILS';
export const SET_EVENT_ADDRESS_DETAILS = 'SET_EVENT_ADDRESS_DETAILS';
export const GET_EVENT_ADDRESS_DETAILS_ERROR = 'GET_EVENT_ADDRESS_DETAILS_ERROR';

export const SET_SELECTED_PROPERTY_ADDRESS = 'SET_SELECTED_PROPERTY_ADDRESS';


export const GET_ADDRESSES = 'GET_ADDRESSES';
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const GET_ADDRESSES_ERROR = 'GET_ADDRESSES_ERROR';
export const RESET_ADDRESSES = 'RESET_ADDRESSES';


export const GET_ADDRESS_DETAILS = 'GET_ADDRESS_DETAILS';
export const SET_ADDRESS_DETAILS = 'SET_ADDRESS_DETAILS';
export const GET_ADDRESS_DETAILS_ERROR = 'GET_ADDRESS_DETAILS_ERROR';
