export default {
  error: null,
  branches: null,
  branch: null,
  totalBranches: null,
  branchDepartments: null,
  branchDepartment: null,
  branchDepartmentUpdate: null,
  branchDepartmentDataFeed: null,
  departments: null,
  data_providers: null,
  data_provider: null,
  isLoading: false,
  createBranchProcessed: null,
  createBranchProcessedSuccess: null,
  createBranchProcessedError: null,
  updateBranchProcessed: null,
  updateBranchProcessedSuccess: null,
  updateBranchProcessedError: null,
  createDepartmentProcessed: null,
  createDepartmentProcessedSuccess: null,
  createDepartmentProcessedError: null,
  updateDepartmentProcessed: false,
  updateDepartmentProcessedSuccess: false,
  updateDepartmentProcessedError: false,
  createDepartmentCommunicationInfoProcessed: false,
  createDepartmentCommunicationInfoProcessedSuccess: false,
  createDepartmentCommunicationInfoProcessedError: false,
};
