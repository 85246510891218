// eslint-disable-next-line import/prefer-default-export
export const defaultEmailSender = (state, getters, rootState, rootGetters) => {
  const me = rootGetters['Users/me'];

  const LOGGED_USER_SENDER = state.emailSenders.find(sender => sender.user_id === me.user_id);

  if (LOGGED_USER_SENDER) {
    return LOGGED_USER_SENDER.sender_email_id;
  }

  const defaultSender = state.emailSenders.find(sender => sender.default);
  if (defaultSender) {
    return defaultSender.sender_email_id;
  }

  if (state.emailSenders && state.emailSenders[0]) {
    return state.emailSenders[0].sender_email_id;
  }
  return null;
};
