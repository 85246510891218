import Vue from 'vue';
import * as types from './mutation_types';
// eslint-disable-next-line
import { getErrors } from '../../../mixins/ErrorManager';

export default {
  [types.GET_BAIT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'bait', null);
  },
  [types.SET_BAIT](state, data) {
    Vue.set(state, 'bait', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_LENGTH_OF_AGREEMENTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_LENGTH_OF_AGREEMENTS](state, data) {
    Vue.set(state, 'lengthOfAgreements', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_LENGTH_OF_AGREEMENTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_LENGTH_OF_AGREEMENTS](state) {
    Vue.set(state, 'lengthOfAgreements', null);
  },

  [types.CREATE_LENGTH_OF_AGREEMENT](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.CREATE_LENGTH_OF_AGREEMENT_SUCCESS](state, data) {
    Vue.set(state, 'lengthOfAgreement', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_LENGTH_OF_AGREEMENT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.UPDATE_LENGTH_OF_AGREEMENT](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.UPDATE_LENGTH_OF_AGREEMENT_SUCCESS](state, data) {
    Vue.set(state, 'lengthOfAgreement', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateLengthOfAgreementSuccess', true);
  },
  [types.UPDATE_LENGTH_OF_AGREEMENT_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateLengthOfAgreementError', true);
  },
  [types.GET_BAIT_DEFAULT_SETTINGS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'defaultSettings', null);
  },
  [types.SET_BAIT_DEFAULT_SETTINGS](state, data) {
    Vue.set(state, 'defaultSettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_BAIT_DEFAULT_SETTINGS](state) {
    Vue.set(state, 'defaultSettings', null);
  },
  [types.GET_BAIT_FORM_ANSWERS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'baitFormAnswer', null);
  },
  [types.SET_BAIT_FORM_ANSWERS](state, data) {
    Vue.set(state, 'baitFormAnswer', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_BAIT_FORM_ANSWERS](state) {
    Vue.set(state, 'baitFormAnswer', []);
  },
  [types.GET_FEES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_FEES](state, data) {
    Vue.set(state, 'fees', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FEES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_FEES](state) {
    Vue.set(state, 'fees', null);
  },

  [types.CREATE_FEE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'feeCreateError', null);
    Vue.set(state, 'feeCreateSuccess', null);
  },
  [types.CREATE_FEE_SUCCESS](state, data) {
    Vue.set(state, 'feeCreateSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_FEE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'feeCreateError', error.data.message);
    let showingMessage = null;
    if (error && error.data && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'feeUpdateError', showingMessage);
    } else {
      Vue.set(state, 'feeUpdateError', error.data.message);
    }
  },

  [types.UPDATE_FEE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'feeUpdateSuccess', null);
    Vue.set(state, 'feeUpdateError', null);
  },
  [types.UPDATE_FEE_SUCCESS](state, data) {
    Vue.set(state, 'feeUpdateSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_FEE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'feeUpdateError', showingMessage);
    } else {
      Vue.set(state, 'feeUpdateError', error.data.message);
    }
  },

  [types.SAVE_FEES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SAVE_FEES_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'allFeesData', data.result.data);
  },
  [types.SAVE_FEES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.DELETE_FEE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.DELETE_FEE_SUCCESS](state, data) {
    Vue.set(state, 'deletedFee', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_FEE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'deletedFeeError', showingMessage);
    } else {
      Vue.set(state, 'deletedFeeError', error.data.message);
    }
  },

  [types.UPDATE_BAIT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateBaitSuccess', false);
    Vue.set(state, 'updateBaitError', false);
  },
  [types.UPDATE_BAIT_SUCCESS](state, data) {
    Vue.set(state, 'bait', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateBaitSuccess', data);
  },
  [types.UPDATE_BAIT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.code === 400) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'updateBaitError', showingMessage);
    } else {
      Vue.set(state, 'updateBaitError', error.data.message);
    }
  },

  [types.SEND_APPOINTMENT_MAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendAppointmentMailSuccess', null);
    Vue.set(state, 'sendAppointmentMailError', null);
  },
  [types.SEND_APPOINTMENT_MAIL_SUCCESS](state, success) {
    Vue.set(state, 'sendAppointmentMailSuccess', success.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_APPOINTMENT_MAIL_ERROR](state) {
    Vue.set(state, 'sendAppointmentMailError', 'Couldn\'t send e-mail');
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BAIT_STATUSES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_STATUSES](state, data) {
    Vue.set(state, 'baitStatuses', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_STATUSES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.UPDATE_BAIT_STATUS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updatingBaitStatusSuccess', null);
    Vue.set(state, 'updatingBaitStatusError', null);
  },
  [types.UPDATE_BAIT_STATUS_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updatingBaitStatusSuccess', data.message);
  },
  [types.UPDATE_BAIT_STATUS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updatingBaitStatusError', error.data.message);
  },


  [types.REVERT_BAIT_LAST_STATUS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'revertBaitLastStatusSuccess', null);
    Vue.set(state, 'revertBaitLastStatusError', null);
  },
  [types.REVERT_BAIT_LAST_STATUS_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'revertBaitLastStatusSuccess', data.message);
  },
  [types.REVERT_BAIT_LAST_STATUS_ERROR](state, error) {
    Vue.set(state, 'revertBaitLastStatusError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_FAQS_BY_BAIT_ID](state) {
    Vue.set(state, 'FAQsByBaitId', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_FAQS_BY_BAIT_ID](state, data) {
    Vue.set(state, 'FAQsByBaitId', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FAQS_BY_BAIT_ID_ERROR](state, message) {
    Vue.set(state, 'FAQsByBaitIdError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_FAQS_BY_BAIT_ID](state) {
    Vue.set(state, 'FAQsByBaitId', null);
  },

  [types.CREATE_FAQ](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createFAQSuccess', null);
    Vue.set(state, 'FAQCreateMessage', null);
  },
  [types.CREATE_FAQ_SUCCESS](state, data) {
    if (data.code === 400) {
      Vue.set(state, 'FAQCreateMessage', data.message);
    }
    Vue.set(state, 'createFAQSuccess', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_FAQ_ERROR](state, message) {
    Vue.set(state, 'createFAQsError', message);
    Vue.set(state, 'isLoading', false);
  },

  [types.UPDATE_FAQ](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateFAQSuccess', null);
    Vue.set(state, 'FAQUpdateMessage', null);
  },
  [types.UPDATE_FAQ_SUCCESS](state, data) {
    if (data.code === 400) {
      Vue.set(state, 'FAQUpdateMessage', data.message);
    }
    Vue.set(state, 'updateFAQSuccess', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_FAQ_ERROR](state, message) {
    Vue.set(state, 'updateFAQsError', message);
    Vue.set(state, 'isLoading', false);
  },

  [types.DELETE_FAQ](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteFAQSuccess', null);
    Vue.set(state, 'FAQDeleteMessage', null);
  },
  [types.DELETE_FAQ_SUCCESS](state, data) {
    if (data.code === 400) {
      Vue.set(state, 'FAQDeleteMessage', data.message);
    }
    Vue.set(state, 'deleteFAQSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_FAQ_ERROR](state, message) {
    Vue.set(state, 'deleteFAQsError', message);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_COMPARABLE_PROPERTIES_BY_BAIT_ID](state) {
    Vue.set(state, 'comparablePropertiesByBaitId', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_COMPARABLE_PROPERTIES_BY_BAIT_ID](state, data) {
    Vue.set(state, 'comparablePropertiesByBaitId', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_COMPARABLE_PROPERTIES_BY_BAIT_ID_ERROR](state, message) {
    Vue.set(state, 'comparablePropertiesByBaitIdError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_COMPARABLE_PROPERTIES_BY_BAIT_ID](state) {
    Vue.set(state, 'comparablePropertiesByBaitId', null);
  },

  [types.CREATE_COMPARABLE_PROPERTY](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createComparablePropertySuccess', null);
    Vue.set(state, 'createComparablePropertysError', null);
  },
  [types.CREATE_COMPARABLE_PROPERTY_SUCCESS](state, data) {
    Vue.set(state, 'createComparablePropertySuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_COMPARABLE_PROPERTY_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'createComparablePropertysError', showingMessage);
    } else {
      Vue.set(state, 'createComparablePropertysError', error.data.message);
    }
  },

  [types.UPDATE_COMPARABLE_PROPERTY](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateComparablePropertysError', null);
    Vue.set(state, 'updateComparablePropertySuccess', null);
  },
  [types.UPDATE_COMPARABLE_PROPERTY_SUCCESS](state, data) {
    Vue.set(state, 'updateComparablePropertySuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_COMPARABLE_PROPERTY_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'updateComparablePropertysError', showingMessage);
    } else {
      Vue.set(state, 'updateComparablePropertysError', error.data.message);
    }
  },

  [types.DELETE_COMPARABLE_PROPERTY](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteComparablePropertySuccess', null);
    Vue.set(state, 'deleteComparablePropertysError', null);
  },
  [types.DELETE_COMPARABLE_PROPERTY_SUCCESS](state, data) {
    Vue.set(state, 'deleteComparablePropertySuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_COMPARABLE_PROPERTY_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'deleteComparablePropertysError', showingMessage);
    } else {
      Vue.set(state, 'deleteComparablePropertysError', error.data.message);
    }
  },

  [types.SEND_APPOINTMENT_OR_BAIT_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendAppointmentOrBaitSmsSuccess', null);
    Vue.set(state, 'sendAppointmentOrBaitSmsError', null);
  },
  [types.SEND_APPOINTMENT_OR_BAIT_SMS_SUCCESS](state, data) {
    Vue.set(state, 'sendAppointmentOrBaitSmsSuccess', { data: data.result.data, message: data.message });
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_APPOINTMENT_OR_BAIT_SMS_ERROR](state, error) {
    if (error && error.data && error.data.result && error.data.result.data) {
      Vue.set(state, 'sendAppointmentOrBaitSmsError', error.data.result.data);
    } else {
      Vue.set(state, 'sendAppointmentOrBaitSmsError', getErrors(error)[0]);
    }
    Vue.set(state, 'isLoading', false);
  },

  [types.SEND_APPOINTMENT_OR_BAIT_EMAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendAppointmentOrBaitEmailSuccess', null);
    Vue.set(state, 'sendAppointmentOrBaitEmailError', null);
  },
  [types.SEND_APPOINTMENT_OR_BAIT_EMAIL_SUCCESS](state, data) {
    Vue.set(state, 'sendAppointmentOrBaitEmailSuccess', { data: data.result.data, message: data.message });
    Vue.set(state, 'isLoading', false);
  },
  [types.SEND_APPOINTMENT_OR_BAIT_EMAIL_ERROR](state, error) {
    if (error && error.data && error.data.result && error.data.result.data) {
      Vue.set(state, 'sendAppointmentOrBaitEmailError', error.data.result.data);
    } else {
      Vue.set(state, 'sendAppointmentOrBaitEmailError', getErrors(error)[0]);
    }
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CONTENTS_BY_BAIT_ID](state) {
    Vue.set(state, 'contentsByBaitId', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CONTENTS_BY_BAIT_ID](state, data) {
    Vue.set(state, 'contentsByBaitId', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CONTENTS_BY_BAIT_ID_ERROR](state, message) {
    Vue.set(state, 'contentsByBaitIdError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_CONTENTS_BY_BAIT_ID](state) {
    Vue.set(state, 'contentsByBaitId', null);
  },

  [types.CREATE_BAIT_CONTENT](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.CREATE_BAIT_CONTENT_SUCCESS](state, data) {
    Vue.set(state, 'createBaitContentSuccess', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_BAIT_CONTENT_ERROR](state, message) {
    Vue.set(state, 'createBaitContentError', message);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BAIT_CONTENTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_CONTENTS](state, data) {
    Vue.set(state, 'baitContents', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_CONTENTS_ERROR](state, message) {
    Vue.set(state, 'baitContentsError', message);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_COMPLETION_TOOL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createCompletionToolSuccess', null);
    Vue.set(state, 'createCompletionToolError', null);
  },
  [types.CREATE_COMPLETION_TOOL_SUCCESS](state, data) {
    Vue.set(state, 'createCompletionToolSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_COMPLETION_TOOL_ERROR](state, error) {
    Vue.set(state, 'createCompletionToolError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_ASKED_QUESTIONS](state) {
    Vue.set(state, 'askedQuestions', null);
    Vue.set(state, 'askedQuestionsError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_ASKED_QUESTIONS](state, data) {
    Vue.set(state, 'askedQuestions', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_ASKED_QUESTIONS_ERROR](state, error) {
    Vue.set(state, 'askedQuestionsError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.RESET_BAIT_ASKED_QUESTIONS](state) {
    Vue.set(state, 'askedQuestions', null);
  },

  [types.RESET_SEND_QUICK_EMAIL_AND_SMS](state) {
    Vue.set(state, 'sendQuickEmailAndSmsResults', null);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SEND_QUICK_EMAIL_AND_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendQuickEmailAndSmsResults', null);
    Vue.set(state, 'sendQuickEmailAndSmsResultsError', null);
  },
  [types.SET_SEND_QUICK_EMAIL_AND_SMS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sendQuickEmailAndSmsResults', data);
  },
  [types.SET_SEND_QUICK_EMAIL_AND_SMS_ERROR](state, error) {
    Vue.set(state, 'sendQuickEmailAndSmsResultsError', error);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CHECK_BAIT_REQUIREMENTS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'checkBaitRequirementsState', null);
  },
  [types.SET_CHECK_BAIT_REQUIREMENTS](state, data) {
    Vue.set(state, 'checkBaitRequirementsState', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CHECK_BAIT_REQUIREMENTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CHANGE_BAIT_PUBLISH_STATUS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'changeBaitPublishStatusSuccess', null);
    Vue.set(state, 'changeBaitPublishStatusError', null);
  },
  [types.SET_CHANGE_BAIT_PUBLISH_STATUS_SUCCESS](state, data) {
    Vue.set(state, 'changeBaitPublishStatusSuccess', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CHANGE_BAIT_PUBLISH_STATUS_ERROR](state, error) {
    Vue.set(state, 'changeBaitPublishStatusError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },
};
