import Vue from 'vue';
import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';


export default {
  [types.GET_FORM](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'form', null);
  },
  [types.SET_FORM](state, data) {
    const formData = { ...data.result.data };
    if (formData.options && formData.options.goodbye_message) {
      delete formData.options.goodbye_message;
    }
    Vue.set(state, 'form', formData);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FORM_ERROR](state) {
    Vue.set(state, 'form', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_FORMS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_FORMS](state, data) {
    Vue.set(state, 'totalForms', new TotalCountAdapter(data.result).count);
    Vue.set(state, 'forms', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FORMS_ERROR](state) {
    Vue.set(state, 'forms', []);
    Vue.set(state, 'isLoading', false);
  },


  [types.GET_SEND_FORMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createFormProcessed', true);
    Vue.set(state, 'createFormProcessedSuccess', false);
    Vue.set(state, 'createFormProcessedError', false);
  },
  [types.SET_SEND_FORMS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createFormProcessed', false);
    Vue.set(state, 'createFormProcessedSuccess', data);
  },
  [types.GET_SEND_FORMS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createFormProcessed', false);
    Vue.set(state, 'createFormProcessedError', getErrors(error)[0]);
  },

  [types.GET_UPDATE_FORM](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateFormProcessed', true);
    Vue.set(state, 'updateFormProcessedSuccess', false);
    Vue.set(state, 'updateFormProcessedError', false);
  },
  [types.SET_UPDATE_FORM](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateFormProcessedSuccess', data.message);
  },
  [types.GET_UPDATE_FORM_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateFormProcessedError', getErrors(error)[0]);
  },

  [types.GET_FORM_DETAIL](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_FORM_DETAIL](state, data) {
    Vue.set(state, 'formDetail', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FORM_DETAIL_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'formDetail', null);
  },

  [types.GET_FORM_QUESTION_FIELDS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_FORM_QUESTION_FIELDS](state, data) {
    Vue.set(state, 'formQuestionFields', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FORM_QUESTION_FIELDS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_FORM_QUESTION](state, data) {
    Vue.set(state, 'createFormQuestion', data);
  },

  [types.DELETE_FORM_QUESTION](state, data) {
    Vue.set(state, 'deleteFormQuestion', data);
  },

  [types.GET_FETCH_NOTIFY_USER_TYPES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'notifyUserTypes', null);
  },
  [types.SET_FETCH_NOTIFY_USER_TYPES](state, data) {
    Vue.set(state, 'notifyUserTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FETCH_NOTIFY_USER_TYPES_ERROR](state) {
    Vue.set(state, 'notifyUserTypes', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_FETCH_FORM_ENTRY_ANSWER](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'formEntryAnswer', null);
    Vue.set(state, 'formEntryContact', null);
  },
  [types.SET_FETCH_FORM_ENTRY_ANSWER](state, data) {
    Vue.set(state, 'formEntryAnswer', data.result.data.form_entry_answers);
    Vue.set(state, 'formEntryContact', data.result.data.contact);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FETCH_FORM_ENTRY_ANSWER_ERROR](state) {
    Vue.set(state, 'formEntryAnswer', null);
    Vue.set(state, 'formEntryContact', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_FETCH_FORM_INTEGRATION](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'formIntegrations', null);
  },
  [types.SET_FETCH_FORM_INTEGRATION](state, data) {
    Vue.set(state, 'formIntegrations', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FETCH_FORM_INTEGRATION_ERROR](state) {
    Vue.set(state, 'formIntegrations', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPLOAD_FAVICON](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'uploadFavicon', null);
  },
  [types.SET_UPLOAD_FAVICON](state, data) {
    Vue.set(state, 'uploadFavicon', data.result.url);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPLOAD_FAVICON_ERROR](state) {
    Vue.set(state, 'uploadFavicon', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_FORM_QUESTION](state) {
    Vue.set(state, 'updateFormQuestionProcess', true);
    Vue.set(state, 'updateFormQuestionSuccess', false);
    Vue.set(state, 'updateFormQuestionError', false);
  },
  [types.SET_UPDATE_FORM_QUESTION](state, data) {
    Vue.set(state, 'updateFormQuestionProcess', null);
    Vue.set(state, 'updateFormQuestionSuccess', data.result.data);
  },
  [types.GET_UPDATE_FORM_QUESTION_ERROR](state, error) {
    Vue.set(state, 'updateFormQuestionProcess', false);
    Vue.set(state, 'updateFormQuestionError', getErrors(error)[0]);
  },

  [types.CLONE_FORM](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'cloneFormSuccess', false);
    Vue.set(state, 'cloneFormError', false);
  },
  [types.CLONE_FORM_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'cloneFormSuccess', data.message);
  },
  [types.CLONE_FORM_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'cloneFormError', getErrors(error)[0]);
  },


  [types.GET_FETCH_FORM_QUESTIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'formQuestions', null);
  },
  [types.SET_FETCH_FORM_QUESTIONS](state, data) {
    Vue.set(state, 'formQuestions', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FETCH_FORM_QUESTIONS_ERROR](state) {
    Vue.set(state, 'formQuestions', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_FETCH_FORM_ENTRIES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'formEntries', null);
  },
  [types.SET_FETCH_FORM_ENTRIES](state, data) {
    Vue.set(state, 'formEntries', data.result.data);
    Vue.set(state, 'totalFormEntries', data.result.data[0].total_count);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_FETCH_FORM_ENTRIES_ERROR](state) {
    Vue.set(state, 'formEntries', null);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_DELETE_FORM](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteFormSuccess', false);
    Vue.set(state, 'deleteFormError', false);
  },
  [types.SET_DELETE_FORM](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteFormSuccess', data.message);
  },
  [types.GET_DELETE_FORM_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteFormError', getErrors(error)[0]);
  },
};
