import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestCsvImportFields = ({ commit }) => commit(types.GET_CSV_IMPORT_FIELDS);
export const receiveCsvImportFieldsSuccess = ({ commit }, data) => commit(types.SET_CSV_IMPORT_FIELDS, data);
export const receiveCsvImportFieldsError = ({ commit }, error) => commit(types.GET_CSV_IMPORT_FIELDS_ERROR, error);
export const fetchCsvImportFields = ({ dispatch }, payload) => {
  dispatch('requestCsvImportFields');
  Api.get(`csv_imports/match/${payload.csv_import_id}`)
    .then(({ data }) => {
      dispatch('receiveCsvImportFieldsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCsvImportFieldsError', response);
    });
};

export const requestMailLists = ({ commit }) => commit(types.GET_MAIL_LISTS);
export const receiveMailListsSuccess = ({ commit }, data) => commit(types.SET_MAIL_LISTS, data);
export const receiveMailListsError = ({ commit }, error) => commit(types.GET_MAIL_LISTS_ERROR, error);
export const fetchMailLists = ({ dispatch }) => {
  dispatch('requestMailLists');
  Api.get('mail_lists?__limit=250&__order_by=name')
    .then(({ data }) => { dispatch('receiveMailListsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveMailListsError', response); });
};

export const requestCsv = ({ commit }) => commit(types.GET_CSV);
export const receiveCsvSuccess = ({ commit }, data) => commit(types.SET_CSV, data);
export const receiveCsvError = ({ commit }, error) => commit(types.GET_CSV_ERROR, error);
export const fetchCsv = ({ dispatch }, payload) => {
  dispatch('requestCsv');
  Api.get(`csv_imports/${payload}`)
    .then(({ data }) => { dispatch('receiveCsvSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCsvError', response); });
};

export const requestSendCsv = ({ commit }) => commit(types.GET_SEND_CSV);
export const receiveSendCsvError = ({ commit }, error) => commit(types.GET_SEND_CSV_ERROR, error);
export const receiveSendCsvSuccess = ({ commit }, data) => commit(types.SET_SEND_CSV_SUCCESS, data);
export const sendCsv = ({ dispatch }, payload) => {
  dispatch('requestSendCsv');

  Api.postFile('csv_imports', payload)
    .then(({ data }) => {
      dispatch('receiveSendCsvSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveSendCsvError', response);
    });
};


export const requestImportCsv = ({ commit }) => commit(types.GET_IMPORT_CSV);
export const receiveImportCsvError = ({ commit }, error) => commit(types.GET_IMPORT_CSV_ERROR, error);
export const receiveImportCsvSuccess = ({ commit }, data) => commit(types.SET_IMPORT_CSV_SUCCESS, data);
export const importCsv = ({ dispatch }, payload) => {
  dispatch('requestImportCsv');
  const payloadData = { ...payload };
  delete payloadData.id;
  Api.post(`csv_imports/match/${payload.id}`, payloadData)
    .then(({ data }) => {
      dispatch('receiveImportCsvSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveImportCsvError', response);
    });
};
