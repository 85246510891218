import Vue from 'vue';
import router from '@/router';
import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';
// eslint-disable-next-line
import vueApp from '../../../main';

export default {
  [types.GET_BULK_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'totalBulkSms', 0);
  },
  [types.SET_BULK_SMS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'bulkSms', data.result.data);
    Vue.set(state, 'totalBulkSms', new TotalCountAdapter(data.result).count);
  },
  [types.GET_BULK_SMS_ERROR](state) {
    Vue.set(state, 'bulkSms', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BULK_SMS_DETAIL](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BULK_SMS_DETAIL](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'bulkSmsDetail', data.result.data);
  },
  [types.GET_BULK_SMS_DETAIL_ERROR](state) {
    Vue.set(state, 'bulkSmsDetail', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SENT_BULK_SMS_DETAIL_LIST](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'totalBulkSmsList', 0);
  },
  [types.SET_SENT_BULK_SMS_DETAIL_LIST](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sentBulkSmsListData', data.result.data);
    Vue.set(state, 'totalBulkSmsList', new TotalCountAdapter(data.result).count);
  },
  [types.GET_SENT_BULK_SMS_DETAIL_LIST_ERROR](state) {
    Vue.set(state, 'sentBulkSmsListData', []);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_RESET_SENT_BULK_SMS_DETAIL_LIST](state) {
    Vue.set(state, 'sentBulkSmsListData', false);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_DELETE_BULK_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteBulkSmsSuccess', null);
    Vue.set(state, 'deleteBulkSmsError', null);
  },
  [types.SET_DELETE_BULK_SMS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteBulkSmsSuccess', data.message);
  },
  [types.GET_DELETE_BULK_SMS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteBulkSmsError', getErrors(error)[0]);
  },
};
