import Vue from 'vue';
import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';
import { produceKey } from '@/core/utils/helper';
import * as types from './mutation_types';


export default {
  [types.RESET_SEARCH](state) {
    Vue.set(state, 'searchProcessed', false);
    Vue.set(state, 'searchResults', null);
    Vue.set(state, 'searchError', null);
    Vue.set(state, 'searchTotalCount', 0);
  },
  [types.GET_SEARCH](state) {
    Vue.set(state, 'searchProcessed', true);
    Vue.set(state, 'searchResults', null);
    Vue.set(state, 'searchError', null);
  },
  [types.SET_SEARCH](state, data) {
    Vue.set(state, 'searchResults', data.result.data);
    Vue.set(state, 'searchTotalCount', new TotalCountAdapter(data.result).count);
    Vue.set(state, 'searchProcessed', false);
  },
  [types.GET_SEARCH_ERROR](state, error) {
    Vue.set(state, 'searchProcessed', false);
    Vue.set(state, 'searchTotalCount', 0);
    Vue.set(state, 'searchError', error);
  },

  [types.RESET_ELASTIC_SEARCH](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'elasticSearchResults', null);
    Vue.set(state, 'elasticSearchError', null);
    Vue.set(state, 'elasticSearchResultCount', 0);
  },
  [types.GET_ELASTIC_SEARCH](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'elasticSearchResults', null);
    Vue.set(state, 'elasticSearchError', null);
    Vue.set(state, 'elasticSearchResultCount', 0);
  },
  [types.SET_ELASTIC_SEARCH](state, data) {
    Vue.set(state, 'elasticSearchResults', data.result.data);
    Vue.set(state, 'elasticSearchResultCount', data.result.meta.total);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ELASTIC_SEARCH_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'elasticSearchError', error);
  },

  [types.OPEN_LS_MODAL](state, config) {
    Vue.set(state, 'activeModals', {
      ...state.activeModals,
      [config.key]: {
        selector: produceKey(),
        data: config.data,
      },
    });
  },

  async [types.CLOSE_LS_MODAL](state, key) {
    const modal = state.activeModals[key];
    if (modal && modal.selector) {
      const query = $(`#${modal.selector}`);

      if (query.is(':visible')) {
        await query.modal('hide');
      }
    }

    const modals = {
      ...state.activeModals,
    };

    delete modals[key];

    Vue.set(state, 'activeModals', {
      ...modals,
    });
  },

  [types.CLOSE_ALL_LS_MODALS](state) {
    Vue.set(state, 'activeModals', {});
  },

  [types.SET_CONFIGS](state, appConfigs) {
    Vue.set(state, 'appConfigs', appConfigs);
  },
};
