export default {
  error: null,
  isLogged: false,
  isLoading: false,
  resetPasswordError: null,
  resetPasswordSuccess: null,
  authError: null,
  updatePasswordSuccess: null,
  updatePasswordError: null,
};
