import Api from '@/libraries/api';
import * as types from './mutation_types';
import MailTemplatesServices from '../services/mailTemplates.services';

export const requestMailTemplates = ({ commit }) => commit(types.GET_MAIL_TEMPLATES);
export const receiveMailTemplatesSuccess = ({ commit }, data) => commit(types.SET_MAIL_TEMPLATES, data);
export const receiveMailTemplatesError = ({ commit }, error) => commit(types.GET_MAIL_TEMPLATES_ERROR, error);
export const fetchMailTemplates = ({ dispatch }, payload) => {
  dispatch('requestMailTemplates');
  Api.get(`mail_templates${payload.request}`)
    .then(({ data }) => { dispatch('receiveMailTemplatesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveMailTemplatesError', response); });
};

/*  NEW ONE */
export const requestEmailTemplates = ({ commit }) => commit(types.GET_E_MAIL_TEMPLATES);
export const receiveEmailTemplatesSuccess = ({ commit }, data) => commit(types.SET_E_MAIL_TEMPLATES, data);
export const fetchEmailTemplates = async ({ dispatch }, params) => {
  dispatch('requestEmailTemplates');
  try {
    const data = await new MailTemplatesServices().fetch(params);
    dispatch('receiveEmailTemplatesSuccess', data);
  } catch (e) {
    dispatch('receiveEmailTemplatesSuccess', false);
  }
};


export const requestCompanysDefaultTemplate = ({ commit }) => commit(types.GET_COMPANYS_DEFAULT_MAIL_TEMPLATE);
export const receiveCompanysDefaultTemplateSuccess = ({ commit }, data) => commit(types.SET_COMPANYS_DEFAULT_MAIL_TEMPLATE, data);
export const receiveCompanysDefaultTemplateError = ({ commit }, error) => commit(types.GET_COMPANYS_DEFAULT_MAIL_TEMPLATE_ERROR, error);
export const fetchCompanysDefaultTemplate = ({ dispatch }) => {
  dispatch('requestCompanysDefaultTemplate');
  Api.get('mail_templates/company/default_template')
    .then(({ data }) => { dispatch('receiveCompanysDefaultTemplateSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCompanysDefaultTemplateError', response); });
};

export const requestPreviewTemplate = ({ commit }) => commit(types.GET_PREVIEW_TEMPLATE);
export const receivePreviewTemplateSuccess = ({ commit }, data) => commit(types.SET_PREVIEW_TEMPLATE, data);
export const receivePreviewTemplateError = ({ commit }, error) => commit(types.GET_PREVIEW_TEMPLATE_ERROR, error);
export const fetchPreviewTemplate = ({ dispatch }, filter) => {
  dispatch('requestPreviewTemplate');
  Api.get(`mail_templates/${filter.filters}/preview`)
    .then(({ data }) => { dispatch('receivePreviewTemplateSuccess', data); })
    .catch(({ response }) => { dispatch('receivePreviewTemplateError', response); });
};

export const requestDeleteMailTemplate = ({ commit }) => commit(types.DELETE_MAIL_TEMPLATE);
export const receiveDeleteMailTemplateSuccess = ({ commit }, data) => commit(types.DELETE_MAIL_TEMPLATE_SUCCESS, data);
export const receiveDeleteMailTemplateError = ({ commit }, error) => commit(types.DELETE_MAIL_TEMPLATE_ERROR, error);
export const fetchDeleteMailTemplate = async ({ dispatch }, payload) => {
  dispatch('requestDeleteMailTemplate');
  try {
    const { data } = await Api.delete(`mail_templates/${payload.id}`);
    dispatch('receiveDeleteMailTemplateSuccess', data);
  } catch ({ response }) {
    dispatch('receiveDeleteMailTemplateError', response);
  }
};

export const requestCloneMailTemplate = ({ commit }) => commit(types.CLONE_MAIL_TEMPLATE);
export const receiveCloneMailTemplateSuccess = ({ commit }, data) => commit(types.CLONE_MAIL_TEMPLATE_SUCCESS, data);
export const receiveCloneMailTemplateError = ({ commit }, error) => commit(types.CLONE_MAIL_TEMPLATE_ERROR, error);
export const fetchCloneMailTemplate = async ({ dispatch }, payload) => {
  dispatch('requestCloneMailTemplate');
  try {
    const { data } = await Api.post(`mail_templates/clone_mail_template/${payload.id}`);
    dispatch('receiveCloneMailTemplateSuccess', data);
  } catch ({ response }) {
    dispatch('receiveCloneMailTemplateError', response);
  }
};

export const requestCreateMailTemplate = ({ commit }) => commit(types.CREATE_MAIL_TEMPLATE);
export const receiveCreateMailTemplateSuccess = ({ commit }, data) => commit(types.CREATE_MAIL_TEMPLATE_SUCCESS, data);
export const receiveCreateMailTemplateError = ({ commit }, error) => commit(types.CREATE_MAIL_TEMPLATE_ERROR, error);
export const fetchCreateMailTemplate = ({ dispatch }, sendData) => {
  dispatch('requestCreateMailTemplate');
  Api.post('mail_templates/create_mail_template', sendData)
    .then(({ data }) => { dispatch('receiveCreateMailTemplateSuccess', data); })
    .catch(({ response }) => { dispatch('receiveCreateMailTemplateError', response); });
};

export const requestSingleMailTemplate = ({ commit }) => commit(types.GET_MAIL_TEMPLATE);
export const receiveSingleMailTemplateSuccess = ({ commit }, data) => commit(types.GET_MAIL_TEMPLATE_SUCCESS, data);
export const receiveSingleMailTemplateError = ({ commit }, error) => commit(types.GET_MAIL_TEMPLATE_ERROR, error);
export const fetchSingleMailTemplate = ({ dispatch }, mailTemplateId) => {
  dispatch('requestSingleMailTemplate');
  Api.get(`mail_templates/${mailTemplateId}`)
    .then(({ data }) => { dispatch('receiveSingleMailTemplateSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSingleMailTemplateError', response); });
};

export const requestUpdateMailTemplate = ({ commit }) => commit(types.UPDATE_MAIL_TEMPLATE);
export const receiveUpdateMailTemplateSuccess = ({ commit }, data) => commit(types.UPDATE_MAIL_TEMPLATE_SUCCESS, data);
export const receiveUpdateMailTemplateError = ({ commit }, error) => commit(types.UPDATE_MAIL_TEMPLATE_ERROR, error);
export const fetchUpdateMailTemplate = ({ dispatch }, payload) => {
  dispatch('requestUpdateMailTemplate');
  const sendData = { ...payload };
  delete sendData.mail_template_id;
  Api.patch(`mail_templates/${payload.mail_template_id}`, sendData)
    .then(({ data }) => { dispatch('receiveUpdateMailTemplateSuccess', data); })
    .catch(({ response }) => { dispatch('receiveUpdateMailTemplateError', response); });
};


export const requestNewEditSession = ({ commit }) => commit(types.GET_NEW_EDIT_SESSION);
export const receiveNewEditSessionSuccess = ({ commit }, data) => commit(types.SET_NEW_EDIT_SESSION_SUCCESS, data);
export const receiveNewEditSessionError = ({ commit }, error) => commit(types.GET_NEW_EDIT_SESSION_ERROR, error);
export const fetchNewEditSession = ({ dispatch }, payload) => {
  dispatch('requestNewEditSession');
  Api.get(`mail_templates/edit_session/${payload.edit_session}`)
    .then(({ data }) => { dispatch('receiveNewEditSessionSuccess', data); })
    .catch(({ response }) => { dispatch('receiveNewEditSessionError', response); });
};
