import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestArticles = ({ commit }) => commit(types.GET_ARTICLES);
export const receiveArticlesSuccess = ({ commit }, data) => commit(types.SET_ARTICLES, data);
export const receiveArticlesError = ({ commit }, error) => commit(types.GET_ARTICLES_ERROR, error);

export const fetchArticles = ({ dispatch }, filter) => {
  dispatch('requestArticles');

  Api.get(`gb/articles${filter.request}`)
    .then(({ data }) => { dispatch('receiveArticlesSuccess', data); })
    .catch(({ response }) => { dispatch('receiveArticlesError', response); });
};

export const requestGetBlog = ({ commit }) => commit(types.GET_BLOG);
export const recieveGetBlogSuccess = ({ commit }, data) => commit(types.GET_BLOG_SUCCESS, data);
export const recieveGetBlogError = ({ commit }, error) => commit(types.GET_BLOG_ERROR, error);
export const resetBlog = ({ commit }, error) => commit(types.RESET_BLOG, error);

export const fetchGetBlog = ({ dispatch }, blogId) => {
  dispatch('requestGetBlog');
  Api.get(`gb/articles/${blogId}`)
    .then(({ data }) => {
      dispatch('recieveGetBlogSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recieveGetBlogError', response);
    });
};

export const requestCreateBlog = ({ commit }) => commit(types.CREATE_BLOG);
export const recieveCreateBlogSuccess = ({ commit }, data) => commit(types.CREATE_BLOG_SUCCESS, data);
export const recieveCreateBlogError = ({ commit }, error) => commit(types.CREATE_BLOG_ERROR, error);

export const fetchCreateBlog = ({ dispatch }, blogData) => {
  dispatch('requestCreateBlog');
  Api.postFile('gb/articles', blogData)
    .then(({ data }) => {
      dispatch('recieveCreateBlogSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recieveCreateBlogError', response);
    });
};

export const requestDeleteBlog = ({ commit }) => commit(types.DELETE_BLOG);
export const recieveDeleteBlogSuccess = ({ commit }, data) => commit(types.DELETE_BLOG_SUCCESS, data);
export const recieveDeleteBlogError = ({ commit }, error) => commit(types.DELETE_BLOG_ERROR, error);

export const fetchDeleteBlog = ({ dispatch }, payload) => {
  dispatch('requestDeleteBlog');
  Api.delete(`gb/articles/${payload.id}`)
    .then(({ data }) => {
      dispatch('recieveDeleteBlogSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recieveDeleteBlogError', response);
    });
};

export const requestUpdateBlog = ({ commit }) => commit(types.UPDATE_BLOG);
export const recieveUpdateBlogSuccess = ({ commit }, data) => commit(types.UPDATE_BLOG_SUCCESS, data);
export const recieveUpdateBlogError = ({ commit }, error) => commit(types.UPDATE_BLOG_ERROR, error);

export const fetchUpdateBlog = ({ dispatch }, blogData) => {
  dispatch('requestUpdateBlog');
  Api.postFile(`gb/articles/${blogData.get('article_id')}`, blogData)
    .then(({ data }) => {
      dispatch('recieveUpdateBlogSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recieveUpdateBlogError', response);
    });
};

export const requestUpdateBlogActive = ({ commit }) => commit(types.UPDATE_BLOG_ACTIVE);
export const recieveUpdateBlogActiveSuccess = ({ commit }, data) => commit(types.UPDATE_BLOG_ACTIVE_SUCCESS, data);
export const recieveUpdateBlogActiveError = ({ commit }, error) => commit(types.UPDATE_BLOG_ACTIVE_ERROR, error);

export const fetchUpdateBlogActive = ({ dispatch }, blogData) => {
  dispatch('requestUpdateBlogActive');
  Api.postFile(`gb/articles/status/${blogData.article_id}`, blogData.data)
    .then(({ data }) => {
      dispatch('recieveUpdateBlogActiveSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('recieveUpdateBlogActiveError', response);
    });
};

export const requestArticleCategories = ({ commit }) => commit(types.GET_ARTICLE_CATEGORIES);
export const receiveArticleCategories = ({ commit }, data) => commit(types.SET_ARTICLE_CATEGORIES, data);
export const receiveArticleCategoriesError = ({ commit }, error) => commit(types.SET_ARTICLE_CATEGORIES, error);
export const fetchArticleCategories = ({ dispatch }) => {
  dispatch('requestArticleCategories');
  Api.get('/gb/article_categories')
    .then(({ data }) => {
      dispatch('receiveArticleCategories', data);
    })
    .catch(({ response }) => {
      dispatch('receiveArticleCategoriesError', response);
    });
};

export const requestShareBlogOnSocialMedias = ({ commit }) => commit(types.GET_SHARE_BLOG_ON_SOCIAL_MEDIAS);
export const receiveShareBlogOnSocialMedias = ({ commit }, data) => commit(types.SET_SHARE_BLOG_ON_SOCIAL_MEDIAS, data);
export const receiveShareBlogOnSocialMediasError = ({ commit }, error) => commit(types.GET_SHARE_BLOG_ON_SOCIAL_MEDIAS_ERROR, error);
export const fetchShareBlogOnSocialMedias = ({ dispatch }, payload) => {
  dispatch('requestShareBlogOnSocialMedias');
  Api.post('shared_social_media_posts', payload)
    .then(({ data }) => {
      dispatch('receiveShareBlogOnSocialMedias', data);
    })
    .catch(({ response }) => {
      dispatch('receiveShareBlogOnSocialMediasError', response);
    });
};

export const requestBlogWaitingShares = ({ commit }) => commit(types.GET_BLOG_WAITING_SHARES);
export const receiveBlogWaitingShares = ({ commit }, data) => commit(types.SET_BLOG_WAITING_SHARES, data);
export const receiveBlogWaitingSharesError = ({ commit }, error) => commit(types.GET_BLOG_WAITING_SHARES_ERROR, error);
export const fetchBlogWaitingShares = ({ dispatch }, payload) => {
  dispatch('requestBlogWaitingShares');
  Api.get(`gb/shared_social_media_articles/waiting_shares/${payload.article_id}`)
    .then(({ data }) => {
      dispatch('receiveBlogWaitingShares', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBlogWaitingSharesError', response);
    });
};

export const requestSocialMediaShareInfo = ({ commit }) => commit(types.GET_SOCIAL_MEDIA_SHARE_INFO);
export const receiveSocialMediaShareInfo = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_SHARE_INFO, data);
export const receiveSocialMediaShareInfoError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_SHARE_INFO_ERROR, error);
export const fetchSocialMediaShareInfo = ({ dispatch }, payload) => {
  dispatch('requestSocialMediaShareInfo');
  Api.get(`shared_social_media_posts/related_content/${payload.related_id}?order_by=updated_at&shared_social_media_content_type_id=${payload.shared_social_media_content_type_id}&__limit=500`)
    .then(({ data }) => {
      dispatch('receiveSocialMediaShareInfo', data.result.data);
    })
    .catch(({ response }) => {
      dispatch('receiveSocialMediaShareInfoError', response);
    });
};

export const requestArticlesMinimal = ({ commit }) => commit(types.GET_ARTICLES_MINIMAL);
export const receiveArticlesMinimalSuccess = ({ commit }, data) => commit(types.SET_ARTICLES_MINIMAL, data);
export const receiveArticlesMinimalError = ({ commit }, error) => commit(types.GET_ARTICLES_MINIMAL_ERROR, error);

export const fetchArticlesMinimal = ({ dispatch }, filter) => {
  dispatch('requestArticlesMinimal');

  Api.get(`gb/articles/minimal${filter.request}`)
    .then(({ data }) => { dispatch('receiveArticlesMinimalSuccess', data); })
    .catch(({ response }) => { dispatch('receiveArticlesMinimalError', response); });
};

export const socialMediaAllPostsSuccess = ({ commit }, data) => commit(types.SET_SOCIAL_MEDIA_ALL_POSTS, data);
export const socialMediaAllPostsError = ({ commit }, error) => commit(types.GET_SOCIAL_MEDIA_ALL_POSTS_ERROR, error);

export const fetchSocialMediaAllPosts = ({ dispatch }) => {
  Api.get('shared_social_media_posts/?__order_by=-created_at&__limit=500')
    .then(({ data }) => { dispatch('socialMediaAllPostsSuccess', data); })
    .catch(({ response }) => { dispatch('socialMediaAllPostsError', response); });
};
