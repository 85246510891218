import * as types from './mutation_types';

export default {
  [types.SET_COMPANIES_DATA](state, { data, meta }) {
    if (data.length) {
      state.companiesList = data;
      state.companiesTotal = meta;
    } else {
      state.companiesList = null;
    }
  },
  [types.SET_MEMBERS_DATA](state, { data, meta }) {
    if (data.length) {
      state.membersList = data;
      state.membersTotal = meta;
    } else {
      state.membersList = null;
    }
  },
  [types.SET_RELATED_PROPERTY_DATA](state, { data }) {
    state.relatedPropertyMemberDetail = data;
    if (data.related_properties.length) {
      state.relatedPropertyList = data.related_properties;
      state.relatedPropertyTotal = data.related_property_count;
    } else {
      state.relatedPropertyList = null;
    }
  },
  [types.RESET_RELATED_PROPERTY_DATA](state) {
    state.relatedPropertyMemberDetail = null;
    state.relatedPropertyList = [];
    state.relatedPropertyTotal = null;
  },
  [types.SET_CUSTOM_FIELDS_DATA](state, data) {
    if (data.length) {
      state.customFields = data;
    }
  },
  [types.RESET_CUSTOM_FIELDS_DATA](state) {
    state.customFields = null;
  },
};
