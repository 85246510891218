export default [
  {
    path: '/calendar',
    meta: {
      title: 'Calendar',
      breadcrumb: [
        { name: 'Calendar' },
      ],
    },
    name: 'Calendar',
    component: () => import('@/modules/calendar/views/Calendar.vue'),
  },
];
