export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';
export const SET_EVENTS = 'SET_EVENTS';

export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';
export const SET_EVENT = 'SET_EVENT';
export const UNSET_EVENT = 'UNSET_EVENT';
export const REMOVE_EVENT = 'REMOVE_EVENT';

export const GET_EVENT_TYPES = 'GET_EVENT_TYPES';
export const GET_EVENT_TYPES_ERROR = 'GET_EVENT_TYPES_ERROR';
export const SET_EVENT_TYPES = 'SET_EVENT_TYPES';

export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS';
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';
export const GET_CALENDAR_EVENTS_ERROR = 'GET_CALENDAR_EVENTS_ERROR';

export const GET_CALENDAR_EVENTS_BY_STATUS = 'GET_CALENDAR_EVENTS_BY_STATUS';
export const SET_CALENDAR_EVENTS_BY_STATUS = 'SET_CALENDAR_EVENTS_BY_STATUS';
export const GET_CALENDAR_EVENTS_BY_STATUS_ERROR = 'GET_CALENDAR_EVENTS_BY_STATUS_ERROR';

export const GET_EVENT_STATUSES = 'GET_EVENT_STATUSES';
export const SET_EVENT_STATUSES = 'SET_EVENT_STATUSES';
export const GET_EVENT_STATUSES_ERROR = 'GET_EVENT_STATUSES_ERROR';

export const UPDATE_CALENDAR_EVENT_STATUS = 'UPDATE_CALENDAR_EVENT_STATUS';
export const UPDATE_CALENDAR_EVENT_STATUS_SUCCESS = 'UPDATE_CALENDAR_EVENT_STATUS_SUCCESS';
export const UPDATE_CALENDAR_EVENT_STATUS_ERROR = 'UPDATE_CALENDAR_EVENT_STATUS_ERROR';

export const CREATE_CALENDAR_EVENT = 'CREATE_CALENDAR_EVENT';
export const CREATE_CALENDAR_EVENT_SUCCESS = 'CREATE_CALENDAR_EVENT_SUCCESS';
export const CREATE_CALENDAR_EVENT_ERROR = 'CREATE_CALENDAR_EVENT_ERROR';

export const UPDATE_CALENDAR_EVENT = 'UPDATE_CALENDAR_EVENT';
export const UPDATE_RECURRING_EVENT = 'UPDATE_RECURRING_EVENT';
export const REMOVE_RECURRING_EVENTS = 'REMOVE_RECURRING_EVENTS';
export const PATCH_RECURRING_EVENT = 'PATCH_RECURRING_EVENT';
export const UPDATE_CALENDAR_EVENT_SUCCESS = 'UPDATE_CALENDAR_EVENT_SUCCESS';
export const UPDATE_CALENDAR_EVENT_ERROR = 'UPDATE_CALENDAR_EVENT_ERROR';

export const CALENDAR_USER_SETTINGS = 'CALENDAR_USER_SETTINGS';
export const CALENDAR_USER_SETTINGS_SUCCESS = 'CALENDAR_USER_SETTINGS_SUCCESS';
export const CALENDAR_USER_SETTINGS_ERROR = 'CALENDAR_USER_SETTINGS_ERROR';

export const SEARCH_CALENDAR_EVENT = 'SEARCH_CALENDAR_EVENTS';
export const SEARCH_CALENDAR_EVENT_SUCCESS = 'SEARCH_CALENDAR_EVENTS_SUCCESS';
export const SEARCH_CALENDAR_EVENT_ERROR = 'SEARCH_CALENDAR_EVENTS_ERROR';

export const SET_COLLAPSES = 'SET_COLLAPSES';

export const SET_ALL_USERS = 'SET_ALL_USERS';

export const SET_ALL_TYPES = 'SET_ALL_TYPES';

export const SET_ALL_TRANSACTION_TYPES = 'SET_ALL_TRANSACTION_TYPES';

export const SET_ALL_VIEWING_TYPES = 'SET_ALL_VIEWING_TYPES';

export const SET_ALL_CONFIRMATION_TYPES = 'SET_ALL_CONFIRMATION_TYPES';

export const SET_ALL_DIALOG_USERS = 'SET_ALL_DIALOG_USERS';

export const SET_ALL_DIALOG_TYPES = 'SET_ALL_DIALOG_TYPES';

export const SET_ALL_DIALOG_TRANSACTION_TYPES = 'SET_ALL_DIALOG_TRANSACTION_TYPES';

export const SET_ALL_DIALOG_VIEWING_TYPES = 'SET_ALL_DIALOG_VIEWING_TYPES';

export const SET_ALL_DIALOG_CONFIRMATION_TYPES = 'SET_ALL_DIALOG_CONFIRMATION_TYPES';

export const SET_SELECT_DATE = 'SET_SELECT_DATE';

export const SET_SELECT_DATE_WITHOUT_VIEW = 'SET_SELECT_DATE_WITHOUT_VIEW';

export const DROP_RESIZE_CALENDAR_EVENT = 'DROP_RESIZE_CALENDAR_EVENT';

export const SET_FETCHED_RANGE = 'SET_FETCHED_RANGE';

export const SET_FETCHED_RECURRING = 'SET_FETCHED_RECURRING';

export const SET_FOLLOW_UP = 'SET_FOLLOW_UP';

export const SET_MA_NOT_YET_SENT = 'SET_MA_NOT_YET_SENT';

export const ADD_EVENT = 'ADD_EVENT';

export const PATCH_EVENT = 'PATCH_EVENT';

export const RESET_STATE = 'RESET_STATE';

export const SEARCH_APPLICANT_OR_PROPERTY = 'SEARCH_APPLICANT_OR_PROPERTY';

export const RESET_SEARCH_APPLICANT_OR_PROPERTY = 'RESET_SEARCH_APPLICANT_OR_PROPERTY';

export const REMOVE_APPLICANT = 'REMOVE_APPLICANT';

export const REMOVE_PROPERTY = 'REMOVE_PROPERTY';

export const SELECT_APPLICANT = 'SELECT_APPLICANT';

export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';

export const SET_SELECTED_APPLICANT = 'SET_SELECTED_APPLICANT';

export const UNSET_SELECTED_APPLICANT = 'UNSET_SELECTED_APPLICANT';

export const FIND_VIEWED_CONTACT = 'FIND_VIEWED_CONTACT';

export const RESET_VIEWING_STATE = 'RESET_VIEWING_STATE';

export const HANDLE_VIEWING_DATA = 'HANDLE_VIEWING_DATA';

export const SET_APPLICANT_KEYWORD = 'SET_APPLICANT_KEYWORD';

export const SET_APPLICANT_LIST = 'SET_APPLICANT_LIST';

export const RESET_APPLICANT_LIST = 'RESET_APPLICANT_LIST';

export const SET_APPLICANT_LIST_META = 'SET_APPLICANT_LIST_META';

export const GET_TENANT = 'GET_TENANT';

export const CONTROL_STATUS_AVAILABILITY = 'CONTROL_STATUS_AVAILABILITY';

export const OPEN_CALENDAR_MODAL = 'OPEN_CALENDAR_MODAL';

export const SET_CANCELLED_EVENT_ITEM = 'SET_CANCELLED_EVENT_ITEM';

export const SET_EVENTS_LIST = 'SET_EVENTS_LIST';

export const SET_SKELETONS = 'SET_SKELETONS';
