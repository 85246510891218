import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_SUBS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SUBS](state, data) {
    Vue.set(state, 'subscriptionTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SUBS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
};
