import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_COMMUNICATION_METHODS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'communicationMethods', false);
  },
  [types.SET_COMMUNICATION_METHODS](state, data) {
    Vue.set(state, 'communicationMethods', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_COMMUNICATION_METHODS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
};
