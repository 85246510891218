import ApiService from '@/core/api/api.service';


export default class ContractsServices extends ApiService {
  constructor() {
    super();
    this.url = 'sg';
  }

  create(payload) {
    this.payload = payload;
    this.url = `${this.url}/documents`;
    return this.post();
  }

  async sendSms(id, payload, resend = false) {
    this.payload = payload;
    this.url = `${this.url}/common/${resend ? 're_' : ''}send_sms/${id}`;
    return this.post();
  }

  async sendEmail(id, payload, resend = false) {
    this.payload = payload;
    this.url = `${this.url}/common/${resend ? 're_' : ''}send_email/${id}`;
    return this.post();
  }

  async fetchSettings() {
    this.url = `${this.url}/settings`;
    const { result: { data } } = await this.get();
    return data;
  }

  async archive(id) {
    this.url = `${this.url}/documents/${id}`;
    const { message } = await this.delete();
    return message;
  }

  async preview(payload) {
    this.url = `${this.url}/common/fly-preview`;
    this.payload = payload;
    const { result: { data } } = await this.post();
    return data;
  }

  async update(documentId, payload) {
    this.url = `${this.url}/documents/minimal_update/${documentId}`;
    this.payload = payload;
    return this.baseRemitter('patch', 'data');
  }

  async revokeContact(payload) {
    this.url = `${this.url}/document_contacts/revoke_contact`;
    this.payload = payload;
    return this.baseRemitter('patch');
  }

  async addContact(payload) {
    this.url = `${this.url}/document_contacts/assign_contact`;
    this.payload = payload;
    return this.baseRemitter('patch');
  }

  async previewDocumentContact(documentContactId) {
    this.url = `${this.url}/document_contacts/preview/${documentContactId}`;
    return this.baseRemitter('get', 'data', false);
  }
}
