import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_TAGS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'tags', null);
  },
  [types.SET_TAGS](state, data) {
    Vue.set(state, 'tags', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_TAGS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'tags', []);
  },

  [types.GET_CREATE_TAG](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createTagSuccess', null);
    Vue.set(state, 'createTagError', null);
  },
  [types.SET_CREATE_TAG_SUCCESS](state, data) {
    Vue.set(state, 'createTagSuccess', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_TAG_ERROR](state, error) {
    Vue.set(state, 'createTagError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_AUTOCOMPLETE_TAG](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'searchedAutocompleteTags', false);
  },
  [types.SET_AUTOCOMPLETE_SUCCESS](state, data) {
    Vue.set(state, 'searchedAutocompleteTags', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_AUTOCOMPLETE_ERROR](state) {
    Vue.set(state, 'searchedAutocompleteTags', []);
    Vue.set(state, 'isLoading', false);
  },
};
