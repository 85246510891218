import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';

export default {
  SET_CONTRACT_TEMPLATES(state, { data, meta }) {
    state.totalContractTemplate = new TotalCountAdapter({ meta, data }).count;
    state.contractTemplates = data;
  },
  SET_CONTRACT_SETTINGS(state, data) {
    state.contractSettings = data;
  },
  SET_CONTRACT(state, data) {
    state.contract = data;
  },
  SET_CONTRACT_TAGS(state, data) {
    state.contractTags = data;
  },
};
