import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_PROPERTY_PROFILE_PRICES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PROPERTY_PROFILE_PRICES](state, data) {
    Vue.set(state, 'propertyProfilePrices', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_PROFILE_PRICES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_PROPERTY_PROFILE_PRICE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.CREATE_PROPERTY_PROFILE_PRICE_SUCCESS](state, data) {
    Vue.set(state, 'propertyProfilePrice', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_PROPERTY_PROFILE_PRICE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.UPDATE_PROPERTY_PROFILE_PRICE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.UPDATE_PROPERTY_PROFILE_PRICE_SUCCESS](state, data) {
    Vue.set(state, 'propertyProfilePrice', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateProfilePriceSuccess', true);
  },
  [types.UPDATE_PROPERTY_PROFILE_PRICE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateProfilePriceError', true);
  },
};
