export const centralLoader = state => state.runningRequests.filter(request => request.show).length > 0;

export const isProcessInTheBackground = state => state.runningRequests.filter(request => !request.show).length > 0;

/**
 * @description Checks whether specific named request is loading or not
 * @param state
 * @returns {function(*): boolean}
 */
export const isProcessLoading = state => requestName => !!state.runningRequests.find(request => request.name === requestName);
