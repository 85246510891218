import Vue from 'vue';
import * as types from './mutation_types';
// eslint-disable-next-line
import { getErrors } from '@/mixins/ErrorManager';

export default {
  [types.GET_MAIL_LISTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_MAIL_LISTS](state, data) {
    Vue.set(state, 'totalMailLists', data.result.meta.total);
    Vue.set(state, 'mailLists', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_MAIL_LISTS_ERROR](state, error) {
    if (error.data.code === 404) {
      Vue.set(state, 'mailLists', []);
    }
    Vue.set(state, 'error', {
      message: error.data.message,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 2000,
    });
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SMS_INFO](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SMS_INFO](state, data) {
    Vue.set(state, 'smsInfo', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SMS_INFO_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.SET_SELECTED_AUDIENCES](state, data) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state.smsSelectedAudiences, state.smsSelectedAudiences.length, data);
    Vue.set(state, 'isLoading', false);
  },
  [types.UNSET_SELECTED_AUDIENCES](state, data) {
    Vue.set(state, 'isLoading', true);
    const item = state.smsSelectedAudiences.findIndex(c => c.mail_list_id === data.mail_list_id);
    state.smsSelectedAudiences.splice(item, 1);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SEND_BULK_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sendBulkSmsSuccess', null);
    Vue.set(state, 'sendBulkSmsErrors', null);
  },
  [types.SET_SEND_BULK_SMS_SUCCESS](state, data) {
    Vue.set(state, 'sendBulkSmsSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SEND_BULK_SMS_ERROR](state, error) {
    Vue.set(state, 'sendBulkSmsErrors', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_ACCEPT_SMS_CREDIT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'acceptSmsCreditSuccess', null);
    Vue.set(state, 'acceptSmsCreditErrors', null);
  },
  [types.SET_ACCEPT_SMS_CREDIT](state, data) {
    Vue.set(state, 'acceptSmsCreditSuccess', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ACCEPT_SMS_CREDIT_ERROR](state, error) {
    Vue.set(state, 'acceptSmsCreditErrors', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_NOT_ACCEPT_SMS_CREDIT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'notAcceptSmsCreditSuccess', null);
    Vue.set(state, 'notAcceptSmsCreditErrors', null);
  },
  [types.SET_NOT_ACCEPT_SMS_CREDIT](state, data) {
    Vue.set(state, 'notAcceptSmsCreditSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_NOT_ACCEPT_SMS_CREDIT_ERROR](state, error) {
    Vue.set(state, 'notAcceptSmsCreditErrors', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },
};
