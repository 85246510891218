export default {
  isLoading: false,
  error: null,
  success: null,
  mailLists: null,
  totalMailLists: null,
  smsInfo: null,
  smsSelectedAudiences: [],
  sendBulkSmsSuccess: null,
  sendBulkSmsErrors: null,
  acceptSmsCreditSuccess: null,
  acceptSmsCreditErrors: null,
  notAcceptSmsCreditSuccess: null,
  notAcceptSmsCreditErrors: null,
};
