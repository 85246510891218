import Api from '@/libraries/api';

import NotificationServices from '@/modules/users/services/notification.services';
import UsersServices from '@/modules/users/services/users.services';
import logoutService from '@/modules/users/services/logout.service';
import * as types from './mutation_types';

export const requestMe = ({ commit }) => commit(types.GET_USER);
export const receiveMeSuccess = ({ commit }, data) => {
  commit(types.SET_USER, data);
};

export const fetchMe = async ({ dispatch }) => {
  try {
    dispatch('requestMe');
    const data = await new UsersServices().fetchMe();
    dispatch('receiveMeSuccess', data);
  } catch (e) {
    logoutService();
  }
};


export const requestUsers = ({ commit }) => commit(types.GET_USERS);
export const receiveUsersSuccess = ({ commit }, data) => commit(types.SET_USERS, data);
export const receiveUsersError = ({ commit }, error) => commit(types.GET_USERS_ERROR, error);
export const fetchUsers = ({ dispatch }) => {
  dispatch('requestUsers');
  Api.get('users?__limit=250&__order_by=name')
    .then(({ data }) => {
      dispatch('receiveUsersSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveUsersError', response);
    });
};

export const requestBranchUsers = ({ commit }) => commit(types.GET_BRANCH_USERS);
export const receiveBranchUsersSuccess = ({ commit }, data) => commit(types.SET_BRANCH_USERS, data);
export const receiveBranchUsersError = ({ commit }, error) => commit(types.GET_BRANCH_USERS_ERROR, error);
export const fetchBranchUsers = ({ dispatch }, branchId) => {
  dispatch('requestBranchUsers');
  Api.get(`users2?branch_id=${branchId}&__limit=250__order_by=name`)
    .then(({ data }) => {
      dispatch('receiveBranchUsersSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBranchUsersError', response);
    });
};

export const fetchNotifyUserTypes = async ({ commit }) => {
  try {
    const data = await new NotificationServices().fetchTypes();
    commit('SET_USER_NOTIFY_TYPES', data);
  } catch (response) {
    commit('SET_USER_NOTIFY_TYPES', []);
  }
};
