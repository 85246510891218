import ApiService from '@/core/api/api.service';


// eslint-disable-next-line import/prefer-default-export
export class PropertyProfileServices extends ApiService {
  constructor() {
    super();
    this.url = 'properties';
  }

  async update({ propertyId, propertyProfileId, payload }) {
    this.payload = payload;
    this.url = `${this.url}/${propertyId}/profiles/${propertyProfileId}`;
    return this.patch();
  }

  async show(propertyProfileId, isLoaderEnabled = true) {
    this.url = `property_profiles/${propertyProfileId}`;
    this.loader = isLoaderEnabled;
    return this.get();
  }

  async fetchContacts(propertyProfileId) {
    this.loader = false;
    this.url = `property_profiles/${propertyProfileId}/contacts`;
    return this.get();
  }

  async getContact(propertyProfileId, profileId) {
    this.url = `property_profiles/${propertyProfileId}/contacts?profile_id=${profileId}`;
    return this.get();
  }

  async addHistoricalNote(payload) {
    this.url = 'properties/historical_notes';
    this.payload = payload;
    return this.post();
  }

  async search(keyword) {
    this.params = {
      '_search[q]': keyword,
      __order_by: '-created_at',
      __limit: 250,
    };
    this.url = 'property_profiles';
    const { result: { data } } = await this.get();
    return data;
  }


  async getBoardInfo(propertyProfileId) {
    this.loader = false;
    this.url = `property_profiles/${propertyProfileId}/sale_board`;
    const { result: { data } } = await this.get();
    return data;
  }

  async getSlipOptions() {
    this.loader = false;
    this.url = 'sale_board_slip_options';
    const { result: { data } } = await this.get();
    return data;
  }

  async getDisciplineOptions() {
    this.loader = false;
    this.url = 'sale_bord_discipline';
    const { result: { data } } = await this.get();
    return data;
  }

  async postBoardInfo(boardInfo, propertyProfileId) {
    this.url = `property_profiles/${propertyProfileId}/sale_board`;
    this.payload = boardInfo;
    const result = await this.post();
    return result;
  }

  async submitFeedback(payload) {
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/viewing_feedbacks`;
    this.payload = payload.payload;
    return this.baseRemitter('post', 'data', true);
  }

  getFeedbackAppointment(viewingAppointmentId, feedbackId) {
    this.url = `cr/viewing_appointments/${viewingAppointmentId}/viewing_feedbacks/${feedbackId}`;
    return this.baseRemitter('get', 'data', false);
  }

  updateFeedbackAppointments(payload) {
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/viewing_feedbacks`;
    this.payload = payload.payload;
    return this.baseRemitter('post', 'message');
  }

  getOffer(viewingAppointmentId, offerId) {
    this.url = `cr/viewing_appointments/${viewingAppointmentId}/offers/${offerId}`;
    return this.baseRemitter('get', 'data', false);
  }

  getViewing(viewingAppointmentId) {
    this.url = `cr/viewing_appointments/${viewingAppointmentId}`;
    return this.baseRemitter('get', 'data', false);
  }

  getFeedback(viewingAppointmentId, feedbackId) {
    this.url = `cr/viewing_appointments/${viewingAppointmentId}/viewing_feedbacks/${feedbackId}`;
    return this.baseRemitter('get', 'data', false);
  }

  updateOfferNote(payload) {
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/offers/${payload.offer_id}`;
    this.payload.note = payload.note;
    this.payload.proceedable = payload.proceedable;
    return this.baseRemitter('patch', 'data', true);
  }

  updateOfferStatus(payload) {
    this.url = `cr/viewing_appointments/${payload.viewing_appointment_id}/offers/${payload.offer_id}/status`;
    this.payload.offer_status_id = payload.offer_status_id;
    this.payload.reject_others = payload.reject_others;
    this.payload.note = payload.note;
    this.payload.mark_as_sstc = payload.mark_as_sstc;
    return this.baseRemitter('patch', 'data', true);
  }

  async getStats(propertyProfileId) {
    this.loader = false;
    this.url = `search_engine/stats/property/overview/${propertyProfileId}`;
    const { result: { data } } = await this.get();
    return data;
  }

  async sendConfirmationMail(viewingAppointmentId, payload) {
    this.url = `cr/viewing_appointments/report/mail_and_sms/${viewingAppointmentId}`;
    this.payload = payload;
    return this.baseRemitter('post', 'data', true);
  }

  async sendMultipleConfirmationMail(viewingAppointmentId, payload) {
    this.url = `cr/viewing_appointments/report/multiple_mail_and_sms/${viewingAppointmentId}`;
    this.payload = payload;
    return this.baseRemitter('post', 'data', false);
  }

  async sendMultipleCancellationMail(viewingAppointmentId, payload) {
    this.url = `/cr/viewing_appointments/report/cancelled/multiple_mail_and_sms/${viewingAppointmentId}`;
    this.payload = payload;
    return this.baseRemitter('post', 'data', false);
  }

  async getOverviewPIQ(propertyProfileId) {
    this.loader = false;
    this.url = `property_profiles/${propertyProfileId}/piq_forms`;
    const { result: { data } } = await this.get();
    return data;
  }

  async previewPIQ(propertyProfileId) {
    this.url = `property_profiles/${propertyProfileId}/piq_forms/preview`;
    return this.baseRemitter('get', 'data', true);
  }

  async completePIQ(propertyProfileId) {
    this.url = `property_profiles/${propertyProfileId}/piq_forms/complete`;
    return this.baseRemitter('post', 'data', true);
  }

  async downloadPiqFiles(formId) {
    this.url = `properties/property_information_form/download_all/${formId}`;
    return this.baseRemitter('post', 'data', true);
  }

  async piqPreviewFiles(propertyProfileId) {
    this.url = `property_profiles/${propertyProfileId}/piq_forms/preview_files`;
    return this.baseRemitter('get', 'data', true);
  }

  async previewSinglePiqFile(propertyProfileId, filePath) {
    this.url = `property_profiles/${propertyProfileId}/piq_forms/preview_files/show?file=${filePath}`;
    return this.baseRemitter('get', 'data', true);
  }

  async getMailSettings() {
    this.url = 'common_email_sms_variables';
    const { result } = await this.get();
    return result;
  }

  async sendFeedbackNote(payload) {
    this.url = `/cr/viewing_appointments/${payload.viewing_appointment_id}/viewing_feedbacks/feedback_note/${payload.viewing_feedback_id}`;
    this.payload = payload;
    return this.baseRemitter('post', 'data', false);
  }

  async feedbackReportVerbally(payload) {
    this.url = `/cr/viewing_feedbacks/${payload.viewing_feedback_id}/report/verbal`;
    this.payload = payload;
    return this.baseRemitter('post', 'data', true);
  }
}

export class PropertyProfileTimelineServices extends ApiService {
  constructor() {
    super();
    this.url = 'properties';
  }

  async getTimelineActivityTypes() {
    this.url = 'activities/property_activities_for_front_end';
    this.params = {
      __order_by: 'sentence',
      __limit: 250,
    };
    const { result: { data } } = await this.get();
    return data;
  }

  async getTimelineActivityContents(propertyActivityId) {
    this.url = `activities/property_activities_content/${propertyActivityId}`;
    const { result: { data } } = await this.get();
    return data;
  }

  async getTimelineData(propertyProfileId, request) {
    this.url = `property_profiles/${propertyProfileId}/timeline${request}`;
    return this.get();
  }
}
