export const GET_CONTACTS = 'GET_CONTACTS';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONTACTS_LIST = 'SET_CONTACTS_LIST';
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';

export const GET_CONTACT = 'GET_CONTACT';
export const SET_CONTACT = 'SET_CONTACT';
export const GET_CONTACT_ERROR = 'GET_CONTACT_ERROR';
export const RESET_CONTACT = 'RESET_CONTACT';

export const GET_LAST_CREATED_CONTACT = 'GET_LAST_CREATED_CONTACT';
export const SET_LAST_CREATED_CONTACT = 'SET_LAST_CREATED_CONTACT';
export const GET_LAST_CREATED_CONTACT_ERROR = 'GET_LAST_CREATED_CONTACT_ERROR';

export const GET_CONTACT_SOURCES = 'GET_CONTACT_SOURCES';
export const SET_CONTACT_SOURCES = 'SET_CONTACT_SOURCES';
export const GET_CONTACT_SOURCES_ERROR = 'GET_CONTACT_SOURCES_ERROR';

export const GET_CONTACT_LEAD_SOURCES = 'GET_CONTACT_LEAD_SOURCES';
export const SET_CONTACT_LEAD_SOURCES = 'SET_CONTACT_LEAD_SOURCES';
export const GET_CONTACT_LEAD_SOURCES_ERROR = 'GET_CONTACT_LEAD_SOURCES_ERROR';

export const SET_ALL_CONTACT = 'SET_ALL_CONTACT';
export const UNSET_ALL_CONTACT = 'UNSET_ALL_CONTACT';
export const CLEAR_SELECTED_CONTACTS = 'CLEAR_SELECTED_CONTACTS';
export const CLEAR_ALL_SELECTED_CONTACTS = 'CLEAR_ALL_SELECTED_CONTACTS';

export const SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT';
export const UNSET_SELECTED_CONTACT = 'UNSET_SELECTED_CONTACT';

export const SET_CORRESPONDANCE_ADDRESS = 'SET_CORRESPONDANCE_ADDRESS';

export const SET_EXPORT_CONTACTS = 'SET_EXPORT_CONTACTS';
export const GET_EXPORT_CONTACTS = 'GET_EXPORT_CONTACTS';
export const GET_EXPORT_CONTACTS_ERROR = 'GET_EXPORT_CONTACTS_ERROR';

export const GET_PROFILES = 'GET_PROFILES';
export const SET_PROFILES = 'SET_PROFILES';
export const GET_PROFILES_ERROR = 'GET_PROFILES_ERROR';

export const GET_SMS_SENDERS = 'GET_SMS_SENDERS';
export const SET_SMS_SENDERS = 'SET_SMS_SENDERS';
export const GET_SMS_SENDERS_ERROR = 'GET_SMS_SENDERS_ERROR';

export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_ERROR = 'SEND_SMS_ERROR';

export const SEND_CSV = 'SEND_CSV';
export const SEND_CSV_SUCCESS = 'SEND_CSV_SUCCESS';
export const SEND_CSV_ERROR = 'SEND_CSV_ERROR';

export const SET_UPDATE_CONTACT_ERROR = 'SET_UPDATE_CONTACT_ERROR';
export const GET_WILL_BE_UPDATE_CONTACT = 'GET_WILL_BE_UPDATE_CONTACT';
export const SET_UPDATED_CONTACT = 'SET_UPDATED_CONTACT';

export const GET_CONTACT_SUBSCRIPTIONS = 'GET_CONTACT_SUBSCRIPTIONS';
export const GET_CONTACT_SUBSCRIPTIONS_ERROR = 'GET_CONTACT_SUBSCRIPTIONS_ERROR';
export const SET_CONTACT_SUBSCRIPTIONS = 'SET_CONTACT_SUBSCRIPTIONS';

export const GET_SAVE_CONTACT_SUBSCRIPTIONS = 'GET_SAVE_CONTACT_SUBSCRIPTIONS';
export const SET_SAVE_CONTACT_SUBSCRIPTIONS = 'SET_SAVE_CONTACT_SUBSCRIPTIONS';
export const GET_SAVE_CONTACT_SUBSCRIPTIONS_ERROR = 'GET_SAVE_CONTACT_SUBSCRIPTIONS_ERROR';

export const GET_FORM_ENTRIES = 'GET_FORM_ENTRIES';
export const SET_FORM_ENTRIES = 'SET_FORM_ENTRIES';
export const GET_FORM_ENTRIES_ERROR = 'GET_FORM_ENTRIES_ERROR';
export const GET_STICKY_NOTE = 'GET_STICKY_NOTE';
export const SET_STICKY_NOTE = 'SET_STICKY_NOTE';
export const GET_STICKY_NOTE_ERROR = 'GET_STICKY_NOTE_ERROR';

export const UPDATE_STICKY_NOTE = 'UPDATE_STICKY_NOTE';
export const UPDATE_STICKY_NOTE_ERROR = 'UPDATE_STICKY_NOTE_ERROR';
export const UPDATE_STICKY_NOTE_SUCCESS = 'UPDATE_STICKY_NOTE_SUCCESS';

export const GET_UPDATE_PROFILE_PHOTO = 'GET_UPDATE_PROFILE_PHOTO';
export const SET_UPDATED_PROFILE_PHOTO = 'SET_UPDATED_PROFILE_PHOTO';
export const SET_UPDATE_PROFILE_PHOTO_ERROR = 'SET_UPDATE_PROFILE_PHOTO_ERROR';

export const GET_CONTACT_DETAIL_RELATED_PROPERTIES = 'GET_CONTACT_DETAIL_RELATED_PROPERTIES';
export const GET_CONTACT_DETAIL_RELATED_PROPERTIES_ERROR = 'GET_CONTACT_DETAIL_RELATED_PROPERTIES_ERROR';
export const GET_CONTACT_DETAIL_RELATED_PROPERTIES_SUCCESS = 'GET_CONTACT_DETAIL_RELATED_PROPERTIES_SUCCESS';

export const GET_CONTACT_MAIL_LISTS = 'GET_CONTACT_MAIL_LISTS';
export const SET_CONTACT_MAIL_LISTS = 'SET_CONTACT_MAIL_LISTS';
export const GET_CONTACT_MAIL_LISTS_ERROR = 'GET_CONTACT_MAIL_LISTS_ERROR';

export const GET_CONTACT_BLOG_READS = 'GET_CONTACT_BLOG_READS';
export const SET_CONTACT_BLOG_READS = 'SET_CONTACT_BLOG_READS';
export const GET_CONTACT_BLOG_READS_ERROR = 'GET_CONTACT_BLOG_READS_ERROR';

export const GET_CUSTOM_FIELD_TYPES = 'GET_CUSTOM_FIELD_TYPES';
export const SET_CUSTOM_FIELD_TYPES = 'SET_CUSTOM_FIELD_TYPES';
export const GET_CUSTOM_FIELD_TYPES_ERROR = 'GET_CUSTOM_FIELD_TYPES_ERROR';

export const GET_CUSTOM_FIELD_GROUPS = 'GET_CUSTOM_FIELD_GROUPS';
export const SET_CUSTOM_FIELD_GROUPS = 'SET_CUSTOM_FIELD_GROUPS';
export const GET_CUSTOM_FIELD_GROUPS_ERROR = 'GET_CUSTOM_FIELD_GROUPS_ERROR';

export const CREATE_CUSTOM_FIELD_GROUP = 'CREATE_CUSTOM_FIELD_GROUP';
export const CREATE_CUSTOM_FIELD_GROUP_SUCCESS = 'CREATE_CUSTOM_FIELD_GROUP_SUCCESS';
export const CREATE_CUSTOM_FIELD_GROUP_ERROR = 'CREATE_CUSTOM_FIELD_GROUP_ERROR';

export const DELETE_CUSTOM_FIELD_GROUP = 'DELETE_CUSTOM_FIELD_GROUP';
export const DELETE_CUSTOM_FIELD_GROUP_SUCCESS = 'DELETE_CUSTOM_FIELD_GROUP_SUCCESS';
export const DELETE_CUSTOM_FIELD_GROUP_ERROR = 'DELETE_CUSTOM_FIELD_GROUP_ERROR';

export const CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD';
export const CREATE_CUSTOM_FIELD_SUCCESS = 'CREATE_CUSTOM_FIELD_SUCCESS';
export const CREATE_CUSTOM_FIELD_ERROR = 'CREATE_CUSTOM_FIELD_ERROR';

export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';
export const GET_CUSTOM_FIELDS_ERROR = 'GET_CUSTOM_FIELDS_ERROR';

export const UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD_ERROR = 'UPDATE_CUSTOM_FIELD_ERROR';
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'UPDATE_CUSTOM_FIELD_SUCCESS';


export const INSERT_NEW_TAG_TO_CONTACT = 'INSERT_NEW_TAG_TO_CONTACT';
export const INSERT_NEW_TAG_TO_CONTACT_SUCCESS = 'INSERT_NEW_TAG_TO_CONTACT_SUCCESS';
export const INSERT_NEW_TAG_TO_CONTACT_ERROR = 'INSERT_NEW_TAG_TO_CONTACT_ERROR';

export const GET_TAGS_BY_CONTACT = 'GET_TAGS_BY_CONTACT';
export const GET_TAGS_BY_CONTACT_SUCCESS = 'GET_TAGS_BY_CONTACT_SUCCESS';
export const GET_TAGS_BY_CONTACT_ERROR = 'GET_TAGS_BY_CONTACT_ERROR';

export const DELETE_TAG_FROM_CONTACT = 'DELETE_TAG_FROM_CONTACT';
export const DELETE_TAG_FROM_CONTACT_SUCCESS = 'DELETE_TAG_FROM_CONTACT_SUCCESS';
export const DELETE_TAG_FROM_CONTACT_ERROR = 'DELETE_TAG_FROM_CONTACT_ERROR';

export const INSERT_NEW_TAG_TO_COMPANY_TAGS = 'INSERT_NEW_TAG_TO_COMPANY_TAGS';
export const INSERT_NEW_TAG_TO_COMPANY_TAGS_SUCCESS = 'INSERT_NEW_TAG_TO_COMPANY_TAGS_SUCCESS';
export const INSERT_NEW_TAG_TO_COMPANY_TAGS_ERROR = 'INSERT_NEW_TAG_TO_COMPANY_TAGS_ERROR';

export const GET_ACTIVITIES_CRM_NOTES = 'GET_ACTIVITIES_CRM_NOTES';
export const GET_ACTIVITIES_CRM_NOTES_SUCCESS = 'GET_ACTIVITIES_CRM_NOTES_SUCCESS';
export const GET_ACTIVITIES_CRM_NOTES_ERROR = 'GET_ACTIVITIES_CRM_NOTES_ERROR';

export const SET_CRM_NOTES = 'SET_CRM_NOTES';
export const SET_CRM_NOTES_SUCCESS = 'SET_CRM_NOTES_SUCCESS';
export const SET_CRM_NOTES_ERROR = 'SET_CRM_NOTES_ERROR';
export const SET_LAST_CONTACT_DATA = 'SET_LAST_CONTACT_DATA';

export const GET_EMAIL_SENDERS = 'GET_EMAIL_SENDERS';
export const SET_EMAIL_SENDERS = 'SET_EMAIL_SENDERS';
export const GET_EMAIL_SENDERS_ERROR = 'GET_EMAIL_SENDERS_ERROR';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';

export const GET_BAIT_STATUS_OF_MARKET_APPRAISAL = 'GET_BAIT_STATUS_OF_MARKET_APPRAISAL';
export const GET_BAIT_STATUS_OF_MARKET_APPRAISAL_SUCCESS = 'GET_BAIT_STATUS_OF_MARKET_APPRAISAL_SUCCESS';
export const GET_BAIT_STATUS_OF_MARKET_APPRAISAL_ERROR = 'GET_BAIT_STATUS_OF_MARKET_APPRAISAL_ERROR';

export const GET_GDPR = 'GET_GDPR';
export const SET_GDPR = 'SET_GDPR';
export const GET_GDPR_ERROR = 'GET_GDPR_ERROR';

export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const SET_SELECTED_ACTIVITY_IDS = 'SET_SELECTED_ACTIVITY_IDS';
export const GET_ACTIVITIES_ERROR = 'GET_ACTIVITIES_ERROR';

export const GET_UPLOAD_CONTACT_FILE = 'GET_UPLOAD_CONTACT_FILE';
export const SET_UPLOADED_CONTACT_FILE = 'SET_UPLOADED_CONTACT_FILE';
export const SET_UPLOAD_CONTACT_FILE_ERROR = 'SET_UPLOAD_CONTACT_FILE_ERROR';

export const GET_CONTACT_FILES = 'GET_CONTACT_FILES';
export const SET_CONTACT_FILES = 'SET_CONTACT_FILES';
export const GET_CONTACT_FILES_ERROR = 'GET_CONTACT_FILES_ERROR';

export const GET_DELETE_CONTACT_FILE = 'GET_DELETE_CONTACT_FILE';
export const SET_DELETED_CONTACT_FILE = 'SET_DELETED_CONTACT_FILE';
export const SET_DELETE_CONTACT_FILE_ERROR = 'SET_DELETE_CONTACT_FILE_ERROR';

export const GET_UPDATE_CONTACT_FILE = 'GET_UPDATE_CONTACT_FILE';
export const SET_UPDATED_CONTACT_FILE = 'SET_UPDATED_CONTACT_FILE';
export const SET_UPDATE_CONTACT_FILE_ERROR = 'SET_UPDATE_CONTACT_FILE_ERROR';

export const GET_COMPANY_SUBSCRIPTIONS = 'GET_COMPANY_SUBSCRIPTIONS';
export const GET_COMPANY_SUBSCRIPTIONS_ERROR = 'GET_COMPANY_SUBSCRIPTIONS_ERROR';
export const SET_COMPANY_SUBSCRIPTIONS = 'SET_COMPANY_SUBSCRIPTIONS';

export const GET_MULTIPLE_CONTACTS = 'GET_MULTIPLE_CONTACTS';
export const GET_MULTIPLE_CONTACTS_ERROR = 'GET_MULTIPLE_CONTACTS_ERROR';
export const SET_MULTIPLE_CONTACTS = 'SET_MULTIPLE_CONTACTS';
export const RESET_MULTIPLE_CONTACTS = 'RESET_MULTIPLE_CONTACTS';

export const RESET_CONTACT_TIMELINE_DATA = 'RESET_CONTACT_TIMELINE_DATA';
export const GET_CONTACT_TIMELINE_DATA = 'GET_CONTACT_TIMELINE_DATA';
export const GET_CONTACT_TIMELINE_DATA_ERROR = 'GET_CONTACT_TIMELINE_DATA_ERROR';
export const SET_CONTACT_TIMELINE_DATA = 'SET_CONTACT_TIMELINE_DATA';

export const GET_CONTACT_ACTIVITY_DETAIL = 'GET_CONTACT_ACTIVITY_DETAIL';
export const GET_CONTACT_ACTIVITY_DETAIL_ERROR = 'GET_CONTACT_ACTIVITY_DETAIL_ERROR';
export const SET_CONTACT_ACTIVITY_DETAIL = 'SET_CONTACT_ACTIVITY_DETAIL';

export const GET_CONTACT_LEAD_DATA = 'GET_CONTACT_LEAD_DATA';
export const GET_CONTACT_LEAD_DATA_ERROR = 'GET_CONTACT_LEAD_DATA_ERROR';
export const SET_CONTACT_LEAD_DATA = 'SET_CONTACT_LEAD_DATA';

export const GET_CONTACT_ONLINE_VALUATION_DATA = 'GET_CONTACT_ONLINE_VALUATION_DATA';
export const GET_CONTACT_ONLINE_VALUATION_DATA_ERROR = 'GET_CONTACT_ONLINE_VALUATION_DATA_ERROR';
export const SET_CONTACT_ONLINE_VALUATION_DATA = 'SET_CONTACT_ONLINE_VALUATION_DATA';

export const SET_SELECTED_APPLICANT = 'SET_SELECTED_APPLICANT';
export const GET_SELECTED_APPLICANT = 'GET_SELECTED_APPLICANT';
export const GET_SELECTED_APPLICANT_ERROR = 'GET_SELECTED_APPLICANT_ERROR';


export const SET_APPLICANT_QUESTIONS = 'SET_APPLICANT_QUESTIONS';
export const SET_ACTIVITIES_CONTENT_GROUP = 'SET_ACTIVITIES_CONTENT_GROUP';
