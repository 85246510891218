import Vue from 'vue';
import router from '@/router';
import TotalCountAdapter from '@/libraries/adapters/TotalCountAdapter';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';
// eslint-disable-next-line
import vueApp from '../../../main';

export default {
  [types.GET_BULK_MAILS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'totalBulkMails', 0);
  },
  [types.SET_BULK_MAILS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'bulkMails', data.result.data);
    if (data.result && data.result.meta && data.result.meta.total) {
      Vue.set(state, 'totalBulkMails', data.result.meta.total);
    }
  },
  [types.GET_BULK_MAILS_ERROR](state) {
    Vue.set(state, 'bulkMails', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BULK_MAILS_DETAIL](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BULK_MAILS_DETAIL](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'bulkMailsDetail', data.result.data);
  },
  [types.GET_BULK_MAILS_DETAIL_ERROR](state) {
    Vue.set(state, 'bulkMailsDetail', []);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SENT_BULK_MAILS_DETAIL_LIST](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SENT_BULK_MAILS_DETAIL_LIST](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'sentBulkMailsListData', data.result.data);
    if (data.result.meta) {
      Vue.set(state, 'totalBulkMailsList', data.result.meta.total);
    }
  },
  [types.GET_SENT_BULK_MAILS_DETAIL_LIST_ERROR](state) {
    Vue.set(state, 'sentBulkMailsListData', []);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_RESET_SENT_BULK_MAILS_DETAIL_LIST](state) {
    Vue.set(state, 'sentBulkMailsListData', false);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_DELETE_BULK_MAIL](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteBulkMailSuccess', null);
    Vue.set(state, 'deleteBulkMailError', null);
  },
  [types.SET_DELETE_BULK_MAIL](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteBulkMailSuccess', data.message);
  },
  [types.GET_DELETE_BULK_MAIL_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteBulkMailError', getErrors(error)[0]);
  },

};
