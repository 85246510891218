export default [
  {
    path: '/social_distancing_valuations',
    meta: {
      title: 'Social Distancing Valuations',
      breadcrumb: [
        { name: 'Leads', link: '/leads' },
        { name: 'Social Distancing Valuations' },
      ],
    },
    name: 'OnlineValuationsSocialDistancing',
    component: () => import('@/modules/online_valuations/views/OnlineValuations.vue'),
  },
];
