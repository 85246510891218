export default [{
  name: 'SocialMediaShare',
  path: '/social_media_share',
  meta: {
    title: 'Social Media Share',
    breadcrumb: [
      { name: 'Marketing & Autopilot', link: '/marketing' },
      { name: 'Social Media Share' },
    ],
  },
  component: () => import('../views/SocialMediaShare.vue'),
},
{
  name: 'Create Post',
  path: '/social_media_share/create_post',
  meta: {
    title: 'Create Post',
    breadcrumb: [
      { name: 'Social Media Share', link: '/social_media_share' },
      { name: 'Create Post' },
    ],
  },
  component: () => import('../components/create_post/CreatePost.vue'),
},
];
