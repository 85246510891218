import ApiService from '@/core/api/api.service';


export default class CalendarRecurringEventsServices extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_NODE_URL';
  }

  async createRecurringEvent(payload) {
    this.apiType = 'VUE_APP_NODE_URL';
    this.url = 'events/recurring';
    this.payload = payload;
    const { result } = await this.post();
    return result;
  }

  async fetchRecurringEvents(params) {
    this.apiType = 'VUE_APP_NODE_URL';
    this.loader = false;
    this.url = 'events/recurring';
    this.params = params;
    const { result } = await this.get();
    return result;
  }

  async getInstance(instanceId) {
    this.apiType = 'VUE_APP_NODE_URL';
    this.loader = false;
    this.url = `events/recurring/${instanceId}`;
    const { result } = await this.get();
    return result;
  }

  async deleteRecurringEvent(actionType, instanceId) {
    this.apiType = 'VUE_APP_NODE_URL';
    this.loader = false;
    this.url = `events/recurring/${actionType}/${instanceId}`;
    return this.baseRemitter('delete');
  }

  async updateRecurringEvent(payload, actionType, instanceId) {
    this.apiType = 'VUE_APP_NODE_URL';
    this.loader = false;
    this.payload = payload;
    this.url = `events/recurring/${actionType}/${instanceId}`;
    return this.baseRemitter('patch', 'data');
  }

  async dragRecurringEvent(payload, actionType, instanceId) {
    this.apiType = 'VUE_APP_NODE_URL';
    this.loader = false;
    this.payload = payload;
    this.url = `events/recurring/${actionType}/${instanceId}`;
    const { result } = await this.patch();
    return result;
  }
}
