import ApiService from '@/core/api/api.service';


export default class AutomationServices extends ApiService {
  async fetchAutomation() {
    this.url = 'automations';
    const { result: { data } } = await this.get();
    return data;
  }

  async updateAutomationNotificationRule(payload) {
    this.url = 'automations';
    this.payload = payload;
    return this.patch();
  }
}
