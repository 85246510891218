export const GET_AUDIENCES = 'GET_AUDIENCES';
export const SET_AUDIENCES = 'SET_AUDIENCES';
export const SET_LOAD_MORE_AUDIENCES = 'SET_LOAD_MORE_AUDIENCES';
export const GET_AUDIENCES_ERROR = 'GET_AUDIENCES_ERROR';

export const GET_STATIC_AUDIENCES = 'GET_STATIC_AUDIENCES';
export const SET_STATIC_AUDIENCES = 'SET_STATIC_AUDIENCES';
export const GET_STATIC_AUDIENCES_ERROR = 'GET_STATIC_AUDIENCES_ERROR';

export const SET_ALL_AUDIENCES = 'SET_ALL_AUDIENCES';
export const UNSET_ALL_AUDIENCES = 'UNSET_ALL_AUDIENCES';
export const SET_SELECTED_AUDIENCES = 'SET_SELECTED_AUDIENCES';
export const UNSET_SELECTED_AUDIENCES = 'UNSET_SELECTED_AUDIENCES';
export const CLEAR_SELECTED_AUDIENCES = 'CLEAR_SELECTED_AUDIENCES';

export const GET_DELETE_AUDIENCE = 'GET_DELETE_AUDIENCE';
export const GET_DELETE_AUDIENCE_ERROR = 'GET_DELETE_AUDIENCE_ERROR';
export const SET_DELETE_AUDIENCE_SUCCESS = 'SET_DELETE_AUDIENCE_SUCCESS';

export const GET_ADD_STATIC_AUDIENCE = 'GET_ADD_STATIC_AUDIENCE';
export const GET_ADD_STATIC_AUDIENCE_ERROR = 'GET_ADD_STATIC_AUDIENCE_ERROR';
export const SET_ADD_STATIC_AUDIENCE_SUCCESS = 'SET_ADD_STATIC_AUDIENCE_SUCCESS';

export const GET_CLONE_AUDIENCE = 'GET_CLONE_AUDIENCE';
export const GET_CLONE_AUDIENCE_ERROR = 'GET_CLONE_AUDIENCE_ERROR';
export const SET_CLONE_AUDIENCE_SUCCESS = 'SET_CLONE_AUDIENCE_SUCCESS';

export const GET_AUDIENCE_DETAILS = 'GET_AUDIENCE_DETAILS';
export const GET_AUDIENCE_DETAILS_ERROR = 'GET_AUDIENCE_DETAILS_ERROR';
export const SET_AUDIENCE_DETAILS = 'SET_AUDIENCE_DETAILS';

export const GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS = 'GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS';
export const GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_ERROR = 'GET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_ERROR';
export const SET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_SUCCESS = 'SET_ADD_CONTACTS_TO_AUDIENCE_WITH_EMAILS_SUCCESS';

export const CREATE_AUDIENCE = 'CREATE_AUDIENCE';
export const CREATE_AUDIENCE_SUCCESS = 'CREATE_AUDIENCE_SUCCESS';
export const CREATE_AUDIENCE_ERROR = 'CREATE_AUDIENCE_ERROR';

export const GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA = 'GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA';
export const GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA_ERROR = 'GET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA_ERROR';
export const SET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA = 'SET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA';
export const RESET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA = 'RESET_DYNAMIC_AUDIENCE_BUILDER_SCHEMA';
export const UPDATE_AVAILABLE_FORMS = 'UPDATE_AVAILABLE_FORMS';
export const SET_BUILDER_DATA = 'SET_BUILDER_DATA';

export const SEND_BULK_EMAIL = 'SEND_BULK_EMAIL';
export const SEND_BULK_EMAIL_SUCCESS = 'SEND_BULK_EMAIL_SUCCESS';
export const SEND_BULK_EMAIL_ERROR = 'SEND_BULK_EMAIL_ERROR';

export const GET_DYNAMIC_AUDIENCE_SEARCH = 'GET_DYNAMIC_AUDIENCE_SEARCH';
export const SET_DYNAMIC_AUDIENCE_SEARCH = 'SET_DYNAMIC_AUDIENCE_SEARCH';
export const GET_DYNAMIC_AUDIENCE_SEARCH_ERROR = 'GET_DYNAMIC_AUDIENCE_SEARCH_ERROR';
