import * as types from './mutation_types';


export const setAction = ({ commit }, data) => {
  commit(types.SET_ACTION, data);
};

export const setLimit = ({ commit }, data) => {
  commit(types.SET_LIMIT, data);
};

export const setSearch = ({ commit }, data) => {
  commit(types.SET_SEARCH, data);
};

export const setSearchType = ({ commit }, data) => {
  commit(types.SET_SEARCH_TYPE, data);
};

export const setSort = ({ commit }, data) => {
  commit(types.SET_SORT, data);
};

export const setStaticFilter = ({ commit }, data) => {
  commit(types.SET_STATIC_FILTER, data);
};

export const setDynamicFilter = ({ commit }, data) => {
  commit(types.SET_DYNAMIC_FILTER, data);
};


export const setTotal = ({ commit }, data) => {
  commit(types.SET_TOTAL, data);
};

export const setPerPage = ({ commit }, data) => {
  commit(types.SET_PER_PAGE, data);
};

export const setTotalPages = ({ commit }, data) => {
  commit(types.SET_TOTAL_PAGES, data);
};

export const setActivePage = ({ commit }, data) => {
  commit(types.SET_ACTIVE_PAGE, data);
};

export const setSortSequence = ({ commit }, data) => {
  commit(types.SET_SORT_SEQUENCE, data);
};

export const setSortField = ({ commit }, data) => {
  commit(types.SET_SORT_FIELD, data);
};

export const setSortTitle = ({ commit }, data) => {
  commit(types.SET_SORT_TITLE, data);
};

export const setCentralLoader = ({ commit }, status) => {
  commit(types.SET_LOADER, status);
};

export const manageRequest = ({ commit }, data) => {
  commit(types.MANAGE_REQUEST, data);
};
