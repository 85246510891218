import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_FUNNEL_STATS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_FUNNEL_STATS](state, data) {
    Vue.set(state, 'isLoading', false);
    if (data.contactProfile.profile === 'v') {
      Vue.set(state, 'vendorFunnelStats', data.result.data);
    } else if (data.contactProfile.profile === 'l') {
      Vue.set(state, 'landlordFunnelStats', data.result.data);
    }
  },
  [types.GET_FUNNEL_STATS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_FUNNEL_STATS_CONTACTS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'funnelStatsContactsTotal', 0);
  },
  [types.SET_FUNNEL_STATS_CONTACTS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'funnelStatsContacts', data.result.data);
    if (data && data.result && data.result.data && data.result.meta) {
      Vue.set(state, 'funnelStatsContactsTotal', data.result.meta.total);
    } else if (data && data.result && data.result.data && data.result.data[0]) {
      Vue.set(state, 'funnelStatsContactsTotal', data.result.data[0].total_count);
    }
  },
  [types.GET_FUNNEL_STATS_CONTACTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'funnelStatsContacts', []);
    Vue.set(state, 'funnelStatsContactsTotal', 0);
  },

  [types.GET_CONTENT_STATS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CONTENT_STATS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contentStats', data.result.data);
  },
  [types.GET_CONTENT_STATS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'contentStats', null);
  },

  [types.GET_TEAM_STATS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'teamStats', null);
  },
  [types.SET_TEAM_STATS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'teamStats', data.result.data);
  },
  [types.GET_TEAM_STATS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'teamStats', null);
  },

  [types.GET_PROPERTY_STATS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'propertyStats', null);
  },
  [types.SET_PROPERTY_STATS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyStats', data.result.data);
  },
  [types.GET_PROPERTY_STATS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'propertyStats', null);
  },

  [types.GET_AUTOPILOT_STATS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_AUTOPILOT_STATS](state, data) {
    const { parts } = data;
    Vue.set(state, 'isLoading', false);
    parts.forEach((item) => {
      Vue.set(state.autopilotStats, `${item}`, data.result.data[`${item}`]);
    });
  },
  [types.GET_AUTOPILOT_STATS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },
};
