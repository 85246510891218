// eslint-disable-next-line import/no-cycle
import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestBulkMails = ({ commit }) => commit(types.GET_BULK_MAILS);
export const receiveBulkMailsSuccess = ({ commit }, data) => commit(types.SET_BULK_MAILS, data);
export const receiveBulkMailsError = ({ commit }, error) => commit(types.GET_BULK_MAILS_ERROR, error);
export const fetchBulkMails = ({ dispatch }, payload) => {
  dispatch('requestBulkMails');
  Api.get(`bulk_mails${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkMailsSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkMailsError', response);
    });
};


export const requestBulkMailsDetail = ({ commit }) => commit(types.GET_BULK_MAILS_DETAIL);
export const receiveBulkMailsDetailSuccess = ({ commit }, data) => commit(types.SET_BULK_MAILS_DETAIL, data);
export const receiveBulkMailsDetailError = ({ commit }, error) => commit(types.GET_BULK_MAILS_DETAIL_ERROR, error);
export const fetchBulkMailsDetail = ({ dispatch }, params) => {
  dispatch('requestBulkMailsDetail');
  Api.get(`search_engine/stats/mails/bulk-info/${params.bulk_mail_id}`)
    .then(({ data }) => {
      dispatch('receiveBulkMailsDetailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkMailsDetailError', response);
    });
};

export const requestResetSentBulkMailsDetailList = ({ commit }) => commit(types.GET_RESET_SENT_BULK_MAILS_DETAIL_LIST);
export const requestBulkMailsSentDetailList = ({ commit }) => commit(types.GET_SENT_BULK_MAILS_DETAIL_LIST);
export const receiveBulkMailsSentDetailListSuccess = ({ commit }, data) => commit(types.SET_SENT_BULK_MAILS_DETAIL_LIST, data);
export const receiveBulkMailsSentDetailListError = ({ commit }, error) => commit(types.GET_SENT_BULK_MAILS_DETAIL_LIST_ERROR, error);


export const fetchBulkMailsSent = ({ dispatch }, payload) => {
  dispatch('requestBulkMailsSentDetailList');
  Api.get(`/search_engine/stats/mails/bulk/${payload.params.bulk_mail_id}/email_send${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkMailsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkMailsSentDetailListError', response);
    });
};


export const fetchBulkMailsSentClick = ({ dispatch }, payload) => {
  dispatch('requestBulkMailsSentDetailList');
  Api.get(`/search_engine/stats/mails/bulk/${payload.params.bulk_mail_id}/email_click${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkMailsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkMailsSentDetailListError', response);
    });
};

export const fetchBulkMailsSentOpen = ({ dispatch }, payload) => {
  dispatch('requestBulkMailsSentDetailList');
  Api.get(`/search_engine/stats/mails/bulk/${payload.params.bulk_mail_id}/email_open${payload.request}`)
    .then(({ data }) => {
      dispatch('receiveBulkMailsSentDetailListSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveBulkMailsSentDetailListError', response);
    });
};

export const requestDeleteBulkMail = ({ commit }) => commit(types.GET_DELETE_BULK_MAIL);
export const receiveDeleteBulkMailSuccess = ({ commit }, data) => commit(types.SET_DELETE_BULK_MAIL, data);
export const receiveDeleteBulkMailError = ({ commit }, error) => commit(types.GET_DELETE_BULK_MAIL_ERROR, error);
export const fetchDeleteBulkMail = ({ dispatch }, payload) => {
  dispatch('requestDeleteBulkMail');
  Api.delete(`bulk_mails/${payload.bulk_mail_id}`)
    .then(({ data }) => {
      dispatch('receiveDeleteBulkMailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveDeleteBulkMailError', response);
    });
};
