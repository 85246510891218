export default [
  {
    path: '/sms',
    meta: {
      title: 'SMS',
      breadcrumb: [
        { name: 'Marketing & Autopilot', link: '/marketing' },
        { name: 'SMS' },
      ],
    },
    name: 'SMS',
    component: () => import('@/modules/sms/views/SMS.vue'),
  },
];
