import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.GET_SHARED_POSTS_IN_SOCIAL_MEDIA](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'sharedPosts', false);
  },
  [types.SET_SHARED_POSTS_IN_SOCIAL_MEDIA](state, data) {
    Vue.set(state, 'totalSharedPosts', data.result.data[0].total_count);
    Vue.set(state, 'sharedPosts', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SHARED_POSTS_IN_SOCIAL_MEDIA_ERROR](state) {
    Vue.set(state, 'sharedPosts', []);
    Vue.set(state, 'isLoading', false);
  },

};
