export default {
  error: null,
  isLoading: false,
  events: null,
  event: null,
  eventTypes: null,
  storeCalendarEvents: null,
  calendarEvent: null,
  calendarEventsByStatus: null,
  eventStatuses: null,
  user_settings: null,
  calendarCollapses: {},
  selectedUsers: [],
  selectedTypes: [],
  selectedTransactionTypes: [],
  selectedViewingTypes: [],
  selectedConfirmationTypes: [],
  selectedDialogUsers: [],
  selectedDialogTypes: [],
  selectedDialogTransactionTypes: [],
  selectedDialogViewingTypes: [],
  selectedDialogConfirmationTypes: [],
  skeletons: [],
  fetchedRange: { start: null, end: null },
  fetchedRecurring: { start: null, end: null },
  filteredCalendarEvents: null,
  selectedDatepicker: new Date(),
  calendarEventProcessed: false,
  calendarEventProcessedSuccess: false,
  calendarEventProcessedError: false,
  selectedDateToDayView: null,
  updateCalendarEventProcessed: false,
  updateCalendarEventSuccess: null,
  updateCalendarEventError: null,
  dropResizeCalendarEvent: null,
  searchCalendarEvents: null,
  calendarEventStatusUpdateSuccess: null,
  calendarEventStatusUpdateError: null,
  followUpCount: null,
  maNotYetSentCount: null,
  show: {
    searchApplicant: false,
    searchProperty: false,
    searchProfiles: false,
    viewing: true,
    selectedApplicant: false,
    selectedProperty: false,
    isSearch: false,
  },
  selectedApplicant: {
    contact: null,
    applicant: null,
  },
  searchApplicantKeyword: null,
  applicantList: null,
  applicantListMeta: {
    after_key: [],
    total: null,
  },
  searchProfiles: null,
  selectedProperty: {
    property: null,
  },
  viewedContact: {},
  viewingData: {},
  tenant_contact: [],
  calendarModal: {
    show: false,
    selector: null,
    data: null,
  },
  cancelledEvents: [],
};
