import axios from 'axios';
import logoutService from '@/modules/users/services/logout.service';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const interceptor = axiosInstance => (error) => {
  const _axios = axiosInstance;
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }))
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return _axios.request(originalRequest);
        })
        .catch(err => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(async (resolve, reject) => {
      try {
        const payload = {
          grant_type: 'refresh_token',
          // eslint-disable-next-line radix
          client_id: parseInt(process.env.VUE_APP_CLIENT_ID),
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          refresh_token: localStorage.getItem('refresh_token'),
        };
        const response = await axios.post(process.env.VUE_APP_API_AUTH_REFRESH_TOKEN_URL, payload);
        _axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, `Bearer ${response.data.access_token}`);
        processQueue(null, response.data.access_token);
        resolve(_axios(originalRequest));
      } catch (err) {
        if (err && error.response && error.response.status === 401) {
          logoutService();
        }
        processQueue(err, null);
        reject(err);
      } finally {
        isRefreshing = false;
      }
    });
  }

  return Promise.reject(error);
};

export default interceptor;
