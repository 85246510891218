import ApiService from '@/core/api/api.service';

export default class CreditsServices extends ApiService {
  async getCreditDetails() {
    this.url = 'credit_management';
    const { result } = await this.get();
    return result;
  }

  async postAmlCredits(payload, type) {
    this.url = `credit_management/request/${type}`;
    this.payload = payload;
    return this.baseRemitter('post', 'data');
  }

  async getWidgetCreditDetails() {
    this.url = 'credit_management/widget';
    const { result } = await this.get();
    return result;
  }
}
