// Extensions

// Types

export default class Breakpoint {
  static property = 'breakpoint'

  // Public
  xs = false

  sm = false

  md = false

  lg = false

  xl = false

  xxsOnly = false;

  xsOnly = false

  smOnly = false

  smAndDown = false

  smAndUp = false

  mdOnly = false

  mdAndDown = false

  mdAndUp = false

  lgOnly = false

  lgAndDown = false

  lgAndUp = false

  xlOnly = false

  // Value is xs to match v2.x functionality
  name = 'xs'

  height = 0

  width = 0

  // Value is true to match v2.x functionality
  mobile = true

  mobileBreakpoint

  thresholds

  scrollBarWidth

  resizeTimeout = 0

  constructor(preset) {
    const {
      mobileBreakpoint,
      scrollBarWidth,
      thresholds,
    } = preset;

    this.mobileBreakpoint = mobileBreakpoint;
    this.scrollBarWidth = scrollBarWidth;
    this.thresholds = thresholds;
  }

  init() {
    this.update();

    window.addEventListener(
      'resize',
      this.onResize.bind(this),
      { passive: true },
    );
  }

  /* eslint-disable-next-line max-statements */
  update(ssr = false) {
    const height = ssr ? 0 : this.getClientHeight();
    const width = ssr ? 0 : this.getClientWidth();

    const xxs = width < this.thresholds.xxs;
    const xs = width < this.thresholds.xs;
    const sm = width < this.thresholds.sm && !xs;
    const md = width < (this.thresholds.md - this.scrollBarWidth) && !(sm || xs);
    const lg = width < (this.thresholds.lg - this.scrollBarWidth) && !(md || sm || xs);
    const xl = width >= (this.thresholds.lg - this.scrollBarWidth);

    this.height = height;
    this.width = width;

    this.xxs = xxs;
    this.xs = xs;
    this.sm = sm;
    this.md = md;
    this.lg = lg;
    this.xl = xl;

    this.xxsOnly = xxs;
    this.xsOnly = xs;
    this.smOnly = sm;
    this.smAndDown = (xs || sm) && !(md || lg || xl);
    this.smAndUp = !xs && (sm || md || lg || xl);
    this.mdOnly = md;
    this.mdAndDown = (xs || sm || md) && !(lg || xl);
    this.mdAndUp = !(xs || sm) && (md || lg || xl);
    this.lgOnly = lg;
    this.lgAndDown = (xs || sm || md || lg) && !xl;
    this.lgAndUp = !(xs || sm || md) && (lg || xl);
    this.xlOnly = xl;

    switch (true) {
      case (xs):
        this.name = 'xs';
        break;
      case (sm):
        this.name = 'sm';
        break;
      case (md):
        this.name = 'md';
        break;
      case (lg):
        this.name = 'lg';
        break;
      default:
        this.name = 'xl';
        break;
    }

    if (typeof this.mobileBreakpoint === 'number') {
      this.mobile = width < parseInt(this.mobileBreakpoint, 10);

      return;
    }

    const breakpoints = {
      xs: 0,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 4,
    };

    const current = breakpoints[this.name];
    const max = breakpoints[this.mobileBreakpoint];

    this.mobile = current <= max;
  }

  onResize() {
    clearTimeout(this.resizeTimeout);

    this.resizeTimeout = window.setTimeout(this.update.bind(this), 200);
  }

  // Cross-browser support as described in:
  // https://stackoverflow.com/questions/1248081
  // eslint-disable-next-line class-methods-use-this
  getClientWidth() {
    /* istanbul ignore if */
    if (typeof document === 'undefined') return 0; // SSR
    return Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getClientHeight() {
    /* istanbul ignore if */
    if (typeof document === 'undefined') return 0; // SSR
    return Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    );
  }
}
