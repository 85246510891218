import Api from '@/libraries/api';
import TagsServices from '@/modules/tags/services/tags.services';
import * as types from './mutation_types';


export const requestTags = ({ commit }) => commit(types.GET_TAGS);
export const receiveTagsSuccess = ({ commit }, data) => commit(types.SET_TAGS, data);
export const receiveTagsError = ({ commit }, error) => commit(types.GET_TAGS_ERROR, error);
export const fetchTags = async ({ dispatch }, companyId) => {
  dispatch('requestTags');
  try {
    const data = await new TagsServices().fetch(companyId);
    dispatch('receiveTagsSuccess', data);
  } catch (e) {
    dispatch('receiveTagsError');
  }
};

export const requestCreateTag = ({ commit }) => commit(types.GET_CREATE_TAG);
export const receiveCreateTagSuccess = ({ commit }, data) => commit(types.SET_CREATE_TAG_SUCCESS, data);
export const receiveCreateTagError = ({ commit }, error) => commit(types.GET_CREATE_TAG_ERROR, error);
export const createTag = ({ dispatch }, payload) => {
  // Deprecated - USE METHOD THAT INSIDE TAGS SERVICE.
  dispatch('requestCreateTag');
  Api.post('tags', payload)
    .then(({ data }) => {
      dispatch('receiveCreateTagSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveCreateTagError', response);
    });
};

export const requestAutocompleteTag = ({ commit }) => commit(types.GET_AUTOCOMPLETE_TAG);
export const receiveAutocompleteTagSuccess = ({ commit }, data) => commit(types.SET_AUTOCOMPLETE_SUCCESS, data);
export const receiveAutocompleteTagError = ({ commit }, error) => commit(types.GET_AUTOCOMPLETE_ERROR, error);
export const searchAutocompleteTag = ({ dispatch }, payload) => {
  dispatch('requestAutocompleteTag');
  Api.get(`/tags/search/autocomplete?tag=${payload.text}`)
    .then(({ data }) => {
      dispatch('receiveAutocompleteTagSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAutocompleteTagError', response);
    });
};
