export const GET_PROPERTY_PROFILE_PRICES = 'GET_PROPERTY_PROFILE_PRICES';
export const SET_PROPERTY_PROFILE_PRICES = 'SET_PROPERTY_PROFILE_PRICES';
export const GET_PROPERTY_PROFILE_PRICES_ERROR = 'GET_PROPERTY_PROFILE_PRICES_ERROR';

export const CREATE_PROPERTY_PROFILE_PRICE = 'CREATE_PROPERTY_PROFILE_PRICE';
export const CREATE_PROPERTY_PROFILE_PRICE_SUCCESS = 'CREATE_PROPERTY_PROFILE_PRICE_SUCCESS';
export const CREATE_PROPERTY_PROFILE_PRICE_ERROR = 'CREATE_PROPERTY_PROFILE_PRICE_ERROR';

export const UPDATE_PROPERTY_PROFILE_PRICE = 'UPDATE_PROPERTY_PROFILE_PRICE';
export const UPDATE_PROPERTY_PROFILE_PRICE_SUCCESS = 'UPDATE_PROPERTY_PROFILE_PRICE_SUCCESS';
export const UPDATE_PROPERTY_PROFILE_PRICE_ERROR = 'UPDATE_PROPERTY_PROFILE_PRICE_ERROR';
