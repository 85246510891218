import ApiService from '@/core/api/api.service';

export class ConfigsService extends ApiService {
  setApiType() {
    this.apiType = 'VUE_APP_SEARCH_ENGINE_API';
  }

  async getMeConfig() {
    this.loader = false;
    this.url = '/configs/me';
    const { result: { data } } = await this.get();
    return data;
  }
}
