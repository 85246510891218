export default {
  isLoading: false,
  totalAudiences: null,
  audiences: null,
  selectedAudiences: [],
  deleteAudienceSuccess: null,
  deleteAudienceError: null,
  addStaticAudienceSuccess: null,
  addStaticAudienceError: null,
  cloneAudienceSuccess: null,
  cloneAudienceError: null,
  audienceDetails: null,
  audienceDetailsErrors: null,
  addContactsToAudienceWithEmailsSuccess: null,
  addContactsToAudienceWithEmailsError: null,
  staticAudiences: null,
  createAudienceSuccess: null,
  createAudienceError: null,
  dynamicAudienceBuilderSchema: null,
  availableForms: [],
  sendBulkEmailSuccess: null,
  sendBulkEmailError: null,
  audienceSearchResult: [],
  audienceSearchProcess: false,
  totalAudienceSearchResult: 0,
  builderMemoryData: false,

  schemaBase: null,
};
