export const socialMedia = state => state.socialMedia;
export const socialMediaError = state => state.socialMediaError;

export const socialMediaAccounts = state => state.socialMediaAccounts;
export const socialMediaAccountsError = state => state.socialMediaAccountsError;

export const socialMediaAccount = state => state.socialMediaAccount;
export const socialMediaAccountError = state => state.socialMediaAccountError;

export const socialMediaAccountPages = state => state.socialMediaAccountPages;
export const socialMediaAccountPagesError = state => state.socialMediaAccountPagesError;

export const socialMediaAccountPage = state => state.socialMediaAccountPage;
export const socialMediaAccountPageError = state => state.socialMediaAccountPageError;
export const updateRuleProcessed = state => state.updateRuleProcessed;
export const updateRuleProcessedSuccess = state => state.updateRuleProcessedSuccess;
export const updateRuleProcessedError = state => state.updateRuleProcessedError;

export const createRuleProcessed = state => state.createRuleProcessed;
export const createRuleProcessedSuccess = state => state.createRuleProcessedSuccess;
export const createRuleProcessedError = state => state.createRuleProcessedError;

export const deleteRuleProcessed = state => state.deleteRuleProcessed;
export const deleteRuleProcessedSuccess = state => state.deleteRuleProcessedSuccess;
export const deleteRuleProcessedError = state => state.deleteRuleProcessedError;

export const updateWebPushProcessed = state => state.updateWebPushProcessed;
export const updateWebPushProcessedSuccess = state => state.updateWebPushProcessedSuccess;
export const updateWebPushProcessedError = state => state.updateWebPushProcessedError;

export const blogSettings = state => state.blogSettings;
export const blogSettingsError = state => state.blogSettingsError;
export const updateBlogSettings = state => state.updateBlogSettings;
export const updateBlogSettingsError = state => state.updateBlogSettingsError;
export const isLoading = state => state.isLoading;
export const blogMenus = state => state.blogMenus;
export const blogMenusError = state => state.blogMenusError;
export const blogMenu = state => state.blogMenu;
export const blogMenuError = state => state.blogMenuError;
export const createBlogMenu = state => state.createBlogMenu;
export const createBlogMenuError = state => state.createBlogMenuError;
export const deleteBlogMenu = state => state.deleteBlogMenu;
export const deleteBlogMenuError = state => state.deleteBlogMenuError;
export const updateBlogMenu = state => state.updateBlogMenu;
export const updateBlogMenuError = state => state.updateBlogMenuError;
export const sortBlogMenu = state => state.sortBlogMenu;
export const sortBlogMenuError = state => state.sortBlogMenuError;

export const createSocialMediaProcessed = state => state.createSocialMediaProcessed;
export const createSocialMediaProcessedSuccess = state => state.createSocialMediaProcessedSuccess;
export const createSocialMediaProcessedError = state => state.createSocialMediaProcessedError;

export const createSocialMediaAccountPagesProcessed = state => state.createSocialMediaAccountPagesProcessed;
export const createSocialMediaAccountPagesProcessedSuccess = state => state.createSocialMediaAccountPagesProcessedSuccess;
export const createSocialMediaAccountPagesProcessedError = state => state.createSocialMediaAccountPagesProcessedError;

export const createSocialMediaPageProcessed = state => state.createSocialMediaPageProcessed;
export const createSocialMediaPageProcessedSuccess = state => state.createSocialMediaPageProcessedSuccess;
export const createSocialMediaPageProcessedError = state => state.createSocialMediaPageProcessedError;

export const deleteSocialMediaPageProcessed = state => state.deleteSocialMediaPageProcessed;
export const deleteSocialMediaPageProcessedSuccess = state => state.deleteSocialMediaPageProcessedSuccess;
export const deleteSocialMediaPageProcessedError = state => state.deleteSocialMediaPageProcessedError;

export const deleteSocialMediaAccountProcessed = state => state.deleteSocialMediaAccountProcessed;
export const deleteSocialMediaAccountProcessedSuccess = state => state.deleteSocialMediaAccountProcessedSuccess;
export const deleteSocialMediaAccountProcessedError = state => state.deleteSocialMediaAccountProcessedError;

export const updateSocialMediaAccountPagesProcessed = state => state.updateSocialMediaAccountPagesProcessed;
export const updateSocialMediaAccountPagesProcessedSuccess = state => state.updateSocialMediaAccountPagesProcessedSuccess;
export const updateSocialMediaAccountPagesProcessedError = state => state.updateSocialMediaAccountPagesProcessedError;

export const updateSocialMediaProcessed = state => state.updateSocialMediaProcessed;
export const updateSocialMediaProcessedSuccess = state => state.updateSocialMediaProcessedSuccess;
export const updateSocialMediaProcessedError = state => state.updateSocialMediaProcessedError;

export const createNewEmailSenderProcessed = state => state.createNewEmailSenderProcessed;
export const createNewEmailSenderProcessedSuccess = state => state.createNewEmailSenderProcessedSuccess;
export const createNewEmailSenderProcessedError = state => state.createNewEmailSenderProcessedError;

export const updateEmailSenderProcessed = state => state.updateEmailSenderProcessed;
export const updateEmailSenderProcessedSuccess = state => state.updateEmailSenderProcessedSuccess;
export const updateEmailSenderProcessedError = state => state.updateEmailSenderProcessedError;

export const deleteEmailSenderProcessed = state => state.deleteEmailSenderProcessed;
export const deleteEmailSenderProcessedSuccess = state => state.deleteEmailSenderProcessedSuccess;
export const deleteEmailSenderProcessedError = state => state.deleteEmailSenderProcessedError;

export const createNewSmsSenderProcessed = state => state.createNewSmsSenderProcessed;
export const createNewSmsSenderProcessedSuccess = state => state.createNewSmsSenderProcessedSuccess;
export const createNewSmsSenderProcessedError = state => state.createNewSmsSenderProcessedError;

export const updateSmsSenderProcessed = state => state.updateSmsSenderProcessed;
export const updateSmsSenderProcessedSuccess = state => state.updateSmsSenderProcessedSuccess;
export const updateSmsSenderProcessedError = state => state.updateSmsSenderProcessedError;

export const deleteSmsSenderProcessed = state => state.deleteSmsSenderProcessed;
export const deleteSmsSenderProcessedSuccess = state => state.deleteSmsSenderProcessedSuccess;
export const deleteSmsSenderProcessedError = state => state.deleteSmsSenderProcessedError;

export const updateCompaniesLogoProcessed = state => state.updateCompaniesLogoProcessed;
export const updateCompaniesLogoProcessedSuccess = state => state.updateCompaniesLogoProcessedSuccess;
export const updateCompaniesLogoProcessedError = state => state.updateCompaniesLogoProcessedError;

export const updateBaitSettingsProcessed = state => state.updateBaitSettingsProcessed;
export const updateBaitSettingsProcessedSuccess = state => state.updateBaitSettingsProcessedSuccess;
export const updateBaitSettingsProcessedError = state => state.updateBaitSettingsProcessedError;

export const updateBaitMarketingPlanOverviewProcessed = state => state.updateBaitMarketingPlanOverviewProcessed;
export const updateBaitMarketingPlanOverviewProcessedSuccess = state => state.updateBaitMarketingPlanOverviewProcessedSuccess;
export const updateBaitMarketingPlanOverviewProcessedError = state => state.updateBaitMarketingPlanOverviewProcessedError;

export const provideSettings = state => state.providerSettings;
