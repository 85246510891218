import ApiService from '@/core/api/api.service';

export default class QuestionsServices extends ApiService {
  constructor(props) {
    super(props);
    this.url = 'contact_questions/';
  }

  async list(profile) {
    this.loader = false;
    this.url = `${this.url}${profile}`;
    const { result } = await this.get();
    return result;
  }
}
