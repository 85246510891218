import ApiService from '@/core/api/api.service';

export default class SupplierMembersServices extends ApiService {
  getMembersList({ payload, query }) {
    this.loader = true;
    this.url = `/sp/sectors/${payload.categoryId}/clients/${payload.companyId}/members?${query}`;
    return this.get();
  }

  getMemberDetail({ payload }) {
    this.loader = true;
    this.url = `/sp/sectors/${payload.categoryId}/clients/${payload.companyId}/members/${payload.memberId}`;
    return this.get();
  }

  addMember({ payload, query }) {
    this.loader = true;
    this.payload = query;
    this.url = `/sp/sectors/${payload.categoryId}/clients/${payload.companyId}/members`;
    return this.post();
  }

  updateMember({ payload, query }) {
    this.loader = true;
    this.payload = query;
    this.url = `/sp/sectors/${payload.categoryId}/clients/${payload.companyId}/members/${payload.memberId}`;
    return this.patch();
  }

  getCustomFields({ payload }) {
    this.url = `/sp/sectors/${payload.categoryId}/custom_fields`;
    return this.get();
  }

  getRelatedProperties({ payload, query, loader = true }) {
    this.loader = loader;
    this.url = `/sp/sectors/${payload.categoryId}/clients/${payload.companyId}/members/${payload.memberId}?${query}`;
    return this.get();
  }

  deleteMember({ payload, query }) {
    this.payload = query;
    this.url = `/sp/sectors/${payload.categoryId}/clients/${payload.companyId}/members/${payload.memberId}`;
    return this.delete();
  }
}
