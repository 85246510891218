export const GET_MAIL_LISTS = 'GET_MAIL_LISTS';
export const SET_MAIL_LISTS = 'SET_MAIL_LISTS';
export const GET_MAIL_LISTS_ERROR = 'GET_MAIL_LISTS_ERROR';

export const GET_UPDATE_DRIPS = 'GET_UPDATE_DRIPS';
export const SET_UPDATE_DRIPS = 'SET_UPDATE_DRIPS';
export const GET_UPDATE_DRIPS_ERROR = 'GET_UPDATE_DRIPS_ERROR';

export const GET_DRIP = 'GET_DRIP';
export const SET_DRIP = 'SET_DRIP';
export const GET_DRIP_ERROR = 'GET_DRIP_ERROR';

export const GET_SEND_SUPPORT_MAIL = 'GET_SEND_SUPPORT_MAIL';
export const SET_SEND_SUPPORT_MAIL = 'SET_SEND_SUPPORT_MAIL';
export const GET_SEND_SUPPORT_MAIL_ERROR = 'GET_SEND_SUPPORT_MAIL_ERROR';

export const GET_UPDATE_DRIP = 'GET_UPDATE_DRIP';
export const SET_UPDATE_DRIP = 'SET_UPDATE_DRIP';
export const GET_UPDATE_DRIP_ERROR = 'GET_UPDATE_DRIP_ERROR';
