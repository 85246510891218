import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.SET_TASK_SUBCATEGORIES](state, data) {
    Vue.set(state, 'taskSubcategories', data.data);
  },

  [types.RESET_TASK_SUBCATEGORIES](state) {
    Vue.set(state, 'taskSubcategories', []);
  },
};
