export default [
  {
    path: '*',
    meta: {
      title: 'Page not found',
    },
    name: 'NotFound',
    component: () => import('@/components/information/NotFound.vue'),
  },
];
