import Vue from 'vue';
import * as types from './mutation_types';

export default {
  [types.SET_USER](state, data) {
    Vue.set(state, 'user', data);
    Vue.set(state, 'isLogged', true);
  },
  [types.GET_USER](state) {
    Vue.set(state, 'user', null);
  },


  [types.SET_USERS](state, data) {
    Vue.set(state, 'users', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_USERS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_USERS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },
  [types.SET_BRANCH_USERS](state, data) {
    Vue.set(state, 'branchUsers', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BRANCH_USERS](state) {
    Vue.set(state, 'branchUsers', []);
    Vue.set(state, 'isLoading', true);
  },
  [types.GET_BRANCH_USERS_ERROR](state, message) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'error', {
      message,
      type: 'is-danger',
      duration: 10000,
    });
  },
  SET_USER_NOTIFY_TYPES(state, data) {
    state.userNotifyTypes = data;
  },
};
