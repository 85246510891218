import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestMailLists = ({ commit }) => commit(types.GET_MAIL_LISTS);
export const receiveMailListsSuccess = ({ commit }, data) => commit(types.SET_MAIL_LISTS, data);
export const receiveMailListsError = ({ commit }, error) => commit(types.GET_MAIL_LISTS_ERROR, error);
export const fetchMailLists = ({ dispatch }) => {
  dispatch('requestMailLists');
  Api.get('mail_lists')
    .then(({ data }) => { dispatch('receiveMailListsSuccess', data); })
    .catch(({ response }) => { dispatch('receiveMailListsError', response); });
};

export const requestSmsInfo = ({ commit }) => commit(types.GET_SMS_INFO);
export const receiveSmsInfoSuccess = ({ commit }, data) => commit(types.SET_SMS_INFO, data);
export const receiveSmsInfoError = ({ commit }, error) => commit(types.GET_SMS_INFO_ERROR, error);
export const fetchSmsInfo = ({ dispatch }) => {
  dispatch('requestSmsInfo');
  Api.get('sms_credit_logs/total_credit')
    .then(({ data }) => { dispatch('receiveSmsInfoSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSmsInfoError', response); });
};

export const requestSendBulkSMS = ({ commit }) => commit(types.GET_SEND_BULK_SMS);
export const receiveSendBulkSMSSuccess = ({ commit }, data) => commit(types.SET_SEND_BULK_SMS_SUCCESS, data);
export const receiveSendBulkSMSError = ({ commit }, error) => commit(types.GET_SEND_BULK_SMS_ERROR, error);
export const sendBulkSMS = ({ dispatch }, payload) => {
  dispatch('requestSendBulkSMS');
  Api.post('bulk_sms', payload)
    .then(({ data }) => { dispatch('receiveSendBulkSMSSuccess', data); })
    .catch(({ response }) => { dispatch('receiveSendBulkSMSError', response); });
};

export const setSingle = ({ commit }, data) => {
  commit(types.SET_SELECTED_AUDIENCES, data);
};

export const unsetSingle = ({ commit }, data) => {
  commit(types.UNSET_SELECTED_AUDIENCES, data);
};

export const requestAcceptSmsCredit = ({ commit }) => commit(types.GET_ACCEPT_SMS_CREDIT);
export const receiveAcceptSmsCreditSuccess = ({ commit }, data) => commit(types.SET_ACCEPT_SMS_CREDIT, data);
export const receiveAcceptSmsCreditError = ({ commit }, error) => commit(types.GET_ACCEPT_SMS_CREDIT_ERROR, error);
export const fetchAcceptSmsCredit = ({ dispatch }) => {
  dispatch('requestAcceptSmsCredit');
  Api.post('sms_credit_logs')
    .then(({ data }) => { dispatch('receiveAcceptSmsCreditSuccess', data); })
    .catch(({ response }) => { dispatch('receiveAcceptSmsCreditError', response); });
};

export const requestNotAcceptSmsCredit = ({ commit }) => commit(types.GET_NOT_ACCEPT_SMS_CREDIT);
export const receiveNotAcceptSmsCreditSuccess = ({ commit }, data) => commit(types.SET_NOT_ACCEPT_SMS_CREDIT, data);
export const receiveNotAcceptSmsCreditError = ({ commit }, error) => commit(types.GET_NOT_ACCEPT_SMS_CREDIT_ERROR, error);
export const fetchNotAcceptSmsCredit = ({ dispatch }) => {
  dispatch('requestNotAcceptSmsCredit');
  Api.post('sms_credit_logs/sms_credit_install_not_accept')
    .then(({ data }) => { dispatch('receiveNotAcceptSmsCreditSuccess', data); })
    .catch(({ response }) => { dispatch('receiveNotAcceptSmsCreditError', response); });
};
