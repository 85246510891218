import moment from 'moment';
import router from '@/router';

export default (data) => {
  localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, `Bearer ${data.access_token}`);
  localStorage.setItem('session_start_time', moment());

  if (localStorage.getItem('redirect_url') !== null && localStorage.getItem('redirect_url').length > 0) {
    router.push(localStorage.getItem('redirect_url'));
    localStorage.removeItem('redirect_url');
  } else {
    router.push('/dashboard');
  }
};
