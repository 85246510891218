import Vue from 'vue';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';
import { backendDateFormat } from '../../../core/services/dates/dates.service';

export default {
  [types.GET_ROLES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_ROLES](state, data) {
    Vue.set(state, 'roles', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_ROLES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_USER](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_USER](state, data) {
    Vue.set(state, 'user', data.result.data[0]);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_USER_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'userError', getErrors(error)[0]);
  },

  [types.GET_CREATE_USER](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createUserProcessedSuccess', false);
    Vue.set(state, 'createUserProcessedError', false);
  },
  [types.SET_CREATE_USER](state, data) {
    Vue.set(state, 'user', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createUserProcessedSuccess', data.message);
  },
  [types.GET_CREATE_USER_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.code === 400 && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'createUserProcessedError', showingMessage);
    } else {
      Vue.set(state, 'createUserProcessedError', getErrors(error)[0]);
    }
  },

  [types.GET_UPDATE_USER](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateUserProcessedSuccess', false);
    Vue.set(state, 'updateUserProcessedError', false);
  },
  [types.SET_UPDATE_USER](state, data) {
    Vue.set(state, 'user', data.result.data);
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateUserProcessedSuccess', data.message);
  },
  [types.GET_UPDATE_USER_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    let showingMessage = null;
    if (error && error.data && error.data.code === 400 && error.data.result) {
      let messages = '';
      messages = Object.keys(error.data.result).map(el => error.data.result[el]);
      messages = messages.map(item => item.join());
      [showingMessage] = messages;
    }
    if (showingMessage) {
      Vue.set(state, 'updateUserProcessedError', showingMessage);
    } else {
      Vue.set(state, 'updateUserProcessedError', getErrors(error)[0]);
    }
  },

  [types.GET_CREATE_USER_AVATAR](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CREATE_USER_AVATAR](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_USER_AVATAR_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CREATE_SIGNATURE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CREATE_SIGNATURE](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_SIGNATURE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_SIGNATURE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_UPDATE_SIGNATURE](state) {
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_SIGNATURE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SOCIAL_MEDIA](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOCIAL_MEDIA](state, data) {
    Vue.set(state, 'socialMedia', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaError', getErrors(error)[0]);
  },

  [types.GET_SOCIAL_MEDIA_ACCOUNTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOCIAL_MEDIA_ACCOUNTS](state, data) {
    Vue.set(state, 'socialMediaAccounts', data.result.data);
    Vue.set(state, 'socialMediaAccountsTotal', data.result.meta.total);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_ACCOUNTS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaAccountsError', getErrors(error)[0]);
  },

  [types.GET_SOCIAL_MEDIA_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOCIAL_MEDIA_ACCOUNT](state, data) {
    Vue.set(state, 'socialMediaAccount', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaAccountError', getErrors(error)[0]);
  },

  [types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateSocialMediaAccountPageProcessed', true);
    Vue.set(state, 'updateSocialMediaAccountPageProcessedSuccess', false);
    Vue.set(state, 'updateSocialMediaAccountPageProcessedError', false);
  },
  [types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSocialMediaAccountPageProcessedSuccess', data.message);
  },
  [types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSocialMediaAccountPageProcessedError', getErrors(error)[0]);
  },

  [types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createSocialMediaPageProcessed', true);
    Vue.set(state, 'createSocialMediaPageProcessedSuccess', false);
    Vue.set(state, 'createSocialMediaPageProcessedError', false);
  },
  [types.SET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE](state, data) {
    Vue.set(state, 'createSocialMediaPageProcessedSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createSocialMediaPageProcessedError', getErrors(error)[0]);
  },

  [types.GET_DELETE_SOCIAL_MEDIA_PAGE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteSocialMediaPageProcessed', true);
    Vue.set(state, 'deleteSocialMediaPageProcessedSuccess', false);
    Vue.set(state, 'deleteSocialMediaPageProcessedError', false);
  },
  [types.SET_DELETE_SOCIAL_MEDIA_PAGE](state, data) {
    Vue.set(state, 'deleteSocialMediaPageProcessedSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DELETE_SOCIAL_MEDIA_PAGE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteSocialMediaPageProcessedError', getErrors(error)[0]);
  },

  [types.GET_DELETE_SOCIAL_MEDIA_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteSocialMediaAccountProcessed', true);
    Vue.set(state, 'deleteSocialMediaAccountProcessedSuccess', false);
    Vue.set(state, 'deleteSocialMediaAccountProcessedError', false);
  },
  [types.SET_DELETE_SOCIAL_MEDIA_ACCOUNT](state, data) {
    Vue.set(state, 'deleteSocialMediaAccountProcessedSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DELETE_SOCIAL_MEDIA_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteSocialMediaAccountProcessedError', getErrors(error)[0]);
  },

  [types.GET_SOCIAL_MEDIA_ACCOUNT_PAGES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOCIAL_MEDIA_ACCOUNT_PAGES](state, data) {
    Vue.set(state, 'socialMediaAccountPages', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaAccountPagesError', getErrors(error)[0]);
  },

  [types.GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'socialMediaAccountAllPages', null);
    Vue.set(state, 'socialMediaAccountPagesError', null);
  },
  [types.SET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES](state, data) {
    Vue.set(state, 'socialMediaAccountAllPages', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_ACCOUNT_ALL_PAGES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaAccountPagesError', getErrors(error)[0]);
  },

  [types.GET_SOCIAL_MEDIA_ACCOUNT_PAGE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SOCIAL_MEDIA_ACCOUNT_PAGE](state, data) {
    Vue.set(state, 'socialMediaAccountPage', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SOCIAL_MEDIA_ACCOUNT_PAGE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'socialMediaAccountPageError', getErrors(error)[0]);
  },

  [types.CREATE_SOCIAL_MEDIA_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createSocialMediaProcessed', true);
    Vue.set(state, 'createSocialMediaProcessedSuccess', false);
    Vue.set(state, 'createSocialMediaProcessedError', false);
  },
  [types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createSocialMediaProcessedError', getErrors(error)[0]);
  },
  [types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createSocialMediaProcessedSuccess', data.message);
  },

  [types.CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createSocialMediaAccountPagesProcessed', true);
    Vue.set(state, 'createSocialMediaAccountPagesProcessedSuccess', false);
    Vue.set(state, 'createSocialMediaAccountPagesProcessedError', false);
  },
  [types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createSocialMediaAccountPagesProcessedError', getErrors(error)[0]);
  },
  [types.GET_CREATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createSocialMediaAccountPagesProcessedSuccess', data.message);
  },

  [types.UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateSocialMediaAccountPagesProcessed', true);
    Vue.set(state, 'updateSocialMediaAccountPagesProcessedSuccess', false);
    Vue.set(state, 'updateSocialMediaAccountPagesProcessedError', false);
  },
  [types.GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_SUCCESS](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSocialMediaAccountPagesProcessedError', getErrors(error)[0]);
  },
  [types.GET_UPDATE_SOCIAL_MEDIA_ACCOUNT_PAGES_ERROR](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSocialMediaAccountPagesProcessedSuccess', data.message);
  },

  [types.UPDATE_SOCIAL_MEDIA_ACCOUNT](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateSocialMediaProcessed', true);
    Vue.set(state, 'updateSocialMediaProcessedSuccess', false);
    Vue.set(state, 'updateSocialMediaProcessedError', false);
  },
  [types.UPDATE_SOCIAL_MEDIA_ACCOUNT_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSocialMediaProcessed', false);
    Vue.set(state, 'updateSocialMediaProcessedError', getErrors(error)[0]);
  },
  [types.UPDATE_SOCIAL_MEDIA_ACCOUNT_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSocialMediaProcessed', false);
    Vue.set(state, 'updateSocialMediaProcessedSuccess', data.message);
  },

  [types.GET_AD_ACCOUNTS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_AD_ACCOUNTS](state, data) {
    Vue.set(state, 'accounts', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_AD_ACCOUNTS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_NOTIFICATION_TYPES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_NOTIFICATION_TYPES](state, data) {
    Vue.set(state, 'notificationTypes', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_NOTIFICATION_TYPES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_AUTOMATIONS](state) {
    Vue.set(state, 'automations', null);
  },
  [types.SET_AUTOMATIONS](state, data) {
    Vue.set(state, 'automations', data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_NOTIFICATION_RULE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_NOTIFICATION_RULE](state, data) {
    Vue.set(state, 'notificationRule', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_NOTIFICATION_RULE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_TRACKING_SITES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_TRACKING_SITES](state, data) {
    Vue.set(state, 'trackingSites', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_TRACKING_SITES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PERIOD](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PERIOD](state, data) {
    Vue.set(state, 'period', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PERIOD_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_NOTIFICATION_RULES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createRuleProcessed', true);
    Vue.set(state, 'createRuleProcessedSuccess', false);
    Vue.set(state, 'createRuleProcessedError', false);
  },
  [types.CREATE_NOTIFICATION_RULES_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createRuleProcessed', false);
    Vue.set(state, 'createRuleProcessedSuccess', data.message);
  },
  [types.CREATE_NOTIFICATION_RULES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createRuleProcessed', false);
    Vue.set(state, 'createRuleProcessedError', getErrors(error)[0]);
  },

  [types.UPDATE_AUTOMATIONS](state) {
    Vue.set(state, 'updateRuleProcessedSuccess', false);
    Vue.set(state, 'updateRuleProcessedError', false);
  },
  [types.UPDATE_AUTOMATIONS_SUCCESS](state, e) {
    Vue.set(state, 'updateRuleProcessedSuccess', e);
  },
  [types.UPDATE_AUTOMATIONS_ERROR](state, e) {
    Vue.set(state, 'updateRuleProcessedError', e);
  },

  [types.GET_NOTIFICATION_CHANNELS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_NOTIFICATION_CHANNELS](state, data) {
    Vue.set(state, 'notificationChannels', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_NOTIFICATION_CHANNELS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.DELETE_NOTIFICATION_RULE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteRuleProcessed', true);
    Vue.set(state, 'deleteRuleProcessedSuccess', false);
    Vue.set(state, 'deleteRuleProcessedError', false);
  },
  [types.DELETE_NOTIFICATION_RULE_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteRuleProcessed', false);
    Vue.set(state, 'deleteRuleProcessedSuccess', data.message);
  },
  [types.DELETE_NOTIFICATION_RULE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteRuleProcessed', false);
    Vue.set(state, 'deleteRuleProcessedError', getErrors(error)[0]);
  },

  [types.GET_BROWSER_NOTIFICATIONS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BROWSER_NOTIFICATIONS](state, data) {
    Vue.set(state, 'browserNotifications', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BROWSER_NOTIFICATIONS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_BROWSER_NOTIFICATIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateWebPushProcessed', true);
    Vue.set(state, 'updateWebPushProcessedSuccess', false);
    Vue.set(state, 'updateWebPushProcessedError', false);
  },
  [types.SET_UPDATE_BROWSER_NOTIFICATIONS](state, data) {
    Vue.set(state, 'updateWebPushProcessed', false);
    Vue.set(state, 'updateWebPushProcessedSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_BROWSER_NOTIFICATIONS_ERROR](state, error) {
    Vue.set(state, 'updateWebPushProcessed', false);
    Vue.set(state, 'updateWebPushProcessedError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BLOG_SETTINGS](state) {
    Vue.set(state, 'blogSettings', null);
    Vue.set(state, 'blogSettingsError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BLOG_SETTINGS](state, data) {
    Vue.set(state, 'blogSettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BLOG_SETTINGS_ERROR](state, error) {
    Vue.set(state, 'blogSettingsError', error);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_BLOG_SETTINGS](state) {
    Vue.set(state, 'updateBlogSettings', null);
    Vue.set(state, 'updateBlogSettingsError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_UPDATE_BLOG_SETTINGS](state, data) {
    Vue.set(state, 'updateBlogSettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_BLOG_SETTINGS_ERROR](state, error) {
    Vue.set(state, 'updateBlogSettingsError', error.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BLOG_MENUS](state) {
    Vue.set(state, 'blogMenus', null);
    Vue.set(state, 'blogMenusError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BLOG_MENUS](state, data) {
    Vue.set(state, 'blogMenus', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BLOG_MENUS_ERROR](state, error) {
    Vue.set(state, 'blogMenusError', error);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BLOG_MENU](state) {
    Vue.set(state, 'blogMenu', null);
    Vue.set(state, 'blogMenuError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BLOG_MENU](state, data) {
    Vue.set(state, 'blogMenu', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BLOG_MENU_ERROR](state, error) {
    Vue.set(state, 'blogMenuError', error);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CREATE_BLOG_MENU](state) {
    Vue.set(state, 'createBlogMenu', null);
    Vue.set(state, 'createBlogMenuError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CREATE_BLOG_MENU](state, data) {
    Vue.set(state, 'createBlogMenu', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_BLOG_MENU_ERROR](state, error) {
    Vue.set(state, 'createBlogMenuError', error.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_DELETE_BLOG_MENU](state) {
    Vue.set(state, 'deleteBlogMenu', null);
    Vue.set(state, 'deleteBlogMenuError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_DELETE_BLOG_MENU](state, data) {
    Vue.set(state, 'deleteBlogMenu', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_DELETE_BLOG_MENU_ERROR](state, error) {
    Vue.set(state, 'deleteBlogMenuError', error);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_BLOG_MENU](state) {
    Vue.set(state, 'updateBlogMenu', null);
    Vue.set(state, 'updateBlogMenuError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_UPDATE_BLOG_MENU](state, data) {
    Vue.set(state, 'updateBlogMenu', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_BLOG_MENU_ERROR](state, error) {
    Vue.set(state, 'updateBlogMenuError', error.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SORT_BLOG_MENU](state) {
    Vue.set(state, 'sortBlogMenu', null);
    Vue.set(state, 'sortBlogMenuError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SORT_BLOG_MENU](state, data) {
    Vue.set(state, 'sortBlogMenu', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SORT_BLOG_MENU_ERROR](state, error) {
    Vue.set(state, 'sortBlogMenuError', error);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SENDER_EMAILS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SENDER_EMAILS](state, data) {
    Vue.set(state, 'senderEmails', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SENDER_EMAILS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SENDER_EMAIL](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SENDER_EMAIL](state, data) {
    Vue.set(state, 'senderEmail', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SENDER_EMAIL_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_SENDER_SETTING](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createNewEmailSenderProcessed', true);
    Vue.set(state, 'createNewEmailSenderProcessedSuccess', false);
    Vue.set(state, 'createNewEmailSenderProcessedError', false);
  },
  [types.CREATE_SENDER_SETTING_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createNewEmailSenderProcessed', false);
    Vue.set(state, 'createNewEmailSenderProcessedError', getErrors(error)[0]);
  },
  [types.CREATE_SENDER_SETTING_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createNewEmailSenderProcessed', false);
    Vue.set(state, 'createNewEmailSenderProcessedSuccess', data.message);
  },

  [types.UPDATE_SENDER_SETTING](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateEmailSenderProcessed', true);
    Vue.set(state, 'updateEmailSenderProcessedSuccess', false);
    Vue.set(state, 'updateEmailSenderProcessedError', false);
  },
  [types.UPDATE_SENDER_SETTING_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateEmailSenderProcessed', false);
    Vue.set(state, 'updateEmailSenderProcessedError', getErrors(error)[0]);
  },
  [types.UPDATE_SENDER_SETTING_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateEmailSenderProcessed', false);
    Vue.set(state, 'updateEmailSenderProcessedSuccess', data.message);
  },

  [types.DELETE_SENDER_SETTING](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteEmailSenderProcessed', true);
    Vue.set(state, 'deleteEmailSenderProcessedSuccess', false);
    Vue.set(state, 'deleteEmailSenderProcessedError', false);
  },
  [types.DELETE_SENDER_SETTING_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteEmailSenderProcessed', false);
    Vue.set(state, 'deleteEmailSenderProcessedError', getErrors(error)[0]);
  },
  [types.DELETE_SENDER_SETTING_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteEmailSenderProcessed', false);
    Vue.set(state, 'deleteEmailSenderProcessedSuccess', data.message);
  },

  [types.GET_SENDER_SMS_SETTINGS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SENDER_SMS_SETTINGS](state, data) {
    Vue.set(state, 'senderSmsSettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SENDER_SMS_SETTINGS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SENDER_SMS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_SENDER_SMS](state, data) {
    Vue.set(state, 'senderSms', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SENDER_SMS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_SENDER_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createNewSmsSenderProcessed', true);
    Vue.set(state, 'createNewSmsSenderProcessedSuccess', false);
    Vue.set(state, 'createNewSmsSenderProcessedError', false);
  },
  [types.CREATE_SENDER_SMS_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createNewSmsSenderProcessed', false);
    Vue.set(state, 'createNewSmsSenderProcessedSuccess', data.message);
  },
  [types.CREATE_SENDER_SMS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createNewSmsSenderProcessed', false);
    Vue.set(state, 'createNewSmsSenderProcessedError', getErrors(error)[0]);
  },

  [types.UPDATE_SENDER_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateSmsSenderProcessed', true);
    Vue.set(state, 'updateSmsSenderProcessedSuccess', false);
    Vue.set(state, 'updateSmsSenderProcessedError', false);
  },
  [types.UPDATE_SENDER_SMS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSmsSenderProcessed', false);
    Vue.set(state, 'updateSmsSenderProcessedError', getErrors(error)[0]);
  },
  [types.UPDATE_SENDER_SMS_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateSmsSenderProcessed', false);
    Vue.set(state, 'updateSmsSenderProcessedSuccess', data.message);
  },

  [types.DELETE_SENDER_SMS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'deleteSmsSenderProcessed', true);
    Vue.set(state, 'deleteSmsSenderProcessedSuccess', false);
    Vue.set(state, 'deleteSmsSenderProcessedError', false);
  },
  [types.DELETE_SENDER_SMS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteSmsSenderProcessed', false);
    Vue.set(state, 'deleteSmsSenderProcessedError', getErrors(error)[0]);
  },
  [types.DELETE_SENDER_SMS_SUCCESS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'deleteSmsSenderProcessed', false);
    Vue.set(state, 'deleteSmsSenderProcessedSuccess', data.message);
  },

  [types.GET_COMPANIES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_COMPANIES](state, data) {
    Vue.set(state, 'companies', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_COMPANIES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_COMPANY_SETTINGS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_COMPANY_SETTINGS](state, data) {
    Vue.set(state, 'companySettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_COMPANY_SETTINGS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_UPDATE_COMPANIES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateCompaniesLogoProcessed', true);
    Vue.set(state, 'updateCompaniesLogoProcessedSuccess', false);
    Vue.set(state, 'updateCompaniesLogoProcessedError', false);
  },
  [types.SET_UPDATE_COMPANIES](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateCompaniesLogoProcessed', false);
    Vue.set(state, 'updateCompaniesLogoProcessedSuccess', data.message);
  },
  [types.GET_UPDATE_COMPANIES_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateCompaniesLogoProcessed', false);
    Vue.set(state, 'updateCompaniesLogoProcessedError', getErrors(error)[0]);
  },

  [types.GET_UPDATE_COMPANY_SETTINGS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateCompanySettingsProcessed', true);
    Vue.set(state, 'updateCompanySettingsProcessedSuccess', false);
    Vue.set(state, 'updateCompanySettingsProcessedError', false);
  },
  [types.SET_UPDATE_COMPANY_SETTINGS](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateCompanySettingsProcessed', false);
    Vue.set(state, 'updateCompanySettingsProcessedSuccess', data.message);
  },
  [types.GET_UPDATE_COMPANY_SETTINGS_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateCompanySettingsProcessed', false);
    Vue.set(state, 'updateCompanySettingsProcessedError', getErrors(error)[0]);
  },

  [types.GET_BAIT_SETTINGS_UPDATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateBaitSettingsProcessed', true);
    Vue.set(state, 'updateBaitSettingsProcessedSuccess', false);
    Vue.set(state, 'updateBaitSettingsProcessedError', false);
  },
  [types.SET_BAIT_SETTINGS_UPDATE](state, data) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateBaitSettingsProcessed', false);
    Vue.set(state, 'updateBaitSettingsProcessedSuccess', data.message);
  },
  [types.GET_BAIT_SETTINGS_UPDATE_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateBaitSettingsProcessed', false);
    Vue.set(state, 'updateBaitSettingsProcessedError', getErrors(error)[0]);
  },

  [types.GET_BAIT_SETTINGS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_SETTINGS](state, data) {
    Vue.set(state, 'defaultSettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_SETTINGS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BAIT_SETTINGS_SALES](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_SETTINGS_SALES](state, data) {
    Vue.set(state, 'defaultSettingsSales', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_SETTINGS_SALES_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BAIT_SETTINGS_LETTINGS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_SETTINGS_LETTINGS](state, data) {
    Vue.set(state, 'defaultSettingsLettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_SETTINGS_LETTINGS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID](state, data) {
    if (data.transaction_type_id_ === 1) {
      Vue.set(state, 'salesFaqs', data.result.data);
    } else if (data.transaction_type_id_ === 2) {
      Vue.set(state, 'lettingsFaqs', data.result.data);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_FAQS_BY_TRANSACTION_TYPE_ID_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BAIT_MARKETING_PLAN_OVERVIEW](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_BAIT_MARKETING_PLAN_OVERVIEW](state, data) {
    Vue.set(state, 'marketingPlanOverview', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_BAIT_MARKETING_PLAN_OVERVIEW_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateBaitMarketingPlanOverviewProcessed', true);
    Vue.set(state, 'updateBaitMarketingPlanOverviewProcessedSuccess', false);
    Vue.set(state, 'updateBaitMarketingPlanOverviewProcessedError', false);
    Vue.set(state, 'overviewMarketingPlanUpdateMessage', null);
  },
  [types.SET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW](state, data) {
    Vue.set(state, 'isLoading', false);
    if (data.code === 400) {
      Vue.set(state, 'overviewMarketingPlanUpdateMessage', data.message);
    }
    Vue.set(state, 'updateBaitMarketingPlanOverviewProcessed', false);
    Vue.set(state, 'updateBaitMarketingPlanOverviewProcessedSuccess', data);
  },
  [types.GET_BAIT_CREATE_MARKETING_PLAN_OVERVIEW_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateBaitMarketingPlanOverviewProcessed', false);
    Vue.set(state, 'updateBaitMarketingPlanOverviewProcessedError', getErrors(error)[0]);
  },

  [types.GET_CUSTOM_FIELD_GROUPS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CUSTOM_FIELD_GROUPS](state, data) {
    Vue.set(state, 'customFieldGroups', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CUSTOM_FIELD_GROUPS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'customFieldGroups', []);
  },

  [types.GET_UPDATE_CUSTOM_FIELD_GROUP](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateCustomFieldGroupSuccess', false);
    Vue.set(state, 'updateCustomFieldGroupError', false);
  },
  [types.SET_UPDATE_CUSTOM_FIELD_GROUP](state, data) {
    Vue.set(state, 'updateCustomFieldGroupSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_CUSTOM_FIELD_GROUP_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateCustomFieldGroupError', getErrors(error)[0]);
  },

  [types.GET_CREATE_CUSTOM_FIELD_GROUP](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createCustomFieldGroupSuccess', false);
    Vue.set(state, 'createCustomFieldGroupError', false);
  },
  [types.SET_CREATE_CUSTOM_FIELD_GROUP](state, data) {
    Vue.set(state, 'createCustomFieldGroupSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_CUSTOM_FIELD_GROUP_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createCustomFieldGroupError', getErrors(error)[0]);
  },

  [types.GET_CREATE_CUSTOM_FIELD](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createCustomFieldSuccess', false);
    Vue.set(state, 'createCustomFieldError', false);
  },
  [types.SET_CREATE_CUSTOM_FIELD](state, data) {
    Vue.set(state, 'createCustomFieldSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_CUSTOM_FIELD_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'createCustomFieldError', getErrors(error)[0]);
  },

  [types.GET_UPDATE_CUSTOM_FIELD](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateCustomFieldSuccess', false);
    Vue.set(state, 'updateCustomFieldError', false);
  },
  [types.SET_UPDATE_CUSTOM_FIELD](state, data) {
    Vue.set(state, 'updateCustomFieldSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_CUSTOM_FIELD_ERROR](state, error) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'updateCustomFieldError', getErrors(error)[0]);
  },

  [types.GET_PROPERTY_LIVE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PROPERTY_LIVE](state, data) {
    Vue.set(state, 'propertyLive', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PROPERTY_LIVE_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CHECK_SOCIAL_AUTH_STATUS](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_CHECK_SOCIAL_AUTH_STATUS](state, data) {
    Vue.set(state, 'socialAuthStatus', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CHECK_SOCIAL_AUTH_STATUS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_LOCATIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'locations', null);
  },
  [types.SET_LOCATIONS](state, data) {
    Vue.set(state, 'locations', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_LOCATIONS_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'locations', null);
  },

  [types.GET_CREATE_LOCATIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'createLocationSuccess', false);
    Vue.set(state, 'createLocationError', false);
  },
  [types.SET_CREATE_LOCATIONS](state, data) {
    Vue.set(state, 'createLocationSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_CREATE_LOCATIONS_ERROR](state, error) {
    Vue.set(state, 'createLocationError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_LOCATION](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'location', null);
  },
  [types.SET_LOCATION](state, data) {
    Vue.set(state, 'location', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_LOCATION_ERROR](state) {
    Vue.set(state, 'isLoading', false);
    Vue.set(state, 'location', null);
  },

  [types.GET_UPDATE_LOCATIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateLocationSuccess', false);
    Vue.set(state, 'updateLocationError', false);
  },
  [types.SET_UPDATE_LOCATIONS](state, data) {
    Vue.set(state, 'updateLocationSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_UPDATE_LOCATIONS_ERROR](state, error) {
    Vue.set(state, 'updateLocationError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_SORT_LOCATIONS](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'updateSortLocationSuccess', false);
    Vue.set(state, 'updateSortLocationError', false);
  },
  [types.SET_SORT_LOCATIONS](state, data) {
    Vue.set(state, 'updateSortLocationSuccess', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_SORT_LOCATIONS_ERROR](state, error) {
    Vue.set(state, 'updateSortLocationError', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },


  [types.GET_PROVIDER_SETTINGS](state) {
    Vue.set(state, 'providerSettings', null);
    Vue.set(state, 'providerSettingsError', null);
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PROVIDER_SETTINGS](state, data) {
    Vue.set(state, 'providerSettings', data.result.data);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_CREDIT_INFO](state, data) {
    Vue.set(state, 'creditInfo', data);
    Vue.set(state, 'creditFetchedTime', backendDateFormat(new Date(), true));
  },
};
