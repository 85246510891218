export default [
  {
    path: '/valuation_appointment',
    meta: {
      title: 'Create an MA Appointment',
      breadcrumb: [
        { name: 'Create an MA Appointment' },
      ],
    },
    name: 'CreateValuationAppointment',
    component: () => import('@/modules/valuation_appointment/views/ValuationAppointment.vue'),
    props: { update: false },
  },
  {
    path: '/valuation_appointment/:bait_id',
    meta: {
      title: 'Update an MA Appointment',
      breadcrumb: [
        { name: 'Update an MA Appointment' },
      ],
    },
    name: 'CreateValuationAppointmentWithData',
    component: () => import('@/modules/valuation_appointment/views/ValuationAppointment.vue'),
    props: { update: true },
  },
];
