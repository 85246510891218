import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestTransactionTypes = ({ commit }) => commit(types.GET_TRANSACTION_TYPES);
export const receiveTransactionTypesSuccess = ({ commit }, data) => commit(types.SET_TRANSACTION_TYPES, data);
export const receiveTransactionTypesError = ({ commit }, error) => commit(types.GET_TRANSACTION_TYPES_ERROR, error);
export const fetchTransactionTypes = ({ dispatch }) => {
  dispatch('requestTransactionTypes');
  Api.get('properties/transaction_types?__limit=250')
    .then(({ data }) => {
      dispatch('receiveTransactionTypesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveTransactionTypesError', response);
    });
};
