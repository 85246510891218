/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
/* Modules */
import Auth from '@/stores/modules/auth';
import Tags from '@/modules/tags/stores';
import Users from '@/modules/users/stores';
import Branches from '@/modules/branches/stores';
import Contacts from '@/modules/contacts/stores';
import Calendar from '@/modules/calendar/stores';
import PostCode from '@/modules/post_code/stores';
import Properties from '@/modules/properties/stores';
import Audiences from '@/modules/audiences/stores';
import Salutations from '@/modules/salutations/stores';
import PropertyTypes from '@/modules/property_types/stores';
import PropertyStyles from '@/modules/property_styles/stores';
import TransactionTypes from '@/modules/transaction_types/stores';
import CommunicationMethods from '@/modules/communication_methods/stores';
import SubscriptionTypes from '@/modules/subscription_types/stores';
import MailTemplates from '@/modules/mail_templates/stores';
import Sms from '@/modules/sms/stores';
import Blog from '@/modules/blog/stores';
import Csv from '@/modules/csv/stores';
import PropertyProfilePrices from '@/modules/property_profile_prices/stores';
import Bait from '@/modules/bait/stores';
import Request from '@/stores/modules/request';
import Settings from '@/modules/settings/stores';
import ValuationAppointment from '@/modules/valuation_appointment/stores';
import JourneyLists from '@/modules/journey_list/stores';
import MarketAppraisal from '@/modules/market_appraisal/stores';
import Common from '@/modules/common/stores';
import Dashboard from '@/modules/dashboard/stores';
import Marketing from '@/modules/marketing/stores';
import Stats from '@/modules/stats/stores';
import Forms from '@/modules/forms/stores';
import OnlineValuations from '@/modules/online_valuations/stores';
import SocialMediaShare from '@/modules/social_media_share/stores';
import Campaigns from '@/modules/campaigns/stores';
import BulkSms from '@/modules/bulk_sms/stores';
import BulkMails from '@/modules/bulk_mail/stores';
import E_signatures from '@/modules/e_signatures/stores';
import Shortener from '@/modules/shortener/stores';
import SendMailAndSms from '@/modules/send_mail_and_sms/stores';
import Offers from '@/modules/offers/stores';
import Suppliers from '@/modules/suppliers/stores';
import Tasks from '@/modules/tasks/stores';
import Uzair from '@/modules/uzair/stores';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    Auth,
    Tags,
    Users,
    Branches,
    Contacts,
    Calendar,
    PostCode,
    Properties,
    Audiences,
    Salutations,
    PropertyTypes,
    PropertyStyles,
    TransactionTypes,
    CommunicationMethods,
    SubscriptionTypes,
    MailTemplates,
    Sms,
    Blog,
    Csv,
    PropertyProfilePrices,
    Bait,
    Request,
    Settings,
    ValuationAppointment,
    JourneyLists,
    MarketAppraisal,
    Common,
    Dashboard,
    Marketing,
    Stats,
    Forms,
    OnlineValuations,
    SocialMediaShare,
    Campaigns,
    BulkSms,
    BulkMails,
    E_signatures,
    Shortener,
    SendMailAndSms,
    Offers,
    Suppliers,
    Tasks,
    Uzair,
  },
});
