export default {
  error: null,
  contact: null,
  properties: null,
  property: null,
  propertyProfile: null,
  propertyProfileError: null,
  propertyPrices: null,
  exportProperties: null,
  isLoading: false,
  elasticResults: null,
  contactSources: null,
  contactLeadSources: null,
  selectedProperties: [],
  search: null,
  revokePropertyContactSuccess: null,
  revokePropertyContactError: null,
  addPropertyProfileContactSuccess: null,
  addPropertyProfileContactError: null,
  baitModuleEntries: null,
  baitModuleProcessed: false,
  baitModuleProcessedSuccess: false,
  baitModuleProcessedError: false,
  propertyProfileContactSuccess: null,
  propertyProfileContactError: null,
  propertyTypes: [],
  propertyTypesError: null,
  propertyTypeStyles: [],
  propertyTypeStylesError: null,
  totalProperties: 0,
  galleryFiles: null,
  getCompanyInfoSuccess: null,
  getCompanyInfoError: null,
  contacts: null,
  contactsError: null,
  galleryPhotos: null,
  tenureTypes: [],
  tenureTypesError: null,
  totalAwaitingValuations: 0,
  awaitingValuations: null,
  propertyInformationProcessed: false,
  propertyInformationSuccess: null,
  propertyInformationError: null,
  propertyUpdateProcessed: false,
  propertyUpdateSuccess: null,
  propertyUpdateError: null,
  propertyProfileUpdateProcessed: false,
  propertyProfileUpdateSuccess: null,
  propertyProfileUpdateError: null,
  parkingTypes: ['None', 'Garage', 'Carport', 'Permit Parking', 'Double Garage', 'Drive', 'Secure Gated Parking', 'Triple Garage', 'Street Parking', 'Allocated Parking'],
  ageTypes: ['Not Specified', 'New Build', 'Modern', '1980s to 1990s', '1950s, 1960s and 1970s', '1940s', '1920s to 1930s', 'Edwardian (1901 - 1910)', 'Victorian (1837 - 1901)', 'Georgian (1714 - 1830)', 'Pre 18th Century'],
  landTypes: ['Not Specified', 'Less Than 1 Acre', '1-2 Acres', '2-3 Acres', '4-5 Acres', '5+ Acres'],
  internalAreaTypes: ['0 - 100', '100 - 500', '500 - 1000', '1000 - 2000', '2000 - 3000', '3000 - 4000', '4000+'],
  letRentFrequencyTypes: [{ id: 1, label: 'Weekly' }, { id: 2, label: 'Monthly' }, { id: 3, label: 'Quarterly' }, { id: 4, label: 'Annual' }],
  propertyProfileTimelineData: null,
  totalPropertyProfileTimelineData: null,
  propertyProfileTimelineDataError: null,
  relatedPropertyProfiles: null,
  relatedPropertyProfilesError: null,
  propertyProfileLive: false,
  propertyProfileLiveError: false,
  propertyPhotos: null,
  prices: [
    { value: 50000, display: '£50,000' },
    { value: 60000, display: '£60,000' },
    { value: 70000, display: '£70,000' },
    { value: 80000, display: '£80,000' },
    { value: 90000, display: '£90,000' },
    { value: 100000, display: '£100,000' },
    { value: 110000, display: '£110,000' },
    { value: 120000, display: '£120,000' },
    { value: 125000, display: '£125,000' },
    { value: 130000, display: '£130,000' },
    { value: 140000, display: '£140,000' },
    { value: 150000, display: '£150,000' },
    { value: 160000, display: '£160,000' },
    { value: 170000, display: '£170,000' },
    { value: 175000, display: '£175,000' },
    { value: 180000, display: '£180,000' },
    { value: 190000, display: '£190,000' },
    { value: 200000, display: '£200,000' },
    { value: 210000, display: '£210,000' },
    { value: 220000, display: '£220,000' },
    { value: 230000, display: '£230,000' },
    { value: 240000, display: '£240,000' },
    { value: 250000, display: '£250,000' },
    { value: 260000, display: '£260,000' },
    { value: 270000, display: '£270,000' },
    { value: 280000, display: '£280,000' },
    { value: 290000, display: '£290,000' },
    { value: 300000, display: '£300,000' },
    { value: 325000, display: '£325,000' },
    { value: 350000, display: '£350,000' },
    { value: 375000, display: '£375,000' },
    { value: 400000, display: '£400,000' },
    { value: 425000, display: '£425,000' },
    { value: 450000, display: '£450,000' },
    { value: 475000, display: '£475,000' },
    { value: 500000, display: '£500,000' },
    { value: 550000, display: '£550,000' },
    { value: 600000, display: '£600,000' },
    { value: 650000, display: '£650,000' },
    { value: 700000, display: '£700,000' },
    { value: 800000, display: '£800,000' },
    { value: 900000, display: '£900,000' },
    { value: 1000000, display: '£1,000,000' },
    { value: 1250000, display: '£1,250,000' },
    { value: 1500000, display: '£1,500,000' },
    { value: 1750000, display: '£1,750,000' },
    { value: 2000000, display: '£2,000,000' },
    { value: 2500000, display: '£2,500,000' },
    { value: 3000000, display: '£3,000,000' },
    { value: 4000000, display: '£4,000,000' },
    { value: 5000000, display: '£5,000,000' },
    { value: 7500000, display: '£7,500,000' },
    { value: 10000000, display: '£10,000,000' },
    { value: 15000000, display: '£15,000,000' },
    { value: 20000000, display: '£20,000,000' },
  ],
  lettingsPrices: [
    {
      value: 100,
      display: '£100 PCM',
    },
    {
      value: 150,
      display: '£150 PCM',
    },
    {
      value: 200,
      display: '£200 PCM',
    },
    {
      value: 250,
      display: '£250 PCM',
    },
    {
      value: 300,
      display: '£300 PCM',
    },
    {
      value: 350,
      display: '£350 PCM',
    },
    {
      value: 400,
      display: '£400 PCM',
    },
    {
      value: 450,
      display: '£450 PCM',
    },
    {
      value: 500,
      display: '£500 PCM',
    },
    {
      value: 600,
      display: '£600 PCM',
    },
    {
      value: 700,
      display: '£700 PCM',
    },
    {
      value: 800,
      display: '£800 PCM',
    },
    {
      value: 900,
      display: '£900 PCM',
    },
    {
      value: 1000,
      display: '£1,000 PCM',
    },
    {
      value: 1100,
      display: '£1,100 PCM',
    },
    {
      value: 1200,
      display: '£1,200 PCM',
    },
    {
      value: 1250,
      display: '£1,250 PCM',
    },
    {
      value: 1300,
      display: '£1,300 PCM',
    },
    {
      value: 1400,
      display: '£1,400 PCM',
    },
    {
      value: 1500,
      display: '£1,500 PCM',
    },
    {
      value: 1750,
      display: '£1,750 PCM',
    },
    {
      value: 2000,
      display: '£2,000 PCM',
    },
    {
      value: 2250,
      display: '£2,250 PCM',
    },
    {
      value: 2500,
      display: '£2,500 PCM',
    },
    {
      value: 2750,
      display: '£2,750 PCM',
    },
    {
      value: 3000,
      display: '£3,000 PCM',
    },
    {
      value: 3500,
      display: '£3,500 PCM',
    },
    {
      value: 4000,
      display: '£4,000 PCM',
    },
    {
      value: 4500,
      display: '£4,500 PCM',
    },
    {
      value: 5000,
      display: '£5,000 PCM',
    },
    {
      value: 5500,
      display: '£5,500 PCM',
    },
    {
      value: 6000,
      display: '£6,000 PCM',
    },
    {
      value: 6500,
      display: '£6,500 PCM',
    },
    {
      value: 7000,
      display: '£7,000 PCM',
    },
    {
      value: 8000,
      display: '£8,000 PCM',
    },
    {
      value: 9000,
      display: '£9,000 PCM',
    },
    {
      value: 10000,
      display: '£10,000 PCM',
    },
    {
      value: 12500,
      display: '£12,500 PCM',
    },
    {
      value: 15000,
      display: '£15,000 PCM',
    },
    {
      value: 17500,
      display: '£17,500 PCM',
    },
    {
      value: 20000,
      display: '£20,000 PCM',
    },
    {
      value: 25000,
      display: '£25,000 PCM',
    },
    {
      value: 30000,
      display: '£30,000 PCM',
    },
    {
      value: 35000,
      display: '£35,000 PCM',
    },
    {
      value: 40000,
      display: '£40,000 PCM',
    },
  ],
  preferences: [
    { value: 0, display: 'Studio' },
    { value: 1, display: '1' },
    { value: 2, display: '2' },
    { value: 3, display: '3' },
    { value: 4, display: '4' },
    { value: 5, display: '5' },
  ],
  offer_statuses: [
    { offer_status_id: 2, name: 'Offer Accepted' },
    { offer_status_id: 3, name: 'Offer Rejected' },
    { offer_status_id: 4, name: 'Offer Withdrawn' },
  ],
  priceQualifiers: null,
  lastPropertyTimelineData: null,
  activitiesCrmNotes: null,
  sendCrmNotesSuccess: null,
  sendCrmNotesError: null,
  propertyKeyTimelineList: {},
};
