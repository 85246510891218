export default {
  error: null,
  succcess: null,
  mail_templates_list: null,
  total_mail_templates_list: null,
  isLoading: false,
  companysDefaultMailTemplate: null,
  companysDefaultMailTemplateError: null,
  preview_template: null,
  error_preview_template: null,
  del_mail_temp: null,
  del_mail_temp_success: null,
  del_mail_temp_error: null,
  clone_mail_temp: null,
  clone_mail_temp_success: null,
  clone_mail_temp_error: null,
  create_mail_temp: null,
  create_mail_temp_success: null,
  create_mail_temp_error: null,
  request_mail_temp: null,
  request_mail_temp_success: null,
  request_mail_temp_error: null,
  update_mail_temp: null,
  update_mail_temp_success: null,
  update_mail_temp_error: null,
  newEditSession: false,
  newEditSessionError: false,
  emailTemplates: false,
};
