export const bait = state => state.bait;
export const baitStatuses = state => state.baitStatuses;
export const updatingBaitStatusSuccess = state => state.updatingBaitStatusSuccess;
export const updatingBaitStatusError = state => state.updatingBaitStatusError;
export const lengthOfAgreements = state => state.lengthOfAgreements;
export const lengthOfAgreement = state => state.lengthOfAgreement;
export const questionsForm = (state) => {
  if (state.defaultSettings !== null) {
    return state.defaultSettings.find(item => item.key === 'bait_questions_form_id');
  }
  return null;
};
export const baitFormAnswer = state => state.baitFormAnswer;
export const appointmentMailTemplateSales = (state) => {
  if (state.defaultSettings !== null) {
    return state.defaultSettings.find(
      item => item.key === 'appointment_mail_template' && item.transaction_type_id === 1,
    );
  }
  return null;
};
export const appointmentMailTemplateLettings = (state) => {
  if (state.defaultSettings !== null) {
    return state.defaultSettings.find(
      item => item.key === 'appointment_mail_template' && item.transaction_type_id === 2,
    );
  }
  return null;
};

export const baitMailTemplateSales = (state) => {
  if (state.defaultSettings !== null) {
    return state.defaultSettings.find(
      item => item.key === 'bait_mail_template' && item.transaction_type_id === 1,
    );
  }
  return null;
};

export const baitMailTemplateLettings = (state) => {
  if (state.defaultSettings !== null) {
    return state.defaultSettings.find(
      item => item.key === 'bait_mail_template' && item.transaction_type_id === 2,
    );
  }
  return null;
};

export const fees = state => state.fees;
export const fee = state => state.fee;
export const defaultSettings = state => state.defaultSettings;
export const sendAppointmentMailSuccess = state => state.sendAppointmentMailSuccess;
export const sendAppointmentMailError = state => state.sendAppointmentMailError;
export const updateBaitSuccess = state => state.updateBaitSuccess;
export const updateBaitError = state => state.updateBaitError;
export const updateLengthOfAgreementSuccess = state => state.updateLengthOfAgreementSuccess;
export const updateLengthOfAgreementError = state => state.updateLengthOfAgreementError;
