export default [{
  name: 'AddingProperty',
  path: 'add_property',
  meta: {
    title: 'Add Property',
    breadcrumb: [
      { name: 'Add Property' },
    ],
  },
  component: () => import('../views/AddingProperty.vue'),
}];
