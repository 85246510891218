export default [
  {
    name: 'Properties',
    path: '/properties',
    meta: {
      title: 'Properties',
      breadcrumb: [
        { name: 'Properties' },
      ],
    },
    props: { type: 'property_profiles', filterActive: true },
    component: () => import('../views/Properties.vue'),
  },
  {
    name: 'Property Category',
    path: '/properties/:category',
    meta: {
      title: 'Property List',
      breadcrumb: [
        { name: 'Properties', link: '/properties' },
        { name: 'Property List' },
      ],
    },
    props: { type: 'property_profiles', filterActive: true },
    component: () => import('../components/properties/PropertyList.vue'),
  },
  {
    name: 'Property Import',
    path: '/property_import',
    meta: {
      title: 'Property Import',
      breadcrumb: [
        { name: 'Property Import' },
      ],
    },
    component: () => import('../components/property_import/ThePropertyImport.vue'),
  },
  {
    name: 'Bulk Import',
    path: '/property_import/bulk_import',
    meta: {
      title: 'Property',
      breadcrumb: [
        { name: 'Property Import', link: '/property_import' },
        { name: 'Bulk Import' },
      ],
    },
    component: () => import('../components/property_import/bulk_import/TheBulkImport.vue'),
  },
  {
    name: 'Market Appraisal',
    path: '/property_import/ma_import',
    meta: {
      title: 'Property',
      breadcrumb: [
        { name: 'Property Import', link: '/property_import' },
        { name: 'Manual Import' },
      ],
    },
    component: () => import('../components/property_import/manual_import/TheManualImport.vue'),
  },
  {
    name: 'Instruction Property',
    path: '/property_import/instruction_import',
    meta: {
      title: 'Property',
      breadcrumb: [
        { name: 'Property Import', link: '/property_import' },
        { name: 'Manual Import' },
      ],
    },
    component: () => import('../components/property_import/manual_import/TheManualImport.vue'),
  },
  {
    name: 'Manual Completion',
    path: '/property_import/completion',
    meta: {
      title: 'Property Import',
    },
    component: () => import('../components/property_import/ConfirmCreation.vue'),
  },
  {
    name: 'Bulk Completion',
    path: '/property_import/completion',
    component: () => import('../components/property_import/ConfirmCreation.vue'),
  },
  {
    name: 'AwaitingValuations',
    path: '/awaiting_valuations',
    meta: {
      title: 'MA\'s Not Yet Sent',
      breadcrumb: [
        { name: 'MA\'s Not Yet Sent' },
      ],
    },
    props: { search_type: 'MA Not Yet Sent' },
    component: () => import('../views/Properties.vue'),
  },
  {
    name: 'PropertyProfile',
    path: '/property_profile/:property_profile_id',
    meta: {
      title: 'Property Profile',
      breadcrumb: [
        { name: 'Property Profile' },
      ],
    },
    component: () => import('../views/PropertyProfile.vue'),
  },

];
