import Api from '@/libraries/api';
import * as types from './mutation_types';

export const requestPropertyStyles = ({ commit }) => commit(types.GET_PROPERTY_STYLES);
export const receivePropertyStylesSuccess = ({ commit }, data) => commit(types.SET_PROPERTY_STYLES, data);
export const receivePropertyStylesError = ({ commit }, error) => commit(types.GET_PROPERTY_STYLES_ERROR, error);
export const fetchPropertyStyles = ({ dispatch }) => {
  dispatch('requestPropertyStyles');
  Api.get('properties/property_styles?__limit=250')
    .then(({ data }) => {
      dispatch('receivePropertyStylesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receivePropertyStylesError', response);
    });
};

export const requestAllPropertyStyles = ({ commit }) => commit(types.GET_ALL_PROPERTY_STYLES);
export const receiveAllPropertyStylesSuccess = ({ commit }, data) => commit(types.SET_ALL_PROPERTY_STYLES, data);
export const receiveAllPropertyStylesError = ({ commit }, error) => commit(types.GET_ALL_PROPERTY_STYLES_ERROR, error);
export const fetchAllPropertyStyles = ({ dispatch }) => {
  dispatch('requestAllPropertyStyles');
  Api.get('properties/property_styles?__limit=250')
    .then(({ data }) => {
      dispatch('receiveAllPropertyStylesSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveAllPropertyStylesError', response);
    });
};
