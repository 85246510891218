import ApiService from '@/core/api/api.service';


// eslint-disable-next-line import/prefer-default-export
export class ValuationAppointmentServices extends ApiService {
  async getCommonTypes(id) {
    this.url = `properties/common_types?transaction_type_id=${id}&group=LIVE&__order_by=name`;
    const { result } = await this.get();
    return result;
  }

  async createAppointment(payload) {
    if (payload.source_name) {
      this.url = 'calendar/events/create_sdv_or_completion_tool';
    } else {
      this.url = 'calendar/events/valuation_appointment_light';
    }
    this.payload = payload;
    return this.baseRemitter('post', 'data');
  }
}
