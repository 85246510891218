export default [{
  name: 'Audiences',
  path: '/audiences',
  meta: {
    title: 'Audiences',
    breadcrumb: [
      { name: 'Marketing & Autopilot', link: '/marketing' },
      { name: 'Audiences' },
    ],
  },
  component: () => import('../views/Audiences.vue'),
},
{
  name: 'AudienceDetail',
  path: '/audience_detail/:mail_list_id',
  meta: {
    title: 'Audience Detail',
    breadcrumb: [
      { name: 'Audiences', link: '/audiences' },
      { name: 'Audience Detail' },
    ],
  },
  component: () => import('../views/AudienceDetail.vue'),
},
{
  name: 'DynamicAudienceBuilderUpdate',
  path: '/dynamic_audience_builder/:mail_list_id',
  meta: {
    title: 'Update Dynamic Audience',
    breadcrumb: [
      { name: 'Audiences', link: '/audiences' },
      { name: 'Update Dynamic Audience' },
    ],
  },
  component: () => import('../views/DynamicAudienceBuilder.vue'),
},
{
  name: 'DynamicAudienceBuilderResultPreview',
  path: '/dynamic_audience_builder_result_preview',
  meta: {
    title: 'Advanced Search Result Preview',
    breadcrumb: [
      { name: 'Advanced Search Result Preview' },
    ],
  },
  props: {
    contactListType: 'audiencePreview',
    kind: 'audiencePreview',
    externalConfig: {
      user: {
        use: false,
      },
    },
    filters: [],
  },
  component: () => import('../../contacts/views/Contacts.vue'),
},
{
  name: 'DynamicAudienceBuilder',
  path: '/dynamic_audience_builder',
  meta: {
    title: 'Create Dynamic Audience',
    breadcrumb: [
      { name: 'Audiences', link: '/audiences' },
      { name: 'Create Dynamic Audience' },
    ],
  },
  component: () => import('../views/DynamicAudienceBuilder.vue'),
},
];
