import Api from '@/libraries/api';
import * as types from './mutation_types';


export const requestOnlineValuationDetail = ({ commit }) => commit(types.GET_ONLINE_VALUATION_DETAIL);
export const receiveOnlineValuationDetailSuccess = ({ commit }, data) => commit(types.SET_ONLINE_VALUATION_DETAIL, data);
export const receiveOnlineValuationDetailError = ({ commit }, data) => commit(types.GET_ONLINE_VALUATION_DETAIL_ERROR, data);
export const fetchOnlineValuationDetail = ({ dispatch }, payload) => {
  dispatch('requestOnlineValuationDetail');
  Api.get(`leads/online_valuations/${payload.form_entry_id}`)
    .then(({ data }) => {
      dispatch('receiveOnlineValuationDetailSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveOnlineValuationDetailError', response);
    });
};

export const requestConvertOnlineValuationToMa = ({ commit }) => commit(types.GET_CONVERT_ONLINE_VALUATION_TO_MA);
export const receiveConvertOnlineValuationToMaSuccess = ({ commit }, data) => commit(types.SET_CONVERT_ONLINE_VALUATION_TO_MA, data);
export const receiveConvertOnlineValuationToMaError = ({ commit }, data) => commit(types.GET_CONVERT_ONLINE_VALUATION_TO_MA_ERROR, data);
export const convertOnlineValuationToMa = ({ dispatch }, payload) => {
  dispatch('requestConvertOnlineValuationToMa');
  Api.post(`leads/online_valuations/${payload.form_entry_id}`, payload)
    .then(({ data }) => {
      dispatch('receiveConvertOnlineValuationToMaSuccess', data);
    })
    .catch(({ response }) => {
      dispatch('receiveConvertOnlineValuationToMaError', response);
    });
};
