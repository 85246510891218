export default [
  {
    path: '/email_templates',
    meta: {
      title: 'Email Templates',
      breadcrumb: [
        { name: 'Marketing & Autopilot', link: '/marketing' },
        { name: 'Email Templates' },
      ],
    },
    name: 'EmailTemplates',
    component: () => import('@/modules/mail_templates/views/EmailTemplates.vue'),
  },
  {
    path: '/email_templates/email_builder/:email_template_id',
    meta: {
      title: 'Email Template Builder',
      breadcrumb: [
        { name: 'Email Templates', link: '/email_templates' },
        { name: 'Email Template Builder' },
      ],
    },
    name: 'EmailTemplateBuilder',
    component: () => import('@/modules/mail_templates/views/EmailTemplateBuilder.vue'),
  },
];
