export const GET_SENT_CAMPAIGNS = 'GET_SENT_CAMPAIGNS';
export const SET_SENT_CAMPAIGNS = 'SET_SENT_CAMPAIGNS';
export const GET_SENT_CAMPAIGNS_ERROR = 'GET_SENT_CAMPAIGNS_ERROR';

export const GET_SENT_CAMPAIGN_DETAIL = 'GET_SENT_CAMPAIGN_DETAIL';
export const SET_SENT_CAMPAIGN_DETAIL = 'SET_SENT_CAMPAIGN_DETAIL';
export const GET_SENT_CAMPAIGN_DETAIL_ERROR = 'GET_SENT_CAMPAIGN_DETAIL_ERROR';

export const GET_SENT_CAMPAIGN_DETAIL_LIST = 'GET_SENT_CAMPAIGN_DETAIL_LIST';
export const SET_SENT_CAMPAIGN_DETAIL_LIST = 'SET_SENT_CAMPAIGN_DETAIL_LIST';
export const GET_SENT_CAMPAIGN_DETAIL_LIST_ERROR = 'GET_SENT_CAMPAIGN_DETAIL_LIST_ERROR';
export const GET_RESET_SENT_CAMPAIGN_DETAIL_LIST = 'GET_RESET_SENT_CAMPAIGN_DETAIL_LIST';

export const GET_SENT_CAMPAIGN_EMAIL_TEMPLATE = 'GET_SENT_CAMPAIGN_EMAIL_TEMPLATE';
export const SET_SENT_CAMPAIGN_EMAIL_TEMPLATE = 'SET_SENT_CAMPAIGN_EMAIL_TEMPLATE';
export const GET_SENT_CAMPAIGN_EMAIL_TEMPLATE_ERROR = 'GET_SENT_CAMPAIGN_EMAIL_TEMPLATE_ERROR';

export const GET_NEXT_MONTH_CAMPAIGN_BLOGS = 'GET_NEXT_MONTH_CAMPAIGN_BLOGS';
export const SET_NEXT_MONTH_CAMPAIGN_BLOGS = 'SET_NEXT_MONTH_CAMPAIGN_BLOGS';
export const GET_NEXT_MONTH_CAMPAIGN_BLOGS_ERROR = 'GET_NEXT_MONTH_CAMPAIGN_BLOGS_ERROR';

export const GET_REMOVE_BLOG_FROM_CAMPAIGN = 'GET_REMOVE_BLOG_FROM_CAMPAIGN';
export const SET_REMOVE_BLOG_FROM_CAMPAIGN = 'SET_REMOVE_BLOG_FROM_CAMPAIGN';
export const GET_REMOVE_BLOG_FROM_CAMPAIGN_ERROR = 'GET_REMOVE_BLOG_FROM_CAMPAIGN_ERROR';

export const GET_SORT_NEWSLETTER_BLOGS = 'GET_SORT_NEWSLETTER_BLOGS';
export const SET_SORT_NEWSLETTER_BLOGS = 'SET_SORT_NEWSLETTER_BLOGS';
export const GET_SORT_NEWSLETTER_BLOGS_ERROR = 'GET_SORT_NEWSLETTER_BLOGS_ERROR';

export const GET_SEND_NEWSLETTER_TEST_EMAIL = 'GET_SEND_NEWSLETTER_TEST_EMAIL';
export const SET_SEND_NEWSLETTER_TEST_EMAIL = 'SET_SEND_NEWSLETTER_TEST_EMAIL';
export const GET_SEND_NEWSLETTER_TEST_EMAIL_ERROR = 'GET_SEND_NEWSLETTER_TEST_EMAIL_ERROR';

export const GET_CAMPAIGN_SETTINGS = 'GET_CAMPAIGN_SETTINGS';
export const SET_CAMPAIGN_SETTINGS = 'SET_CAMPAIGN_SETTINGS';
export const GET_CAMPAIGN_SETTINGS_ERROR = 'GET_CAMPAIGN_SETTINGS_ERROR';
