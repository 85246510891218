import Vue from 'vue';
import { getErrors } from '@/mixins/ErrorManager';
import * as types from './mutation_types';

export default {
  [types.GET_MAIL_TEMPLATES](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'mail_templates_list', null);
  },
  [types.SET_MAIL_TEMPLATES](state, data) {
    Vue.set(state, 'mail_templates_list', data.result.data);
    if (data.result.meta.total > 0) {
      Vue.set(state, 'total_mail_templates_list', data.result.meta.total);
    } else {
      Vue.set(state, 'total_mail_templates_list', data.result.data[0].total_count);
    }
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_MAIL_TEMPLATES_ERROR](state, message) {
    Vue.set(state, 'error', message);
    Vue.set(state, 'isLoading', false);
  },


  [types.GET_E_MAIL_TEMPLATES](state) {
    Vue.set(state, 'emailTemplates', false);
  },
  [types.SET_E_MAIL_TEMPLATES](state, data) {
    Vue.set(state, 'emailTemplates', data);
  },

  [types.GET_COMPANYS_DEFAULT_MAIL_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'companysDefaultMailTemplate', null);
    Vue.set(state, 'companysDefaultMailTemplateError', null);
  },
  [types.SET_COMPANYS_DEFAULT_MAIL_TEMPLATE](state, data) {
    Vue.set(state, 'companysDefaultMailTemplate', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_COMPANYS_DEFAULT_MAIL_TEMPLATE_ERROR](state, error) {
    Vue.set(state, 'companysDefaultMailTemplateError', error.data.message);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_PREVIEW_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
  },
  [types.SET_PREVIEW_TEMPLATE](state, data) {
    Vue.set(state, 'preview_template', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_PREVIEW_TEMPLATE_ERROR](state, message) {
    Vue.set(state, 'error_preview_template', message);
    Vue.set(state, 'isLoading', false);
  },

  [types.DELETE_MAIL_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'del_mail_temp_success', null);
    Vue.set(state, 'del_mail_temp_error', null);
  },
  [types.DELETE_MAIL_TEMPLATE_SUCCESS](state, data) {
    Vue.set(state, 'del_mail_temp_success', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.DELETE_MAIL_TEMPLATE_ERROR](state, error) {
    Vue.set(state, 'del_mail_temp_error', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.CLONE_MAIL_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'clone_mail_temp_success', null);
    Vue.set(state, 'clone_mail_temp_error', null);
  },
  [types.CLONE_MAIL_TEMPLATE_SUCCESS](state, data) {
    Vue.set(state, 'clone_mail_temp_success', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.CLONE_MAIL_TEMPLATE_ERROR](state, error) {
    Vue.set(state, 'clone_mail_temp_error', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.CREATE_MAIL_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'create_mail_temp_success', null);
    Vue.set(state, 'create_mail_temp_error', null);
  },
  [types.CREATE_MAIL_TEMPLATE_SUCCESS](state, data) {
    Vue.set(state, 'create_mail_temp_success', data);
    Vue.set(state, 'isLoading', false);
  },
  [types.CREATE_MAIL_TEMPLATE_ERROR](state, error) {
    Vue.set(state, 'create_mail_temp_error', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_MAIL_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'request_mail_temp_success', null);
    Vue.set(state, 'request_mail_temp_error', null);
  },
  [types.GET_MAIL_TEMPLATE_SUCCESS](state, data) {
    Vue.set(state, 'request_mail_temp_success', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_MAIL_TEMPLATE_ERROR](state, error) {
    Vue.set(state, 'request_mail_temp_error', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.UPDATE_MAIL_TEMPLATE](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'update_mail_temp_success', null);
    Vue.set(state, 'update_mail_temp_error', null);
  },
  [types.UPDATE_MAIL_TEMPLATE_SUCCESS](state, data) {
    Vue.set(state, 'update_mail_temp_success', data.message);
    Vue.set(state, 'isLoading', false);
  },
  [types.UPDATE_MAIL_TEMPLATE_ERROR](state, error) {
    Vue.set(state, 'update_mail_temp_error', getErrors(error)[0]);
    Vue.set(state, 'isLoading', false);
  },

  [types.GET_NEW_EDIT_SESSION](state) {
    Vue.set(state, 'isLoading', true);
    Vue.set(state, 'newEditSession', false);
    Vue.set(state, 'newEditSessionError', false);
  },
  [types.SET_NEW_EDIT_SESSION_SUCCESS](state, data) {
    Vue.set(state, 'newEditSession', data.result.data);
    Vue.set(state, 'isLoading', false);
  },
  [types.GET_NEW_EDIT_SESSION_ERROR](state, message) {
    Vue.set(state, 'newEditSessionError', message);
    Vue.set(state, 'isLoading', false);
  },

};
