import ApiService from '@/core/api/api.service';


// eslint-disable-next-line import/prefer-default-export
export class PropertyProfileLiveServices extends ApiService {
  constructor() {
    super();
    this.url = 'lv/properties';
  }

  async show(id) {
    this.url = `lv/properties/property_profile/${id}`;
    const { result } = await this.get();
    return result;
  }

  async getAvailableServices(PropertyProfileId) {
    this.loader = false;
    this.url = `publish_properties/is_available/${PropertyProfileId}`;
    const { result } = await this.get();
    return result;
  }

  publishProperty(payload) {
    this.payload = payload.payload;
    this.url = `publish_properties/${payload.status}`;
    return this.baseRemitter('post', 'message');
  }

  publishPropertyOnWebsite(payload) {
    this.payload = payload;
    this.url = `property_profiles/${payload.property_profile.property_profile_id}`;
    return this.baseRemitter('patch', 'message');
  }


  create(payload) {
    this.payload = payload;
    return this.post();
  }
}
