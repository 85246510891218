export default {
  error: null,
  isLoading: null,
  contactAddresses: null,
  contactAddressesError: null,
  contactAddressDetails: null,
  evnetAddresses: null,
  evnetAddressDetails: null,
  propertyAddresses: null,
  propertyAddressesError: null,
  propertyAddressDetails: null,
  propertyAddressProcessedError: false,
  contactAddressDetail: null,
  propertyAddressDetail: null,
  contactAddressDetailError: null,
  propertyAddressDetailError: null,
  branchAddresses: null,
  branchAddressesError: null,
  branchAddressesDetail: null,
  branchAddressesDetailError: null,
};
