<template>
  <div id="app" class="ls-application ls-application--is-ltr">
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    isRemember() {
      return this.$store.state.Auth.is_remember;
    },
    ...mapGetters('Users', ['me']),
  },
};
</script>

<style lang="scss">
@import "core/styles/main.scss";
</style>
