export default {
  success: null,
  error: null,
  validate_errors: null,
  already_exists_data: null,
  contact: null,
  contacts: null,
  contactList: [],
  isLoading: false,
  leadSources: null,
  contactSources: null,
  selectedContacts: [],
  listedContacts: [],
  valuationContacts: [],
  lastCreatedContact: null,
  filenameupdate: null,
  exportContacts: null,
  profiles: null,
  contactLeadSources: null,
  message: null,
  smsSenders: null,
  lastSendSMS: null,
  contactSubscriptions: null,
  contactSubscriptionsSuccess: null,
  contactSubscriptionsError: null,
  formEntries: null,
  totalContacts: 0,
  stickyNote: null,
  stickyNoteisLoading: false,
  relatedProperties: null,
  contactMailLists: null,
  contactBlogReads: null,
  contactBlogReadsTotal: null,
  contactBlogReadsIsEnd: false,
  customFieldTypes: null,
  customFieldTypesError: null,
  customFieldGroups: null,
  customFieldGroupsError: null,
  searchedContacts: null,
  customFields: null,
  customFieldsError: null,
  insertTagToContactSuccess: null,
  insertTagToContactError: null,
  deleteTagFromContactSuccess: null,
  deleteTagFromContactError: null,
  insertTagToCompanyTags: null,
  activitiesCrmNotes: null,
  sendCrmNotesSuccess: null,
  lastContactTimelineData: null,
  lastCRMNote: null,
  totalCustomFields: null,
  contactTags: null,
  emailSenders: null,
  countries: null,
  baitStatusOfMarketAppraisal: null,
  baitStatusOfMarketAppraisalIsLoading: false,
  gdpr: null,
  activities: null,
  activitiesTotal: null,
  activitiesError: null,
  selectedActivitiesIds: [],
  autoCompleteIsLoading: null,
  contactFoundError: null,
  contactFiles: null,
  contactFilesError: null,
  uploadContactFileSuccess: null,
  uploadContactFileError: null,
  deleteContactFileSuccess: null,
  deleteContactFileError: null,
  updateContactFileSuccess: null,
  updateContactFileError: null,
  updateContactPhotoSuccess: null,
  updateContactPhotoError: null,
  sendEmailError: null,
  sendEmailSuccess: null,
  sendSMSError: null,
  sendSMSSuccess: null,
  setStickyNoteSuccess: null,
  setStickyNoteError: null,
  createCustomFieldSuccess: null,
  createCustomFieldError: null,
  deleteCustomFieldGroupSuccess: null,
  deleteCustomFieldGroupError: null,
  createCustomFieldGroupSuccess: null,
  createCustomFieldGroupError: null,
  updateCustomFieldSuccess: null,
  updateCustomFieldError: null,
  contactCompanySubscriptions: null,
  contactCompanySubscriptionsError: null,
  multipleContacts: [],
  multipleContactsError: null,
  createdContactInfo: null,
  createContactError: false,
  updateContactSuccess: null,
  updateContactError: null,
  contactTimelineData: null,
  totalContactTimelineData: null,
  contactTimelineDataError: null,
  activityDetail: null,
  activityStatus: null,
  isLoadingContacts: false,
  contactLeadData: false,
  contactLeadDataError: false,
  contactOnlineValuationData: false,
  contactOnlineValuationDataError: false,
  applicant: false,
  applicantQuestions: {
    buyer: null,
    tenant: null,
  },
  activitiesContentGroup: { historical_notes: [], task_notes: [] },
};
