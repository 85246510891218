export const contactAddress = state => state.contactAddress;
export const contactAddressDetails = state => state.contactAddressDetails;
export const propertyAddress = state => state.propertyAddress;
export const propertyAddressDetails = state => state.propertyAddressDetails;
export const eventAddress = state => state.eventAddress;
export const eventAddressDetails = state => state.eventAddressDetails;
export const selectedPropertyAddress = state => state.selectedPropertyAddress;
export const propertyAddressProcessedError = state => state.propertyAddressProcessedError;
export const branchAddresses = state => state.branchAddresses;
export const branchAddressesDetail = state => state.branchAddressesDetail;
