export default {
  isLoading: false,
  solnyList: null,
  solnyDetail: null,
  paperCopies: null,
  paperSizes: null,
  paperLaminations: null,
  paperTypes: null,
  paperWeights: null,
  solnyBrochure: null,
  orderPrices: null,
  sendOrderProcessed: false,
  sendOrderProcessedSuccess: false,
  sendOrderProcessedError: false,
  socialMediaAccounts: false,
  updateAdAccountSuccess: false,
  updateAdAccountError: false,
  adAccountDetail: false,
  connectedAudiencesWithAdAccount: false,
  connectedAudiencesWithAdAccountTotal: 0,
  connectAudienceWithAdAccountSuccess: false,
  connectAudienceWithAdAccountError: false,
  updateConnectAudienceWithAdAccountSuccess: false,
  updateConnectAudienceWithAdAccountError: false,
  deleteConnectionAudienceWithAdAccountSuccess: false,
  deleteConnectionAudienceWithAdAccountError: false,
};
