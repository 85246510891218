export default class TransactionTypesConstants {
  static SALES = 1;

  static LETTINGS = 2;

  static BOTH = 3;

  static SALES_ACTION = 'sale';

  static LETTINGS_ACTION = 'rent';

  static SALES_REQUESTER = 'buyer';

  static LETTINGS_REQUESTER = 'tenant';

  static SALES_SOLD = 'SSTC';

  static LETTINGS_RENT = 'Let Agreed';

  static PURE_TYPES = [
    {
      name: 'Sales',
      value: TransactionTypesConstants.SALES,
    },
    {
      name: 'Lettings',
      value: TransactionTypesConstants.LETTINGS,
    },
  ]

  static types = [
    ...TransactionTypesConstants.PURE_TYPES,
    {
      name: 'Misc.',
      value: TransactionTypesConstants.BOTH,
    },
  ]


  static computeType(id) {
    if (id === this.SALES) {
      return 'Sales';
    }
    if (id === this.LETTINGS) {
      return 'Lettings';
    }
    return 'Misc.';
  }

  static computeTypeName(id) {
    return TransactionTypesConstants.computeType(id).toLowerCase();
  }

  static computeTypeString(type) {
    if (type === this.SALES_REQUESTER) {
      return 'Sales';
    }
    if (type === this.LETTINGS_REQUESTER) {
      return 'Lettings';
    }
    return 'Misc.';
  }

  static computeRequesterTransaction(type = TransactionTypesConstants.SALES_REQUESTER) {
    if (type === TransactionTypesConstants.LETTINGS_REQUESTER) {
      return TransactionTypesConstants.LETTINGS_REQUESTER;
    }
    return TransactionTypesConstants.SALES_REQUESTER;
  }
}
