export default {
  error: null,
  isLoading: false,
  valuationAppointmentProcessSuccess: false,
  valuationAppointmentProcessError: false,
  updateValuationAppointmentProcessSuccess: false,
  updateValuationAppointmentProcessError: false,
  checkUserAvailabilitySuccess: null,
  checkUserAvailabilityError: null,
  commonTypesSales: null,
  commonTypesLettings: null,
};
